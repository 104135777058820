/**
 * DEPRECATED
 *
 * Values under systems will be migrated under the constants directory or
 * renamed or removed
 */
import { breakpoints } from './breakpoints'
import * as colors from './colors'
import { shadows } from './shadows'
import { spacing } from './spacing'
import { fontSizes, fontWeights, lineHeights, primaryFont } from './typography'

const defaultTheme = {
  breakpoints,
  colors: {
    primary: colors.purple,
    secondary: colors.gray,
    status: {
      error: colors.red[500],
    },
    ...colors,
  },
  fonts: [primaryFont],
  fontSizes,
  fontWeights,
  lineHeights,
  space: spacing,
  shadows,
}

const chakraThemeOverrides = {
  colors: defaultTheme.colors,
  fontSizes: defaultTheme.fontSizes,
  fontWeights: defaultTheme.fontWeights,
  lineHeights: defaultTheme.lineHeights,
}

export type Theme = typeof defaultTheme

export const themes = {
  default: defaultTheme,
  chakra: chakraThemeOverrides,
}
