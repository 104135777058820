import { analytics } from 'analytics'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { AVATARS } from 'mockdata'
import { TRADE_ACTION } from 'types'
import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { paveApi } from 'api'
import { Box } from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import FlexBox from 'components/common/FlexBox/FlexBox'
import {
  AccountAllSuggestedTradeActivitiesModal,
  AccountAllTasksModal,
  AccountAllocation,
  AccountEditClientsModal,
  AccountEditSettingsModal,
  AccountHeader,
  AccountPreferences,
  CreatePortfolioModal,
  EditAccountModal,
  PageLayout,
  PortfolioList,
  SuggestedTradeActivities,
} from 'components/partials'
import ConfirmDeleteGroupModal from 'components/partials/Account/ConfirmDeleteGroupModal'
import {
  GROUP_EDIT_DELETE_CANCELED,
  GROUP_EDIT_DELETE_CONFIRMED,
  GROUP_EDIT_DELETE_FAILED,
  GROUP_EDIT_DELETE_STARTED,
  GROUP_EDIT_DELETE_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { closeEditAccountSettings } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { SPACE } from 'styles/constants/space'

function AccountPage() {
  const navigate = useNavigate()
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const { externalId } = useParams()
  const {
    data: clientAccountResponse,
    isError,
    refetch,
    isFetching,
  } = paveApi.useGetClientAccountQuery(externalId || '', {
    skip: !externalId,
    refetchOnMountOrArgChange: true,
  })
  const { clientAccount } = clientAccountResponse || {
    clientAccount: {} as ClientAccountDto,
  }

  const { data } = paveApi.useGetClientAccountPortfoliosQuery(
    externalId || '',
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const { portfolios } = data || { portfolios: [] }
  const dispatch = useAppDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const [updateClientAccount, { isLoading }] =
    paveApi.useUpdateClientAccountMutation()
  const [deleteClientAccount] = paveApi.useDeleteClientAccountMutation()

  const onEditSettings = (editedClientAccount: Partial<ClientAccountDto>) => {
    updateClientAccount({ ...editedClientAccount, id: clientAccount?.id || '' })
      .unwrap()
      .then(() => {
        dispatch(closeEditAccountSettings())
        showSuccessToast('Group updated!')
      })
      .catch((e) => {
        const displayMessage = e?.data?.displayMessage
        if (displayMessage) {
          showErrorToast(displayMessage)
        } else {
          showErrorToast('An error occurred updating the group')
        }
      })
  }

  const onEditAccount = async (
    editedClientAccount: Partial<ClientAccountDto>
  ) => {
    updateClientAccount({
      ...editedClientAccount,
      id: clientAccount?.id || '',
    })
      .unwrap()
      .then(() => {
        return refetch()
      })
  }

  const onDeleteAccount = () => {
    analytics.track(GROUP_EDIT_DELETE_CONFIRMED)
    deleteClientAccount(clientAccount?.id || '')
      .unwrap()
      .then(() => {
        analytics.track(GROUP_EDIT_DELETE_SUCCESS)
        dispatch(closeEditAccountSettings())
        navigate('/')
        showSuccessToast('Group successfully deleted.')
      })
      .catch(() => {
        analytics.track(GROUP_EDIT_DELETE_FAILED)
        showErrorToast('Hmm, something went wrong')
      })
  }

  const openDeleteModal = () => {
    analytics.track(GROUP_EDIT_DELETE_STARTED)
    setShowDeleteModal(true)
  }

  const hideDeleteModal = () => {
    analytics.track(GROUP_EDIT_DELETE_CANCELED)
    setShowDeleteModal(false)
  }

  const activityGroups = portfolios
    ?.filter((p) => p.suggestedTrades.length > 0)
    .map((p) => {
      const sortedTrades = [...p.suggestedTrades].sort((a, b) => {
        // Sort by action: "buy" first, then "sell"
        if (a.action === TRADE_ACTION.BUY && b.action === TRADE_ACTION.SELL)
          return -1
        if (a.action === TRADE_ACTION.SELL && b.action === TRADE_ACTION.BUY)
          return 1

        // Sort by shares within the same action group
        return b.shares - a.shares // Descending order by shares
      })

      return {
        id: p.id,
        headerText: `${sortedTrades.length} suggested trades`,
        when: '',
        accountName: p.displayName,
        accountLogo: AVATARS[0],
        activities: sortedTrades.map((trade, index: number) => {
          return {
            id: index.toString(),
            leftText: `${trade.symbol}`,
            rightText: `${capitalize(trade.action)} ${trade.shares} shares`,
            link: `/asset/${trade.symbol}?portfolioId=${p.id}`,
          }
        }),
        count: sortedTrades.length,
        link: `/portfolio/${p.id}`,
      }
    })

  if (isError) {
    showErrorToast('An error occurred loading the account')
  }

  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: clientAccount?.displayName || 'Group Overview',
      route: `#`,
    },
  ]

  return (
    <PageLayout showLoader={isFetching}>
      <BreadcrumbBar paths={breadcrumbs} />
      <Box paddingY={SPACE.sp24} paddingX={SPACE.sp36} display="grid">
        <AccountHeader accountName={clientAccount?.displayName || ''} />
        <FlexBox gap={SPACE.sp24} marginTop={SPACE.sp24}>
          <FlexBox flexDirection="column" gap={SPACE.sp24} width="17.25rem">
            <AccountAllocation
              clientAccountId={clientAccount?.id || ''}
              totalPortfolios={portfolios?.length || 0}
            />
            <AccountPreferences account={clientAccount} />
          </FlexBox>
          <Box gridArea="portfolios" display="grid" gap={SPACE.sp24}>
            <SuggestedTradeActivities activities={activityGroups || []} />
            <PortfolioList portfolios={portfolios || []} />
          </Box>
        </FlexBox>
        <CreatePortfolioModal />
        <AccountAllTasksModal />
        <AccountEditSettingsModal
          account={clientAccount}
          isDeleteDisabled={portfolios.length !== 0}
          onDelete={openDeleteModal}
          onEditClientAccountSettings={onEditSettings}
          isLoading={isLoading}
        />
        <EditAccountModal
          account={clientAccount}
          onEditAccount={onEditAccount}
        />
        <AccountAllSuggestedTradeActivitiesModal
          activities={activityGroups || []}
        />
        <AccountEditClientsModal
          account={clientAccount}
          onEditClientAccountSettings={onEditSettings}
        />
        <ConfirmDeleteGroupModal
          hideModal={hideDeleteModal}
          onClick={onDeleteAccount}
          showModal={showDeleteModal}
        />
      </Box>
    </PageLayout>
  )
}

export default AccountPage
