import styled from '@emotion/styled'
import React from 'react'

import { Text } from 'components/common'
import { PageLayout } from 'components/partials'

function NotificationsPage() {
  return (
    <PageLayout>
      <Container>
        <Text fontSize="3xl" as="p">
          Coming Soon
        </Text>
        <Text as="p">
          See Pave's latest market insights and track upcoming activity within
          your portfolios.
        </Text>
      </Container>
    </PageLayout>
  )
}

const Container = styled.div`
  padding: 32px;
`

export default NotificationsPage
