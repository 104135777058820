import { AssetClass } from 'enums'
import { Portfolio, PortfolioLinkType } from 'types'

import { Box, Button, Separator, Text } from 'components/common'
import { AutomationPopOver } from 'components/partials'
import { strategySelectorOptions } from 'constants/general.constants'
import { portfolioFormOptions } from 'constants/portfolio.constants'

import { SelectedItemsPreference } from './SelectedItemsPreference'
import { UntoggleablePreference } from './UntoggleablePreference'
import { ValuePreference } from './ValuePreference'

type Props = {
  portfolio?: Portfolio
  handleEditPortfolio?: () => void
  disabled?: boolean
  linkType: PortfolioLinkType
}

function InvestmentPreferences({
  portfolio,
  handleEditPortfolio,
  disabled,
  linkType,
}: Props) {
  const {
    benchmark,
    assets,
    investmentPreference,
    isThirdPartyManaged,
    investmentPreferencesTemplate,
  } = portfolio || {}

  const { multiAssetClassStrategy, assetClass: portfolioAssetClass } =
    investmentPreference || {}

  const strategy = strategySelectorOptions.strategies.find(
    (option) => option.value === multiAssetClassStrategy
  )

  const assetClass = portfolioFormOptions.assetClassOptions.find(
    (option) => option.value === portfolioAssetClass
  )

  const requiredAssets =
    assets
      ?.filter((asset: any) => asset.tradablePercentage < 1)
      .map(({ symbol }: any) => symbol) || []

  return (
    <>
      <Separator color="coolGray.300" />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0px"
        gap="8px"
        width="100%"
      >
        <Text
          fontSize="sm"
          fontWeight="bold"
          lineHeight="xs"
          color="coolGray.500"
        >
          Investment Preferences
        </Text>
        <Button
          size="xs"
          variant="tertiary"
          onClick={handleEditPortfolio}
          disabled={disabled}
        >
          Edit
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="0px"
        gap="12px"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="12px"
          width="100%"
        >
          <ValuePreference
            preference="Template"
            value={investmentPreferencesTemplate?.displayName ?? 'None'}
          />

          {!isThirdPartyManaged && (
            <>
              {linkType !== PortfolioLinkType.None && (
                <>
                  <UntoggleablePreference
                    preference="Automation"
                    isEnabled={investmentPreference?.automation}
                    tooltip={<AutomationPopOver />}
                  />
                  <UntoggleablePreference
                    preference="Fractional Shares"
                    isEnabled={investmentPreference?.fractionalShares}
                  />
                  <UntoggleablePreference
                    preference="Tax Optimization"
                    isEnabled={investmentPreference?.enableTaxOptimization}
                  />
                </>
              )}
            </>
          )}
          <>
            {portfolioAssetClass === AssetClass.EQUITY && (
              <ValuePreference
                preference="Selected Benchmark"
                value={benchmark?.name}
              />
            )}

            {!isThirdPartyManaged && (
              <>
                {assetClass && (
                  <ValuePreference
                    preference="Asset Class"
                    value={assetClass.label}
                  />
                )}

                {strategy ? (
                  <>
                    <ValuePreference
                      preference="Selected Strategy"
                      value={strategy.label}
                    />
                  </>
                ) : (
                  <>
                    {investmentPreference?.targetAssetCount && (
                      <ValuePreference
                        preference="Target Asset Count"
                        value={investmentPreference?.targetAssetCount?.toString()}
                      />
                    )}

                    <SelectedItemsPreference
                      colorVariant="red"
                      preference="Excluded Sectors"
                      items={investmentPreference?.excludedSectors || []}
                    />

                    <SelectedItemsPreference
                      colorVariant="red"
                      preference="Excluded Industries"
                      items={investmentPreference?.excludedIndustries || []}
                    />

                    <SelectedItemsPreference
                      colorVariant="red"
                      preference="Excluded Assets"
                      items={investmentPreference?.excludedAssets || []}
                    />

                    <SelectedItemsPreference
                      colorVariant="green"
                      preference="Required Assets"
                      items={requiredAssets}
                    />
                  </>
                )}
              </>
            )}
          </>
        </Box>
      </Box>
    </>
  )
}

export default InvestmentPreferences
