/**
 * PerformancePriority Enum
 */

export const PerformancePriority = {
  TRACKING_ERROR: 1,
  BALANCED: 2,
  SLIGHTLY_O: 3,
  OUTPERFORMANCE: 4,
  STRONGLY_O: 5,
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PerformancePriority =
  typeof PerformancePriority[keyof typeof PerformancePriority]

export const PerformancePriorityValues = Object.values(PerformancePriority)
