import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function BuyListPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Buy List
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Pave Pro allows you to set which assets Pave Pro can recommend
                within a given client portfolio.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                When a Buy List is set, Pave Pro will be constrained to only
                include those assets in the client’s portfolio. If an asset
                outside the list is market as not tradable, it may also be
                included.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Setting a Buy List does not guarantee all assets in that Buy
                List will be included in a portfolio.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                This field is not required. If you leave it empty, Pave Pro will
                default to using assets from the chosen benchmark.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default BuyListPopOver
