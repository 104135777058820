/**
 * DEPRECATED
 *
 * Values under systems will be migrated under the constants directory or
 * renamed or removed
 */
export const primaryFont = '"Inter", sans-serif'

export const fontSizes = {
  xxs: '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
}

export const lineHeights = {
  xxs: '1rem',
  xs: '1rem',
  sm: '1.25rem',
  md: '1.5rem',
  lg: '1.75rem',
  xl: '1.75rem',
  xxl: '2rem',
}

export const fontWeights = {
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extrabold: 800,
}
