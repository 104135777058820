import styled from '@emotion/styled'
import { UserIcon } from '@heroicons/react/solid'
import React, { HTMLAttributes } from 'react'

import Text from '../Text/Text'

type Props = HTMLAttributes<HTMLDivElement> & {
  imgUrl: string
}
function PhotoPreview({ ...props }: Props) {
  return (
    <PhotoPreviewContainer {...props}>
      <PhotoPreviewText>Photo</PhotoPreviewText>
      <StyledPhotoPreview>
        {props.imgUrl ? (
          <StyledPhotoImg src={props.imgUrl}></StyledPhotoImg>
        ) : (
          <StyledUserIcon></StyledUserIcon>
        )}
      </StyledPhotoPreview>
    </PhotoPreviewContainer>
  )
}
const PhotoPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const PhotoPreviewText = styled(Text)`
  width: 302px;
  height: 20px;
  color: ${(props) => props.theme.colors.coolGray[500]};
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.sm};
  line-height: 20px;
`
const StyledPhotoImg = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
`
const StyledUserIcon = styled(UserIcon)`
  position: absolute;
  left: 15%;
  right: 15%;
  top: 10.89%;
  color: ${({ theme }) => theme.colors.gray[200]};
`
const StyledPhotoPreview = styled.div`
  margin-top: 5px;
  position: relative;
  width: 72px;
  height: 72px;
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 50%;
`

export default PhotoPreview
