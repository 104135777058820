import { analytics } from 'analytics'
import { useState } from 'react'

import { InvestmentTemplateFormPayload } from 'types/InvestmentTemplateTypes'

import { paveApi } from 'api'
import { Box, Button } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { EMPTY_INVESTMENT_TEMPLATE } from 'constants/investmentTemplate.constants'
import {
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED,
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { SPACE } from 'styles/constants/space'

import ConfirmCreateTemplateModal from './ConfirmCreateTemplateModal'
import InvestmentTemplateForm from './CreatePortfolio/InvestmentTemplateForm'

type Props = {
  closeModal: () => void
  showModal: boolean
  managementOrganizationId: string
}

function AddInvestmentTemplateSlideIn({
  closeModal,
  showModal,
  managementOrganizationId,
}: Props) {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [templateFormPayload, setTemplateFormPayload] =
    useState<InvestmentTemplateFormPayload | null>(null)
  const [createInvestmentTemplate, { isLoading }] =
    paveApi.useCreateInvestmentTemplateMutation()

  const handleConfirm = () => {
    analytics.track(INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED)
    if (templateFormPayload) {
      createInvestmentTemplate({
        ...templateFormPayload,
        managementOrganizationId,
      })
        .unwrap()
        .then(() => {
          analytics.track(INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_SUCCESS)
          showSuccessToast('Template created!')
          setShowConfirmationModal(false)
          closeModal()
        })
        .catch((e) => {
          const displayMessage = e?.data?.displayMessage
          if (displayMessage) {
            showErrorToast(displayMessage)
          } else {
            showErrorToast('An error occurred when creating the template.')
          }

          analytics.track(INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED)
        })
    }
  }

  const handleSubmit = (
    investmentTemplatePayload: InvestmentTemplateFormPayload
  ) => {
    setShowConfirmationModal(true)
    setTemplateFormPayload(investmentTemplatePayload)
  }

  const handleClose = () => {
    setTemplateFormPayload(null)
    setShowConfirmationModal(false)
  }

  return (
    <>
      <SlideOutMenu
        isOpen={showModal}
        onClose={closeModal}
        title="Create Investment Template"
        size="xl"
        formId="investment-template-form"
        customHeaderButtons={
          <Box display="flex" gap={SPACE.sp12}>
            <Button
              type="submit"
              disabled={isLoading}
              form="investment-template-form"
            >
              Save
            </Button>
          </Box>
        }
      >
        <InvestmentTemplateForm
          initialTemplate={EMPTY_INVESTMENT_TEMPLATE}
          isNewTemplate={true}
          submitInvestmentTemplateForm={handleSubmit}
        />
      </SlideOutMenu>
      <ConfirmCreateTemplateModal
        showModal={showConfirmationModal}
        hideModal={handleClose}
        onClick={handleConfirm}
        isLoading={isLoading}
      />
    </>
  )
}
export default AddInvestmentTemplateSlideIn
