import { Button } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { CSSProperties, ReactElement } from 'react'
import Select, { Props, createFilter } from 'react-select'

import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import FlexBox from '../FlexBox/FlexBox'
import Text from '../Text/Text'
import DropdownIndicator from './DropDownIndicator'

export type DropdownVariant = 'base' | 'green' | 'template'

type DropdownProps = Props & {
  errorMessage?: string
  label?: string
  onClearValue?: () => void
  clearValueLabel?: string
  styles?: CSSProperties
  popOver?: ReactElement
  showRequiredStar?: boolean
  variant?: DropdownVariant
}

function Dropdown({
  label,
  theme,
  errorMessage,
  value,
  options = [],
  isClearable = false,
  onClearValue = () => null,
  clearValueLabel = 'Clear Value',
  styles,
  showRequiredStar = false,
  variant = 'base',
  ...props
}: DropdownProps) {
  const selectedValue = options.find((option: any) => option?.value === value)

  let DropdownVariant
  switch (variant) {
    case 'base':
      DropdownVariant = BaseCustomSelect
      break
    case 'green':
      DropdownVariant = GreenCustomSelect
      break
    case 'template':
      DropdownVariant = TemplateCustomSelect
      break
    default:
      DropdownVariant = BaseCustomSelect
  }

  return (
    <Container>
      {label && (
        <FlexBox marginBottom={SPACE.sp4} justifyContent="space-between">
          <FlexBox gap={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs14}
              fontWeight={500}
              marginBottom={SPACE.sp4}
            >
              {label}{' '}
              {showRequiredStar && <Text color={COLOR.purple700}>*</Text>}
            </Text>
            {props.popOver && props.popOver}
          </FlexBox>

          {isClearable && !(value === null || value === undefined) && (
            <Button
              color={COLOR.purple700}
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault()
                onClearValue()
              }}
              variant="secondary"
              size="xs"
            >
              {clearValueLabel}
            </Button>
          )}
        </FlexBox>
      )}
      <DropdownVariant
        className="select-container"
        classNamePrefix="select"
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        filterOption={createFilter({ ignoreAccents: true })}
        menuPlacement="auto"
        options={options}
        styles={{
          control: (base: any) => ({
            ...base,
            height: '3.25rem',
          }),
          ...styles,
        }}
        value={(selectedValue || value) ?? null}
        {...props}
      />
      {errorMessage && (
        <Text
          color="status.error"
          fontWeight="medium"
          fontSize="xs"
          lineHeight="xxs"
          mt="sm"
          mb={0}
          as="p"
        >
          {errorMessage}
        </Text>
      )}
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
`
const BaseCustomSelect = styled(Select)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  color: ${COLOR.black};

  .select__control {
    border-color: ${COLOR.coolGray300};
  }

  .select__control--is-focused {
    border-color: ${COLOR.purple700};
    box-shadow: none;
    :hover {
      border-color: ${COLOR.purple700};
    }
  }

  .select__control--is-disabled {
    background: ${COLOR.coolGray50};
    color: ${COLOR.coolGray400};
    cursor: not-allowed;
  }

  .select__menu {
    box-shadow: ${({ theme }) => theme.shadows.md};
    z-index: 2;
  }

  .select__option {
    padding: 12px 16px;
    color: ${COLOR.black};
    font-size: ${({ theme }) => theme.fontSizes.xxs};
  }

  .select__option--is-focused {
    background-color: ${COLOR.coolGray50};
  }

  .select__option--is-selected {
    background-color: ${COLOR.blue50};
    color: ${COLOR.blue500};
  }

  .select__option--is-disabled {
    color: ${COLOR.coolGray500};
  }
`

const GreenCustomSelect = styled(BaseCustomSelect)`
  .select__control {
    border-color: ${COLOR.green700};
  }
`

const TemplateCustomSelect = styled(BaseCustomSelect)`
  .select__control {
    border-color: ${COLOR.purple50};
    background-color: ${COLOR.purple50};
    color: ${COLOR.purple800};
  }

  .select__single-value--is-disabled {
    color: ${COLOR.purple800};
  }

  .select__dropdown-indicator {
    display: none;
  }

  .select__placeholder {
    color: ${COLOR.purple800};
  }
`

export default Dropdown
