import { Icon } from '@chakra-ui/react'
import {
  ClipboardListIcon,
  CogIcon,
  LinkIcon,
  PencilAltIcon,
} from '@heroicons/react/outline'
import { LogoutIcon } from '@heroicons/react/solid'

import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import Box from '../Box/Box'
import FlexBox from '../FlexBox/FlexBox'
import Text from '../Text/Text'

export type MenuItem = {
  action: () => void
  color?: string
  icon: string
  name: string
}

type Props = {
  item: MenuItem
}

export default function DropdownMenuItem({ item }: Props) {
  const { action, color, icon, name } = item
  const itemColor = color ? color : COLOR.coolGray600

  // TODO: Create a dedicated icon component that renders based on the
  // icon name passed in, and refactor out this switch statement
  let renderedIcon
  switch (icon) {
    case 'cog':
      renderedIcon = <CogIcon />
      break
    case 'link':
      renderedIcon = <LinkIcon />
      break
    case 'pencil':
      renderedIcon = <PencilAltIcon />
      break
    case 'clipboard':
      renderedIcon = <ClipboardListIcon />
      break
    case 'logout':
      renderedIcon = <LogoutIcon />
      break
    default:
      renderedIcon = <Icon />
      break
  }

  return (
    <FlexBox
      alignItems="center"
      borderRadius="0.25rem"
      className="dropdown-menu-item"
      color={itemColor}
      padding={SPACE.sp8}
      onClick={action}
    >
      <Box marginRight={SPACE.sp8} width="1.25rem">
        {renderedIcon}
      </Box>
      <Text fontWeight="medium" fontSize={FONT_SIZE.fs16} lineHeight="xs">
        {name}
      </Text>
    </FlexBox>
  )
}
