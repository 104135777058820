import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function USDollarSensitivityPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                US Dollar Sensitivity
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                The relative strength of the US Dollar has a significant impact
                on the economy domestically and abroad. Some assets are less
                exposed to changes in the desirability of the US Dollar and
                therefore are likely to perform better when the dollar is weak.
                One interesting trend to note is that during a global economic
                downturn, the dollar tends to do well as investors seek
                security. In these scenarios, assets exposed to the US Dollar
                may outperform.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                If US Dollar Sensitivity is left on Default, Pave Pro will
                automatically adjust client portfolios such that their US Dollar
                exposure is as close as possible to that of their benchmark. If
                you would like to tilt Pave Pro towards favoring assets that are
                more sensitive to the dollar, select Overweight. For the
                opposite, select Underweight.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default USDollarSensitivityPopOver
