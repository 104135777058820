import { useParams, useSearchParams } from 'react-router-dom'

import { ASSET_SUPPORTED_TYPE } from 'types'

import { paveApi } from 'api'
import { Box, Text } from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import FlexBox from 'components/common/FlexBox/FlexBox'
import CardSection from 'components/common/Section/CardSection'
import {
  AssetAbout,
  AssetHeader,
  AssetOutlook,
  AssetPerformance,
  AssetStatistics,
  PageLayout,
} from 'components/partials'
import { SPACE } from 'styles/constants/space'

function AssetOverviewPage() {
  const { symbol } = useParams()

  const [searchParams] = useSearchParams()
  const portfolioId = searchParams.get('portfolioId')

  const { data: portfolio } = paveApi.useGetPortfolioQuery(portfolioId || '')
  const { data } = paveApi.useGetClientAccountQuery(
    portfolio?.clientAccountId || '',
    {
      skip: !portfolio?.clientAccountId,
    }
  )

  const {
    data: info,
    isLoading: isInfoLoading,
    isError,
  } = paveApi.useGetAssetOverviewInfoQuery(symbol || '')
  const asset = info?.asset
  const description = info?.description
  const type = info?.type

  if (isError || !asset?.isSupported) {
    return (
      <PageLayout showLoader={isInfoLoading}>
        <Box padding={4}>
          <Text fontSize="3xl" as="p">
            An error has occured.
          </Text>
          <Text as="p">
            Hmm... something went wrong when gathering the asset's information.
            Please try again later!
          </Text>
        </Box>
      </PageLayout>
    )
  }
  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: data?.clientAccount?.displayName || 'Group Overview',
      route: `/account/${portfolio?.clientAccountId || ''}`,
    },
    {
      name: portfolio?.displayName || 'Portfolio Overview',
      route: `/portfolio/${portfolio?.id || ''}`,
    },
    {
      name: asset?.name || 'Asset Overview',
      route: ``,
    },
  ]
  const showAssetOutlook =
    asset?.supportedType === ASSET_SUPPORTED_TYPE.ALL &&
    asset?.returnRating &&
    asset?.riskRating
  return (
    <PageLayout showLoader={isInfoLoading}>
      {portfolio?.id && <BreadcrumbBar paths={breadcrumbs} />}
      <Box margin={SPACE.sp32}>
        {asset && <AssetHeader asset={asset} portfolio={portfolio} />}
        <FlexBox flexDirection="column" gap={SPACE.sp32}>
          <CardSection>
            {asset && <AssetPerformance asset={asset} />}
          </CardSection>

          <CardSection>
            {asset && (
              <AssetAbout asset={asset} type={type} description={description} />
            )}
          </CardSection>

          <CardSection>
            {asset && (
              <AssetStatistics
                asset={asset}
                initialBenchmark={portfolio?.benchmark}
              />
            )}
          </CardSection>

          {showAssetOutlook && (
            <CardSection>
              <AssetOutlook asset={asset} />
            </CardSection>
          )}
        </FlexBox>
      </Box>
    </PageLayout>
  )
}

export default AssetOverviewPage
