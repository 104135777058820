import { PencilIcon, XIcon } from '@heroicons/react/solid'

import { UserDto } from 'types/dto/User.dto'

import { Box, Text } from 'components/common'

type Props = UserDto & {
  removable?: boolean
  portfolios: any[]
  onRemove?: () => void
  onEdit?: (clientId: string) => void
}
export function AccountClient(props: Props) {
  return (
    <Box
      key={props.id}
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      padding="0px"
      gap="8px"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        padding="0px"
        gap="0px"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="xs"
            fontWeight="bold"
            lineHeight="xs"
            color="coolGray.700"
          >
            {`${props.firstName} ${props.lastName}`}
          </Text>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap="8px"
          >
            {props.removable ? (
              <Box
                width="24px"
                height="24px"
                color="coolGray.500"
                style={{ cursor: 'pointer' }}
                onClick={props.onRemove}
              >
                <XIcon></XIcon>
              </Box>
            ) : null}
            {props.removable ? (
              <Box
                width="24px"
                height="24px"
                color="coolGray.500"
                style={{ cursor: 'pointer' }}
                onClick={() => props.onEdit?.(props.id)}
              >
                <PencilIcon></PencilIcon>
              </Box>
            ) : null}
          </Box>
        </Box>

        {props.portfolios ? (
          <Text
            fontSize="xs"
            fontWeight="regular"
            lineHeight="xs"
            color="coolGray.700"
          >
            {`${props.portfolios.length} ${
              props.portfolios.length > 1 ? 'porfolios' : 'portfolio'
            }`}
          </Text>
        ) : null}
      </Box>
    </Box>
  )
}
