import React, { useEffect, useState } from 'react'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'
import { LinkedManagementOrganizationAccountResponseDto } from 'types/dto/ManagementOrganization.dto'

import { Box, Button, Dropdown, SearchBar, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import ListView from 'components/partials/ListView'
import ManagementOrganizationAccountListItem from 'components/partials/Settings/ManagementOrganizationAccountListItem'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

import { DropdownOptionString } from '../DropdownWithTags'

const DropDownValues = {
  ALL: 'all',
  LINKED: 'linked',
  UNLINKED: 'unlinked',
} as const
const DROP_DOWN_OPTIONS = [
  {
    label: 'All Accounts',
    value: DropDownValues.ALL,
  },
  {
    label: 'Linked Accounts',
    value: DropDownValues.LINKED,
  },
  {
    label: 'Unlinked Accounts',
    value: DropDownValues.UNLINKED,
  },
] as DropdownOptionString[]

type Props = {
  managementOrganizationAccounts: LinkedManagementOrganizationAccountResponseDto[]
  clientAccounts?: ClientAccountDto[]
  handleAssignToGroup: (selectedSubAccountNumbers: string[]) => void
}
function ManagementOrganizationAccountsList({
  managementOrganizationAccounts,
  clientAccounts,
  handleAssignToGroup,
}: Props) {
  const [accountsToShow, setAccountsToShow] = useState(
    managementOrganizationAccounts.slice(
      0,
      DEFAULT_ITEMS_PER_PAGE_OPTIONS[0].value
    )
  )
  const [selectedAccountType, setSelectedAccountType] = useState<any>(
    DROP_DOWN_OPTIONS[0]
  )

  const ALL_DROP_DOWN_OPTIONS = DROP_DOWN_OPTIONS.concat(
    clientAccounts?.map((c) => {
      return {
        value: c.id,
        label: c.displayName,
      }
    }) as DropdownOptionString[]
  )

  const [selectedSubAccountNumbers, setSelectedSubAccountNumbers] = useState<
    string[]
  >([])

  const [search, setSearch] = useState('')

  const [page, setPage] = useState(1)
  const [itemsCount, setItemsCount] = useState(
    managementOrganizationAccounts.length
  )
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )

  useEffect(() => {
    let filteredAccounts = jsonClone(managementOrganizationAccounts)

    filteredAccounts = filteredAccounts.filter((item: any) =>
      searchFilter(item, 'title', search)
    )

    switch (selectedAccountType.value) {
      case DropDownValues.ALL:
        break
      case DropDownValues.LINKED:
        filteredAccounts = filteredAccounts.filter(
          (account: LinkedManagementOrganizationAccountResponseDto) =>
            account.clientAccount
        )
        break
      case DropDownValues.UNLINKED:
        filteredAccounts = filteredAccounts.filter(
          (account: LinkedManagementOrganizationAccountResponseDto) =>
            !account.clientAccount
        )
        break
      default:
        filteredAccounts = filteredAccounts.filter(
          (account: LinkedManagementOrganizationAccountResponseDto) =>
            account?.clientAccount?.id === selectedAccountType.value
        )
    }

    const accountsToShow = filteredAccounts.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )

    setAccountsToShow(accountsToShow)
    setItemsCount(filteredAccounts.length)
  }, [
    managementOrganizationAccounts,
    search,
    selectedAccountType,
    page,
    selectedItemsPerPageOption,
  ])

  useEffect(() => setPage(1), [selectedAccountType])

  const handlePageChange = (page: number) => {
    setPage(page)
  }
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }

  const handleCheckboxChange = (newSubAccountNumber: string) => {
    const updatedSubAccountNumbers = [...selectedSubAccountNumbers]
    const index = updatedSubAccountNumbers.indexOf(newSubAccountNumber)
    if (index !== -1) {
      updatedSubAccountNumbers.splice(index, 1)
    } else {
      updatedSubAccountNumbers.push(newSubAccountNumber)
    }
    setSelectedSubAccountNumbers(updatedSubAccountNumbers)
  }

  return (
    <FlexBox flexDirection="column" height="100%" alignItems="auto">
      <FlexBox
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={SPACE.sp10}
      >
        <Box>
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs18}
            fontWeight="700"
          >
            Connected Accounts{' '}
          </Text>
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs18}
            fontWeight="400"
          >
            ({itemsCount})
          </Text>
        </Box>
        <FlexBox flexDirection="row" gap={SPACE.sp8}>
          <FlexBox gridColumn="span 2">
            <Dropdown
              options={ALL_DROP_DOWN_OPTIONS}
              onChange={(option: any) => {
                setSelectedAccountType(option)
                setPage(1)
              }}
              value={selectedAccountType}
              styles={{
                control: (base: any) => ({
                  ...base,
                  borderRadius: '0.5rem',
                  height: '2.5rem',
                }),
              }}
            />
          </FlexBox>
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleAssignToGroup(selectedSubAccountNumbers)}
            disabled={!selectedSubAccountNumbers.length}
          >
            Assign to Group
          </Button>
        </FlexBox>
      </FlexBox>
      <FlexBox
        width="100%"
        gap={SPACE.sp12}
        alignItems="center"
        marginBottom={SPACE.sp20}
      >
        <Box>
          <Dropdown
            options={DEFAULT_ITEMS_PER_PAGE_OPTIONS}
            onChange={(option: any) => {
              setSelectedItemsPerPageOption(option)
              setPage(1)
            }}
            value={selectedItemsPerPageOption}
            styles={{
              control: (base: any) => ({
                ...base,
                borderRadius: '0.5rem',
                height: '2.5rem',
              }),
            }}
          />
        </Box>
        <Box flexGrow={1}>
          <SearchBar
            placeholder="Search by sub account name"
            value={search}
            onChange={handleSearch}
          />
        </Box>
      </FlexBox>
      <ListView
        data={accountsToShow}
        header={
          <FlexBox
            fontSize="xxs"
            fontWeight="medium"
            color={COLOR.coolGray600}
            padding={`${SPACE.sp16}`}
            justifyContent="flex-start"
          >
            <Box flex={3} paddingRight={SPACE.sp12}>
              <Text>SUB ACCOUNT NAME</Text>
            </Box>
            <Box flex={1}>
              <Text>SUB ACCOUNT ID</Text>
            </Box>
            <Box flex={1}>
              <Text>MAIN ACCOUNT ID</Text>
            </Box>
            <Box flex={1}>
              <Text>CUSTODIAN</Text>
            </Box>
            <FlexBox flex={1.5} justifyContent="center">
              <Text>GROUP</Text>
            </FlexBox>
          </FlexBox>
        }
        renderItem={(account) => {
          return (
            <ManagementOrganizationAccountListItem
              key={account.id}
              account={account}
              handleCheckboxChange={handleCheckboxChange}
              isChecked={selectedSubAccountNumbers.includes(
                account.subAccountNumber
              )}
            />
          )
        }}
      />
      <FlexBox
        flexDirection="column"
        marginTop={SPACE.sp16}
        alignItems="flex-end"
      >
        <Pagination
          currentPage={page}
          handlePageChange={handlePageChange}
          itemsCount={itemsCount}
          itemsPerPage={
            selectedItemsPerPageOption.value ??
            DEFAULT_ITEMS_PER_PAGE_OPTIONS[0].value
          }
        />
      </FlexBox>
    </FlexBox>
  )
}

export default ManagementOrganizationAccountsList
