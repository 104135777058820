import styled from '@emotion/styled'
import React, { HTMLAttributes } from 'react'

import Text from '../Text/Text'

type Props = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean
}

function StyledFileUpload({ ...props }: Props) {
  return (
    <FileUploadContainer>
      <PlaceHolderText {...props}>{props.placeholder}</PlaceHolderText>
      <NoteText {...props}>Note: Use a high resolution photo.</NoteText>
      <FileUpload {...props}>
        <FileUpLoadText {...props}>
          Drag & drop or <BrowseFileText {...props}>browse</BrowseFileText>{' '}
        </FileUpLoadText>
      </FileUpload>
    </FileUploadContainer>
  )
}
const FileUploadContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')}; ;
`

const FileUpLoadText = styled(Text)<Props>`
  width: 100%;
  height: 20px;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.coolGray[200]
      : props.theme.colors.coolGray[500]};
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.sm};
  line-height: 20px;
`
const BrowseFileText = styled(FileUpLoadText)<Props>`
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.coolGray[200]
      : props.theme.colors.blue[500]};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`
const NoteText = styled(FileUpLoadText)<Props>`
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.coolGray[300]
      : props.theme.colors.coolGray[600]};
  padding: 1px;
`
const PlaceHolderText = styled(FileUpLoadText)<Props>`
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.coolGray[300]
      : props.theme.colors.coolGray[600]};
  font-weight: ${(props) => props.theme.fontWeights.medium};
`
const FileUpload = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 16px;
  gap: 10px;
  width: 100%;
  height: 52px;
  border: 1px dashed
    ${({ theme, disabled }) => {
      return disabled ? theme.colors.coolGray[200] : theme.colors.blue[500]
    }};
  border-radius: 4px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  :hover {
    background: ${(props) =>
      props.disabled ? 'none' : props.theme.colors.blue[50]};
  }
  margin-top: 5px;
`

export default StyledFileUpload
