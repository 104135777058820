import { analytics } from 'analytics'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { paveApi } from 'api'
import { AUTH_CHALLENGES } from 'api/authChallenges'
import { API_ERRORS } from 'api/errors'
import { Box, Button } from 'components/common'
import {
  PublicLayout,
  SignInForm,
  TopNav,
  VerifyMFACodeForm,
} from 'components/partials'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { NETWORK_ERROR } from 'constants/error.constants'
import {
  LOGIN_SUBMITTED,
  LOGIN_SUBMITTED_FAILED,
  LOGIN_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { setEmail as setEmailRedux } from 'store/signUpSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

function SignInPage() {
  const { showErrorToast } = useCustomToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [mustInsertMFACode, setMustInsertMFACode] = useState(false)
  const [signIn, { isLoading: isSignInLoading }] = paveApi.useSignInMutation()
  const [verifySoftwareToken, { isLoading: isVerifySoftwareTokenLoading }] =
    paveApi.useVerifySoftwareTokenMfaMutation()
  const isDisabled = isSignInLoading || isVerifySoftwareTokenLoading
  const { Session } = useAppSelector((state) => state.auth)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onVerifySoftwareToken = (code: string) => {
    verifySoftwareToken({
      session: Session,
      code,
      email,
    })
      .unwrap()
      .then(() => {
        navigate('/')
      })
      .catch((error) => {
        const { error: errorCode } = error.data
        if (errorCode === API_ERRORS.CODE_MISMATCH) {
          showErrorToast(
            'Incorrect code entered. Please check your authenticator for the correct code.'
          )
        } else {
          showErrorToast(ERROR_MESSAGE_1)
        }
      })
  }

  const onSubmit = (formData: any) => {
    const { email, password } = formData
    analytics.track(LOGIN_SUBMITTED)
    signIn({ email, password })
      .unwrap()
      .then((data) => {
        analytics.track(LOGIN_SUBMITTED_SUCCESS)
        if (data?.result?.ChallengeName === AUTH_CHALLENGES.MFA_SETUP) {
          navigate('/setup-mfa')
          return
        }
        if (
          data?.result?.ChallengeName === AUTH_CHALLENGES.SOFTWARE_TOKEN_MFA
        ) {
          setMustInsertMFACode(true)
          return
        }
        navigate('/')
      })
      .catch((error) => {
        analytics.track(LOGIN_SUBMITTED_FAILED, error)
        const errorCode = error?.data?.error
        if (errorCode) {
          if (errorCode === API_ERRORS.USER_NOT_CONFIRMED) {
            dispatch(setEmailRedux(email))
            navigate('/confirm-user', { state: { redirectedFromLogin: true } })
          } else if (errorCode === API_ERRORS.USER_NOT_AUTHORIZED) {
            showErrorToast('Incorrect username or password.')
          } else {
            showErrorToast('Error signing in', errorCode)
          }
        } else {
          if (error.status === NETWORK_ERROR) {
            showErrorToast('Error signing in', 'Network error')
          } else {
            showErrorToast(ERROR_MESSAGE_1)
          }
        }
      })
  }
  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/signup">
              <Button>Sign up</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
      >
        {mustInsertMFACode ? (
          <VerifyMFACodeForm onSubmit={onVerifySoftwareToken} />
        ) : (
          <SignInForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
          />
        )}
      </Box>
    </PublicLayout>
  )
}

export default SignInPage
