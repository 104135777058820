import { analytics } from 'analytics'
import { useEffect } from 'react'

import { paveApi } from 'api'
import { AUTH_CHALLENGES } from 'api/authChallenges'
import { Box } from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import FlexBox from 'components/common/FlexBox/FlexBox'
import {
  AdvisorPreferences,
  EditUserPreferencesModal,
  PageLayout,
} from 'components/partials'
import { SETTINGS_EDIT_ADVISOR_INFORMATION_SUBMITTED } from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'
import { SPACE } from 'styles/constants/space'

function SettingsPage() {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const { userId } = useAppSelector((state: any) => state.user)
  const {
    data: user,
    isLoading,
    refetch,
  } = paveApi.useGetUserByIdQuery(userId || '', {
    skip: !userId,
  })
  const [updateUser] = paveApi.useUpdateUserMutation()
  const [setUserMFAPreference, setUserMFAPreferenceResult] =
    paveApi.useSetUserMFAPreferenceMutation()

  const onEditUserSettings = (editedUser: any) => {
    analytics.track(SETTINGS_EDIT_ADVISOR_INFORMATION_SUBMITTED)
    updateUser({ ...editedUser })
      .unwrap()
      .then(() => {
        refetch()
        showSuccessToast('Settings updated!')
      })
      .catch(() => {
        showErrorToast(`Hmm, something went wrong please try again`)
      })
  }

  const [getUserDetails, getUserDetailsResult] =
    paveApi.useGetUserDetailsMutation()

  useEffect(() => {
    getUserDetails()
  }, [getUserDetails])

  useEffect(() => {
    if (setUserMFAPreferenceResult.isSuccess) {
      getUserDetails()
    }
  }, [setUserMFAPreferenceResult, getUserDetails])

  const mfaMethods = getUserDetailsResult?.data?.UserMFASettingList || []
  const mfaEnabled = mfaMethods.includes(AUTH_CHALLENGES.SOFTWARE_TOKEN_MFA)

  if (isLoading || !user) return <div>Loading...</div>

  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: 'Settings',
      route: `#`,
    },
  ]

  return (
    <PageLayout>
      <BreadcrumbBar paths={breadcrumbs} />
      <FlexBox flexDirection="row" gap={SPACE.sp24} padding={SPACE.sp32}>
        <Box flex={1} maxWidth="15rem">
          <AdvisorPreferences user={user} mfaEnabled={mfaEnabled} />
        </Box>
      </FlexBox>
      <EditUserPreferencesModal
        onEditUserSettings={onEditUserSettings}
        setUserMFAPreference={setUserMFAPreference}
        user={user}
        mfaEnabled={mfaEnabled}
      ></EditUserPreferencesModal>
    </PageLayout>
  )
}

export default SettingsPage
