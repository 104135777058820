import { ChevronUpIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

import Box from '../Box/Box'
import Button from '../Button/Button'
import FlexBox from '../FlexBox/FlexBox'
import DropdownMenuItem, { MenuItem } from './DropdownMenuItem'

type Props = {
  menuName: string
  menuItems: MenuItem[]
}

export default function DropdownMenu({ menuName, menuItems }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Box position="relative">
      <Button onClick={() => setIsOpen(!isOpen)} variant="tertiary">
        <FlexBox color={COLOR.coolGray600}>
          <Box marginRight={SPACE.sp8}>{menuName}</Box>
          <Box width="1.25rem">
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Box>
        </FlexBox>
        {isOpen && (
          <Box
            backgroundColor={COLOR.white}
            border={`1px solid ${COLOR.coolGray300}`}
            borderRadius="0.5rem"
            boxShadow={`inset 0 0 1px 1px ${COLOR.coolGray300}`}
            left="-5rem"
            top="2.5rem"
            padding={SPACE.sp8}
            position="absolute"
            width="15rem"
            zIndex={2}
          >
            {menuItems.map((item, i) => {
              return <DropdownMenuItem key={item.name} item={item} />
            })}
          </Box>
        )}
      </Button>
    </Box>
  )
}
