import { HomeNavigator } from 'pages/navigators'
import { BrowserRouter } from 'react-router-dom'

import { AuthSelector } from 'components/partials'
import RootProvider from 'components/providers/RootProvider'

function App() {
  return (
    <RootProvider>
      <BrowserRouter>
        <AuthSelector>
          <HomeNavigator />
        </AuthSelector>
      </BrowserRouter>
    </RootProvider>
  )
}

export default App
