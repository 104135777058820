import styled from '@emotion/styled'

import { Box, Button } from 'components/common'

export const FormInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  label {
    width: 100%;
  }
`
export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 44px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.coolGray[300]};
  border-radius: 8px;

  a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.lightBlue[600]};
    text-decoration: none;
  }
`
export const AuthActionButton = styled(Button)`
  width: 100%;
`
