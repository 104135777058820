import { difference } from 'lodash'

import { BuyListAsset } from 'types'

export function convertAssetsToSymbols(assets: { symbol: string }[]): string[] {
  return assets?.map((asset) => asset?.symbol)
}

export function getBuyListAssetDifference(
  updatedAssets: string[],
  initialAssets: string[]
) {
  const addedSymbols = difference(updatedAssets, initialAssets)
  const removedSymbols = difference(initialAssets, updatedAssets)
  return { addedSymbols, removedSymbols }
}

export function getSupportedAndUnsupportedAssetsFromBuyList(
  assets: BuyListAsset[]
) {
  let unsupportedAssets: BuyListAsset[] = []
  let supportedAssets: BuyListAsset[] = []
  assets.forEach((asset: BuyListAsset) => {
    asset.isSupported
      ? supportedAssets.push(asset)
      : unsupportedAssets.push(asset)
  })
  return { unsupportedAssets, supportedAssets }
}
