import React from 'react'
import { NumericFormat } from 'react-number-format'

import { PasswordInput, Text, TextInput } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

import { AuthActionButton, FormContainer, FormInputsContainer } from './shared'

type Props = {
  onSubmit: (formData: any) => void
}
function ConfirmForgotPassword(props: Props) {
  const [code, setCode] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  return (
    <FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          props.onSubmit({ code, newPassword, confirmPassword })
        }}
      >
        <FlexBox flexDirection="column" alignItems="center" gap={SPACE.sp24}>
          <Text textAlign="center" color={COLOR.coolGray500}>
            We sent a confirmation code to your email. Please enter it here with
            your new password to reset your account.
          </Text>
          <FormInputsContainer>
            <NumericFormat
              name="code"
              customInput={TextInput}
              placeholder="Code"
              value={code}
              required
              onChange={(e) => setCode(e.target.value)}
            />
            <PasswordInput
              type="password"
              placeholder="Password"
              label="New Password"
              value={newPassword}
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <PasswordInput
              type="password"
              placeholder="Confirm Password"
              label="Confirm Password"
              value={confirmPassword}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormInputsContainer>
          <AuthActionButton type="submit" variant="primary" size="lg">
            Reset
          </AuthActionButton>
        </FlexBox>
      </form>
    </FormContainer>
  )
}

export default ConfirmForgotPassword
