import { DownloadOutlined } from '@ant-design/icons'
import { analytics } from 'analytics'
import { isEmpty } from 'lodash'

import { STATUS_ENUM } from 'types'
import { BuyListFormPayload } from 'types/buyListTypes'

import { paveApi } from 'api'
import { Button } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SlideOutMenu } from 'components/partials'
import {
  BUY_LIST_EDIT_FORM_SUBMITTED,
  BUY_LIST_EDIT_FORM_SUBMITTED_FAILED,
  BUY_LIST_EDIT_FORM_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { SPACE } from 'styles/constants/space'
import {
  convertAssetsToSymbols,
  getBuyListAssetDifference,
} from 'utils/assetUtils'

import BuyListForm from './BuyLists/BuyListForm'
import BuyListReadOnlyView from './BuyLists/BuyListReadOnlyView'
import ModalLoader from './ModalLoader'

type Props = {
  selectedBuyListId: string
  isBuyListOwner: boolean
  closeModal: () => void
  showModal: boolean
}

function SelectedBuyListSlideIn({
  selectedBuyListId,
  isBuyListOwner,
  closeModal,
  showModal,
}: Props) {
  const { showErrorToast, showSuccessToast, showInfoToast } = useCustomToast()

  const { data: initialBuyList, isLoading: isGetLoading } =
    paveApi.useGetBuyListQuery(selectedBuyListId)
  const [getBuyListChangeLog] = paveApi.useLazyGetBuyListChangeLogQuery()
  const [updateBuyList, { isLoading: isEditLoading }] =
    paveApi.useEditBuyListMutation()

  const submitBuyListForm = (editBuyListRequest: BuyListFormPayload) => {
    const { assetSymbols: updatedAssetSymbols } = editBuyListRequest
    const initialAssetSymbols = convertAssetsToSymbols(
      initialBuyList?.assets || []
    )

    const { addedSymbols, removedSymbols } = getBuyListAssetDifference(
      updatedAssetSymbols,
      initialAssetSymbols
    )

    if (isEmpty(addedSymbols) && isEmpty(removedSymbols)) {
      showInfoToast('No changes detected.', 'Please try again.')
      return
    }

    const payload = {
      buyListId: selectedBuyListId,
      addedSymbols,
      removedSymbols,
    }

    analytics.track(BUY_LIST_EDIT_FORM_SUBMITTED)
    updateBuyList(payload)
      .unwrap()
      .then((res) => {
        if (res?.status === STATUS_ENUM.ERROR) {
          analytics.track(BUY_LIST_EDIT_FORM_SUBMITTED_FAILED)
          throw res
        }

        analytics.track(BUY_LIST_EDIT_FORM_SUBMITTED_SUCCESS)
        showSuccessToast('Buy list updated!')
        closeModal()
      })
      .catch((e) => {
        analytics.track(BUY_LIST_EDIT_FORM_SUBMITTED_FAILED)
        if (e?.status === STATUS_ENUM.ERROR) {
          showErrorToast(e.errorMessage)
        } else {
          showErrorToast('Error updating buy list.')
        }
      })
  }

  const handleChangeLogClick = () => {
    getBuyListChangeLog(selectedBuyListId)
      .unwrap()
      .then((res) => {
        const { entries } = res
        const rows = [
          ['Date', 'Added', 'Removed', 'Supported', 'Unsupported', 'Edited By'],
          ...entries.map((entry) => [
            entry.updatedAt,
            `"${entry.added.join()}"`,
            `"${entry.removed.join()}"`,
            `"${entry.supported.join()}"`,
            `"${entry.unsupported.join()}"`,
            entry.editedBy,
          ]),
        ]

        let csvContent = 'data:text/csv;charset=utf-8,'

        rows.forEach(function (rowArray) {
          const row = rowArray.join(',')
          csvContent += row + '\r\n'
        })

        const currentDate = new Date().toLocaleDateString().replaceAll('/', '-')
        const encodedDisplayName = initialBuyList?.displayName.replaceAll(
          ' ',
          '-'
        )
        const fileName = `${encodedDisplayName}-change-log-${currentDate}.csv`
        const encodedUri = encodeURI(csvContent)
        let link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
  }

  const isLoading = isGetLoading || isEditLoading

  return (
    <SlideOutMenu
      isOpen={showModal}
      onClose={closeModal}
      title={initialBuyList?.displayName}
      size="xl"
      formId="buy-list-form"
      customHeaderButtons={
        <FlexBox gap={SPACE.sp12}>
          {isBuyListOwner && (
            <>
              <Button type="submit" form="buy-list-form">
                Save
              </Button>
              <Button onClick={handleChangeLogClick} variant="secondary">
                <DownloadOutlined /> Download Edit Log
              </Button>
            </>
          )}
        </FlexBox>
      }
    >
      {isLoading && <ModalLoader />}
      {!isLoading &&
        (isBuyListOwner ? (
          <BuyListForm
            initialBuyList={initialBuyList ?? null}
            isNewBuyList={false}
            submitBuyListForm={submitBuyListForm}
          />
        ) : (
          <BuyListReadOnlyView assets={initialBuyList?.assets || []} />
        ))}
    </SlideOutMenu>
  )
}

export default SelectedBuyListSlideIn
