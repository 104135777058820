import styled from '@emotion/styled'
import { round } from 'lodash'

import { Benchmark, GetPortfolioStatsResponse } from 'types'

import { DEFAULT_BENCHMARK_FOR_STATS } from '../../../constants/portfolio.constants'
import { COLOR } from '../../../styles/constants/color'
import { Box, Dropdown, Text } from 'components/common'
import ModalLoader from 'components/partials/ModalLoader'
import { FONT_SIZE } from 'styles/constants/fontSize'

import { HeaderContainer } from '../TradeActivities/styled'
import PortfolioStatisticsInformation from './PortfolioStatisticsInformation'
import StatItem from './StatItem'

type Props = {
  statistics?: GetPortfolioStatsResponse
  isLoading?: boolean
  benchmark?: Benchmark
}

function PortfolioStatistics({ statistics, isLoading, benchmark }: Props) {
  if (!statistics?.current) {
    if (isLoading) {
      return (
        <Container>
          <Box
            position="relative"
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            padding="0px"
            gap="24px"
            width="100%"
          >
            <ModalLoader />
          </Box>
        </Container>
      )
    } else {
      return null
    }
  }
  const { returnPotential, relativeRisk } = statistics.current
  const values = [{ value: benchmark?.id, label: benchmark?.name }]
  return (
    <Container>
      <HeaderContainer>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="4px"
        >
          <Box display="flex">
            <Text
              fontWeight="bold"
              fontSize={FONT_SIZE.fs20}
              lineHeight="xl"
              color={COLOR.coolGray700}
              as="p"
            >
              Statistics vs.
            </Text>
          </Box>
          {values[0] ? (
            <Box display="flex">
              <StyledDropdown value={values[0]} options={values} />
            </Box>
          ) : (
            <>
              <Text
                fontWeight="bold"
                fontSize={FONT_SIZE.fs20}
                lineHeight="xl"
                color={COLOR.coolGray600}
                as="p"
              >
                {DEFAULT_BENCHMARK_FOR_STATS}
              </Text>
            </>
          )}
          <Box display="flex" flex={1} justifyContent="flex-end">
            <PortfolioStatisticsInformation />
          </Box>
        </Box>
      </HeaderContainer>
      <Text
        fontWeight={600}
        fontSize={FONT_SIZE.fs24}
        color={COLOR.coolGray700}
      >
        Your current portfolio has:
      </Text>
      <Box
        position="relative"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        padding="0px"
        gap="24px"
        width="100%"
      >
        {isLoading && <ModalLoader />}
        <StatItem value={round(returnPotential, 1)} label="Return Potential" />
        <StatItem
          value={round(relativeRisk, 1)}
          label="Relative Risk"
          reversed
        />
      </Box>
      <Text
        fontWeight="medium"
        fontSize={FONT_SIZE.fs12}
        lineHeight="xs"
        color={COLOR.coolGray700}
      >
        Statistics track the expected performance of your portfolio against your
        portfolio's benchmark. For each category, the triangle indicates the
        level of the benchmark and the color line indicates how your current
        portfolio compares.
      </Text>
    </Container>
  )
}
const StyledDropdown = styled(Dropdown)`
  .select__control {
    min-height: 0px;
    border-color: ${({ theme }) => theme.colors.coolGray[300]};
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`

export default PortfolioStatistics
