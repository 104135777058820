/**
 * DEPRECATED
 *
 * Values under systems will be migrated under the constants directory or
 * renamed or removed
 */
export const breakpoints: any = ['48rem', '80rem', '96rem']
breakpoints.md = breakpoints[0]
breakpoints.lg = breakpoints[1]
breakpoints.xl = breakpoints[2]
