import { configureStore } from '@reduxjs/toolkit'

import { paveApi } from 'api'

import authReducer from './authSlice'
import createPortfolioSlice from './createPortfolioSlice'
import forgotPasswordReducer from './forgotPasswordSlice'
import modalsReducer from './modalsSlice'
import signUpReducer from './signUpSlice'
import userReducer from './userSlice'

export const store = configureStore({
  reducer: {
    modals: modalsReducer,
    auth: authReducer,
    signUp: signUpReducer,
    forgotPassword: forgotPasswordReducer,
    user: userReducer,
    [paveApi.reducerPath]: paveApi.reducer,
    createPortfolio: createPortfolioSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(paveApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
