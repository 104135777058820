import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function BuyListAssetPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Asset Information
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                This panel displays the assets that are included in this Buy
                List. It includes supported and unsupported assets, which are
                both described below.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="medium">
                Supported Assets
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                When an asset is supported, it means Pave has the requisite data
                to include it in portfolio optimizations. Pave supports over
                9,000 assets. These assets are displayed in purple.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="medium">
                Unsupported Assets
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                There are certain assets Pave does not have requisite data to
                make decisions on. These assets are referred to as unsupported
                and displayed in grey with a red outline.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                When an asset is unsupported, it cannot be include in portfolio
                optimizations. As a result, any unsupported asset in a Buy List
                will not be recommended in client portfolios even if the Buy
                List is applied.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default BuyListAssetPopOver
