/**
 * ChartTimePeriod Enum
 */
export const ChartTimePeriod = {
  ONE_MONTH: 'ONE_MONTH',
  SIX_MONTH: 'SIX_MONTH',
  ONE_YEAR: 'ONE_YEAR',
  FIVE_YEAR: 'FIVE_YEAR',
  SINCE_INCEPTION: 'SINCE_INCEPTION',
} as const

// eslint-disable-next-line
export type ChartTimePeriod =
  typeof ChartTimePeriod[keyof typeof ChartTimePeriod]
