import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function UnsupportedAssetsPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Unsupported Assets
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                There are certain assets Pave does not have requisite data to
                make decisions on. These assets are referred to as unsupported.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                When an asset is unsupported, it cannot be include in portfolio
                optimizations. As a result, it is recommended that these assets
                be sold out of. Unfortunately, in most cases, Pave Pro cannot
                trade these assets meaning advisors need to sell out of them
                manually.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Unsupported assets are included in portfolio value, performance,
                and allocation. However, they are not considered when
                calculating portfolio statistics.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default UnsupportedAssetsPopOver
