export const ibAccountTypeOptions = [
  { label: 'Individual', value: 'INDIVIDUAL' },
  {
    label: 'Retirement - Coming soon',
    value: 'RETIREMENT',
    isDisabled: true,
  },
]

export const IB_HOME_PAGE = 'https://www.interactivebrokers.com/en/home.php'
