import { AssetClass } from 'enums'
import { PerformancePriority } from 'enums/PerformancePriorityEnum'
import { InvestmentTemplate } from 'types'
import { InvestmentTemplateFormState } from 'types/InvestmentTemplateTypes'
import { PerformancePriorityDropdownOption } from 'types/generalTypes'

import { DropdownOptionString } from 'components/partials/DropdownWithTags'

/**
 * Shape and default values for forms editing and creating an investment template
 * Good-to-have: If necessary create different defaults to reset
 */
export const DEFAULT_INVESTMENT_TEMPLATE_FORM_STATE: InvestmentTemplateFormState =
  {
    benchmarkId: null,
    buyListId: null,
    displayName: '',

    /** Investment Preference Fields */
    assetClass: AssetClass.EQUITY,
    automation: null,
    enableTaxOptimization: null,
    excludedAssetClasses: [],
    excludedAssets: [],
    excludedIndustries: [],
    excludedSectors: [],
    fractionalShares: null,
    multiAssetClassStrategy: undefined,
    performancePriority: undefined,
    targetAssetCount: null,
    // Factor Tilts
    factorTilts: [],
    globalMacroSensitivity: '',
    interestRateSensitivity: '',
    investmentStyle: '',
    usDollarSensitivity: '',
    volatilityPreference: '',
  }

/**
 * Investment template shapes
 */
export const EMPTY_INVESTMENT_TEMPLATE: InvestmentTemplate = {
  benchmarkId: null,
  buyListId: null,
  displayName: '',
  id: '',
  investmentPreferences: {
    automation: undefined,
    assetClass: undefined,
    enableTaxOptimization: undefined,
    excludedAssetClasses: [],
    excludedAssets: [],
    excludedIndustries: [],
    excludedSectors: [],
    factorTilts: [],
    fractionalShares: undefined,
    multiAssetClassStrategy: undefined,
    performancePriority: undefined,
    targetAssetCount: null,
  },
  portfoliosCount: 0,
}

type DropdownOptionNullableBoolean = {
  label: string
  value: boolean | null
}

interface InvestmentTemplateFormOptions {
  fractionalTradingOptions: DropdownOptionNullableBoolean[]
  investmentStyleOptions: DropdownOptionString[]
  volatilityPreferenceOptions: DropdownOptionString[]
  sensitivityOptions: DropdownOptionString[]
  performancePriorityOptions: PerformancePriorityDropdownOption[]
  performancePriorityOptionsWithTaxOptimizationOff: PerformancePriorityDropdownOption[]
  enableTaxOptimizationOptions: DropdownOptionNullableBoolean[]
  assetClassOptions: DropdownOptionString[]
  automationOptions: DropdownOptionNullableBoolean[]
}
// Investment Template Form Options
export const investmentTemplateFormOptions: InvestmentTemplateFormOptions = {
  // Trading
  automationOptions: [
    {
      label: 'On',
      value: true,
    },
    {
      label: 'Off',
      value: false,
    },
  ],
  fractionalTradingOptions: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],
  enableTaxOptimizationOptions: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],

  // Strategy
  investmentStyleOptions: [
    { label: 'Balanced (Default)', value: 'balanced' },
    { label: 'Growth', value: 'growth' },
    { label: 'Value', value: 'value' },
  ],
  volatilityPreferenceOptions: [
    { label: 'Balanced (Default)', value: 'balanced' },
    { label: 'Momentum', value: 'momentum' },
    { label: 'Min Vol', value: 'minVol' },
  ],
  sensitivityOptions: [
    { label: 'Balanced (Default)', value: 'default' },
    { label: 'Overweight', value: 'overweight' },
    { label: 'Underweight', value: 'underweight' },
  ],
  performancePriorityOptions: [
    {
      label: 'Tracking Error Focus',
      value: PerformancePriority.TRACKING_ERROR,
    },
    { label: 'Balanced', value: PerformancePriority.BALANCED },
    {
      label: 'Slight Outperformance Focus (Recommended)',
      value: PerformancePriority.SLIGHTLY_O,
    },
    {
      label: 'Outperformance Focus',
      value: PerformancePriority.OUTPERFORMANCE,
    },
    {
      label: 'Strong Outperformance Focus',
      value: PerformancePriority.STRONGLY_O,
    },
  ],
  performancePriorityOptionsWithTaxOptimizationOff: [
    {
      label: 'Tracking Error Focus',
      value: PerformancePriority.TRACKING_ERROR,
    },
    {
      label: 'Balanced (Recommended)',
      value: PerformancePriority.BALANCED,
    },
    {
      label: 'Slight Outperformance Focus',
      value: PerformancePriority.SLIGHTLY_O,
    },
    {
      label: 'Outperformance Focus',
      value: PerformancePriority.OUTPERFORMANCE,
    },
    {
      label: 'Strong Outperformance Focus',
      value: PerformancePriority.STRONGLY_O,
    },
  ],
  assetClassOptions: [
    { label: 'Equity Only', value: AssetClass.EQUITY },
    { label: 'Multi-Asset Class', value: AssetClass.MULTI_ASSET_CLASS },
  ],
}
