import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import { variant } from 'styled-system'

type Size = 'sm' | 'md'
type TagStylesConfig = {
  height: string
  padding: string
  gap: string
  iconSize: string
}

type Props = HTMLAttributes<HTMLDivElement> & {
  variant?: 'gray' | 'green' | 'red' | 'white' | 'yellow'
  size?: Size
  icon?: React.ReactNode
  iconPosition?: 'right' | 'left'
  onIconClick?: () => void
}

type IconProps = {
  size: Size
}

const TAG_SIZE_CONFIG: Record<Size, TagStylesConfig> = {
  sm: {
    height: '24px',
    padding: '4px 8px',
    gap: '3px',
    iconSize: '10px',
  },
  md: {
    height: '28px',
    padding: '6px 8px',
    gap: '6px',
    iconSize: '16px',
  },
}

function Tag({
  children,
  variant = 'gray',
  size = 'md',
  icon,
  iconPosition,
  onIconClick,
  ...props
}: Props) {
  const isIconOnLeftSide = icon && iconPosition === 'left'
  const isIconOnRightSide = icon && iconPosition === 'right'
  return (
    <StyledTag variant={variant} size={size} {...props}>
      {isIconOnLeftSide ? (
        <IconContainer size={size}>{icon}</IconContainer>
      ) : null}
      {children}
      {isIconOnRightSide ? (
        <IconContainer size={size} onClick={onIconClick}>
          {icon}
        </IconContainer>
      ) : null}
    </StyledTag>
  )
}

const StyledTag = styled.div<Props>`
  display: flex;
  width: fit-content;
  height: ${({ size }) => TAG_SIZE_CONFIG[size || 'md'].height};
  padding: ${({ size }) => TAG_SIZE_CONFIG[size || 'md'].padding};
  gap: ${({ size }) => TAG_SIZE_CONFIG[size || 'md'].gap};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.xs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  justify-content: center;
  align-items: center;
  ${variant({
    variants: {
      gray: {
        color: 'coolGray.600',
        bg: 'coolGray.200',
      },
      green: {
        color: 'emerald.600',
        bg: 'emerald.50',
      },
      red: {
        color: 'red.600',
        bg: 'red.50',
      },
      yellow: {
        color: 'yellow.600',
        bg: 'yellow.50',
      },
      white: {
        color: 'coolGray.600',
        bg: 'white',
        border: '1px solid',
        borderColor: 'coolGray.300',
      },
    },
  })}
`

const IconContainer = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => TAG_SIZE_CONFIG[size || 'md'].iconSize};
  height: ${({ size }) => TAG_SIZE_CONFIG[size || 'md'].iconSize};
`

export default Tag
