import { Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import { coolGray } from 'styles/system/colors'

function PaveSecuritiesToolTip() {
  return (
    <Tooltip
      label={
        <Box m="12px" display="flex" flexDirection="column" gap="12px">
          <Text
            color="coolGray.800"
            fontWeight="700"
            fontSize="lg"
            lineHeight="3xl"
            as="h2"
          >
            What is my relationship with Pave Securities?
          </Text>
          <Text color="coolGray.700" fontWeight="regular">
            You are a customer of Pave Securities, LLC, which acts as an
            introducing broker on behalf of Interactive Brokers, Inc. (also
            referred to as IBKR). IBKR acts as custodian and clearing agent for
            all customer funds and securities.
          </Text>
        </Box>
      }
      placement="auto-start"
      bg="white"
      borderRadius="8"
    >
      <InformationCircleIcon
        color={coolGray['600']}
        height="16px"
        width="16px"
      />
    </Tooltip>
  )
}

export default PaveSecuritiesToolTip
