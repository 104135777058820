import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, PopupMenu, Text } from 'components/common'

export default function PortfolioStatisticsInformation() {
  return (
    <PopupMenu
      icon={<InformationCircleIcon color="coolGray.400" />}
      content={
        <Box display="flex" flexDirection="column">
          <Box>
            <Text fontSize="lg" fontWeight={700}>
              Portfolio Statistics Information
            </Text>
          </Box>
          <Text fontSize="xs" mt={4}>
            Portfolio statistics show how your portfolio is positioned relative
            to its benchmark. Statistics should not be read as absolutes. For
            example, a 10% better return potential does not imply the asset will
            outperform its benchmark by 10%. Each category is defined below:
          </Text>
          <Text fontSize="xs" mt={4}>
            Each category is defined below:
          </Text>

          <Text fontSize="xs" fontWeight="bold" mt={4}>
            Return Potential
          </Text>
          <Text fontSize="xs" mt={4}>
            How your portfolio compares purely in terms of return. Think of this
            as your portfolio’s ability to make money. The higher the number the
            better, but it’s important to factor in risk.
          </Text>

          <Text fontSize="xs" mt={4}>
            If a portfolio needs to take on significant risk to achieve high
            expected returns, it is likely not a great investment. That’s why
            risk adjusted returns are the best way to gauge the quality of a
            portfolio.
          </Text>

          <Text fontSize="xs" fontWeight="bold" mt={4}>
            Relative Risk
          </Text>
          <Text fontSize="xs" mt={4}>
            How your portfolio compares purely in terms of risk. Think of this
            as your portfolio’s volatility. The higher the number the more your
            portfolio could fluctuate in value. This means it could see higher
            upside but also larger losses.
          </Text>
          <Text fontSize="xs" mt={4}>
            Your benchmark is representative of your risk tolerance and Pave’s
            goal is to keep your portfolio in line with the amount of risk you
            are willing to take. As a result, the closer to 0 relative risk is
            the better, regardless of if it is higher or lower.
          </Text>
        </Box>
      }
    />
  )
}
