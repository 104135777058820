import Sparkles from 'assets/images/sparkles.svg'
import { DEPRECATEDFlexCard, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { PublicLayout, TopNav } from 'components/partials'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function UnderReviewPage() {
  return (
    <PublicLayout navbar={<TopNav />}>
      <FlexBox
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <DEPRECATEDFlexCard alignItems="center" width="34rem" height="16rem">
          <FlexBox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <img src={Sparkles} alt="Pave Logo" width={60} height={60} />

            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs24}
              fontWeight="bold"
              marginTop={SPACE.sp24}
              textAlign="center"
            >
              Your account is currently under review
            </Text>
            <Text
              color={COLOR.coolGray600}
              marginTop={SPACE.sp16}
              textAlign="center"
            >
              A member of the Pave team will reach out via the email or phone
              number provided to get you onboarded as quickly as possible.
            </Text>
          </FlexBox>
        </DEPRECATEDFlexCard>
      </FlexBox>
    </PublicLayout>
  )
}

export default UnderReviewPage
