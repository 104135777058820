import { Box, Button, DEPRECATEDFlexCard, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { openAccountAllSuggestedTradeActivities } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

import ActivityGroup, { ActivityGroupProps } from './ActivityGroup'

type Props = {
  activities: ActivityGroupProps[]
}

function SuggestedTradeActivities({ activities }: Props) {
  const dispatch = useAppDispatch()
  const count = activities.reduce((acc, ag) => acc + ag.count, 0)
  const hideButton = count === 0
  return (
    <DEPRECATEDFlexCard
      flexDirection="column"
      height="100%"
      overflowY="scroll"
      overflowX="hidden"
    >
      <FlexBox justifyContent="space-between">
        <Box>
          <Text
            fontWeight="bold"
            fontSize="xl"
            lineHeight="xl"
            color={COLOR.coolGray600}
            marginBottom={SPACE.sp8}
          >
            Suggested Trades
          </Text>
          <Text
            fontSize="xl"
            lineHeight="xl"
            color={COLOR.coolGray600}
            marginBottom={SPACE.sp8}
          >
            {` (${count})`}
          </Text>
        </Box>
        {!hideButton ? (
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => dispatch(openAccountAllSuggestedTradeActivities())}
          >
            All Suggested Trades
          </Button>
        ) : null}
      </FlexBox>
      {activities.map((ag) => {
        return <ActivityGroup key={ag.id} {...ag} />
      })}
    </DEPRECATEDFlexCard>
  )
}
export default SuggestedTradeActivities
