import styled from '@emotion/styled'

import { Text } from 'components/common'
import { COLOR } from 'styles/constants/color'

export const DEPRECATEDInteractiveText = styled(Text)`
  color: ${COLOR.coolGray500};
  :hover {
    color: ${COLOR.blue500};
    cursor: pointer;
  }
`
