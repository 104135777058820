export const SPACE = {
  sp2: '0.125rem',
  sp4: '0.25rem',
  sp6: '0.375rem',
  sp8: '0.5rem',
  sp10: '0.625rem',
  sp12: '0.75rem',
  sp14: '0.875rem',
  sp15: '0.9375rem',
  sp16: '1rem',
  sp18: '1.125rem',
  sp20: '1.25rem',
  sp22: '1.375rem',
  sp24: '1.5rem',
  sp26: '1.625rem',
  sp28: '1.75rem',
  sp30: '1.855rem',
  sp32: '2rem',
  sp34: '2.125rem',
  sp36: '2.25rem',
  sp48: '3rem',
}
