import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { paveApi } from 'api'
import { API_ERRORS } from 'api/errors'
import { Box, Button } from 'components/common'
import { PublicLayout, TopNav } from 'components/partials'
import SetupMFAForm from 'components/partials/Forms/SetupMFAForm'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'

function SetupMFAPage() {
  const navigate = useNavigate()
  const { showErrorToast } = useCustomToast()
  const { SecretCode, Session, ChallengeParameters } = useAppSelector(
    (state) => state.auth
  )
  const [verifyMfaSetup, verifyMfaSetupResult] =
    paveApi.useVerifyMfaSetupMutation()
  useEffect(() => {
    if (!SecretCode) {
      navigate('/')
    }
    if (verifyMfaSetupResult.isError) {
      const {
        data: { error },
      } = verifyMfaSetupResult.error as any
      if (error === API_ERRORS.CODE_MISMATCH) {
        showErrorToast(
          'Incorrect code entered. Please check your authenticator for the correct code.'
        )
      } else {
        showErrorToast(ERROR_MESSAGE_1)
      }
    }
  }, [verifyMfaSetupResult, showErrorToast, SecretCode, navigate])
  const onSubmit = async (formData: any) => {
    const { code } = formData
    verifyMfaSetup({
      code,
      session: Session,
    })
  }

  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/">
              <Button>Sign in</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
      >
        <SetupMFAForm
          userId={ChallengeParameters?.USER_ID_FOR_SRP || ''}
          secretCode={SecretCode || ''}
          onSubmit={onSubmit}
          mfaSetupSuccess={verifyMfaSetupResult.isSuccess}
        />
      </Box>
    </PublicLayout>
  )
}

export default SetupMFAPage
