import { MainAccountInfo } from 'types/dto/ManagementOrganization.dto'

import { Box, DEPRECATEDFlexCard, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'
import { hideAccountNumber } from 'utils/numberUtils'

type Props = {
  mainAccountsInfo: MainAccountInfo[]
}

export default function MainAccountsCard({ mainAccountsInfo }: Props) {
  return (
    <DEPRECATEDFlexCard
      flexDirection="column"
      height="100%"
      minWidth="12rem"
      overflowY="scroll"
      overflowX="hidden"
    >
      <Box>
        <Text
          fontWeight="bold"
          fontSize="lg"
          lineHeight="lg"
          color={COLOR.coolGray600}
        >
          Main Accounts
        </Text>
        {mainAccountsInfo.map((info) => (
          <Box key={info.accountNumber} marginTop={SPACE.sp4}>
            <Text marginRight={SPACE.sp16}> {`\u2022`}</Text>
            <Text color={COLOR.coolGray600}>
              {hideAccountNumber(info.accountNumber)}
            </Text>
          </Box>
        ))}
      </Box>
    </DEPRECATEDFlexCard>
  )
}
