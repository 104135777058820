import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { analytics } from 'analytics'
import { isNull } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format'

import { Asset, PortfolioLinkType } from 'types'

import { paveApi } from 'api'
import { Box, Button, Text, TextInput } from 'components/common'
import ModalLoader from 'components/partials/ModalLoader'
import { PORTFOLIO_EDIT_ASSETS_SUBMITTED } from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { closeEditPortfolioAssets } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { getPortfolioLinkType } from 'utils/portfolioUtil'

import RequiredAssetsTable from '../RequiredAssetsTable'

const EditHoldingsModal = () => {
  const dispatch = useAppDispatch()
  const { showEditPortfolioAssets, editingPortfolioAssets } = useAppSelector(
    ({ modals }) => modals
  )

  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [editingAssets, setEditingAssets] = useState<Asset[] | null>(null)
  const [editingAvailableCash, setEditingAvailableCash] = useState<
    number | null
  >(null)
  const [hasDoneChanges, setHasDoneChanges] = useState<boolean>(false)
  const [editHoldings, editHoldingsResult] = paveApi.useEditHoldingsMutation()
  const handleClose = useCallback(() => {
    dispatch(closeEditPortfolioAssets())
    setHasDoneChanges(false)
  }, [dispatch])

  const linkType = getPortfolioLinkType(editingPortfolioAssets)
  const readOnly = linkType !== PortfolioLinkType.None

  useEffect(() => {
    setEditingAssets(editingPortfolioAssets?.assets || [])
    setEditingAvailableCash(editingPortfolioAssets?.availableCash || 0)
  }, [editingPortfolioAssets])

  const handleSubmit = () => {
    if (
      isNull(editingPortfolioAssets) ||
      isNull(editingAssets) ||
      isNull(editingAvailableCash)
    ) {
      showErrorToast('An error occurred updating your assets')
      return
    }

    editHoldings({
      id: editingPortfolioAssets.id,
      body: {
        assets: editingAssets,
        availableCash: editingAvailableCash,
      },
    })
      .unwrap()
      .then(() => {
        showSuccessToast('Assets updated!')
        handleClose()
      })
      .catch(() => {
        showErrorToast('An error occurred updating your assets')
      })
    analytics.track(PORTFOLIO_EDIT_ASSETS_SUBMITTED)
  }

  const handleAddAssets = useCallback(
    (newAssets: Asset[]) => {
      setEditingAssets(newAssets)
      if (!hasDoneChanges) {
        setHasDoneChanges(true)
      }
    },
    [hasDoneChanges]
  )
  const handleAvailableCashChange = (v: NumberFormatValues) => {
    const value: number = typeof v.floatValue === 'number' ? v.floatValue : 0
    setEditingAvailableCash(value)
    if (!hasDoneChanges) {
      setHasDoneChanges(true)
    }
  }

  return (
    <Modal isOpen={showEditPortfolioAssets} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box display="flex" justifyContent="space-between">
            <Text fontSize="3xl" fontWeight="bold" color="coolGray.800">
              Edit Holdings
            </Text>
            <Button
              onClick={handleSubmit}
              disabled={!hasDoneChanges || editHoldingsResult.isLoading}
            >
              Save Changes
            </Button>
          </Box>
        </ModalHeader>
        <ModalBody>
          {editHoldingsResult.isLoading ? (
            <ModalLoader />
          ) : (
            <>
              <NumericFormat
                label="Available Cash"
                placeholder="$0.00"
                customInput={TextInput}
                value={editingAvailableCash}
                displayType="input"
                thousandSeparator={true}
                decimalScale={2}
                onValueChange={handleAvailableCashChange}
                disabled={readOnly}
                prefix="$"
                allowNegative={false}
              />
              <RequiredAssetsTable
                assets={editingAssets || []}
                setAssets={handleAddAssets}
                readOnly={readOnly}
                showTradableSwitches={true}
              />
            </>
          )}
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditHoldingsModal
