import { Box, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import FlexBox from '../FlexBox/FlexBox'

type Props = {
  items: string[] | undefined[]
  textColor?: string
}
function BulletList({ items, textColor = COLOR.coolGray600 }: Props) {
  return (
    <FlexBox flexDirection="column" alignItems="flex-start" gap={SPACE.sp12}>
      {items.map((item, index) => (
        <Box key={index}>
          {item && (
            <Box display="flex" alignItems="start" gap={SPACE.sp8}>
              <Text
                fontSize={FONT_SIZE.fs18}
                fontWeight={400}
                lineHeight="xs"
                color={textColor}
              >
                {'\u2022'}
              </Text>
              <Text
                fontSize={FONT_SIZE.fs12}
                fontWeight={400}
                lineHeight="xs"
                color={textColor}
              >
                {item}
              </Text>
            </Box>
          )}
        </Box>
      ))}
    </FlexBox>
  )
}
export default BulletList
