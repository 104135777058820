import { HomeIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useEffect } from 'react'

import countries from 'assets/countries.json'
import states from 'assets/us-states.json'
import { Box } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

import { Dropdown, TextInput } from './Fields'
import { FormInputsContainer } from './shared'

export type AccountPrimaryContactDetails = {
  addressLine1: string
  addressLine2: string
  city: string
  country: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  state: string
  zipCode: string
}

export const accountDetailsInitialValues: AccountPrimaryContactDetails = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'USA',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  state: '',
  zipCode: '',
}

type Props = {
  prefix?: string
  disableEmail?: boolean
  isRequired?: boolean
}

export function AccountDetailsFormFields({
  prefix = '',
  disableEmail = false,
  isRequired = true,
}: Props) {
  const [country, setCountry] = React.useState()
  const { values } = useFormikContext()

  if (prefix) prefix = `${prefix}.`

  const stateOptions = states.map((state: any) => ({
    value: state.abbreviation,
    label: state.name,
  }))
  const countryOptions = countries.map((country: any) => ({
    value: country.code,
    label: country.name,
  }))

  useEffect(() => {
    const country = get(values, `${prefix}country`)
    setCountry(country)
  }, [values, prefix])

  return (
    <FormInputsContainer>
      <Box display="flex" flexDirection="row" gap="12px" width="100%">
        <TextInput
          name={`${prefix}firstName`}
          width="100%"
          type="text"
          placeholder="First Name"
          label="First Name"
          required={isRequired}
        />

        <TextInput
          name={`${prefix}lastName`}
          width="100%"
          type="text"
          placeholder="Last Name"
          label="Last Name"
          required={isRequired}
        />
      </Box>
      <TextInput
        name={`${prefix}email`}
        width="100%"
        type="email"
        placeholder="Email Address"
        label="Email Address"
        icon={
          <MailIcon
            height={SPACE.sp14}
            width={SPACE.sp14}
            color={COLOR.coolGray400}
          />
        }
        required={isRequired}
        disabled={disableEmail}
      />

      <TextInput
        name={`${prefix}phoneNumber`}
        width="100%"
        type="tel"
        placeholder="Phone Number"
        label="Phone Number"
        required={isRequired}
        icon={
          <PhoneIcon
            height={SPACE.sp14}
            width={SPACE.sp14}
            color={COLOR.coolGray400}
          />
        }
      />
      <TextInput
        name={`${prefix}addressLine1`}
        width="100%"
        type="text"
        placeholder="Street Address"
        label="Home Address"
        required={isRequired}
        icon={
          <HomeIcon
            height={SPACE.sp14}
            width={SPACE.sp14}
            color={COLOR.coolGray400}
          />
        }
      />
      <TextInput
        name={`${prefix}addressLine2`}
        width="100%"
        type="text"
        placeholder="Apt, Suite, Unit, Building, Floor, etc."
      />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="12px"
      >
        <TextInput
          name={`${prefix}city`}
          width="100%"
          type="text"
          placeholder="City"
        />

        {country === 'US' || country === 'USA' ? (
          <Dropdown
            name={`${prefix}state`}
            placeholder="State"
            options={stateOptions}
          />
        ) : (
          <TextInput
            name={`${prefix}state`}
            width="100%"
            type="text"
            placeholder="State"
            required={isRequired}
          />
        )}

        <TextInput
          name={`${prefix}zipCode`}
          width="100%"
          type="text"
          placeholder="ZIP Code"
        />
      </Box>
      <Dropdown
        name={`${prefix}country`}
        placeholder="Country"
        options={countryOptions}
      />
    </FormInputsContainer>
  )
}
