import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Text } from 'components/common'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export default function PerformanceInformationModal({
  isOpen,
  onClose,
}: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Performance Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody marginBottom="1rem">
            <Text as="p" mb="12px">
              Pave Pro shows your portfolio’s daily performance. If you would
              like to see your portfolio’s full performance, please click the
              “See Full Performance” button in the top right corner of the
              panel.
            </Text>
            <Text as="p">
              If a deposit or withdrawal is processed over the course of the
              day, daily performance for that day may be inaccurate as it may
              include the value of any deposits or withdrawals.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
