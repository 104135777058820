import { StarIcon as EmptyStarIcon } from '@heroicons/react/outline'
import { StarIcon as SolidStarIcon } from '@heroicons/react/solid'
import React from 'react'

import { Box } from 'components/common'

type Props = {
  maxStars?: number
  filledStars: number
}

function StarRating({ maxStars = 5, filledStars }: Props) {
  return (
    <Box display="flex" flexDirection="row" gap="8px">
      {[...Array(maxStars)].map((star, index) => {
        index += 1
        return (
          <Box key={index} width="20px" height="20px" color="blueGray.500">
            {index > filledStars ? <EmptyStarIcon /> : <SolidStarIcon />}
          </Box>
        )
      })}
    </Box>
  )
}

export default StarRating
