import styled from '@emotion/styled'
import React from 'react'

type Props = {
  navbar: React.ReactNode
  children: React.ReactNode
}

function PublicLayout({ navbar, children }: Props) {
  return (
    <Container>
      {navbar}
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 88px 1fr;
  grid-template-areas:
    'topnav'
    'content';
  min-height: 100vh;
`
const ContentWrapper = styled.div`
  grid-area: content;
  background-color: ${(props) => props.theme.colors.gray[50]};
`

export default PublicLayout
