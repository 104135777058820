import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'

import { themes } from 'styles/system/themes'

type Props = {
  children: React.ReactNode
}

const theme = extendTheme(themes.chakra)

function ChakraThemeProvider({ children }: Props) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}

export default ChakraThemeProvider
