import { analytics } from 'analytics'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { paveApi } from 'api'
import { API_ERRORS } from 'api/errors'
import { Box, Button } from 'components/common'
import { PublicLayout, TopNav } from 'components/partials'
import ConfirmUserForm from 'components/partials/Forms/ConfirmUserForm'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { SIGNUP_EMAIL_CONFIRMED } from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'

function ConfirmUserPage() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { email: signUpEmail } = useAppSelector((state) => state.signUp)
  const { email: forgotPasswordEmail } = useAppSelector(
    (state) => state.forgotPassword
  )
  const email = signUpEmail || forgotPasswordEmail
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [confirmUser, { isLoading: isConfirmLoading }] =
    paveApi.useConfirmUserMutation()
  const [resendConfirmationCode] = paveApi.useResendConfirmationCodeMutation()

  const onResendConfirmationCode = () => {
    resendConfirmationCode({ email })
      .unwrap()
      .then(() => {
        showSuccessToast('Confirmation code sent.')
      })
      .catch(() => {
        showErrorToast('Error sending confirmation code.')
      })
  }

  const onSubmit = (formData: any) => {
    const { code } = formData
    analytics.track(SIGNUP_EMAIL_CONFIRMED)
    confirmUser({ code, email })
      .unwrap()
      .then(() => {
        showSuccessToast('Confirmation successful.')
        navigate('/')
      })
      .catch((error) => {
        const { error: errorCode } = error.data
        if (errorCode === API_ERRORS.CODE_MISMATCH) {
          showErrorToast(
            'Incorrect code entered. Please check your email for the correct code.'
          )
        } else if (errorCode === API_ERRORS.USER_ALREADY_CONFIRMED) {
          showSuccessToast('User already confirmed. Please sign in.')
          setTimeout(() => {
            navigate('/')
          }, 3000)
        } else {
          showErrorToast(ERROR_MESSAGE_1)
        }
      })
  }

  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/">
              <Button>Sign in</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
      >
        <ConfirmUserForm
          email={email}
          isButtonDisabled={isConfirmLoading}
          onResendConfirmationCode={onResendConfirmationCode}
          onSubmit={onSubmit}
          redirectedFromLogin={state?.redirectedFromLogin}
        />
      </Box>
    </PublicLayout>
  )
}

export default ConfirmUserPage
