import { DrawerBody } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'

import { paveApi } from 'api'
import { Box, Dropdown, Text } from 'components/common'
import {
  AccountDetailsFormFields,
  AccountPrimaryContactDetails,
} from 'components/partials/Forms/AccountDetailsFormFields'
import { TextInput } from 'components/partials/Forms/Fields'
import { FormInputsContainer } from 'components/partials/Forms/shared'
import { DISPLAY_NAME_LENGTH_LIMIT } from 'constants/general.constants'
import { useAppSelector } from 'store/storeHooks'

import { DropdownOptionString } from '../DropdownWithTags'

export type AccountFields = {
  displayName: string
  description: string
  primaryContact: AccountPrimaryContactDetails
}

type Props = {
  formId: string
  onSubmit: (values: AccountFields) => void
  initialValues: AccountFields
}
function ClientAccountForm({ formId, onSubmit, initialValues }: Props) {
  const { managementOrganizationId } = useAppSelector(
    (state: any) => state.user
  )

  const { data: accountsInfo, isLoading } =
    paveApi.useGetManagementOrganzationAccountsInfoQuery(
      managementOrganizationId || '',
      {
        skip: !managementOrganizationId,
        refetchOnMountOrArgChange: true,
      }
    )

  const accountsInfoOptions = accountsInfo?.map((c) => {
    return {
      value: c.subAccountNumber,
      label: `${c.firstName} ${c.lastName} ${c.email}`,
    }
  }) as DropdownOptionString[]
  const [currentValues, setCurrentValues] =
    useState<AccountFields>(initialValues)

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={currentValues}
      enableReinitialize
    >
      <Form id={formId}>
        <DrawerBody>
          <Box display="grid" width="100%" gap="24px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              padding="0px"
              gap="12px"
              width="100%"
            >
              <Text
                fontWeight="bold"
                fontSize="lg"
                lineHeight="lg"
                color="coolGray.600"
              >
                General Information
              </Text>
              <FormInputsContainer>
                <TextInput
                  label="Group Name"
                  maxLength={DISPLAY_NAME_LENGTH_LIMIT}
                  name="displayName"
                  placeholder="Group Name"
                  type="text"
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newDisplayName = e.target.value.trim()
                    setCurrentValues({
                      ...currentValues,
                      displayName: newDisplayName,
                    })
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newDisplayName = e.target.value
                    setCurrentValues({
                      ...currentValues,
                      displayName: newDisplayName,
                    })
                  }}
                  required
                  value={currentValues.displayName}
                  width="100%"
                />
                <TextInput
                  label="Group Description"
                  name="description"
                  onChange={(e) => {
                    const newDescription = e.target.value
                    setCurrentValues({
                      ...currentValues,
                      description: newDescription,
                    })
                  }}
                  placeholder="Group Description"
                  type="text"
                  value={currentValues.description}
                  width="100%"
                />
              </FormInputsContainer>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              padding="0px"
              gap="12px"
              width="100%"
            >
              <Text
                fontWeight="bold"
                fontSize="lg"
                lineHeight="lg"
                color="coolGray.600"
              >
                Primary Contact
              </Text>
              {!isLoading && accountsInfoOptions && (
                <Dropdown
                  label="Import Contact Info"
                  placeholder="Select contact info to import"
                  options={accountsInfoOptions}
                  onChange={(option: unknown) => {
                    const { value } = option as { value: string }
                    const selected = accountsInfo?.find(
                      (accountInfo) => accountInfo.subAccountNumber === value
                    )
                    const formattedContactDetails: AccountPrimaryContactDetails =
                      {
                        addressLine1: selected?.addressLine1 || '',
                        addressLine2: selected?.addressLine2 || '',
                        city: selected?.city || '',
                        country: selected?.country || '',
                        email: selected?.email || '',
                        firstName: selected?.firstName || '',
                        lastName: selected?.lastName || '',
                        phoneNumber: selected?.phoneNumber || '',
                        state: selected?.state || '',
                        zipCode: selected?.zipCode || '',
                      }
                    const formattedAccount: AccountFields = {
                      ...currentValues,
                      primaryContact: formattedContactDetails,
                    }
                    setCurrentValues(formattedAccount)
                  }}
                />
              )}
              <AccountDetailsFormFields
                prefix="primaryContact"
                isRequired={false}
              />
            </Box>
          </Box>
        </DrawerBody>
      </Form>
    </Formik>
  )
}
export default ClientAccountForm
