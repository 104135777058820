import styled from '@emotion/styled'
import { analytics } from 'analytics'
import { isEmpty } from 'lodash'

import { Portfolio, PortfolioLinkType } from 'types'

import { ReactComponent as IBLogo } from 'assets/icons/IBKR.svg'
import { ReactComponent as PaveLogo } from 'assets/icons/PAVE.svg'
import { ReactComponent as PlaidLogo } from 'assets/icons/PLAID.svg'
import { ReactComponent as SchwabLogo } from 'assets/icons/SCHWAB.svg'
import { Box, Button, Separator, Text } from 'components/common'
import {
  InvestmentPreferences,
  PaveSecuritiesTooltip,
  ThirdPartyTooltip,
} from 'components/partials'
import { EDIT_CONNECTIONS_MODAL_OPENED } from 'constants/track.constants'
import { PORTFOLIO_EDIT_CLICKED } from 'constants/track.constants'

type Props = {
  portfolio?: Portfolio
  linkType: PortfolioLinkType
  openEditGeneralSettings: Function
  openEditPortfolio: Function
  disabled?: boolean
}

function PortfolioPreferences({
  portfolio,
  linkType,
  openEditGeneralSettings,
  openEditPortfolio,
  disabled = false,
}: Props) {
  const { displayName, brokerageAccounts, isThirdPartyManaged } =
    portfolio || {}

  const createdAtDate = new Date(portfolio?.createdAt || '')
  const managedSince = createdAtDate.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })

  const handleEditPortfolio = () => {
    openEditPortfolio()
    analytics.track(PORTFOLIO_EDIT_CLICKED)
  }

  const handleEditGeneralSettings = () => {
    openEditGeneralSettings()
    analytics.track(EDIT_CONNECTIONS_MODAL_OPENED)
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding="20px 24px"
      backgroundColor="white"
      borderRadius="8px"
      gap="20px"
      width="100%"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0px"
        gap="8px"
        width="100%"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          lineHeight="md"
          color="coolGray.700"
        >
          Portfolio Settings
        </Text>
      </Box>
      <Separator color="coolGray.300" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="0px"
        gap="12px"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          padding="0px"
          gap="24px"
          justifyContent="space-between"
          width="100%"
        >
          <Text
            fontSize="sm"
            fontWeight="bold"
            lineHeight="xs"
            color="coolGray.500"
          >
            General Settings
          </Text>
          <Button
            size="xs"
            variant="tertiary"
            onClick={handleEditGeneralSettings}
            disabled={disabled}
          >
            Edit
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="12px"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="0px"
            gap="24px"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              fontSize="xs"
              fontWeight="bold"
              lineHeight="xs"
              color="coolGray.700"
            >
              {displayName}
            </Text>
            {linkType === PortfolioLinkType.Plaid && <PlaidLogo />}
            {linkType === PortfolioLinkType.InteractiveBrokers && <IBLogo />}
            {linkType === PortfolioLinkType.PaveSecurities && <PaveLogo />}
            {linkType === PortfolioLinkType.CharlesSchwab && <SchwabLogo />}
          </Box>
          <PortfolioDetails>
            {!isEmpty(brokerageAccounts) && (
              <li>
                <Text
                  fontSize="xs"
                  fontWeight="regular"
                  lineHeight="xs"
                  color="coolGray.700"
                  display="flex"
                >
                  {brokerageAccounts?.[0].brokerName}
                  {linkType === PortfolioLinkType.PaveSecurities && (
                    <Box ml="xs">
                      <PaveSecuritiesTooltip />
                    </Box>
                  )}
                </Text>
              </li>
            )}
            <li>
              <Text
                fontSize="xs"
                fontWeight="regular"
                lineHeight="xs"
                color="coolGray.700"
              >
                {`Managed since ${managedSince}`}
              </Text>
            </li>
            {isThirdPartyManaged && (
              <li>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="4px"
                >
                  <Text
                    fontSize="xs"
                    fontWeight="regular"
                    lineHeight="xs"
                    color="coolGray.700"
                  >
                    Managed by 3rd Party
                  </Text>

                  <ThirdPartyTooltip />
                </Box>
              </li>
            )}
          </PortfolioDetails>
        </Box>
      </Box>
      <InvestmentPreferences
        portfolio={portfolio}
        handleEditPortfolio={handleEditPortfolio}
        linkType={linkType}
        disabled={disabled}
      />
    </Container>
  )
}

const PortfolioDetails = styled.ul`
  padding-left: 20px;
  padding-top: 0px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.coolGray[700]};
  li:not(:last-child) {
    margin-bottom: 12px;
  }
`
const Container = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.coolGray[200]};
`
export default PortfolioPreferences
