import { Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import { coolGray } from 'styles/system/colors'

function ThirdPartyTooltip() {
  return (
    <Tooltip
      label={
        <Box m="12px" display="flex" flexDirection="column" gap="12px">
          <Text
            color="coolGray.800"
            fontWeight="700"
            fontSize="lg"
            lineHeight="3xl"
            as="h2"
          >
            What is 3rd Party Managed?
          </Text>
          <Text color="coolGray.700" fontWeight="regular">
            You have designated this account as 3rd party managed. As a result,
            we can track portfolio performance and statistics, but will not
            provide suggested trades.
          </Text>
          <Text color="coolGray.700" fontWeight="regular">
            If you would like to change this, please edit your investment
            preferences.
          </Text>
        </Box>
      }
      placement="auto-start"
      bg="white"
      borderRadius="8"
    >
      <InformationCircleIcon
        color={coolGray['600']}
        height="18px"
        width="18px"
      />
    </Tooltip>
  )
}

export default ThirdPartyTooltip
