import { Global, css } from '@emotion/react'
import { normalize } from 'polished'
import React from 'react'

import InterBold from './fonts/Inter-Bold.ttf'
import InterExtraBold from './fonts/Inter-ExtraBold.ttf'
import InterExtraLight from './fonts/Inter-ExtraLight.ttf'
import InterLight from './fonts/Inter-Light.ttf'
import InterMedium from './fonts/Inter-Medium.ttf'
import InterRegular from './fonts/Inter-Regular.ttf'
import InterSemiBold from './fonts/Inter-SemiBold.ttf'

const styles = css`
  ${normalize()}

  html {
    font-size: 16px;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: 'Inter', sans-serif;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(${InterExtraLight}) format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterExtraBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }
`

const GlobalStyles = () => <Global styles={styles} />

export default GlobalStyles
