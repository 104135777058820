import { Box, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Props = {
  accountName: string
}
function AccountHeader(props: Props) {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Text
          color={COLOR.coolGray600}
          fontWeight="700"
          fontSize={FONT_SIZE.fs16}
        >
          Group Overview
        </Text>
        <Text
          fontSize={FONT_SIZE.fs28}
          color={COLOR.coolGray600}
          fontWeight="700"
        >
          {props.accountName}
        </Text>
      </Box>
    </Box>
  )
}

export default AccountHeader
