import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  hideModal: () => void
  onClick: () => void
  showModal: boolean
  isSchwabPortfolio: boolean
}

export default function ConfirmDeletePortfolioModal({
  hideModal,
  onClick,
  showModal,
  isSchwabPortfolio,
}: Props) {
  return (
    <Modal isOpen={showModal} onClose={hideModal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              Delete portfolio?
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <FlexBox flexDirection="column" gap={SPACE.sp12}>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              Are you sure you want to delete this portfolio? Doing so will
              remove it from its group and delete the portfolio’s performance,
              trade history, and investment preference data.
            </Text>
            {isSchwabPortfolio && (
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                The account connected to this portfolio can still be found
                within the connected accounts page.
              </Text>
            )}
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              This action cannot be undone.
            </Text>
          </FlexBox>
        </ModalBody>

        <ModalFooter marginBottom={SPACE.sp8}>
          <Box width="100%">
            <Box marginBottom={SPACE.sp12}>
              <Button onClick={onClick} size="md" variant="danger" width="100%">
                Delete
              </Button>
            </Box>

            <Button
              onClick={hideModal}
              size="md"
              variant="secondary"
              width="100%"
              active={false}
            >
              Cancel
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
