import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SPACE } from 'styles/constants/space'

function BuyListCardOwnerHeader() {
  return (
    <>
      <Box flex={1} paddingRight={SPACE.sp12}>
        <Text>NAME</Text>
      </Box>
      <Box flex={1}>
        <Text>NUMBER OF ASSETS</Text>
      </Box>
      <Box flex={1}>
        <Text>LINKED PORTFOLIOS</Text>
      </Box>
      <FlexBox justifyContent="flex-end" flex={1} gap={SPACE.sp12}>
        <Text>NUMBER OF USERS</Text>
      </FlexBox>
    </>
  )
}

export default BuyListCardOwnerHeader
