import { analytics } from 'analytics'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Button, Dropdown, SearchBar, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import CardSection from 'components/common/Section/CardSection'
import { ListView } from 'components/partials'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { CONNECTED_ACCOUNTS_ADD_GROUP_CLICKED } from 'constants/track.constants'
import { openAddAccount } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

import ConnectedAccountsGroupListItem from './ConnectedAccountsGroupListItem'

type Props = {
  accounts: ClientAccountDto[]
}

function ConnectedAccountsGroupCardSection({ accounts }: Props) {
  const [search, setSearch] = React.useState('')

  const dispatch = useAppDispatch()

  const [accountsList, setAccountsList] = useState(accounts)
  const [page, setPage] = useState(1)
  const [accountsCount, setAccountsCount] = useState(accounts.length)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )
  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: accountsCount,
  })

  useEffect(() => {
    let clonedAccounts = jsonClone(accounts) as ClientAccountDto[]

    const filteredAccounts = clonedAccounts.filter((item) =>
      searchFilter(item, 'displayName', search)
    )
    const accountsToShow = filteredAccounts.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )

    setAccountsList(accountsToShow)
    setAccountsCount(filteredAccounts.length)
  }, [accounts, search, page, selectedItemsPerPageOption])

  const handleAddAccount = () => {
    dispatch(openAddAccount())
    analytics.track(CONNECTED_ACCOUNTS_ADD_GROUP_CLICKED)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <CardSection>
      <FlexBox flexWrap="wrap" gap={SPACE.sp24}>
        <Box display="grid" gap={SPACE.sp12}>
          <FlexBox justifyContent="space-between" marginTop={SPACE.sp8}>
            <Box>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs18}
                fontWeight="700"
              >
                Groups{' '}
              </Text>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs18}
                fontWeight="400"
              >
                ({accountsCount})
              </Text>
            </Box>
            <Button variant="tertiary" size="xs" onClick={handleAddAccount}>
              Create Group
            </Button>
          </FlexBox>
          <Box
            width="100%"
            display="flex"
            minWidth="24rem"
            gap={SPACE.sp12}
            alignItems="center"
            flexDirection="row"
          >
            <Box width="50%">
              <Dropdown
                options={itemsPerPageOptions}
                onChange={(option: any) => {
                  setSelectedItemsPerPageOption(option)
                  setPage(1)
                }}
                value={selectedItemsPerPageOption}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                  }),
                }}
              />
            </Box>
            <Box width="100%">
              <Box gridColumn="span 6">
                <SearchBar
                  placeholder="Search by group name"
                  value={search}
                  onChange={handleSearch}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <ListView
          gap={SPACE.sp12}
          data={orderBy(accountsList, 'displayName')}
          renderItem={(item) => (
            <ConnectedAccountsGroupListItem key={item.id} {...item} />
          )}
        />

        <Pagination
          currentPage={page}
          handlePageChange={handlePageChange}
          itemsCount={accountsCount}
          itemsPerPage={selectedItemsPerPageOption.value}
        />
      </FlexBox>
    </CardSection>
  )
}

export default ConnectedAccountsGroupCardSection
