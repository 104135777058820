import styled from '@emotion/styled'

import { Box } from 'components/common'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.coolGray[200]};
  border-radius: 8px;
  height: 100%;
  overflow-y: scroll;
`

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;
  height: 32px;
  width: 100%;
`
