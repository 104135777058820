import { Avatar } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useState } from 'react'

import { PlaidAccount } from 'types/plaid'

import { ReactComponent as PlaidLogo } from 'assets/icons/PLAID.svg'
import { Box, Checkbox, Text } from 'components/common'
import { formatAsCurrency } from 'utils'

type Props = {
  account: PlaidAccount
  isChecked: boolean | undefined
  handleChange: (e: any) => void
}

function PlaidAccountItem({ account, isChecked, handleChange }: Props) {
  const [isCheckedState, setIsChecked] = useState(isChecked)
  return (
    <Account>
      <Box display="flex" flexDirection="row" gap="8px">
        <Box>
          <Avatar size="xs" icon={<PlaidLogo />} bg="white.500" />
        </Box>
        <Box display="flex" flexDirection="column">
          <Text fontSize="sm" fontWeight="600" color="coolGray.600" as="p">
            {account.name}
          </Text>
          <Text fontSize="sm" fontWeight="400" color="coolGray.500" as="p">
            {formatAsCurrency(account.balances.current)}
          </Text>
        </Box>
      </Box>
      <Box>
        <Checkbox
          width="24px"
          height="24px"
          onClick={() => {
            handleChange({
              value: account.account_id,
            })
            setIsChecked(!isCheckedState)
          }}
          checked={isCheckedState}
        />
      </Box>
    </Account>
  )
}

const Account = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.coolGray[300]};
  border-radius: 8px;
`

export default PlaidAccountItem
