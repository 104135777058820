import { useToast } from '@chakra-ui/react'
import { useMemo } from 'react'

import { CustomToast } from 'components/common/Toast/CustomToast'

export function useCustomToast() {
  const toast = useToast()

  const customToast = useMemo(
    () => ({
      showErrorToast: function (title: string, description?: string) {
        toast({
          position: 'bottom-right',
          status: 'error',
          isClosable: true,
          render: () => CustomToast({ title, description, type: 'Error' }),
        })
      },

      showSuccessToast: function (title: string, description?: string) {
        toast({
          position: 'bottom-right',
          status: 'success',
          isClosable: true,
          render: () => CustomToast({ title, description, type: 'Success' }),
        })
      },

      showInfoToast: function (title: string, description?: string) {
        toast({
          position: 'bottom-right',
          status: 'info',
          isClosable: true,
          render: () => CustomToast({ title, description, type: 'Info' }),
        })
      },
    }),
    [toast]
  )

  return customToast
}
