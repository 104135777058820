import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, LinkText, Text } from 'components/common'
import { INVESTMENT_ADVISORY_AGREEMENT_URL } from 'constants/general.constants'
import { coolGray } from 'styles/system/colors'

function AutomationPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={coolGray['600']}
            height="18px"
            width="18px"
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <Box m="12px" display="flex" flexDirection="column" gap="12px">
              <Text
                color="coolGray.800"
                fontWeight="700"
                fontSize="lg"
                lineHeight="3xl"
                as="h2"
              >
                What is automation?
              </Text>
              <Text color="coolGray.700" fontWeight="regular">
                When you turn on automation, you are hiring Pave Investment
                Advisors, LLC as the discretionary investment adviser for your
                account.{' '}
                <LinkText
                  href={INVESTMENT_ADVISORY_AGREEMENT_URL}
                  label="Click here"
                  bold
                  openInNewTab
                />{' '}
                to review your Investment Advisory Agreement with Pave
                Investment Advisors, LLC.
              </Text>
              <Text color="coolGray.700" fontWeight="regular">
                Additionally, turning on automation means you agree to receive
                the Investment Advisory Agreement, Form ADV Part 2A, Form CRS,
                as well as other relevant information about the adviser and your
                relationship as a client of the adviser.
              </Text>
            </Box>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default AutomationPopOver
