import { Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { analytics } from 'analytics'
import { size } from 'lodash'
import { useMemo, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CONNECTION_TYPE, Portfolio } from 'types'
import { ErrorObject } from 'types/generalTypes'

import { paveApi } from 'api'
import { Box, Button, Dropdown, Text, TextInput } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SlideOutMenu } from 'components/partials'
import ModalLoader from 'components/partials/ModalLoader'
import {
  portfolioFormReducer,
  setActiveConnectedAccountIds,
  setClientAccountId,
  setDisplayName,
} from 'components/reducers/portfolioFormReducer'
import { API_TAG } from 'constants/app.constants'
import { DISPLAY_NAME_LENGTH_LIMIT } from 'constants/general.constants'
import {
  PORTFOLIO_DELETE_CLICKED,
  PORTFOLIO_DELETE_CONFIRMED,
} from 'constants/track.constants'
import { REQUIRED_FIELD } from 'constants/validation.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatToPortfolioForm } from 'utils/portfolioFormUtil'
import { canDeletePortfolio, isPlaidPortfolio } from 'utils/portfolioUtil'

import ConfirmDeletePortfolioModal from './Account/ConfirmDeletePortfolioModal'
import PlaidLinkedAccounts from './BrokerageAccounts/PlaidLinkedAccounts'
import SchwabAccountItem from './BrokerageAccounts/SchwabAccountItem'
import { DropdownOptionString } from './DropdownWithTags'

type Props = {
  closeModal: () => void
  portfolio: Portfolio
  showModal: boolean
}

function EditGeneralSettingsSlideIn({
  closeModal,
  portfolio,
  showModal,
}: Props) {
  const { managementOrganizationId } = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  const [editConnections] = paveApi.useEditConnectionsMutation()
  const [patchPortfolio, { isLoading: isPatchLoading }] =
    paveApi.usePatchPortfolioMutation()
  const { data: clientAccounts } = paveApi.useGetClientAccountNamesAndIdsQuery(
    {
      managementOrgId: managementOrganizationId as string,
    } || {},
    {
      skip: !managementOrganizationId,
      refetchOnMountOrArgChange: true,
    }
  )
  const [deletePortfolio, { isLoading: isDeleteLoading }] =
    paveApi.useDeletePortfolioMutation()
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const initialPortfolioFormData = useMemo(() => {
    return formatToPortfolioForm(portfolio, false)
  }, [portfolio])
  const [formState, dispatch] = useReducer(
    portfolioFormReducer,
    initialPortfolioFormData
  )

  const isDisabled = isPatchLoading || isDeleteLoading

  const isDeleteEnabled = canDeletePortfolio(portfolio)
  const [isDeletePortfolioConfirmOpen, setIsDeletePortfolioConfirmOpen] =
    useState(false)
  const [formErrors, setFormErrors] = useState<ErrorObject>({})

  const openDeleteConfirm = () => {
    setIsDeletePortfolioConfirmOpen(true)
    analytics.track(PORTFOLIO_DELETE_CLICKED)
  }

  const validateData = () => {
    let newErrors: ErrorObject = {}

    if (!formState.displayName) {
      newErrors.displayName = REQUIRED_FIELD
    }

    setFormErrors(newErrors)
    return {
      isValidData: size(newErrors) === 0,
      errors: newErrors,
    }
  }

  const handleDelete = () => {
    analytics.track(PORTFOLIO_DELETE_CONFIRMED)
    setIsDeletePortfolioConfirmOpen(false)
    deletePortfolio(portfolio.id || '')
      .unwrap()
      .then(() => {
        showSuccessToast('Portfolio deleted!')
        closeModal()
        navigate(`/account/${portfolio?.clientAccountId}`)
      })
      .catch(() => {
        showErrorToast('An error occurred deleting your portfolio')
      })
  }

  const handleSubmit = () => {
    const { isValidData } = validateData()
    if (isValidData) {
      if (isPlaidPortfolio(portfolio)) {
        // TODO: deprecate plaid usage or add a plaid specific patch endpoint
        editConnections({
          id: portfolio.id,
          clientAccountId: portfolio.clientAccountId,
          activeConnectedAccountIds: formState.activeConnectedAccountIds,
        })
      }

      patchPortfolio({
        body: {
          id: portfolio.id,
          clientAccountId: formState.clientAccountId,
          displayName: formState.displayName,
        },
        invalidatesTags: [API_TAG.GROUP],
      })
        .unwrap()
        .then(() => {
          showSuccessToast('General settings updated!')
          closeModal()
        })
        .catch((e) => {
          const displayMessage = e?.data?.displayMessage
          if (displayMessage) {
            showErrorToast(displayMessage)
          } else {
            showErrorToast('An error occurred updating your general settings')
          }
        })
    } else {
      showErrorToast('Please complete the form to continue')
    }
  }

  const clientAccountOptions = clientAccounts?.map((c) => {
    return {
      value: c.id,
      label: c.displayName,
    }
  }) as DropdownOptionString[]

  const isSchwabPortfolio =
    portfolio?.brokerageAccounts[0]?.connectionType &&
    portfolio?.brokerageAccounts[0].connectionType === CONNECTION_TYPE.SCHWAB
  const accountNumber = isSchwabPortfolio
    ? portfolio.brokerageAccounts[0]?.accountInfo?.accountNumber
    : ''

  return (
    <>
      <SlideOutMenu
        isOpen={showModal}
        onClose={closeModal}
        title="Edit General Settings"
        size="xl"
        formId="connections-form"
        disabled={isDisabled}
        customHeaderButtons={
          <Box display="flex" gap="12px" alignItems="center">
            {portfolio.isTrading && (
              <Tooltip
                backgroundColor={COLOR.white}
                border={`1px solid ${COLOR.coolGray300}`}
                borderRadius="8"
                placement="auto-start"
                label={
                  <FlexBox
                    color={COLOR.coolGray600}
                    flexDirection="column"
                    gap={SPACE.sp12}
                    margin={SPACE.sp12}
                  >
                    <Text fontWeight="700" fontSize={FONT_SIZE.fs18}>
                      Why am I unable to delete this Portfolio?
                    </Text>
                    <Text color={COLOR.coolGray700} fontWeight="regular">
                      When portfolios are trading, they cannot be deleted.
                      Please wait until trading has completed and then try
                      again.
                    </Text>
                  </FlexBox>
                }
              >
                <InformationCircleIcon
                  color={COLOR.coolGray500}
                  height="1.25rem"
                  width="1.25rem"
                />
              </Tooltip>
            )}
            <Button
              type="button"
              variant="danger"
              size="md"
              disabled={!isDeleteEnabled}
              onClick={openDeleteConfirm}
            >
              Delete
            </Button>

            <Button
              type="submit"
              form="connections-form"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" gap="12px">
          <TextInput
            errorMessage={formErrors.displayName}
            label="Portfolio Name"
            maxLength={DISPLAY_NAME_LENGTH_LIMIT}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setDisplayName(e.target.value.trim()))
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setDisplayName(e.target.value))
            }
            placeholder="Placeholder Text"
            value={formState.displayName}
          />

          <Dropdown
            label="Group"
            placeholder="Select"
            options={clientAccountOptions}
            onChange={(option: unknown) => {
              const { value } = option as { value: string }
              dispatch(setClientAccountId(value))
            }}
            value={clientAccountOptions?.find(
              (elem) => elem.value === formState?.clientAccountId
            )}
          />

          {(isSchwabPortfolio || isPlaidPortfolio(portfolio)) && (
            <Box>
              {isDisabled && <ModalLoader />}
              <Text fontSize="sm" fontWeight="600" color="coolGray.500">
                Connected Accounts
              </Text>
              {isPlaidPortfolio(portfolio) ? (
                <>
                  <PlaidLinkedAccounts
                    brokerageAccounts={portfolio.brokerageAccounts}
                    setActiveConnectedAccountIds={(value) =>
                      dispatch(setActiveConnectedAccountIds(value))
                    }
                    formState={formState}
                  />
                  <Box height={5} width="100%"></Box>
                </>
              ) : (
                <>
                  <SchwabAccountItem accountNumber={accountNumber ?? ''} />
                  <Box height={5} width="100%"></Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </SlideOutMenu>
      <ConfirmDeletePortfolioModal
        showModal={isDeletePortfolioConfirmOpen}
        hideModal={() => setIsDeletePortfolioConfirmOpen(false)}
        onClick={handleDelete}
        isSchwabPortfolio={Boolean(isSchwabPortfolio)}
      />
    </>
  )
}
export default EditGeneralSettingsSlideIn
