import styled from '@emotion/styled'
import { analytics } from 'analytics'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Button, Separator, Text } from 'components/common'
import BulletList from 'components/common/BulletList/BulletList'
import { GROUP_EDIT_PRIMARY_CONTACT_CLICKED } from 'constants/track.constants'
import { openEditAccountSettings } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  account: ClientAccountDto | undefined
}
function AccountPreferences({ account }: Props) {
  const dispatch = useAppDispatch()
  const { primaryContact } = account || {}

  const preferences = {
    displayName: account?.displayName,
    description: account?.description,
    localTime: account?.localTimezone,
    timeZone: '',
    primaryContact: {
      name: primaryContact?.firstName
        ? `${primaryContact.firstName} ${primaryContact.lastName}`
        : '',
      phone: primaryContact?.phoneNumber,
      email: primaryContact?.email,
      address: [
        primaryContact?.addressLine1,
        primaryContact?.city && `, ${primaryContact.city}`,
        primaryContact?.state && `, ${primaryContact.state}`,
        primaryContact?.zipCode && `, ${primaryContact.zipCode}`,
      ]
        .filter(Boolean)
        .join(''),
    },
    meetingPreferences: {
      cadence: 'Monthly',
      nextMeeting: 'August 15, 2022',
    },
  }

  const isPrimaryContactEmpty = Object.values(preferences.primaryContact).every(
    (x) => !x
  )

  const handleEditPrimaryContact = () => {
    analytics.track(GROUP_EDIT_PRIMARY_CONTACT_CLICKED)
    dispatch(openEditAccountSettings())
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding="20px 24px"
      backgroundColor={COLOR.white}
      borderRadius="8px"
      gap={SPACE.sp12}
      width="100%"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        gap={SPACE.sp8}
        width="100%"
        alignItems="center"
      >
        <Text
          fontSize="xl"
          fontWeight="700"
          lineHeight="lg"
          color="coolGray.700"
        >
          Group Settings
        </Text>
        <Button size="xs" variant="tertiary" onClick={handleEditPrimaryContact}>
          Edit
        </Button>
      </Box>
      <Separator color="coolGray.300" strokeWidth={1} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={SPACE.sp12}
        width="100%"
      >
        <Text
          fontSize={FONT_SIZE.fs14}
          fontWeight="700"
          color={COLOR.coolGray600}
          lineHeight="sm"
        >
          General Information
        </Text>
        <Box display="grid" gap={SPACE.sp4}>
          <Text
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray600}
            fontWeight={700}
          >
            Name
          </Text>
          <Text
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray600}
            fontWeight={400}
            wrapText
          >
            {preferences.displayName}
          </Text>
        </Box>
        {preferences.description && (
          <Box display="grid" gap={SPACE.sp4}>
            <Text
              fontSize={FONT_SIZE.fs12}
              lineHeight="xs"
              color={COLOR.coolGray600}
              fontWeight={700}
            >
              Description
            </Text>
            <Text
              fontSize={FONT_SIZE.fs12}
              lineHeight="xs"
              color={COLOR.coolGray600}
              fontWeight={400}
            >
              {preferences.description}
            </Text>
          </Box>
        )}
      </Box>
      {!isPrimaryContactEmpty && (
        <>
          <Separator color="coolGray.300" strokeWidth={1} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={SPACE.sp12}
            width="100%"
          >
            <Text
              fontSize={FONT_SIZE.fs14}
              fontWeight="700"
              color={COLOR.coolGray600}
              lineHeight="sm"
            >
              Primary Contact
            </Text>
            <Text
              fontSize={FONT_SIZE.fs14}
              lineHeight="xs"
              fontWeight={700}
              color={COLOR.coolGray600}
            >
              {preferences.primaryContact.name}
            </Text>
            <BulletList
              items={[
                preferences.primaryContact.phone,
                preferences.primaryContact.email,
                preferences.primaryContact.address,
              ]}
            />
          </Box>
        </>
      )}
    </Container>
  )
}

const Container = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.coolGray[200]};
  img {
    border-radius: 50%;
  }
`
export default AccountPreferences
