import styled from '@emotion/styled'
import React from 'react'

import { Asset } from 'types'

import { Box, Text } from 'components/common'
import { AssetOutlookInformation, StarRating } from 'components/partials'

type Props = {
  asset: Asset
}

function AssetOutlook({ asset }: Props) {
  const returnRating = asset.returnRating
  const riskRating = asset.riskRating
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding="0px"
        gap="20px"
        width="100%"
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="12px"
        >
          <Box display="flex">
            <Text
              fontWeight="bold"
              fontSize="xl"
              lineHeight="xl"
              color="coolGray.600"
              as="p"
            >
              Pave Outlook
            </Text>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <AssetOutlookInformation />
          </Box>
        </Box>

        <Row justifyContent="space-between" mb="6px">
          <Box
            display="flex"
            flexDirection="column"
            padding="0px"
            gap="20px"
            width="100%"
          >
            <Text fontSize="md" fontWeight="bold" color="coolGray.800">
              Designation
            </Text>
            {generateDesignationSectionFromReturnRating(returnRating)}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            padding="0px"
            gap="20px"
            width="100%"
          >
            <Text fontSize="md" fontWeight="bold" color="coolGray.800">
              Return Rank
            </Text>
            <StarRating filledStars={returnRating} />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            padding="0px"
            gap="20px"
            width="100%"
          >
            <Text fontSize="md" fontWeight="bold" color="coolGray.800">
              Risk Rank
            </Text>
            <StarRating filledStars={riskRating} />
          </Box>
        </Row>
      </Box>
    </>
  )
}

function generateDesignationSectionFromReturnRating(rating: number) {
  const designations = ['Sell', 'Underweight', 'Hold', 'Overweight', 'Buy']
  const designationIndex = Math.min(rating - 1, designations.length - 1)
  const color =
    designationIndex > 2
      ? 'emerald.600'
      : designationIndex < 2
      ? 'red.600'
      : 'coolGray.800'
  return (
    <Text fontSize="md" color={color}>
      {designations[designationIndex]}
    </Text>
  )
}

const Row = styled(Box)`
  display: flex;
  width: 100%;
`

export default AssetOutlook
