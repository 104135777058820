/**
 * SuggestedTradesStatus Enum
 */
export const SuggestedTradesStatus = {
  NEEDS_UPDATE: 'NEEDS_UPDATE',
  NEEDS_UPDATE_AND_MARK_FOR_TRADING_USER_REQUEST:
    'NEEDS_UPDATE_AND_MARK_FOR_TRADING_USER_REQUEST',
  NO_ACTION: 'NO_ACTION',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SuggestedTradesStatus =
  typeof SuggestedTradesStatus[keyof typeof SuggestedTradesStatus]
