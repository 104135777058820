import styled from '@emotion/styled'
import React from 'react'

import { Box, Text } from 'components/common'

type Props = {
  color: string
  label: string
  value: number | string
}

function AllocationSummaryItem({ color, label, value }: Props) {
  return (
    <SummaryItem>
      <Box display="flex" alignItems="center">
        <Box width="6px" height="6px" borderRadius="100%" bg={color} mr="8px" />
      </Box>
      <Text flex={1} mr={4}>
        {label}
      </Text>
      <Text>{value}%</Text>
    </SummaryItem>
  )
}

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.xs};
  color: ${({ theme }) => theme.colors.coolGray[600]};
  margin: 16px 0;
`

export default AllocationSummaryItem
