import { Base64 } from 'js-base64'

export const BASE_URL = process.env.REACT_APP_BASEURL
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY
export const PAVE_API_PRIVATE_KEY = Base64.decode(
  process.env.REACT_APP_PAVE_API_PRIVATE_KEY || ''
)
export const PAVE_API_PUBLIC_KEY = Base64.decode(
  process.env.REACT_APP_PAVE_API_PUBLIC_KEY || ''
)
export const PAVE_API_PASSPHRASE = process.env.REACT_APP_PAVE_API_PASSPHRASE

export const FEATURE_FLAGS = {
  ENABLE_ENCRYPTION: process.env.REACT_APP_ENABLE_ENCRYPTION === 'true',
}

export const API_TAG = {
  GROUP: 'GROUP',
  PERFORMANCE: 'PERFORMANCE',
  PORTFOLIO: 'PORTFOLIO',
  INVESTMENT_PREFERENCES_TEMPLATE: 'INVESTMENT_PREFERENCES_TEMPLATE',
  LINKED_ACCOUNT: 'LINKED_ACCOUNT',
  BUY_LISTS: 'BUY_LISTS',
  BUY_LIST_CHANGE_LOG: 'BUY_LIST_CHANGE_LOG',
}
