import styled from '@emotion/styled'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import React from 'react'

import { Trade } from 'types'

import { Box, Text } from 'components/common'
import { formatAsCurrency } from 'utils'

type Props = {
  data: Trade[]
}

function TradeHistoryTable({ data }: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([])

  const columns = React.useMemo<ColumnDef<Trade>[]>(
    () => [
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="medium"
            color="coolGray.500"
            pl="24px"
          >
            DATE
          </Text>
        ),
        accessorKey: 'date',
        cell: (info) => (
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {format(new Date(info.getValue() as string), 'MM/dd/yyyy')}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="medium"
            color="coolGray.500"
          >
            ACTION
          </Text>
        ),
        accessorKey: 'action',
        cell: (info) => (
          <Text
            fontWeight="bold"
            fontSize="xs"
            lineHeight="xs"
            color="emerald.600"
          >
            {capitalize(info.getValue() as string)}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="medium"
            color="coolGray.500"
          >
            COST BASIS
          </Text>
        ),
        accessorKey: 'costBasis',
        cell: (info) => (
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {formatAsCurrency(info.getValue() as number)}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="medium"
            color="coolGray.500"
          >
            SHARES
          </Text>
        ),
        accessorKey: 'shares',
        cell: (info) => (
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {info.getValue() as number}
          </Text>
        ),
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="medium"
            color="coolGray.500"
          >
            AMOUNT
          </Text>
        ),
        accessorKey: 'amount',
        cell: (info) => (
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {formatAsCurrency(info.getValue() as number)}
          </Text>
        ),
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <Table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <Header
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <ChevronUpIcon width={16} height={16} />,
                        desc: <ChevronDownIcon width={16} height={16} />,
                      }[header.column.getIsSorted() as string] ?? (
                        <Box width="16px" height="16px" />
                      )}
                    </Header>
                  )}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <Row key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </Row>
          )
        })}
      </tbody>
    </Table>
  )
}

const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
`

const Header = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.coolGray[500]};
`

const Row = styled.tr`
  td {
    background-color: ${({ theme }) => theme.colors.coolGray[50]};
    margin-bottom: 8px;
  }
  td:first-of-type {
    border-radius: 4px;
    padding-left: 24px;
  }
  td:last-of-type {
    border-radius: 4px;
  }
`

export default TradeHistoryTable
