import { useTheme } from '@emotion/react'
import { PlotData } from 'plotly.js'
import React from 'react'
import Plot from 'react-plotly.js'

type ValueType = string | number | Date | null
type ChartData = {
  name: string
  labels: string[]
  values: ValueType[]
}

type Position = {
  x: number
  y: number
}

type Annotation = {
  fontFamily?: string
  fontSize?: number
  color?: string
  text?: string
  position?: Position
}

type Props = {
  data: ChartData
  title?: Annotation
  subtitle?: Annotation
  colors: string[]
}

const TICK_LABEL_MAX_LENGTH = 19

function DonutChart({ data, colors, title = {}, subtitle = {} }: Props) {
  const theme = useTheme()

  const chartData = [
    {
      ...data,
      domain: { column: 0 },
      showlegend: false,
      hovertemplate: '<b>%{text}</b><br> <b>%{value}%</b> <extra></extra>',
      text: data.labels.map((label) => {
        return label.length > TICK_LABEL_MAX_LENGTH
          ? label.substring(0, TICK_LABEL_MAX_LENGTH) + '…'
          : label
      }),
      textinfo: 'none',
      hole: 0.7,
      type: 'pie',
      marker: {
        colors,
      },
    },
  ]
  return (
    <Plot
      data={chartData as PlotData[]}
      layout={{
        width: 180,
        height: 180,
        margin: { l: 0, r: 0, b: 0, t: 0, pad: 0 },
        showlegend: false,
        annotations: [
          {
            font: {
              size: title.fontSize || 20,
              color: title.color || theme.colors.coolGray[900],
              family: title.fontFamily || theme.fonts[0],
            },
            showarrow: false,
            text: title.text || '',
            x: title.position?.x || 0.5,
            y: title.position?.y || 0.5,
          },
          {
            font: {
              size: subtitle.fontSize || 12,
              color: subtitle.color || theme.colors.coolGray[500],
              family: subtitle.fontFamily || theme.fonts[0],
            },
            showarrow: false,
            text: subtitle.text || '',
            x: subtitle.position?.x || 0.5,
            y: subtitle.position?.y || 0.4,
          },
        ],
      }}
      config={{ displayModeBar: false }}
    />
  )
}

export default DonutChart
