import styled from '@emotion/styled'
import { ButtonHTMLAttributes } from 'react'

import { Size } from 'types/styleTypes'

import { Box } from 'components/common'

type TabItemConfig = {
  fontSize: string
  height: string
  margin: string
}

const TAB_ITEM_CONFIG: Record<Size, TabItemConfig> = {
  xxs: {
    fontSize: '10px',
    height: '32px',
    margin: '8px 6px',
  },
  xs: {
    fontSize: '12px',
    height: '32px',
    margin: '8px 6px',
  },
  sm: {
    fontSize: '14px',
    height: '39px',
    margin: '12px 16px',
  },
  md: {
    fontSize: '16px',
    height: '44px',
    margin: '12px 16px',
  },
  lg: {
    fontSize: '18px',
    height: '52px',
    margin: '16px 24px',
  },
}

type BaseProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isSelected?: boolean
  opacity?: number
  size?: Size
}

type Props = {
  isSelected: boolean
  name: string
  onClick: () => void
  size?: Size
}

const TabItemBase = styled.button<BaseProps>`
  background-color: none;
  border: none;
  color: ${({ theme }) => theme.colors.purple[800]};
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ size }) => TAB_ITEM_CONFIG[size || 'md'].fontSize};
  height: ${({ size, style }) =>
    style?.height || TAB_ITEM_CONFIG[size || 'md'].height};
  margin: ${({ size, style }) =>
    style?.margin || TAB_ITEM_CONFIG[size || 'md'].margin};
  text-align: center;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.4)};
`

export default function TabItem({
  isSelected,
  name,
  onClick,
  size = 'md',
}: Props) {
  return (
    <Box>
      <TabItemBase isSelected={isSelected} onClick={onClick} size={size}>
        {name}
      </TabItemBase>
    </Box>
  )
}
