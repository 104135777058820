import { Text } from 'components/common'
import { DEPRECATEDInteractiveText } from 'components/common/DEPRECATEDInteractiveText'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Props = {
  leftText: string
  rightText?: string
}

function ActivityGroupText({ leftText, rightText }: Props) {
  return (
    <FlexBox alignItems="center" justifyContent="space-between">
      <DEPRECATEDInteractiveText fontWeight="bold" fontSize={FONT_SIZE.fs12}>
        {leftText}
      </DEPRECATEDInteractiveText>
      <Text
        fontWeight="medium"
        fontSize={FONT_SIZE.fs12}
        color={COLOR.coolGray500}
      >
        {rightText}
      </Text>
    </FlexBox>
  )
}

export default ActivityGroupText
