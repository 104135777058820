import { analytics } from 'analytics'
import { PublicNavigator } from 'pages/navigators'
import { useEffect } from 'react'

import { paveApi } from 'api'
import PageLoader from 'components/common/Loader/PageLoader'
import { useAppSelector } from 'store/storeHooks'

import PageLayout from './PageLayout'

type Props = {
  children: React.ReactNode
}

export default function AuthSelector({ children: AuthorizedContent }: Props) {
  const [getUserById, { isLoading }] = paveApi.useLazyGetUserByIdQuery()
  const { UserId } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (!!UserId) {
      getUserById(UserId)
      analytics.identify(UserId)
    }
  }, [UserId, getUserById])

  if (isLoading) {
    return (
      <PageLayout hideMenu={true}>
        <PageLoader />
      </PageLayout>
    )
  }

  // TODO: It might be better to consolidate these routes into a single
  // Router component
  return UserId ? <>{AuthorizedContent}</> : <PublicNavigator />
}
