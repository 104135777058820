import styled from '@emotion/styled'

import { Box } from 'components/common'
import { COLOR } from 'styles/constants/color'

const Card = styled(Box)`
  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.coolGray300};
  border-radius: 0.5rem;
  padding: 1rem;
`

export default Card
