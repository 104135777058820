import React from 'react'

import { Box, Tag, Text } from 'components/common'

export function SelectedItemsPreference({
  preference = '',
  items = [],
  colorVariant,
}: {
  preference: string
  items: string[]
  colorVariant: 'gray' | 'green' | 'red'
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      padding="0px"
      gap="12px"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="0px"
        gap="12px"
      >
        <Text
          fontSize="xs"
          fontWeight="bold"
          lineHeight="xs"
          color="coolGray.700"
        >
          {preference}
        </Text>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          padding="0px"
          gap="12px"
          flexWrap="wrap"
        >
          {items.map((item: string, i: number) => {
            return (
              <Tag key={i} variant={colorVariant}>
                {item}
              </Tag>
            )
          })}
          {items.length === 0 && <Tag variant="gray">None</Tag>}
        </Box>
      </Box>
    </Box>
  )
}
