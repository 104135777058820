import styled from '@emotion/styled'

import { COLOR } from 'styles/constants/color'

import Card from './Card'

const InteractiveCard = styled(Card)`
  :hover {
    background-color: ${COLOR.coolGray50};
    color: ${COLOR.coolGray600};
    .interactive-text {
      color: ${COLOR.blue500};
    }
  }
`

export default InteractiveCard
