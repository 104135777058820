export const SUPPORT_MAILTO_LINK = 'mailto:support@pavefinance.com'
export const SUPPORT_EMAIL = 'support@pavefinance.com'

export const TERMS_OF_SERVICE_URL = 'https://www.pavepro.ai/s/Pave-Labs-MSLA'

export const IB_HOME_PAGE = 'https://www.interactivebrokers.com/en/home.php'

export const INVESTMENT_ADVISORY_AGREEMENT_URL =
  'https://www.pavefinance.com/s/Investment-Advisory-Agreement-Pro'

export const strategiesLabel = {
  STRONG_GROWTH: 'Strong Growth (95% Equity / 5% Fixed Income)',
  GROWTH: 'Growth (85% Equity / 15% Fixed Income)',
  BALANCED_GROWTH: 'Balanced Growth (75% Equity / 25% Fixed Income)',
  BALANCED: 'Balanced (60% Equity / 40% Fixed Income)',
  BALANCED_INCOME: 'Balanced Income (50% Equity / 50% Fixed Income)',
  INCOME: 'Income (30% Equity / 70% Fixed Income)',
  STRONG_INCOME: 'Strong Income (5% Equity / 95% Fixed Income)',
} as const

export const strategies = {
  STRONG_GROWTH: 'STRONG_GROWTH',
  GROWTH: 'GROWTH',
  BALANCED_GROWTH: 'BALANCED_GROWTH',
  BALANCED: 'BALANCED',
  BALANCED_INCOME: 'BALANCED_INCOME',
  INCOME: 'INCOME',
  STRONG_INCOME: 'STRONG_INCOME',
} as const

export const strategySelectorOptions = {
  strategies: Object.entries(strategiesLabel).map(([key, label]) => ({
    label,
    value: key,
  })),
}

export const BALANCED_OPTION = {
  label: 'Balanced (Default)',
  value: 'balanced',
}
export const DEFAULT_OPTION = { label: 'Balanced (Default)', value: 'default' }

export const DISPLAY_NAME_LENGTH_LIMIT = 80
