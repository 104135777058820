import styled from '@emotion/styled'
import React from 'react'

import { Text } from '../common'

type Props = {
  userName: string
  introSummary: any
}
function IntroCard({ ...props }: Props) {
  return (
    <Container>
      <Text
        fontWeight="bold"
        fontSize="xxl"
        lineHeight="xxl"
        color="white"
      >{`Welcome, ${props.userName}`}</Text>
      <Text fontWeight="regular" fontSize="md" lineHeight="md" color="white">
        {props.introSummary}
      </Text>
      <Text
        fontWeight="regular"
        fontSize="md"
        lineHeight="md"
        color="white"
      ></Text>
    </Container>
  )
}
const Container = styled.div`
  width: 531px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.purple[800]};
  border-radius: 8px;
  margin-bottom: 24px;
  white-space: pre-wrap;
  height: 100%;
`

export default IntroCard
