import { DropdownOptionString } from 'components/partials/DropdownWithTags'

export const useCaseOptions: DropdownOptionString[] = [
  {
    value: 'High Net Worth Individual (Accredited Investor)',
    label: 'High Net Worth Individual (Accredited Investor)',
  },
  {
    value: 'Ultra-High Net Worth Individual (Qualified Purchaser)',
    label: 'Ultra-High Net Worth Individual (Qualified Purchaser)',
  },
  { value: 'Family Office', label: 'Family Office' },
  {
    value: 'Registered Investment Advisor',
    label: 'Registered Investment Advisor',
  },
  {
    value: 'Professional Advisor (Unregistered)',
    label: 'Professional Advisor (Unregistered)',
  },
  { value: 'Institution', label: 'Institution' },
  { value: 'Non-US', label: 'Non-US' },
]
