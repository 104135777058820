import { MenuAlt2Icon } from '@heroicons/react/solid'

import { BuyListSummaryBasic } from 'types'

import { Box, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import { DEPRECATEDInteractiveText } from 'components/common/DEPRECATEDInteractiveText'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  buyListSummary: BuyListSummaryBasic
  handleBuyListClick: (selectedBuyListId: string) => void
}

function BuyListCardViewerItem({ buyListSummary, handleBuyListClick }: Props) {
  const { id, displayName, assetCount } = buyListSummary
  return (
    <InteractiveCard
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      width="100%"
      minHeight={SPACE.sp48}
      onClick={() => handleBuyListClick(id)}
    >
      <FlexBox alignItems="center" flex={1} gap={SPACE.sp10}>
        <DEPRECATEDInteractiveText
          fontWeight="bold"
          fontSize={FONT_SIZE.fs12}
          color={COLOR.coolGray600}
          lineHeight="xs"
        >
          {displayName}
        </DEPRECATEDInteractiveText>
      </FlexBox>

      <FlexBox justifyContent="flex-end" flex={1} gap={SPACE.sp12}>
        <Text
          fontWeight="medium"
          fontSize={FONT_SIZE.fs12}
          color={COLOR.coolGray600}
        >
          <FlexBox alignItems="center" gap={SPACE.sp8}>
            <Box>
              <MenuAlt2Icon width="1rem" height="1rem" />
            </Box>
            {assetCount} Assets
          </FlexBox>
        </Text>
      </FlexBox>
    </InteractiveCard>
  )
}

export default BuyListCardViewerItem
