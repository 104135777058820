import styled from '@emotion/styled'
import React from 'react'

import { IBAccountState, IBAccountStatus } from 'types'

import { Button, Text } from 'components/common'

type Props = {
  isLoading: boolean
  onDeletePortfolio: () => void
  onRedirectToIb: () => void
  portfolioIbState?: IBAccountState
  portfolioIbStatus?: IBAccountStatus
}

function InteractiveBrokersStatus({
  isLoading,
  onDeletePortfolio,
  onRedirectToIb,
  portfolioIbState,
  portfolioIbStatus,
}: Props) {
  switch (portfolioIbStatus) {
    case 'P':
      if (
        portfolioIbState === 'Under Review with IBKR' ||
        portfolioIbState === 'Pending Approval'
      ) {
        return (
          <IbStatusContainer>
            <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
              Application Under Review
            </Text>
            <Text fontSize="sm" color="coolGray.700">
              Your application is currently under review.
            </Text>
            <Button
              disabled={isLoading}
              onClick={onRedirectToIb}
              size="lg"
              type="submit"
              variant="primary"
            >
              Interactive Brokers Portal
            </Button>
          </IbStatusContainer>
        )
      }
      if (portfolioIbState === 'Approved') {
        return (
          <IbStatusContainer>
            <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
              Your account is approved!
            </Text>
            <Text fontSize="sm" color="coolGray.700" as="p">
              We are waiting for Interactive Brokers to open your account,
              please check back later.
            </Text>
            <Button
              disabled={isLoading}
              onClick={onRedirectToIb}
              size="lg"
              type="submit"
              variant="primary"
            >
              Interactive Brokers Portal
            </Button>
          </IbStatusContainer>
        )
      }
      /*
      if portfolioIbState !== 'Approved' the only other states 
      require the user to provide additional details
      */
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Provide Additional Details
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Please provide Interactive Brokers with additional details to
            complete your account application. You can come back to this page to
            check your application status.
          </Text>
          <Button
            disabled={isLoading}
            onClick={onRedirectToIb}
            size="lg"
            type="submit"
            variant="primary"
          >
            Provide Details
          </Button>
        </IbStatusContainer>
      )

    case 'N':
      if (portfolioIbState) {
        return (
          <IbStatusContainer>
            <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
              Finalize Account Application
            </Text>
            <Text fontSize="sm" color="coolGray.700" as="p">
              Please finish your brokerage account application through
              Interactive Brokers. You can come back to this page to check your
              application status.
            </Text>
            <Button
              disabled={isLoading}
              onClick={onRedirectToIb}
              size="lg"
              type="submit"
              variant="primary"
            >
              Finalize Application
            </Button>
          </IbStatusContainer>
        )
      }
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Pending Account Funding
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Please provide funding details through Interactive Brokers. You can
            come back to this page to check your application status.
          </Text>
          <Button
            disabled={isLoading}
            onClick={onRedirectToIb}
            size="lg"
            type="submit"
            variant="primary"
          >
            Fund Account
          </Button>
        </IbStatusContainer>
      )
    case 'A':
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Application Deleted
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Your Interactive Brokers account application was deleted. To start a
            new application, please create a new portfolio. Feel free to delete
            this portfolio as it can no longer be linked to a brokerage account.
          </Text>
          <Button
            disabled={isLoading}
            onClick={onDeletePortfolio}
            size="lg"
            type="submit"
            variant="primary"
          >
            Delete Portfolio
          </Button>
        </IbStatusContainer>
      )
    case 'R':
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Application Rejected
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Your Interactive Brokers account application was rejected. To start
            a new application, please create a new portfolio. Feel free to
            delete this portfolio as it can no longer be linked to a brokerage
            account.
          </Text>
          <Button
            disabled={isLoading}
            onClick={onDeletePortfolio}
            size="lg"
            type="submit"
            variant="primary"
          >
            Delete Portfolio
          </Button>
        </IbStatusContainer>
      )
    case 'C':
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Account Closed
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Your Interactive Brokers account was closed. Feel free to delete
            this portfolio.
          </Text>
          <Button
            disabled={isLoading}
            onClick={onDeletePortfolio}
            size="lg"
            type="submit"
            variant="primary"
          >
            Delete Portfolio
          </Button>
        </IbStatusContainer>
      )
    case 'O':
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Pending Account Funding
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Please provide funding details through Interactive Brokers. You can
            come back to this page to check your application status.
          </Text>
          <Button
            disabled={isLoading}
            onClick={onRedirectToIb}
            size="lg"
            type="submit"
            variant="primary"
          >
            Fund Account
          </Button>
        </IbStatusContainer>
      )
    default:
      return (
        <IbStatusContainer>
          <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
            Hmm, something went wrong.
          </Text>
          <Text fontSize="sm" color="coolGray.700" as="p">
            Sorry, please try again later.
          </Text>
        </IbStatusContainer>
      )
  }
}

const IbStatusContainer = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 24px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray[200]};
  border-radius: 8px;
`

export default InteractiveBrokersStatus
