import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import { ActivityGroupProps } from './ActivityGroup'

export type ActivityGroupHeaderProps = ActivityGroupProps & {
  collapseContentIcon?: React.ReactNode
}

function ActivityGroupHeader(props: ActivityGroupHeaderProps) {
  return (
    <FlexBox marginTop={SPACE.sp16}>
      <Box marginLeft={SPACE.sp2} minWidth="10rem">
        <Text
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs14}
          fontWeight="bold"
        >
          {props.headerText}
        </Text>
        <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
          {`  (${props.activities.length})`}
        </Text>
      </Box>
      <FlexBox
        flex={1}
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <Link
          to={props.link}
          style={{
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'end',
          }}
        >
          <Text fontWeight="bold" fontSize="xs" color={COLOR.lightBlue600}>
            {props.accountName}
          </Text>
        </Link>
        <Box
          width={SPACE.sp16}
          height={SPACE.sp16}
          marginLeft={SPACE.sp8}
          color={COLOR.coolGray400}
        >
          {props.collapseContentIcon}
        </Box>
      </FlexBox>
    </FlexBox>
  )
}

export default ActivityGroupHeader
