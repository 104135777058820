import { capitalize } from 'lodash'
import React, { useEffect } from 'react'

import { Allocation, AllocationFilter } from 'types'

import { Box, Button, Separator, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Spinner from 'components/common/Loader/Spinner'
import { DonutChart } from 'components/partials'
import { chartColors } from 'constants/pieChart.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import AllocationSummaryItem from './AllocationSummaryItem'

type Props = {
  data?: Allocation[]
  isLoading: boolean
  value: number
}

function PortfolioAllocation({ data, isLoading, value }: Props) {
  const [filter, setFilter] = React.useState<AllocationFilter>(
    AllocationFilter.Sector
  )
  const [allocation, setAllocation] = React.useState<Allocation>()

  useEffect(() => {
    const d = data?.find((d: any) => {
      return d.name === filter
    })
    setAllocation(d)
  }, [data, filter])

  return (
    <FlexBox flexDirection="column" width="100%" height="100%">
      <Box marginBottom="0.5rem" width="100%">
        <Text
          fontSize={FONT_SIZE.fs20}
          fontWeight="bold"
          color={COLOR.coolGray700}
        >
          Portfolio Allocation
        </Text>
      </Box>
      {isLoading ? (
        <FlexBox
          flexDirection="column"
          justifyContent="center"
          height="100%"
          margin="auto"
        >
          <Spinner size="small" />
        </FlexBox>
      ) : (
        <>
          {value > 0 ? (
            <>
              <Box width="100%" marginBottom="0.5rem">
                <Button
                  variant="tertiary"
                  size="xs"
                  active={filter === AllocationFilter.Sector}
                  onClick={() => setFilter(AllocationFilter.Sector)}
                >
                  Sector
                </Button>
                <Button
                  variant="tertiary"
                  size="xs"
                  active={filter === AllocationFilter.Industry}
                  onClick={() => setFilter(AllocationFilter.Industry)}
                >
                  Industry
                </Button>
                <Button
                  variant="tertiary"
                  size="xs"
                  active={filter === AllocationFilter.Geography}
                  onClick={() => setFilter(AllocationFilter.Geography)}
                >
                  Geography
                </Button>
              </Box>
              <Box width="100%">
                <Box width="100%">
                  <FlexBox flexDirection="column" gap={SPACE.sp24}>
                    <Box display="flex" justifyContent="center">
                      <DonutChart
                        data={
                          allocation || { name: '', values: [], labels: [] }
                        }
                        colors={chartColors}
                        title={{
                          text: `<b>${capitalize(filter)}</b>`,
                        }}
                      />
                    </Box>
                    <Box>
                      {allocation?.labels?.map(
                        (label: string, index: number) => (
                          <div key={index}>
                            <AllocationSummaryItem
                              color={chartColors[index]}
                              label={label}
                              value={allocation?.values[index]}
                            />
                            {index !== allocation?.labels.length - 1 && (
                              <Separator
                                strokeWidth={1}
                                color={COLOR.coolGray300}
                              />
                            )}
                          </div>
                        )
                      )}
                    </Box>
                  </FlexBox>
                </Box>
              </Box>
            </>
          ) : (
            <FlexBox
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <Text
                fontSize={FONT_SIZE.fs28}
                fontWeight="700"
                color={COLOR.coolGray600}
              >
                0
              </Text>
              <Text
                fontSize={FONT_SIZE.fs14}
                fontWeight="500"
                color={COLOR.coolGray600}
              >
                Assets
              </Text>
            </FlexBox>
          )}
        </>
      )}
    </FlexBox>
  )
}

export default PortfolioAllocation
