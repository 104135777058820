import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import { SUPPORT_EMAIL } from 'constants/general.constants'

type Props = {
  isOpen: boolean
  onClose: () => void
  nextStep: () => void
}

function MFASetupStartModal({ isOpen, onClose, nextStep }: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enable Multi-Factor Authentication?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="p" mb="12px">
              Enabling Multi-Factor Authentication will require you to link an
              authenticator app (e.g., Duo Mobile, Google Authenticator).
            </Text>
            <Text as="p">
              Please contact our customer support team at {SUPPORT_EMAIL} if you
              have any questions.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Box display="flex" width="100%" flexDirection="column" gap="12px">
              <Button
                color="purple.800"
                style={{ width: '100%' }}
                onClick={nextStep}
              >
                Link Authenticator App
              </Button>
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MFASetupStartModal
