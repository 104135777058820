import { Box } from '@chakra-ui/react'

import FlexBox from 'components/common/FlexBox/FlexBox'
import Spinner from 'components/common/Loader/Spinner'
import Text from 'components/common/Text/Text'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

export default function Loader() {
  return (
    <Box>
      <FlexBox justifyContent="center">
        <Spinner />
      </FlexBox>
      <FlexBox justifyContent="center">
        <Text
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs32}
          fontWeight={500}
          marginTop={SPACE.sp16}
        >
          Loading
        </Text>
      </FlexBox>
      <FlexBox justifyContent="center">
        <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs20}>
          Please Wait
        </Text>
      </FlexBox>
    </Box>
  )
}
