import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export default function PlaidPerformanceAccessModal({
  isOpen,
  onClose,
}: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Access Full Performance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="p" mb="12px">
              Since this is a linked portfolio with a 3rd party custodian, Pave
              Pro is unable to display full performance.
            </Text>
            <Text as="p">
              To see the full performance of this portfolio, please navigate to
              the web portal of your linked broker.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Box display="flex" width="100%" flexDirection="column" gap="12px">
              <Button
                color="purple.800"
                style={{ width: '100%' }}
                onClick={() => window.open()}
              >
                Open New Browser Tab
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
