import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system'
import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system'
import type {
  RequiredTheme,
  ResponsiveValue,
  Theme,
  ThemeValue,
} from 'styled-system'

import { themes } from 'styles/system/themes'

// Adding support for flexbox gap property - https://github.com/styled-system/styled-system/pull/1157
interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<'space', ThemeType>
> {
  gap?: ResponsiveValue<TVal, ThemeType>
  rowGap?: ResponsiveValue<TVal, ThemeType>
  columnGap?: ResponsiveValue<TVal, ThemeType>
  padding?: string | number
}

export type BoxProps = HTMLAttributes<HTMLDivElement> &
  BackgroundProps &
  BorderProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  GridProps &
  GapProps &
  SpaceProps &
  ColorProps &
  ShadowProps &
  TypographyProps & {
    gap?: string | number
  }

const Box = styled.div<BoxProps>`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${color}
  ${typography}
  ${shadow}
  ${grid}
  ${system({
    gap: {
      property: 'gap',
      scale: 'space',
      defaultScale: themes.default.space,
    },
    rowGap: {
      property: 'rowGap',
      scale: 'space',
      defaultScale: themes.default.space,
    },
    columnGap: {
      property: 'columnGap',
      scale: 'space',
      defaultScale: themes.default.space,
    },
  })}
`

export default Box
