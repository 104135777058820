import { BuyListAsset } from 'types'

import { Box, TextInput } from 'components/common'
import { SPACE } from 'styles/constants/space'

type Props = {
  asset: BuyListAsset
}

const BuyListAssetRow = ({ asset }: Props) => {
  const { isSupported } = asset
  const variant = isSupported ? 'template' : 'invalid'
  return (
    <tr style={{ width: '100%' }}>
      <td>
        <Box display="flex" padding={SPACE.sp4}>
          <TextInput
            name="symbol"
            value={asset.symbol}
            disabled={true}
            variant={variant}
            width="100%"
            style={{ cursor: 'default' }}
          />
        </Box>
      </td>
      <td>
        <TextInput
          name="name"
          value={asset?.name ?? ''}
          disabled={true}
          variant={variant}
          width="100%"
          style={{ cursor: 'default' }}
        />
      </td>
    </tr>
  )
}

export default BuyListAssetRow
