import { PortfolioFormState } from 'types/portfolioTypes'

import { paveApi } from 'api'
import { Box } from 'components/common'
import ModalLoader from 'components/partials/ModalLoader'

import PlaidAccountItem from './PlaidAccountItem'

type Props = {
  plaidAccountId: string
  formState?: PortfolioFormState
  handleChange: (e: any) => void
}

function PlaidAccount({ plaidAccountId, formState, handleChange }: Props) {
  const { data, isLoading } = paveApi.useGetPlaidAccountQuery(plaidAccountId)
  const accounts = data?.accounts
  const activeConnectedAccountIdsAsSet = new Set(
    formState?.activeConnectedAccountIds
  )
  return (
    <Box gap="8px" display="flex" flexWrap="wrap">
      {isLoading ? (
        <ModalLoader />
      ) : (
        accounts?.map((account: any, index: number) => (
          <PlaidAccountItem
            key={index}
            account={account}
            isChecked={activeConnectedAccountIdsAsSet.has(account.account_id)}
            handleChange={handleChange}
          />
        ))
      )}
    </Box>
  )
}

export default PlaidAccount
