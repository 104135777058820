import { useState } from 'react'

import { ACCOUNT_STATUS, PortfolioLinkType } from 'types'

import { Button, LinkText, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import FlexBox from 'components/common/FlexBox/FlexBox'
import ConfirmDeletePortfolioModal from 'components/partials/Account/ConfirmDeletePortfolioModal'
import { SUPPORT_EMAIL, SUPPORT_MAILTO_LINK } from 'constants/general.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

interface Props {
  onDeletePortfolio: () => void
  isLoading: boolean
  linkType: PortfolioLinkType
  accountStatus?: ACCOUNT_STATUS
}

const DeletePortfolioCard = ({
  onDeletePortfolio,
  isLoading,
  linkType,
  accountStatus,
}: Props) => {
  const [isDeletePortfolioConfirmOpen, setIsDeletePortfolioConfirmOpen] =
    useState(false)
  let title, description

  if (accountStatus === ACCOUNT_STATUS.DISCONNECTED) {
    title = 'Portfolio Disconnected'
    description =
      'Your portfolio has been disconnected. Please delete it by pressing the button below.'
  } else {
    title = 'Empty Portfolio'
    description =
      'Your portfolio is empty. If you would like to delete it, please press the button below.'
  }

  return (
    <>
      <Card height="100%" width="100%">
        <FlexBox flexDirection="column" gap={SPACE.sp24}>
          <Text
            fontSize={FONT_SIZE.fs20}
            fontWeight="bold"
            color={COLOR.coolGray700}
          >
            {title}
          </Text>
          <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray600}>
            {description} If you believe you are seeing this by mistake, please
            contact{' '}
            <LinkText href={SUPPORT_MAILTO_LINK} label={SUPPORT_EMAIL} bold />
          </Text>
          <FlexBox>
            <Button
              type="button"
              variant="danger"
              size="md"
              disabled={isLoading}
              onClick={() => setIsDeletePortfolioConfirmOpen(true)}
            >
              Delete Portfolio
            </Button>
          </FlexBox>
        </FlexBox>
      </Card>
      <ConfirmDeletePortfolioModal
        showModal={isDeletePortfolioConfirmOpen}
        hideModal={() => setIsDeletePortfolioConfirmOpen(false)}
        onClick={onDeletePortfolio}
        isSchwabPortfolio={Boolean(
          linkType === PortfolioLinkType.CharlesSchwab
        )}
      />
    </>
  )
}

export default DeletePortfolioCard
