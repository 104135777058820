import countries from 'assets/countries.json'
import states from 'assets/us-states.json'

export const getCountryName = (countryCode: string) => {
  const country = countries.find((country) => country.code === countryCode)
  if (country) {
    return country.name
  }
  return null
}

export const getStateName = (stateCode: string, countryCode: string) => {
  if (countryCode === 'USA') {
    const state = states.find((state) => state.abbreviation === stateCode)
    if (state) {
      return state.name
    }
  }
  return null
}
