import {
  ArrowDownIcon,
  ArrowUpIcon,
  HomeIcon,
  MailIcon,
  MenuAlt2Icon,
  PhoneIcon,
} from '@heroicons/react/solid'
import React from 'react'
import { Link } from 'react-router-dom'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import { formatAsCurrency } from 'utils'
import { formatStringLabelWithCount } from 'utils/stringUtils'

type Props = ClientAccountDto

function AccountListItem({
  id,
  displayName,
  portfolios,
  primaryContact,
  balance,
  gainPercentage,
}: Props) {
  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    window.location.href = `mailto:${primaryContact.email}`
  }

  const gainPercentageAmount = isNaN(gainPercentage) ? 0.0 : gainPercentage
  return (
    <Link to={`/account/${id}`}>
      <InteractiveCard
        alignItems="center"
        display="flex"
        height="3rem"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" flex={2}>
          <Text
            className="interactive-text"
            fontWeight="bold"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.600"
          >
            {displayName}
          </Text>
        </Box>

        <Box display="flex" flex={2} gap="8px">
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.600"
          >
            {formatAsCurrency(balance)}
          </Text>

          <Text
            fontWeight="bold"
            fontSize="xxs"
            lineHeight="xxs"
            color={gainPercentage >= 0 ? 'emerald.600' : 'red.600'}
          >
            <Box display="flex" alignItems="center">
              {gainPercentage >= 0 ? (
                <ArrowUpIcon width={12} height={12} />
              ) : (
                <ArrowDownIcon width={12} height={12} />
              )}
              {gainPercentageAmount}%
            </Box>
          </Text>
        </Box>

        <Box display="flex" flex={2} gap="12px">
          <Box width="16px" height="16px" color="coolGray.500">
            <MenuAlt2Icon />
          </Box>
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {formatStringLabelWithCount(
              'Portfolio',
              'Portfolios',
              portfolios.length
            )}
          </Text>
        </Box>

        <Box display="flex" flex={2} gap="12px">
          {primaryContact.city && primaryContact.state && (
            <>
              <Box width="16px" height="16px" color="coolGray.500">
                <HomeIcon />
              </Box>
              <Text
                fontWeight="medium"
                fontSize="xs"
                lineHeight="xs"
                color="coolGray.700"
              >
                {primaryContact.city}, {primaryContact.state}
              </Text>
            </>
          )}
        </Box>

        <Box display="flex" flex={2} gap="12px">
          {primaryContact.phoneNumber && (
            <>
              <Box width="16px" height="16px" color="coolGray.500">
                <PhoneIcon />
              </Box>
              <Text
                fontWeight="medium"
                fontSize="xs"
                lineHeight="xs"
                color="coolGray.700"
              >
                {primaryContact.phoneNumber}
              </Text>
            </>
          )}
        </Box>

        <Box display="flex" flex={3}>
          {primaryContact.email && (
            <Box display="flex" alignItems="center" gap={3}>
              <Box color="coolGray.500">
                <MailIcon width={16} height={16} />
              </Box>
              <Text
                color="lightBlue.600"
                onClick={handleEmailClick}
                fontSize="xs"
                fontWeight="medium"
              >
                {primaryContact.email}
              </Text>
            </Box>
          )}
        </Box>
      </InteractiveCard>
    </Link>
  )
}

export default AccountListItem
