import {
  DrawerHeader as ChakraDrawerHeader,
  DrawerCloseButton,
  ThemingProps,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'

import ReturnIcon from 'assets/images/return-icon.svg'
import { Box, Button, Text } from 'components/common'

type DrawerHeaderProps = ThemingProps<'Drawer'> & {
  title?: string
  buttonText?: string
  formId?: string
  disabled?: boolean
  customButtons?: ReactNode
}

const DrawerHeader = ({
  title,
  formId,
  disabled,
  buttonText,
  customButtons,
}: DrawerHeaderProps) => {
  return (
    <ChakraDrawerHeader>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0px"
        gap="24px"
        width="100%"
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <DrawerCloseButton position="sticky">
            <img src={ReturnIcon} alt="Return Icon" width={10} height={20} />
          </DrawerCloseButton>
          <Text
            fontWeight="bold"
            fontSize="xl"
            lineHeight="xl"
            color="coolGray.600"
          >
            {title}
          </Text>
        </Box>
        {React.isValidElement(customButtons) ? (
          <>{customButtons}</>
        ) : (
          <Button
            variant="primary"
            size="md"
            type="submit"
            form={formId}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </ChakraDrawerHeader>
  )
}

export default DrawerHeader
