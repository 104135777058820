import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function InvestmentStylePopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Investment Style
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Pave Pro automatically analyzes the market on an ongoing basis
                to identify which factors are driving risk and return.
                Generally, Pave Pro expresses both value and growth based
                factors in the client portfolios it creates. Depending on market
                conditions it will prioritize one or the other.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                If Investment Style is left on Balanced, Pave Pro will remain
                impartial to growth or value factors and shift weight between
                them as it sees fit. If you would like to tilt the portfolio
                towards a growth or value style, select the Growth or Value
                options.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Pave Pro will continue to analyze the market and ensure the
                portfolio is well balanced to take advantage of current
                conditions. The Investment Style Factor Tilt simply nudges Pave
                Pro in the direction you prefer. For example, in a market that
                is rewarding growth factors, Pave Pro will still include assets
                that express growth factors even if you select Value, the
                portfolio will just be less growth heavy than it would be if you
                had selected Balanced.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default InvestmentStylePopOver
