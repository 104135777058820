import React from 'react'

import { Box, Text } from 'components/common'

export function ValuePreference({ preference = '', value = '' }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      padding="0px"
      gap="12px"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="0px"
        gap="4px"
      >
        <Text
          fontSize="xs"
          fontWeight="bold"
          lineHeight="xs"
          color="coolGray.700"
        >
          {preference}
        </Text>
        <Text
          fontSize="xs"
          fontWeight="regular"
          lineHeight="xs"
          color="coolGray.700"
        >
          {value}
        </Text>
      </Box>
    </Box>
  )
}
