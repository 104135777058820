import { UploadOutlined } from '@ant-design/icons'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button as AntButton, Upload } from 'antd'
import type { GetProp, UploadFile, UploadProps } from 'antd'
import { isEmpty } from 'lodash'
import { useState } from 'react'

import { Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

import ModalLoader from './ModalLoader'

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

type Props = {
  isOpen: boolean
  onClose: () => void
  isLoading?: boolean
  handleFileUpload: (formData: { body: FormData }) => void
}

function UploadBuyListAssetsModal({
  isOpen,
  onClose,
  isLoading,
  handleFileUpload,
}: Props) {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    setFileList(newFileList)
  }

  const onChange = ({ fileList: newFileList }: { fileList: UploadFile[] }) => {
    setFileList(newFileList.slice(-1)) // Keep only the last selected file
  }

  const beforeUpload = (file: UploadFile) => {
    setFileList([...fileList, file])
    return false
  }

  const initiateFileUpload = () => {
    const file = fileList[0]
    const formData = new FormData()
    formData.append('file', file.originFileObj as FileType)
    const upload = {
      body: formData,
    }
    if (upload) {
      handleFileUpload(upload)
      setFileList([])
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload New List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="p" mb={SPACE.sp12}>
            Please upload a CSV with one column that has the header “Ticker”.
            This column should contain the tickers associated with each of the
            assets you would like included in this Buy List. Anything outside of
            the “Ticker” column will be ignored.
          </Text>
          <Text as="p" mb={SPACE.sp12}>
            Uploading a new list will overwrite your existing list.
          </Text>
          <Upload
            fileList={fileList}
            onChange={onChange}
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            multiple={false}
            accept=".csv"
          >
            <AntButton icon={<UploadOutlined />} disabled={!isEmpty(fileList)}>
              Select File
            </AntButton>
          </Upload>
        </ModalBody>

        <ModalFooter>
          <FlexBox width="100%" flexDirection="column" gap={SPACE.sp12}>
            <Button
              color={COLOR.purple800}
              style={{ width: '100%' }}
              onClick={initiateFileUpload}
              disabled={isEmpty(fileList) || isLoading}
            >
              {isLoading && <ModalLoader />}
              Confirm
            </Button>
            <Button
              variant="secondary"
              style={{ width: '100%' }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </FlexBox>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UploadBuyListAssetsModal
