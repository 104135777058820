import { Box, Text } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { closeAccountAllTasks } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

import { Task } from './Task'

function AccountAllTasksModal() {
  const { showAccountAllTasks } = useAppSelector((state) => state.modals)
  const dispatch = useAppDispatch()
  return (
    <>
      <SlideOutMenu
        isOpen={showAccountAllTasks}
        onClose={() => dispatch(closeAccountAllTasks())}
        size="lg"
        title="All Tasks"
        buttonText="Add New Task"
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          gap="24px"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding="0px"
            gap="12px"
          >
            <Text
              fontWeight="bold"
              fontSize="lg"
              lineHeight="lg"
              color="coolGray.600"
            >
              To-Do (4)
            </Text>

            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
              notes="Make sure to create a recurring meeting with X person so that we can do"
            ></Task>
            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
            ></Task>
            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
            ></Task>
            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
              notes="Make sure to create a recurring meeting with X person so that we can do"
            ></Task>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding="0px"
            gap="12px"
          >
            <Text
              fontWeight="bold"
              fontSize="lg"
              lineHeight="lg"
              color="coolGray.600"
            >
              Completed (287)
            </Text>

            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
              notes="Make sure to create a recurring meeting with X person so that we can do"
              isDone={true}
            ></Task>
            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
              isDone={true}
            ></Task>
            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
              isDone={true}
            ></Task>
            <Task
              title="Create meeting for this type of person"
              dueDate="August 15, 2022"
              notes="Make sure to create a recurring meeting with X person so that we can do"
              isDone={true}
            ></Task>
          </Box>
        </Box>
      </SlideOutMenu>
    </>
  )
}
export default AccountAllTasksModal
