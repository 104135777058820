import styled from '@emotion/styled'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { components } from 'react-select'

function DropdownIndicator(props: any) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <IconWrapper>
          <ChevronDownIcon />
        </IconWrapper>
      </components.DropdownIndicator>
    )
  )
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export default DropdownIndicator
