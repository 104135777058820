import { analytics } from 'analytics'
import React, { useEffect } from 'react'

import { paveApi } from 'api'
import { SETTINGS_ENABLE_MFA_STARTED } from 'constants/track.constants'

import MFASetupFinishModal from './MFASetupFinishModal'
import MFASetupQRCodeModal from './MFASetupQRCodeModal'
import MFASetupStartModal from './MFASetupStartModal'
import MFASetupVerifyCodeModal from './MFASetupVerifyCodeModal'

type Props = {
  open: boolean
  onClose: () => void
  setUserMFAPreference: (body: any) => void
}

enum MfaSetupStep {
  Start = 1,
  QRCode = 2,
  VerifyCode = 3,
  Finish = 4,
}

function MFASetupFlow({ open, onClose, setUserMFAPreference }: Props) {
  const [step, setStep] = React.useState(MfaSetupStep.Start)
  const next = () => setStep((step) => step + 1)
  const prev = () => setStep((step) => step - 1)

  const [associateSoftwareToken, associateSoftwareTokenResult] =
    paveApi.useAssociateSoftwareTokenMutation()

  useEffect(() => {
    if (open) {
      associateSoftwareToken()
    }
  }, [associateSoftwareToken, open])

  return (
    <>
      {step === MfaSetupStep.Start && (
        <MFASetupStartModal
          nextStep={() => {
            analytics.track(SETTINGS_ENABLE_MFA_STARTED)
            next()
          }}
          isOpen={open}
          onClose={onClose}
        />
      )}
      {step === MfaSetupStep.QRCode && (
        <MFASetupQRCodeModal
          secretCode={associateSoftwareTokenResult?.data?.SecretCode}
          isOpen={open}
          onClose={onClose}
          nextStep={next}
          prevStep={prev}
        />
      )}
      {step === MfaSetupStep.VerifyCode && (
        <MFASetupVerifyCodeModal
          isOpen={open}
          onClose={onClose}
          nextStep={next}
          prevStep={prev}
        />
      )}
      {step === MfaSetupStep.Finish && (
        <MFASetupFinishModal
          isOpen={open}
          onClose={onClose}
          setUserMFAPreference={setUserMFAPreference}
        />
      )}
    </>
  )
}

export default MFASetupFlow
