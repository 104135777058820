import { COLOR } from 'styles/constants/color'

export const chartColors = [
  // Purple and cool gray
  COLOR.purple900,
  COLOR.coolGray200,
  COLOR.purple700,
  COLOR.coolGray300,
  COLOR.purple800,
  COLOR.coolGray100,
  COLOR.purple600,
  COLOR.coolGray500,
  COLOR.purple400,
  COLOR.coolGray400,
  COLOR.purple500,
  COLOR.coolGray700,
  COLOR.purple200,
  COLOR.coolGray600,
  COLOR.purple300,
  COLOR.coolGray900,
  COLOR.purple100,
  COLOR.coolGray800,
  COLOR.purple50,

  // Start adding blue and emerald
  COLOR.blue900,
  COLOR.emerald50,
  COLOR.blue800,
  COLOR.emerald100,
  COLOR.blue700,
  COLOR.emerald200,
  COLOR.blue600,
  COLOR.emerald300,
  COLOR.blue500,
  COLOR.emerald400,
  COLOR.blue400,
  COLOR.emerald500,
  COLOR.blue300,
  COLOR.emerald600,
  COLOR.blue200,
  COLOR.emerald700,
  COLOR.blue100,
  COLOR.emerald800,
  COLOR.blue50,
  COLOR.emerald900,
  COLOR.green50,

  // Add pink and green
  COLOR.pink900,
  COLOR.green100,
  COLOR.pink800,
  COLOR.green200,
  COLOR.pink700,
  COLOR.green300,
  COLOR.pink600,
  COLOR.green400,
  COLOR.pink500,
  COLOR.green500,
  COLOR.pink400,
  COLOR.green600,
  COLOR.pink300,
  COLOR.green700,
  COLOR.pink200,
  COLOR.green800,
  COLOR.pink100,
  COLOR.green900,
  COLOR.pink50,
]
