import { createSlice } from '@reduxjs/toolkit'
import { analytics } from 'analytics'

import { paveApi } from 'api'

export interface AuthState {
  IdToken: string | null
  RefreshToken: string | null
  AccessToken: string | null
  UserId: string | null
  ChallengeName?: string
  ChallengeParameters?: { [key: string]: string }
  SecretCode?: string
  Session?: string
}

const initialState: AuthState = {
  IdToken: window.localStorage.getItem('IdToken'),
  RefreshToken: window.localStorage.getItem('RefreshToken'),
  AccessToken: window.localStorage.getItem('AccessToken'),
  UserId: window.localStorage.getItem('UserId'),
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      const { IdToken, UserId, RefreshToken, AccessToken } =
        action.payload?.result
      state.IdToken = IdToken
      state.UserId = UserId
      state.RefreshToken = RefreshToken
      state.AccessToken = AccessToken
      window.localStorage.setItem('IdToken', IdToken)
      window.localStorage.setItem('RefreshToken', RefreshToken)
      window.localStorage.setItem('UserId', UserId)
      window.localStorage.setItem('AccessToken', AccessToken)
    },
    signOut: (state) => {
      state.IdToken = null
      state.UserId = null
      state.RefreshToken = null
      state.AccessToken = null
      window.localStorage.removeItem('IdToken')
      window.localStorage.removeItem('UserId')
      window.localStorage.removeItem('RefreshToken')
      window.localStorage.removeItem('AccessToken')
      analytics.reset()
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      paveApi.endpoints.signIn.matchFulfilled,
      (state, action) => {
        const {
          IdToken,
          UserId,
          RefreshToken,
          AccessToken,
          SecretCode,
          ChallengeParameters,
          Session,
        } = action.payload?.result

        state.IdToken = IdToken
        state.UserId = UserId
        state.RefreshToken = RefreshToken
        state.AccessToken = AccessToken
        state.SecretCode = SecretCode
        state.Session = Session
        state.ChallengeParameters = ChallengeParameters
        window.localStorage.setItem('IdToken', IdToken)
        window.localStorage.setItem('RefreshToken', RefreshToken)
        window.localStorage.setItem('AccessToken', AccessToken)
        window.localStorage.setItem('UserId', UserId)
        window.localStorage.setItem('SecretCode', SecretCode)
        window.localStorage.setItem('Session', Session)
      }
    )
    builder.addMatcher(
      paveApi.endpoints.verifySoftwareTokenMfa.matchFulfilled,
      (state, action) => {
        const {
          IdToken,
          UserId,
          RefreshToken,
          AccessToken,
          SecretCode,
          ChallengeParameters,
          Session,
        } = action.payload?.result

        state.IdToken = IdToken
        state.UserId = UserId
        state.RefreshToken = RefreshToken
        state.AccessToken = AccessToken
        state.SecretCode = SecretCode
        state.Session = Session
        state.ChallengeParameters = ChallengeParameters
        window.localStorage.setItem('IdToken', IdToken)
        window.localStorage.setItem('RefreshToken', RefreshToken)
        window.localStorage.setItem('UserId', UserId)
        window.localStorage.setItem('SecretCode', SecretCode)
        window.localStorage.setItem('Session', Session)
        window.localStorage.setItem('AccessToken', AccessToken)
      }
    )
  },
})

export const { signIn, signOut } = authSlice.actions

export default authSlice.reducer
