import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function VolatilityPreferencePopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Volatility Preference
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                When investors become overly enthusiastic or pessimistic about a
                particular market theme, the market’s overall valuation is
                stretched too high or too low, or market breadth becomes
                exceedingly narrow, the market can enter an inflection point. At
                those times, one can lean into the volatility to increase the
                magnitude of potential gains or dial down exposure to batten
                down the hatches.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                If Volatility Preference is left on Balanced, Pave Pro will
                automatically adjust how sensitive a portfolio is to market
                volatility. However, if you would like to tilt Pave Pro towards
                favoring assets that are more sensitive to market volatility,
                select Momentum. For the opposite, select MinVol.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default VolatilityPreferencePopOver
