import { Box } from 'components/common'
import { ActivityGroup, SlideOutMenu } from 'components/partials'
import { ActivityGroupProps } from 'components/partials/TradeActivities/ActivityGroup'
import { closeAccountAllSuggestedTradeActivities } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

type Props = {
  activities: ActivityGroupProps[]
}

function AccountAllSuggestedTradeActivitiesModal({ activities }: Props) {
  const dispatch = useAppDispatch()
  const { showAccountAllSugggestedTradeActivities } = useAppSelector(
    (state) => state.modals
  )
  const count = activities.reduce((acc, ag) => acc + ag.count, 0)
  return (
    <>
      <SlideOutMenu
        isOpen={showAccountAllSugggestedTradeActivities}
        onClose={() => dispatch(closeAccountAllSuggestedTradeActivities())}
        size="lg"
        title={`Suggested Trades (${count})`}
        customHeaderButtons={<></>}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          padding="0px"
          gap="32px"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {activities.map((ag) => {
            return <ActivityGroup key={ag.id} {...ag}></ActivityGroup>
          })}
        </Box>
      </SlideOutMenu>
    </>
  )
}
export default AccountAllSuggestedTradeActivitiesModal
