import React from 'react'

import { Box, Text, TextInput } from 'components/common'

import { AuthActionButton, FormContainer, FormInputsContainer } from './shared'

type Props = {
  email?: string
  isButtonDisabled: boolean
  onResendConfirmationCode: () => void
  onSubmit: (formData: any) => void
  redirectedFromLogin?: boolean
}
function ConfirmUserForm({
  email,
  isButtonDisabled,
  onResendConfirmationCode,
  onSubmit,
  redirectedFromLogin,
}: Props) {
  const [code, setCode] = React.useState('')

  return (
    <FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit({ code })
        }}
      >
        {redirectedFromLogin && (
          <Text color="red.500">
            Looks like you haven't verified your account yet
          </Text>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="0px"
          gap="24px"
          width="100%"
        >
          <Text textAlign="center" color="coolGray.500">
            We sent a user confirmation code to your email. Please enter it here
            to confirm your account.
          </Text>
          <FormInputsContainer>
            <TextInput
              name="code"
              width="100%"
              type="text"
              placeholder="Email"
              value={email}
              readOnly
              required
            />
            <TextInput
              name="code"
              width="100%"
              type="text"
              placeholder="Code"
              value={code}
              required
              onChange={(e) => setCode(e.target.value)}
            />
          </FormInputsContainer>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0px"
            gap="24px"
          >
            <AuthActionButton
              disabled={isButtonDisabled}
              type="submit"
              variant="primary"
              size="lg"
            >
              Confirm
            </AuthActionButton>
            <Text
              color="lightBlue.600"
              style={{ cursor: 'pointer' }}
              onClick={() => onResendConfirmationCode()}
            >
              Didn't receive a confirmation code?, click here to resend.
            </Text>
          </Box>
        </Box>
      </form>
    </FormContainer>
  )
}

export default ConfirmUserForm
