import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import React from 'react'

import { Asset } from 'types'

import { Box, Tag, Text } from 'components/common'
import { formatAsCurrency } from 'utils'

type Props = {
  asset: Asset
}

function AssetPerformance({ asset }: Props) {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding="0px"
        gap="0px"
        width="100%"
      >
        <Text
          color="coolGray.500"
          fontWeight="700"
          fontSize="l"
          lineHeight="l"
          as="h2"
        >
          Asset Performance
        </Text>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          padding="0px"
          gap="20px"
          width="100%"
        >
          <Text
            fontWeight="600"
            fontSize="3xl"
            lineHeight="3xl"
            color="coolGray.700"
          >
            {formatAsCurrency(asset?.price)}
          </Text>
          {isFinite(asset?.priceDetails?.dayGain) && (
            <Tag
              size="md"
              icon={
                asset.priceDetails.dayGain >= 0 ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon />
                )
              }
              iconPosition="left"
              variant={asset.priceDetails.dayGain >= 0 ? 'green' : 'red'}
            >
              <Text fontSize="xxs" fontWeight="bold">
                {asset.priceDetails.dayGain}%
              </Text>
            </Tag>
          )}
          <Tag>Today</Tag>
        </Box>
      </Box>
    </>
  )
}

export default AssetPerformance
