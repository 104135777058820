import { ArrowRightIcon } from '@heroicons/react/solid'
import { capitalize, round } from 'lodash'

import { Asset, PortfolioSuggestedTrade } from 'types'

import { Box, Tag, Text } from 'components/common'
import { formatAsCurrency } from 'utils'
import { roundToHundredths } from 'utils/numberUtils'

import { ViewType } from './SuggestedTradesList'

type Props = {
  asset: Asset
  trade: PortfolioSuggestedTrade
  onAssetClick: (symbol: string) => void
  viewType: ViewType
}

function SuggestedTradeItem({ asset, trade, onAssetClick, viewType }: Props) {
  const {
    symbol,
    shares,
    action,
    currentWeight = 0,
    recommendedWeight = 0,
    value = 0,
  } = trade
  const currentWeightPercentage = round(currentWeight * 100, 2)
  const recommendedWeightPercentage = round(recommendedWeight * 100, 2)
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexBasis="calc(50% - 8px)"
      alignItems="center"
      padding="8px 12px"
      justifyContent="space-between"
      gap="5px"
      border="1px solid"
      borderColor="coolGray.300"
      borderRadius="8px"
      width="100%"
      height="56px"
      onClick={() => onAssetClick(symbol)}
      style={{ cursor: 'pointer' }}
    >
      <Box display="flex" flex={2} gap="8px" alignItems="center">
        <Tag size="sm">{symbol}</Tag>
        <Text
          fontWeight="medium"
          fontSize="xs"
          lineHeight="xs"
          color="coolGray.600"
        >
          {asset?.name}
        </Text>
      </Box>
      {viewType === 'percentage' && (
        <Box
          display="flex"
          flex={1}
          justifyContent="flex-end"
          alignItems="center"
          gap="4px"
        >
          <Tag variant="white" size="sm">
            {' '}
            <Text fontSize="xxs" fontWeight="bold">{`${roundToHundredths(
              currentWeightPercentage
            )}%`}</Text>
          </Tag>
          <ArrowRightIcon width={12} height={12} />
          <Tag
            variant={currentWeight > recommendedWeight ? 'red' : 'green'}
            size="sm"
          >
            <Text fontSize="xxs" fontWeight="bold">
              {`${roundToHundredths(recommendedWeightPercentage)}%`}
            </Text>
          </Tag>
        </Box>
      )}
      {viewType === 'shares' && (
        <Box display="flex" flex={1} justifyContent="flex-end">
          <Text
            fontWeight="bold"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.600"
          >
            {`${capitalize(action)} ${roundToHundredths(shares)} shares`}
          </Text>
        </Box>
      )}
      {viewType === 'value' && (
        <Box display="flex" flex={1} justifyContent="flex-end">
          <Text
            fontWeight="bold"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.600"
          >
            {`${capitalize(action)} ${formatAsCurrency(value)}`}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default SuggestedTradeItem
