import styled from '@emotion/styled'
import { SearchIcon } from '@heroicons/react/solid'
import React, { InputHTMLAttributes } from 'react'

import { TextInput } from 'components/common'

type Props = InputHTMLAttributes<HTMLInputElement>

function SearchBar({ type, ...props }: Props) {
  return (
    <StyledSearchBar
      type="text"
      icon={<SearchIcon width={14} height={14} />}
      {...props}
    />
  )
}

const StyledSearchBar = styled(TextInput)`
  height: 2.5rem;
  border-radius: 0.5rem;
`

export default SearchBar
