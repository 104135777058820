import { isFinite } from 'lodash'
import React from 'react'

import { Box, Text } from 'components/common'

import { PercentageProgressBar } from './PercentageProgressBar'

type Props = {
  label: string
  value: number
  valueLabels?: {
    positive: string
    negative: string
  }
  reversed?: boolean
  inactive?: boolean
}

function StatItem({
  label,
  value,
  valueLabels = { positive: 'increase', negative: 'decrease' },
  reversed = false,
  inactive = false,
}: Props) {
  let isNegative = value < 0
  const valueLabel = isNegative ? valueLabels.negative : valueLabels.positive
  if (reversed) isNegative = !isNegative
  let color = isNegative ? 'red.500' : 'emerald.600'
  const isValidValue = isFinite(value)
  const valueText = isValidValue ? `${Math.abs(value)}% ${valueLabel}` : '-'
  if (inactive || !isValidValue) color = 'coolGray.600'
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding="0px"
      gap="12px"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="0px"
        gap="4px"
        width="100%"
      >
        <PercentageProgressBar value={isValidValue ? value : 0} color={color} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="8px"
          width="100%"
        >
          <Text fontWeight="bold" fontSize="xs" lineHeight="xs" color="black">
            {label}
          </Text>

          <Text fontWeight="medium" fontSize="xs" lineHeight="xs" color={color}>
            {valueText}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default StatItem
