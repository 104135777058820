import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { ConnectionMethod } from 'enums/ConnectionMethodEnum'

import RIASubAccountFlow from 'components/partials/CreatePortfolio/RIASubAccountFlow'
import { closeCreatePortfolio } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

import BrokerageLinkSelector from './BrokerageLinkSelector'
import InteractiveBrokersFlow from './InteractiveBrokersFlow'

function CreatePortfolioModal() {
  const { showCreatePortfolio } = useAppSelector((state) => state.modals)
  const { linkMethod, step } = useAppSelector((state) => state.createPortfolio)
  const dispatch = useAppDispatch()

  const renderStepContent = () => {
    if (step === 1 || !linkMethod) {
      return <BrokerageLinkSelector />
    } else if (step >= 2) {
      switch (linkMethod) {
        case ConnectionMethod.INTERACTIVE_BROKERS:
          return <InteractiveBrokersFlow />
        case ConnectionMethod.RIA_SUB_ACCOUNT:
          return <RIASubAccountFlow />
        default:
          return <BrokerageLinkSelector />
      }
    }
  }

  return (
    <>
      <Modal
        isOpen={showCreatePortfolio}
        onClose={() => dispatch(closeCreatePortfolio())}
        size="2xl"
        trapFocus={false} // DO NOT REMOVE: This is needed for Plaid link to work
      >
        <ModalOverlay />
        <ModalContent>{renderStepContent()}</ModalContent>
      </Modal>
    </>
  )
}

export default CreatePortfolioModal
