import { analytics } from 'analytics'
import { Link, useNavigate } from 'react-router-dom'
import validator from 'validator'

import { paveApi } from 'api'
import { API_ERRORS } from 'api/errors'
import { Box, Button } from 'components/common'
import { PublicLayout, TopNav } from 'components/partials'
import ForgotPassword from 'components/partials/Forms/ForgotPassword'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { FORGOT_PASSWORD_CODE_REQUESTED } from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { setEmail } from 'store/forgotPasswordSlice'
import { useAppDispatch } from 'store/storeHooks'

function ForgotPasswordPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { showErrorToast } = useCustomToast()
  const [forgotPassword, { isLoading }] = paveApi.useForgotPasswordMutation()

  const onSubmit = async (formData: any) => {
    const { email } = formData
    if (!validator.isEmail(email)) {
      showErrorToast(`${email} is not a valid email`)
      return
    }
    analytics.track(FORGOT_PASSWORD_CODE_REQUESTED)
    dispatch(setEmail(email))
    forgotPassword({ email })
      .unwrap()
      .then(() => {
        navigate('/confirm-forgot-password')
      })
      .catch((err) => {
        const { error } = err.data
        if (error === API_ERRORS.USER_NOT_FOUND) {
          showErrorToast('Sorry, we cannot find this email.')
        } else if (error === API_ERRORS.INVALID_PARAMETER_EXCEPTION) {
          showErrorToast(
            'You must verify your email before resetting your password.'
          )
          navigate('/confirm-user')
        } else {
          showErrorToast(ERROR_MESSAGE_1)
        }
      })
  }

  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/">
              <Button>Sign in</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
      >
        <ForgotPassword isLoading={isLoading} onSubmit={onSubmit} />
      </Box>
    </PublicLayout>
  )
}

export default ForgotPasswordPage
