import { ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/react'
import { useState } from 'react'

import { Box, Button, Text } from 'components/common'
import { SUPPORT_EMAIL, SUPPORT_MAILTO_LINK } from 'constants/general.constants'
import { IB_HOME_PAGE } from 'constants/ibkr.constants'
import { setStep } from 'store/createPortfolioSlice'
import { useAppDispatch } from 'store/storeHooks'

enum ScreenState {
  INSTRUCTIONS = 'INSTRUCTIONS',
}

function InteractiveBrokersFlow() {
  const dispatch = useAppDispatch()
  const [screen] = useState<ScreenState>(ScreenState.INSTRUCTIONS)
  const goBack = () => {
    if (screen === ScreenState.INSTRUCTIONS) {
      dispatch(setStep(1))
    }
  }

  const next = async () => {
    if (screen === ScreenState.INSTRUCTIONS) {
      window.location.href = SUPPORT_MAILTO_LINK
    }
  }

  const currentScreen: { [key: string]: any } = {
    [ScreenState.INSTRUCTIONS]: (
      <>
        <Text color="coolGray.500" fontWeight="600">
          Create New Brokerage Account
        </Text>
        <Text fontSize="sm" as="p">
          To create a new brokerage account within Pave Pro, you will first have
          to create a new Interactive Brokers account. After that, our customer
          support team will assist you in connecting the account to Pave Pro.
          Please complete the following steps:
        </Text>

        <Text fontSize="sm" as="p" mt="16px">
          1. Navigate to{' '}
          <Text color="purple.600">
            <a href={IB_HOME_PAGE}>
              <b>{IB_HOME_PAGE}</b>
            </a>
          </Text>
        </Text>
        <Text fontSize="sm" as="p">
          2. Use the “open account” button in the top right corner of the page
          to start your application
        </Text>
        <Text fontSize="sm" as="p">
          3. Create a username and password and keep them for future reference
        </Text>
        <Text fontSize="sm" as="p">
          4. Make sure you choose “IBKR PRO” as your service plan
        </Text>
        <Text fontSize="sm" as="p">
          5. Finalize your application and fund your account
        </Text>

        <Text fontSize="sm" as="p" mt="16px" mb="16px">
          Once you have finished the steps above and your Interactive Brokers
          account is open, please email the items listed below to{' '}
          <Text color="purple.600">
            <a href={SUPPORT_MAILTO_LINK}>
              <b>{SUPPORT_EMAIL}</b>
            </a>
          </Text>{' '}
          to enable our team to connect your new Interactive Brokers account to
          Pave Pro.
        </Text>

        <li>
          <Text fontSize="sm">
            <Text fontWeight="medium">Portfolio Name</Text> - desired name of
            your portfolio
          </Text>
        </li>
        <li>
          <Text fontSize="sm">
            <Text fontWeight="medium">Pave Pro Web Address</Text> - web address
            of this page, also called the link or URL, which can be found at the
            top of your web browser
          </Text>
        </li>
        <li>
          <Text fontSize="sm">
            <Text fontWeight="medium">IBKR Username</Text> - username you set
            when you opened your Interactive Brokers account
          </Text>
        </li>
        <li>
          <Text fontSize="sm">
            <Text fontWeight="medium">IBKR Account ID</Text> - number beginning
            with “U” that can be found in the “Your Portfolio” section of the
            Interactive Brokers portal
          </Text>
        </li>
        <Text mt="16px" fontSize="sm" as="p">
          After sending the requested items to our customer support team, feel
          free to close out of this pop-up. It may take up to a few business
          days for our team to link your account. Please reach out if you have
          any questions.
        </Text>
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          gap="12px"
          mt="12px"
        >
          <Button color="purple.800" style={{ width: '100%' }} onClick={next}>
            Email Customer Support
          </Button>
          <Button variant="secondary" onClick={goBack}>
            Back
          </Button>
        </Box>
      </>
    ),
  }
  return (
    <>
      <ModalHeader>Create New Portfolio</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{currentScreen[screen]}</ModalBody>
    </>
  )
}

export default InteractiveBrokersFlow
