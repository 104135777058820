import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react'
import { ThemingProps } from '@chakra-ui/system'
import { ReactNode } from 'react'

import DrawerHeader from 'components/partials/SlideOutMenu/DrawerHeader'

type SlideOutMenuProps = ThemingProps<'Drawer'> & {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  title?: string
  buttonText?: string
  formId?: string
  disabled?: boolean
  customHeaderButtons?: ReactNode
  useDefaultHeader?: boolean
  trapFocus?: boolean
}

const SlideOutMenu = ({
  isOpen,
  onClose,
  children,
  title,
  size,
  formId,
  customHeaderButtons,
  useDefaultHeader = true,
  buttonText = 'Save Changes',
  disabled = false,
  trapFocus = true,
}: SlideOutMenuProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      size={size}
      trapFocus={trapFocus}
    >
      <DrawerOverlay />
      <DrawerContent>
        {useDefaultHeader ? (
          <>
            <DrawerHeader
              title={title as string}
              buttonText={buttonText}
              formId={formId}
              disabled={disabled}
              customButtons={customHeaderButtons}
            />
            <DrawerBody>{children}</DrawerBody>
          </>
        ) : (
          <>{children}</>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default SlideOutMenu
