import styled from '@emotion/styled'

import { Portfolio } from 'types'

import { Box, Checkbox, Text } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { closeGenerateReport } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { formatAsCurrency, formatAsSignedNumber } from 'utils'

function GenerateReportModal() {
  const { showGenerateReport } = useAppSelector((state) => state.modals)
  const dispatch = useAppDispatch()
  const samplePortfolio: Partial<Portfolio> = {
    id: '1',
    displayName: `Michael's Retirement`,
    value: 185100.09,
    gainPercentage: 1.17,
  }

  const portfolios: Partial<Portfolio>[] = Array(14).fill(
    samplePortfolio,
    0,
    14
  )

  return (
    <>
      <SlideOutMenu
        isOpen={showGenerateReport}
        onClose={() => dispatch(closeGenerateReport())}
        size="lg"
        title="Generate Report"
        buttonText="Generate Report"
      >
        <Box>
          <Text fontSize="lg" fontWeight="medium" color="coolGray.600">
            Choose what information you would like included on your report.
          </Text>
          <Box m="32px 0">
            <Label>
              Portfolio Performance
              <Checkbox width="16px" height="16px" checked />
            </Label>
            <Label>
              Portfolio Breakdown
              <Checkbox width="16px" height="16px" checked />
            </Label>
            <Label>
              Suggested Trades
              <Checkbox width="16px" height="16px" checked />
            </Label>
          </Box>
          <Text fontSize="lg" fontWeight="medium" color="coolGray.600">
            Choose which portfolios you would like included in your report:
          </Text>
          <Box
            m="32px 0"
            gap="16px"
            flexWrap="wrap"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            width="100%"
          >
            {portfolios.map((portfolio, index) => (
              <PortfolioLabel key={index}>
                <Box display="flex" gap="8px">
                  <Text>{portfolio.displayName}</Text>
                  <Text>{formatAsCurrency(portfolio.value as number)}</Text>
                  <Text fontSize="xs" fontWeight="bold" color="emerald.600">
                    {formatAsSignedNumber(portfolio.gainPercentage as number)}%
                  </Text>
                </Box>
                <Checkbox width="16px" height="16px" checked />
              </PortfolioLabel>
            ))}
          </Box>
        </Box>
      </SlideOutMenu>
    </>
  )
}

const Label = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.coolGray[600]};
  background-color: ${({ theme }) => theme.colors.coolGray[50]};
  padding: 8px 12px 5px 16px;
  margin-bottom: 16px;
  border-radius: 8px;
`

const PortfolioLabel = styled(Label)`
  margin-bottom: 0;
`

export default GenerateReportModal
