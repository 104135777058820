import { analytics } from 'analytics'
import { Link, useNavigate } from 'react-router-dom'
import validator from 'validator'

import { Box, Button } from 'components/common'
import { PublicLayout, SignUpForm, TopNav } from 'components/partials'
import { SIGNUP_STARTED } from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { setConfirmPassword, setEmail, setPassword } from 'store/signUpSlice'
import { useAppDispatch } from 'store/storeHooks'

function SignUpPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { showErrorToast } = useCustomToast()
  const onSubmit = (formData: any) => {
    const { email, password, confirmPassword } = formData
    if (!validator.isEmail(email)) {
      showErrorToast(`${email} is not a valid email`)
      return
    }
    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
      })
    ) {
      showErrorToast(
        'Password is too weak, it must contain at least 8 characters, 1 uppercase and 1 lowercase letter'
      )
      return
    }
    if (password !== confirmPassword) {
      showErrorToast('Passwords do not match')
      return
    }
    analytics.track(SIGNUP_STARTED)
    dispatch(setEmail(email))
    dispatch(setPassword(password))
    dispatch(setConfirmPassword(confirmPassword))
    navigate('/complete-profile')
  }
  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/">
              <Button>Sign in</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
      >
        <SignUpForm onSubmit={onSubmit} />
      </Box>
    </PublicLayout>
  )
}

export default SignUpPage
