import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function PerformancePriorityPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Performance Priority
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Pave Pro simultaneously aims to outperform while keeping client
                portfolios inline with the risk profile of their benchmark. Our
                Performance Priority option allows you to indicate how much
                weight you want Pave Pro to put on each of those objectives.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Leaving Performance Priority as Balanced will allow Pave Pro to
                seek outperformance without straying too far from the benchmark.
                Alternatively, if you would like Pave Pro to focus more heavily
                on minimizing tracking error even if it reduces the chance for
                outperformance, select Tracking Error Focus. If you would like
                Pave Pro to focus more heavily on maximizing outperformance even
                if it means taking on more risk to the benchmark, select Slight
                Outperformance, Outperformance, or Strong Outperformance Focus.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default PerformancePriorityPopOver
