import { ReactElement } from 'react'

import { Box, Tag, Text } from 'components/common'

type Props = {
  preference?: string
  preferenceLabel?: string
  isEnabled?: boolean
  tooltip?: ReactElement
}

export function UntoggleablePreference({
  preference = '',
  preferenceLabel = '',
  isEnabled = false,
  tooltip,
}: Props) {
  const variant = isEnabled ? 'green' : 'red'
  const tagText = isEnabled ? 'On' : 'Off'
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      padding="0px"
      gap="12px"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="0px"
        gap="4px"
      >
        <Box display="flex" alignItems="center" gap="4px">
          <Text
            fontSize="xs"
            fontWeight="bold"
            lineHeight="xs"
            color="coolGray.700"
          >
            {preference}
          </Text>
          {tooltip}
        </Box>
        <Text
          fontSize="xs"
          fontWeight="regular"
          lineHeight="xs"
          color="coolGray.700"
        >
          {preferenceLabel}
        </Text>
      </Box>
      <Tag variant={variant}>{tagText}</Tag>
    </Box>
  )
}
