import { BrokerageAccount } from 'types'
import { PortfolioFormState } from 'types/portfolioTypes'

import { GridView } from 'components/partials'

import PlaidAccount from './PlaidAccount'

type Props = {
  brokerageAccounts?: BrokerageAccount[]
  formState?: PortfolioFormState
  setActiveConnectedAccountIds: (activeConnectedAccountIds: string[]) => void
}

function PlaidLinkedAccounts({
  brokerageAccounts = [],
  formState,
  setActiveConnectedAccountIds,
}: Props) {
  const handleChange = (target: HTMLInputElement) => {
    const { value: newId } = target
    const updatedActiveConnectedAccountIds =
      formState?.activeConnectedAccountIds || []

    if (
      updatedActiveConnectedAccountIds.some((accountId) => accountId === newId)
    ) {
      setActiveConnectedAccountIds([
        ...updatedActiveConnectedAccountIds.filter(
          (accountId) => accountId !== newId
        ),
      ])
    } else {
      setActiveConnectedAccountIds([...updatedActiveConnectedAccountIds, newId])
    }
  }

  return (
    <GridView
      gap={4}
      data={brokerageAccounts}
      renderItem={(account) => (
        <PlaidAccount
          key={account.id}
          plaidAccountId={account.id}
          formState={formState}
          handleChange={(target: any) => handleChange(target)}
        />
      )}
    />
  )
}

export default PlaidLinkedAccounts
