import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export default function InvestmentTemplateLearnMoreModal({
  isOpen,
  onClose,
}: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Investment Template Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="grid" gap={SPACE.sp8}>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                Investment templates allow you to quickly set and manage
                investment preferences across several portfolios.
              </Text>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                Filling in a field will standardize it across all portfolios
                with the template applied. As a result, the field will not be
                editable from the portfolio’s investment preferences.
              </Text>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                If a field is edited from the investment template, the change
                will automatically propagate to all portfolios using the
                template. If you want a field to be consistent across all
                portfolios with a certain template, it’s best to fill it in.
              </Text>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                Leaving a field blank will allow the field to be changed from
                portfolio to portfolio even when a template is applied. If you
                plan to personalize a field on a portfolio by portfolio basis,
                it’s best to leave it blank.
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <FlexBox width="100%" gap={SPACE.sp12}>
              <Button
                color={COLOR.purple800}
                style={{ width: '100%' }}
                onClick={onClose}
              >
                Got It
              </Button>
            </FlexBox>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
