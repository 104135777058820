import { useDisclosure } from '@chakra-ui/react'
import { analytics } from 'analytics'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'

import { GetPortfolioStatsResponse, Portfolio, PortfolioLinkType } from 'types'

import { paveApi } from 'api'
import { Box, Button, ConfirmDialog, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SlideOutMenu } from 'components/partials'
import {
  PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED,
  PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED_FAILED,
  PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED_SUCCESS,
  PORTFOLIO_APPROVE_TRADES_CONFIRMATION_MODAL_CLOSED,
  PORTFOLIO_APPROVE_TRADES_CONFIRMATION_MODAL_OPENED,
  PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED,
  PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED_FAILED,
  PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED_SUCCESS,
  PORTFOLIO_APPROVE_TRADES_TRADE_NOW_MODAL_CLOSED,
  PORTFOLIO_APPROVE_TRADES_TRADE_NOW_MODAL_OPENED,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { closePortfolioAllSuggestedTrades } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'
import {
  getPortfolioLinkType,
  isAutomationSupportedPortfolio,
} from 'utils/portfolioUtil'

import PortfolioStats from './PortfolioStats'
import SuggestedTradesList from './SuggestedTradesList'

type Props = {
  portfolio: Portfolio
  statistics?: GetPortfolioStatsResponse
  isStatisticsLoading?: boolean
}

function PortfolioAllSuggestedTradesModal({
  portfolio,
  statistics,
  isStatisticsLoading,
}: Props) {
  const dispatch = useAppDispatch()
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const { showPortfolioAllSuggestedTrades } = useAppSelector(
    (state) => state.modals
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isTradeNowOpen,
    onOpen: onTradeNowOpen,
    onClose: onTradeNowClose,
  } = useDisclosure()

  const [patchPortfolio] = paveApi.usePatchPortfolioMutation()
  const [updateSuggestedTrades] = paveApi.useUpdateSuggestedTradesMutation()

  const linkType = getPortfolioLinkType(portfolio)
  const isAutomationSupported = isAutomationSupportedPortfolio(portfolio)

  const { investmentPreference, suggestedTrades } = portfolio

  const handleExecuteTrades = () => {
    onClose()
    onTradeNowClose()
    let payload: Partial<Portfolio> = {}
    if (isAutomationSupported) {
      payload = {
        needsTradeExecution: true,
        userApprovedAutomatedTradesOnce: !!investmentPreference?.automation,
      }
    } else if (linkType === PortfolioLinkType.Plaid) {
      payload = {
        suggestedTrades: [],
        lastTradedAt: new Date().toISOString(),
      }
    } else {
      payload = {
        suggestedTrades: [],
      }
    }

    const automation = investmentPreference?.automation === true

    const SUBMITTED_EVENT = automation
      ? PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED
      : PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED
    const SUCCESS_EVENT = automation
      ? PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED_SUCCESS
      : PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED_SUCCESS
    const FAILED_EVENT = automation
      ? PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED_FAILED
      : PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED_FAILED

    analytics.track(SUBMITTED_EVENT, {
      type: linkType,
    })
    patchPortfolio({
      body: {
        id: portfolio.id,
        ...payload,
      },
    })
      .unwrap()
      .then(() => {
        analytics.track(SUCCESS_EVENT, {
          type: linkType,
        })
        dispatch(closePortfolioAllSuggestedTrades())
        showSuccessToast('Portfolio updated!')
      })
      .catch(() => {
        analytics.track(FAILED_EVENT, {
          type: linkType,
        })
        showErrorToast('An error occurred updating the portfolio.')
      })
  }

  const trades: any[] = portfolio?.suggestedTrades || []

  useEffect(() => {
    if (showPortfolioAllSuggestedTrades && portfolio?.id) {
      updateSuggestedTrades(portfolio.id)
    }
  }, [portfolio.id, showPortfolioAllSuggestedTrades, updateSuggestedTrades])

  const closeConfirmationModal = () => {
    analytics.track(PORTFOLIO_APPROVE_TRADES_CONFIRMATION_MODAL_CLOSED)
    onClose()
  }

  const closeTradeNowModal = () => {
    analytics.track(PORTFOLIO_APPROVE_TRADES_TRADE_NOW_MODAL_CLOSED)
    onTradeNowClose()
  }

  const openConfirmationModal = () => {
    analytics.track(PORTFOLIO_APPROVE_TRADES_CONFIRMATION_MODAL_OPENED)
    onOpen()
  }

  const openTradeNowModal = () => {
    analytics.track(PORTFOLIO_APPROVE_TRADES_TRADE_NOW_MODAL_OPENED)
    onTradeNowOpen()
  }

  return (
    <>
      <SlideOutMenu
        isOpen={showPortfolioAllSuggestedTrades}
        onClose={() => dispatch(closePortfolioAllSuggestedTrades())}
        size="xl"
        title={`Suggested Trades (${trades.length})`}
        customHeaderButtons={
          <Box display="flex" gap="14px">
            {isAutomationSupported ? (
              <Button
                variant="primary"
                size="md"
                disabled={isEmpty(trades)}
                onClick={openTradeNowModal}
              >
                Trade Now
              </Button>
            ) : (
              <Button
                variant="primary"
                size="md"
                disabled={isEmpty(trades)}
                onClick={openConfirmationModal}
              >
                <Text fontSize="sm" fontWeight="bold">
                  Mark Trades Complete
                </Text>
              </Button>
            )}
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" gap="32px">
          <PortfolioStats
            statistics={statistics}
            isLoading={isStatisticsLoading}
            portfolioNeedsTradeExecution={portfolio.needsTradeExecution}
            hasSuggestedTrades={suggestedTrades?.length > 0}
          />
          <SuggestedTradesList
            suggestedTrades={trades}
            portfolioId={portfolio?.id}
          />
        </Box>
      </SlideOutMenu>
      <ConfirmDialog
        isOpen={isOpen}
        onCancel={closeConfirmationModal}
        onConfirm={handleExecuteTrades}
        confirmButtonText="Confirm"
        title="Confirm trade execution?"
        description="Are you sure you want to execute your suggested trades? This action cannot be undone."
      ></ConfirmDialog>
      <ConfirmDialog
        isOpen={isTradeNowOpen}
        onCancel={closeTradeNowModal}
        onConfirm={handleExecuteTrades}
        confirmButtonText="Confirm"
        title="Place Suggestions Immediately?"
        description={
          <FlexBox flexDirection="column" gap={SPACE.sp8}>
            <Text color={COLOR.coolGray600}>
              You have scheduled these suggested trades to be placed on the
              portfolio’s existing monthly trade cadence.
            </Text>
            <Text color={COLOR.coolGray600}>
              If you would like Pave Pro to place these trades during the next
              trading day instead, please press confirm below.
            </Text>
          </FlexBox>
        }
      />
    </>
  )
}

export default PortfolioAllSuggestedTradesModal
