import {
  CompleteProfilePage,
  ConfirmForgotPassword,
  ConfirmUserPage,
  ForgotPassword,
  SetupMFAPage,
  SignIn,
  SignUpPage,
} from 'pages'
import { Navigate, Route, Routes } from 'react-router-dom'

export default function SigninNavigator() {
  return (
    <Routes>
      <Route index element={<SignIn />} />
      <Route path="/complete-profile" element={<CompleteProfilePage />} />
      <Route path="/confirm-user" element={<ConfirmUserPage />} />
      <Route path="/setup-mfa" element={<SetupMFAPage />} />
      <Route
        path="/confirm-forgot-password"
        element={<ConfirmForgotPassword />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
