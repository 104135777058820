export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [
  {
    label: '12 / page',
    value: 12,
  },
  {
    label: '24 / page',
    value: 24,
  },
  {
    label: '36 / page',
    value: 36,
  },
]
