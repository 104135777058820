// TODO: Remove mock data
export const AVATARS = [
  'https://robohash.org/quasmagnamrerum.png?size=50x50&set=set1',
  'https://randomuser.me/api/portraits/lego/2.jpg',
  "https://robohash.org/randomavatar.png?size=50x50&set=set1'",
  'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Mr._Smiley_Face.svg/800px-Mr._Smiley_Face.svg.png',
]

export const FAKE_USER = {
  userName: 'John Doe',
  userImg: AVATARS[1],
}

const ACTIVITIES = [
  { id: '1', leftText: `'Portfolio Name' \u00B7 5 trades`, link: '' },
  { id: '2', leftText: `'Portfolio Name' \u00B7 5 trades`, link: '' },
  { id: '3', leftText: `'Portfolio Name' \u00B7 5 trades`, link: '' },
  { id: '4', leftText: `'Portfolio Name' \u00B7 5 trades`, link: '' },
]

export const EXECUTED_ACTIVITY_GROUPS = [
  {
    id: '1',
    headerText: '5 trades made to 10 portfolios',
    when: '3 minutes ago',
    accountName: 'Account Name',
    accountLogo: AVATARS[3],
    activities: ACTIVITIES,
    count: 4,
    link: '',
  },
  {
    id: '2',
    headerText: '5 trades made to 10 portfolios',
    when: '3 minutes ago',
    accountName: 'Account Name',
    accountLogo: AVATARS[3],
    activities: ACTIVITIES,
    count: 4,
    link: '',
  },
  {
    id: '3',
    headerText: '2 trades made to 10 portfolios',
    when: '3 minutes ago',
    accountName: 'Account Name',
    accountLogo: AVATARS[3],
    activities: ACTIVITIES,
    count: 4,
    link: '',
  },
  {
    id: '4',
    headerText: '5 trades made to 10 portfolios',
    when: '3 minutes ago',
    accountName: 'Account Name',
    accountLogo: AVATARS[3],
    activities: ACTIVITIES,
    count: 4,
    link: '',
  },
]

export const TASK_LIST = [
  {
    title: 'Create meeting for this type of person',
    dueDate: 'August 15, 2022',
    notes:
      'Make sure to create a recurring meeting with X person so that we can do',
  },
  {
    title: 'Create meeting for this type of person',
    dueDate: 'August 15, 2022',
  },
  {
    title: 'Create meeting for this type of person',
    dueDate: 'August 15, 2022',
  },
  {
    title: 'Create meeting for this type of person',
    dueDate: 'August 15, 2022',
    notes:
      'Make sure to create a recurring meeting with X person so that we can do',
  },
]
