import { IconButton } from '@chakra-ui/button'
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu'
import { forwardRef } from 'react'

type Props = {
  icon: any
  content: any
}

const PopupMenuIcon = ({ icon, content }: Props) => {
  return (
    <Menu>
      <MenuButton
        as={forwardRef((props: any, ref: any) => {
          return (
            <IconButton
              ref={ref}
              aria-label="Edit asset"
              icon={icon}
              padding={2}
              size="sm"
              {...props}
            />
          )
        })}
      >
        Actions
      </MenuButton>
      <MenuList maxWidth={600} padding={4}>
        {content}
      </MenuList>
    </Menu>
  )
}

export default PopupMenuIcon
