import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'

function ModalLoader() {
  return (
    <Overlay>
      <Spinner color="white" size="xl" />
    </Overlay>
  )
}

const Overlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
  z-index: 10;
`

export default ModalLoader
