import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import './styles/stylesheets/classes.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY ?? 'NO_API_KEY',
  plugins: [new BugsnagPluginReact()],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
