import React from 'react'
import { NumericFormat } from 'react-number-format'

import { Asset } from 'types'

import { paveApi } from 'api'
import { Box, Button, Dropdown, Text, TextInput } from 'components/common'
import VirtualOptionsList from 'components/common/VirtualOptionsList/VirtualOptionsList'

type Props = {
  handleAdd: (asset: Asset) => void
  scrollDown: Function
}

function AddAssetForm({ handleAdd, scrollDown }: Props) {
  const [symbol, setSymbol] = React.useState<string>('')
  const [shares, setShares] = React.useState<string>('')
  const { data: assets } = paveApi.useGetAssetsQuery()

  const handleClick = () => {
    const asset = assets?.find((asset) => asset.symbol === symbol)
    if (asset)
      handleAdd({
        ...asset,
        symbol,
        shares: parseFloat(shares),
        tradablePercentage: 1,
      })
  }

  return (
    <>
      <>
        <Text
          fontSize="sm"
          fontWeight="500"
          color="coolGray.500"
          mt="12px"
          mb="8px"
          as="p"
        >
          Which stock would you like to include?
        </Text>
        <Box>
          {assets ? (
            <Dropdown
              onMenuOpen={() => {
                scrollDown()
              }}
              options={assets.map(({ symbol, name }) => ({
                value: symbol,
                label: name,
              }))}
              placeholder="Search by asset name or ticker"
              value={symbol ? { label: symbol, value: symbol } : null}
              onChange={(option: any) => setSymbol(option.value)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                MenuList: VirtualOptionsList,
              }}
            />
          ) : (
            <Text
              fontSize="xs"
              fontWeight="500"
              color="coolGray.500"
              mt="12px"
              mb="8px"
              as="p"
            >
              Loading assets...
            </Text>
          )}
        </Box>
        <Text
          fontSize="sm"
          fontWeight="500"
          color="coolGray.500"
          mt="12px"
          mb="8px"
          as="p"
        >
          How many shares of {symbol} do you own?
        </Text>
        <NumericFormat<any>
          allowNegative={false}
          allowLeadingZeros={false}
          customInput={TextInput}
          decimalScale={4}
          displayType="input"
          onChange={(v) => {
            setShares(v.target.value)
          }}
          maxLength={12}
          placeholder="Number of shares"
          value={shares}
        />

        <Box mt="12px">
          <Button size="sm" onClick={handleClick} disabled={!symbol || !shares}>
            Add Asset
          </Button>
        </Box>
      </>
    </>
  )
}

export default AddAssetForm
