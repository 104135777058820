import styled from '@emotion/styled'

const LabelText = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  color: ${({ theme }) => theme.colors.coolGray[500]};
  margin-bottom: 8px;
`
export default LabelText
