import { Box, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import { ActivityGroupProps } from '../TradeActivities/ActivityGroup'
import HomeActivityGroup from './HomeActivityGroup'

type Props = {
  activities: ActivityGroupProps[]
}

function HomeSuggestedTradesSection({ activities }: Props) {
  const count = activities.reduce((acc, ag) => acc + ag.activities.length, 0)
  return (
    <Card
      flexDirection="column"
      height="100%"
      overflowY="scroll"
      overflowX="hidden"
    >
      <Box>
        <Text
          fontWeight="bold"
          fontSize={FONT_SIZE.fs20}
          color={COLOR.coolGray600}
          marginBottom={SPACE.sp8}
        >
          Portfolios Requiring Approval
        </Text>
        <Text
          fontSize={FONT_SIZE.fs20}
          color={COLOR.coolGray600}
          marginBottom={SPACE.sp8}
        >
          {` (${count})`}
        </Text>
      </Box>
      {activities.map((ag) => {
        return <HomeActivityGroup key={ag.id} {...ag} />
      })}
    </Card>
  )
}
export default HomeSuggestedTradesSection
