import { IconButton } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid'
import { useState } from 'react'

import { Portfolio } from 'types'

import { paveApi } from 'api'
import { Box, Button, Tag, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'
import {
  getIbUsername,
  isIbPortfolio,
  isPlaidPortfolio,
  isSchwabRIAPortfolio,
} from 'utils/portfolioUtil'

import PerformanceInformationModal from '../Modals/PerformanceInformationModal'

type Props = {
  portfolio: Portfolio
  openPlaidModal: () => void
}

export default function PerformanceChartRedirectCard({
  portfolio,
  openPlaidModal,
}: Props) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { showErrorToast } = useCustomToast()
  const { useGetIbSsoSessionMutation } = paveApi
  const [getIbSsoSession] = useGetIbSsoSessionMutation()

  const seePerformance = () => {
    if (isIbPortfolio(portfolio)) {
      const username = getIbUsername(portfolio)
      if (username) {
        getIbSsoSession({ username: username })
          .unwrap()
          .then(({ ssoUrl }) => {
            window.open(ssoUrl, '_blank', 'noreferrer')
          })
          .catch(() => {
            showErrorToast(ERROR_MESSAGE_1)
          })
      }
    } else if (isPlaidPortfolio(portfolio)) {
      openPlaidModal()
    } else {
      showErrorToast(ERROR_MESSAGE_1)
    }
  }

  return (
    <>
      <Card flexDirection="column" display="flex" gap={SPACE.sp10}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text
              fontWeight="bold"
              lineHeight="s"
              fontSize={FONT_SIZE.fs20}
              color={COLOR.coolGray700}
            >
              Performance
            </Text>
            <IconButton
              color="gray"
              variant="unstyled"
              onClick={() => setShowModal(true)}
              aria-label="Performance information"
              icon={<InformationCircleIcon />}
              padding={2}
              size="sm"
            />
          </Box>
          {!isSchwabRIAPortfolio(portfolio) && (
            <Box>
              <Button
                variant="tertiary"
                size="xs"
                onClick={seePerformance}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                See Full Performance
                <ExternalLinkIcon
                  style={{ width: '1rem', marginLeft: '0.25rem' }}
                />
              </Button>
            </Box>
          )}
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row">
          <Box>
            <Text fontWeight="bold" fontSize={FONT_SIZE.fs24}>
              {formatAsCurrency(portfolio.value)}
            </Text>
          </Box>
          <Box marginLeft="0.5rem">
            <Tag
              size="sm"
              icon={
                portfolio.performance >= 0 ? <ArrowUpIcon /> : <ArrowDownIcon />
              }
              iconPosition="left"
              variant={portfolio.performance >= 0 ? 'green' : 'red'}
            >
              <Text fontSize={FONT_SIZE.fs10} fontWeight="bold">
                {portfolio.performance}%
              </Text>
            </Tag>
          </Box>
          <Box marginLeft="0.5rem">
            <Tag size="sm">Today</Tag>
          </Box>
        </Box>
      </Card>
      <PerformanceInformationModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}
