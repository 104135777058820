import React from 'react'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Text, TextInput } from 'components/common'

import { AuthActionButton, FormContainer, FormInputsContainer } from './shared'

type Props = {
  secretCode: string
  userId: string
  onSubmit: (formData: any) => void
  mfaSetupSuccess?: boolean
}
function SetupMFAForm(props: Props) {
  const navigate = useNavigate()
  const [code, setCode] = React.useState('')

  if (props.mfaSetupSuccess) {
    return (
      <FormContainer>
        <FormInputsContainer>
          <Box
            width="450px"
            height="350px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="24px"
          >
            <Text as="p" textAlign="center">
              Your MFA has been setup successfully.
              <br /> You can now sign in.
            </Text>

            <Button onClick={() => navigate('/')}>Sign in</Button>
          </Box>
        </FormInputsContainer>
      </FormContainer>
    )
  }

  return (
    <FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          props.onSubmit({ code })
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="0px"
          gap="24px"
          width="450px"
        >
          <Text
            textAlign="center"
            color="red.500"
            style={{
              cursor: 'pointer',
            }}
          >
            MFA (2-factor authentication) is required.
            <br /> It looks like you haven't set it up yet.
          </Text>
          <Text textAlign="center" color="coolGray.500">
            Please scan the following QR code with your authenticator app and
            enter the code to complete it.
          </Text>

          <QRCode value={`otpauth://totp/PavePro?secret=${props.secretCode}`} />
          <FormInputsContainer>
            <TextInput
              name="code"
              width="100%"
              type="number"
              min={0}
              max={999999}
              placeholder="Code"
              value={code}
              required
              onChange={(e) => setCode(e.target.value)}
            />
          </FormInputsContainer>
          <AuthActionButton type="submit" variant="primary" size="lg">
            Setup MFA
          </AuthActionButton>
        </Box>
      </form>
    </FormContainer>
  )
}

export default SetupMFAForm
