type DateFormatOptions = {
  separator?: 'dash' | 'slash'
}

/**
 * @function formatDateMMDDYYYY
 * Formats a date to a string with the style MMDDYYYYY
 * Separators default to '/' but can be modified with other separators
 * Excludes leading zeroes
 */
export function formatDateMMDDYYYY(
  date: Date | string,
  options?: DateFormatOptions
) {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const separators = {
    dash: '-',
    slash: '/',
  }

  let dateString = date.toLocaleDateString('en-US')
  if (options?.separator) {
    dateString = dateString.split('/').join(separators[options.separator])
  }

  return dateString
}
