import styled from '@emotion/styled'
import { get } from 'lodash'
import { ProgressHTMLAttributes } from 'react'

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg'
import { Box } from 'components/common'

type Props = ProgressHTMLAttributes<HTMLProgressElement> & {
  isReversed?: boolean
  color: string
  value: number
}

export function PercentageProgressBar({ color = 'emerald.600', value }: Props) {
  let barVal = 0
  let reverseBarVal = 0
  if (value > 0) {
    barVal = value
  }
  if (value < 0) {
    reverseBarVal = value * -1
  }
  return (
    <>
      <Box mt="16px">
        <ArrowDown />
      </Box>
      <Box display="flex" flexDirection="row" width="100%">
        <StyledProgressBar
          max={20}
          value={reverseBarVal}
          isReversed={true}
          color={color}
        ></StyledProgressBar>
        <StyledProgressBar
          max={20}
          value={barVal}
          color={color}
        ></StyledProgressBar>
      </Box>
    </>
  )
}
const StyledProgressBar = styled.progress<Props>`
  border: 0;
  background: ${({ theme }) => theme.colors.coolGray[200]};
  height: 10px;
  width: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  ::-webkit-progress-bar {
    background: #eee;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  ::-moz-progress-bar {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background: ${({ theme, color }) =>
      get(theme.colors, color, theme.colors.coolGray[600])};
  }
  ::-webkit-progress-value {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background: ${({ theme, color }) =>
      get(theme.colors, color, theme.colors.coolGray[600])};
  }
  transform: ${({ isReversed }) =>
    isReversed ? `rotate(180deg)` : `rotate(0)`};
`
