import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import PinInput from 'react-pin-input'

import { paveApi } from 'api'
import { API_ERRORS } from 'api/errors'
import { Box, Button, Text } from 'components/common'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { useCustomToast } from 'hooks/useCustomToast'

type Props = {
  isOpen: boolean
  onClose: () => void
  nextStep: () => void
  prevStep: () => void
}

function MFASetupVerifyCodeModal({
  isOpen,
  onClose,
  nextStep,
  prevStep,
}: Props) {
  const [code, setCode] = useState('')
  const [verifyMfaSetup, verifyMfaSetupResult] =
    paveApi.useVerifyMfaSetupMutation()
  const { showErrorToast } = useCustomToast()

  const handleSubmit = () => {
    verifyMfaSetup({ code })
  }

  useEffect(() => {
    if (verifyMfaSetupResult.isError) {
      const {
        data: { error },
      } = verifyMfaSetupResult.error as any
      if (error === API_ERRORS.CODE_MISMATCH) {
        showErrorToast(
          'Incorrect code entered. Please check your authenticator for the correct code.'
        )
      } else {
        showErrorToast(ERROR_MESSAGE_1)
      }
    }
    if (verifyMfaSetupResult.isSuccess) {
      nextStep()
    }
  }, [verifyMfaSetupResult, showErrorToast, nextStep])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link Authenticator App</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="p" mb="12px">
              Please enter the 6-character authentication code provided by your
              authenticator app to complete the linking process.
            </Text>
            <PinInput
              length={6}
              focus
              type="numeric"
              inputMode="text"
              style={{ padding: '8px' }}
              inputStyle={{
                width: '60px',
                height: '60px',
                borderColor: '#D1D5DB',
                borderRadius: '8px',
              }}
              onComplete={(value) => setCode(value)}
            />
          </ModalBody>

          <ModalFooter>
            <Box display="flex" width="100%" flexDirection="column" gap="12px">
              <Button
                color="purple.800"
                style={{ width: '100%' }}
                onClick={handleSubmit}
              >
                Verify
              </Button>
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                onClick={prevStep}
              >
                Back
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MFASetupVerifyCodeModal
