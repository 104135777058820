import { Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { analytics } from 'analytics'
import { useState } from 'react'

import { TradePreference } from 'types'
import { InvestmentTemplateFormPayload } from 'types/InvestmentTemplateTypes'

import { paveApi } from 'api'
import { Box, Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SlideOutMenu } from 'components/partials'
import TradePreferenceDialog from 'components/partials/Portfolio/PortfolioPreferences/TradePreferenceDialog'
import {
  INVESTMENT_TEMPLATE_EDIT_FORM_CONFIRMATION_OPENED,
  INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_OPENED,
  INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED,
  INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_SUCCESS,
  INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED,
  INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { closeEditInvestmentTemplate } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import ConfirmDeleteTemplateModal from './Account/ConfirmDeleteTemplateModal'
import ConfirmEditTemplateModal from './Account/ConfirmEditTemplateModal'
import InvestmentTemplateForm from './CreatePortfolio/InvestmentTemplateForm'

function EditInvestmentTemplateSlideIn() {
  const dispatch = useAppDispatch()
  const { showEditingInvestmentTemplate, editingInvestmentTemplate } =
    useAppSelector((state) => state.modals)

  const [templateFormPayload, setTemplateFormPayload] =
    useState<InvestmentTemplateFormPayload | null>(null)

  const [isDeleteTemplateConfirmOpen, setIsDeleteTemplateConfirmOpen] =
    useState(false)
  const [isEditTemplateConfirmOpen, setIsEditTemplateConfirmOpen] =
    useState(false)
  const [isTradeDialogOpen, setIsTradeDialogOpen] = useState(false)
  const openDeleteConfirm = () => {
    analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_OPENED)
    setIsDeleteTemplateConfirmOpen(true)
  }

  const openEditConfirm = () => {
    analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_CONFIRMATION_OPENED)
    setIsEditTemplateConfirmOpen(true)
  }

  const [editInvestmentTemplate, { isLoading: isEditLoading }] =
    paveApi.useEditInvestmentTemplateMutation()
  const [deleteInvestmentTemplate, { isLoading: isDeleteLoading }] =
    paveApi.useDeleteInvestmentTemplateMutation()
  const { showErrorToast, showSuccessToast } = useCustomToast()

  const submitInvestmentTemplateForm = (
    investmentTemplatePayload: InvestmentTemplateFormPayload
  ) => {
    setTemplateFormPayload(investmentTemplatePayload)

    if (investmentTemplatePayload?.investmentPreferences?.automation) {
      setIsTradeDialogOpen(true)
    } else {
      openEditConfirm()
    }
  }

  const handleEditConfirm = (tradePreference: TradePreference) => {
    analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED)

    // close modals
    setIsEditTemplateConfirmOpen(false)
    setIsTradeDialogOpen(false)

    if (editingInvestmentTemplate?.id && templateFormPayload) {
      editInvestmentTemplate({
        templateId: editingInvestmentTemplate?.id,
        body: { ...templateFormPayload, tradePreference },
      })
        .unwrap()
        .then((res) => {
          if (res.error) throw res

          showSuccessToast('Template updated!')
          analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_SUCCESS)
          dispatch(closeEditInvestmentTemplate())
        })
        .catch((e) => {
          const displayMessage = e?.data?.displayMessage
          const portfolios = e?.portfolios
          if (portfolios) {
            showErrorToast(
              'Could not update investment template because of the following portfolios',
              portfolios.join(', ')
            )
          } else if (displayMessage) {
            showErrorToast(displayMessage)
          } else {
            showErrorToast('An error occurred when updating the template.')
          }

          analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_FAILED)
        })
    } else {
      showErrorToast('An error occurred when updating the template.')
      analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_FAILED)
    }
  }

  const handleDelete = () => {
    analytics.track(INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED)
    if (editingInvestmentTemplate?.id) {
      deleteInvestmentTemplate(editingInvestmentTemplate?.id)
        .unwrap()
        .then(() => {
          setIsDeleteTemplateConfirmOpen(false)
          showSuccessToast('Template deleted!')
          analytics.track(
            INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_SUCCESS
          )
          dispatch(closeEditInvestmentTemplate())
        })
        .catch(() => {
          showErrorToast('An error occurred when deleting the template.')
          analytics.track(
            INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_FAILED
          )
        })
    } else {
      showErrorToast('An error occurred when deleting the template.')
      analytics.track(
        INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_FAILED
      )
    }
  }

  const isDeleteDisabled =
    editingInvestmentTemplate?.portfoliosCount !== undefined &&
    editingInvestmentTemplate?.portfoliosCount > 0

  return (
    <>
      <SlideOutMenu
        isOpen={showEditingInvestmentTemplate}
        onClose={() => dispatch(closeEditInvestmentTemplate())}
        title="Edit Investment Template"
        size="xl"
        formId="investment-template-form"
        customHeaderButtons={
          <Box display="flex" gap={SPACE.sp12} alignItems="center">
            {isDeleteDisabled && (
              <Tooltip
                backgroundColor={COLOR.white}
                border={`1px solid ${COLOR.coolGray300}`}
                borderRadius="8"
                placement="auto-start"
                label={
                  <FlexBox
                    color={COLOR.coolGray600}
                    flexDirection="column"
                    gap={SPACE.sp12}
                    margin={SPACE.sp12}
                  >
                    <Text fontWeight="700" fontSize={FONT_SIZE.fs18}>
                      Why am I unable to delete this template?
                    </Text>
                    <Text color={COLOR.coolGray700} fontWeight="regular">
                      For a template to be deleted, it cannot not be applied to
                      any portfolios. This template is applied to one or more
                      portfolios.
                    </Text>
                    <Text color={COLOR.coolGray700} fontWeight="regular">
                      To delete this template, please unlink it from all
                      portfolios. You can do this by filtering portfolios by
                      template, selecting all applicable portfolios, and then
                      clicking the "Remove Template" button.
                    </Text>
                  </FlexBox>
                }
              >
                <InformationCircleIcon
                  color={COLOR.coolGray500}
                  height="1.25rem"
                  width="1.25rem"
                />
              </Tooltip>
            )}
            <Button
              type="button"
              variant="danger"
              size="md"
              disabled={isDeleteDisabled}
              onClick={openDeleteConfirm}
            >
              Delete
            </Button>
            <Button
              type="submit"
              disabled={isEditLoading}
              form="investment-template-form"
            >
              Save
            </Button>
          </Box>
        }
      >
        {editingInvestmentTemplate && (
          <InvestmentTemplateForm
            initialTemplate={editingInvestmentTemplate}
            isNewTemplate={false}
            submitInvestmentTemplateForm={submitInvestmentTemplateForm}
            showLoader={isEditLoading}
          />
        )}
      </SlideOutMenu>
      <ConfirmDeleteTemplateModal
        showModal={isDeleteTemplateConfirmOpen}
        hideModal={() => setIsDeleteTemplateConfirmOpen(false)}
        onClick={handleDelete}
        isLoading={isDeleteLoading}
      />
      <ConfirmEditTemplateModal
        showModal={isEditTemplateConfirmOpen}
        hideModal={() => setIsEditTemplateConfirmOpen(false)}
        onClick={() => handleEditConfirm(TradePreference.MONTHLY_CADENCE)}
        isLoading={isEditLoading}
      />
      <TradePreferenceDialog
        isOpen={isTradeDialogOpen}
        setIsOpen={setIsTradeDialogOpen}
        handleTradeImmediately={() => handleEditConfirm(TradePreference.NOW)}
        handleTradeOnCadence={() =>
          handleEditConfirm(TradePreference.MONTHLY_CADENCE)
        }
        formType="template"
      />
    </>
  )
}
export default EditInvestmentTemplateSlideIn
