import { analytics } from 'analytics'
import { Link, useNavigate } from 'react-router-dom'
import validator from 'validator'

import { paveApi } from 'api'
import { API_ERRORS } from 'api/errors'
import { Box, Button } from 'components/common'
import { PublicLayout, TopNav } from 'components/partials'
import ConfirmForgotPassword from 'components/partials/Forms/ConfirmForgotPassword'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import {
  FORGOT_PASSWORD_RESET_SUBMITTED,
  FORGOT_PASSWORD_RESET_SUBMITTED_FAILED,
  FORGOT_PASSWORD_RESET_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'

function ConfirmForgotPasswordPage() {
  const navigate = useNavigate()
  const { email } = useAppSelector((state) => state.forgotPassword)
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [confirmForgotPassword] = paveApi.useConfirmForgotPasswordMutation()

  const onSubmit = (formData: any) => {
    const { code, newPassword, confirmPassword } = formData

    if (
      !validator.isStrongPassword(newPassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
      })
    ) {
      showErrorToast(
        'Password is too weak, it must contain at least 8 characters, 1 uppercase and 1 lowercase letter'
      )
      return
    }
    if (newPassword !== confirmPassword) {
      showErrorToast('Passwords do not match')
      return
    }

    analytics.track(FORGOT_PASSWORD_RESET_SUBMITTED)
    confirmForgotPassword({ email, newPassword, code })
      .unwrap()
      .then(() => {
        analytics.track(FORGOT_PASSWORD_RESET_SUBMITTED_SUCCESS)
        showSuccessToast('Password successfully changed.')
        navigate('/sign-in')
      })
      .catch((error) => {
        analytics.track(FORGOT_PASSWORD_RESET_SUBMITTED_FAILED)
        const { error: errorCode } = error.data
        if (errorCode === API_ERRORS.CODE_MISMATCH) {
          showErrorToast(
            'Incorrect code entered. Please check your email for the correct code.'
          )
        } else {
          showErrorToast(ERROR_MESSAGE_1)
        }
      })
  }

  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/">
              <Button>Sign in</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
      >
        <ConfirmForgotPassword onSubmit={onSubmit} />
      </Box>
    </PublicLayout>
  )
}

export default ConfirmForgotPasswordPage
