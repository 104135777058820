import styled from '@emotion/styled/macro'
import { useField } from 'formik'
import React, { InputHTMLAttributes } from 'react'

import { LabelText, Text } from 'components/common'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url'
  label?: string
  icon?: React.ReactNode
  passwordToggle?: React.ReactNode
}

function TextInput({ label, icon, ...props }: Props & { name: string }) {
  const [field, meta] = useField(props)
  return (
    <Label>
      <LabelText>
        {label}
        {props.required && <Text color="purple.800"> *</Text>}
      </LabelText>
      <InputContainer>
        <IconContainer>{icon}</IconContainer>
        <StyledInput
          icon={icon}
          {...field}
          {...props}
          type={props.type}
          error={!!meta.error && meta.touched}
        />
        {props.passwordToggle}
      </InputContainer>
      {meta.error && meta.touched && (
        <Text
          color="status.error"
          fontWeight="medium"
          fontSize="xs"
          lineHeight="xxs"
          mt="sm"
          mb={0}
          as="p"
        >
          {meta.error}
        </Text>
      )}
    </Label>
  )
}

const Label = styled.label`
  display: inline-block;
  width: 100%;
`

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  width: 12px;
  height: 12px;
`

const InputContainer = styled.div<Props>`
  position: relative;
  display: inline-block;
  width: 100%;
  &:focus-within {
    ${IconContainer} {
      color: ${({ theme }) => theme.colors.coolGray[500]};
    }
  }
`

const StyledInput = styled.input<Props & { error: boolean }>`
  padding: 16px 12px;
  gap: 10px;
  width: 100%;
  height: 52px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.status.error : theme.colors.coolGray[300]};
  border-radius: 4px;
  padding-left: ${({ icon }) => (icon ? '32px' : '16px')};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  &::placeholder {
    color: ${({ theme }) => theme.colors.coolGray[400]};
  }
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.purple[700]};
  }
`

export default TextInput
