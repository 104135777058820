import { Link } from 'react-router-dom'

import { ReactComponent as TradeArrows } from 'assets/icons/trade-arrows.svg'
import { Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import { DEPRECATEDInteractiveText } from 'components/common/DEPRECATEDInteractiveText'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  leftText: string
  rightText?: string
  link: string
  index: number
}

function HomeActivityGroupItem({ leftText, rightText, link, index }: Props) {
  return (
    <InteractiveCard
      key={index}
      borderRadius={SPACE.sp8}
      width="100%"
      height="3rem"
      backgroundColor={COLOR.coolGray50}
    >
      <Link to={link} target="_blank" rel="noopener noreferrer">
        <FlexBox alignItems="center" justifyContent="space-between">
          <DEPRECATEDInteractiveText
            fontWeight="bold"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xxs"
            style={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {leftText}
          </DEPRECATEDInteractiveText>
          <FlexBox alignItems="center" gap={SPACE.sp6} justifyContent="center">
            <TradeArrows color={COLOR.blue100} />
            <Text
              fontWeight="medium"
              fontSize={FONT_SIZE.fs12}
              color={COLOR.coolGray700}
              lineHeight="xxs"
            >
              {rightText}
            </Text>
          </FlexBox>
        </FlexBox>
      </Link>
    </InteractiveCard>
  )
}

export default HomeActivityGroupItem
