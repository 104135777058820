import styled from '@emotion/styled'
import React from 'react'

import { Asset } from 'types'

import { paveApi } from 'api'
import { Box, Text } from 'components/common'

type Props = {
  asset: Asset
  description?: string
  type?: string
}

function AssetAbout({ asset, type, description }: Props) {
  const { data: industry } = paveApi.useGetIndustryByIdQuery(
    asset.industryId || ''
  )
  const { data: sector } = paveApi.useGetSectorByIdQuery(
    industry?.sectorId || ''
  )
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding="0px"
        gap="20px"
        width="100%"
      >
        <Text fontSize="xl" fontWeight="bold" color="coolGray.600">
          About
        </Text>

        {description && <Text>{description}</Text>}

        {type && (
          <Row justifyContent="space-between" mb="6px">
            <Text fontSize="md" fontWeight="bold" color="coolGray.800">
              Asset Type
            </Text>
            <Text fontSize="md">{type}</Text>
          </Row>
        )}

        {sector?.name && (
          <Row justifyContent="space-between" mb="6px">
            <Text fontSize="md" fontWeight="bold" color="coolGray.800">
              Sector
            </Text>
            <Text fontSize="md">{sector?.name}</Text>
          </Row>
        )}

        {industry?.name && (
          <Row justifyContent="space-between" mb="6px">
            <Text fontSize="md" fontWeight="bold" color="coolGray.800">
              Industry
            </Text>
            <Text fontSize="md">{industry?.name}</Text>
          </Row>
        )}

        <Row justifyContent="space-between" mb="6px">
          <Text fontSize="md" fontWeight="bold" color="coolGray.800">
            Geography
          </Text>
          <Text fontSize="md">{asset?.country}</Text>
        </Row>
      </Box>
    </>
  )
}

const Row = styled(Box)`
  display: flex;
  width: 100%;
`

export default AssetAbout
