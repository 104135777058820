import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import QRCode from 'react-qr-code'

import { Box, Button, Text } from 'components/common'

type Props = {
  secretCode: string
  isOpen: boolean
  onClose: () => void
  nextStep: () => void
  prevStep: () => void
}

function MFASetupQRCodeModal({
  secretCode,
  isOpen,
  onClose,
  nextStep,
  prevStep,
}: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link Authenticator App</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="p" mb="12px">
              To set up Multi-Factor Authentication, you will need to link an
              authenticator app (e.g., Duo Mobile, Google Authenticator).
            </Text>
            <Text as="p" mb="12px">
              If you don’t already have an authenticator app downloaded, please
              download one from your phone’s app store.
            </Text>
            <Text as="p" mb="12px">
              Complete the following steps to link your authenticator app:
            </Text>
            <Box display="flex" gap="16px" flexWrap="wrap">
              <Box display="flex" gap="10px" width="100%">
                <Circle>1</Circle>
                <Text>Open your authenticator app on your phone </Text>
              </Box>
              <Box display="flex" gap="10px" width="100%">
                <Circle>2</Circle>
                <Text>
                  In the app, select <Text fontWeight="bold">Add Account</Text>
                </Text>
              </Box>
              <Box display="flex" gap="10px" width="100%">
                <Circle>3</Circle>
                <Text>
                  Choose <Text fontWeight="bold">Scan QR Code</Text> and scan
                  below:
                </Text>
              </Box>
            </Box>

            <QRCode
              style={{
                width: '140px',
                height: '140px',
                display: 'block',
                margin: '24px auto',
              }}
              value={`otpauth://totp/PavePro?secret=${secretCode}`}
            />
          </ModalBody>

          <ModalFooter>
            <Box display="flex" width="100%" flexDirection="column" gap="12px">
              <Button
                color="purple.800"
                style={{ width: '100%' }}
                onClick={nextStep}
              >
                Next
              </Button>
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                onClick={prevStep}
              >
                Back
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.coolGray[800]};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default MFASetupQRCodeModal
