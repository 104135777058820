import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import FlexBox from '../FlexBox/FlexBox'

type PathItem = {
  name: string
  route: string
}

type Props = {
  paths: PathItem[]
}

export default function BreadcrumbBar({ paths }: Props) {
  return (
    <FlexBox
      alignItems="center"
      backgroundColor={COLOR.white}
      borderBottom={`1px solid ${COLOR.coolGray300}`}
      padding={`${SPACE.sp20} ${SPACE.sp32}`}
    >
      {paths.length >= 2 && (
        <Link to={paths[paths.length - 2]?.route ?? '/'}>
          <Box marginRight={SPACE.sp8}>
            <ChevronLeftIcon width={SPACE.sp20} />
          </Box>
        </Link>
      )}
      <Breadcrumb
        separator="/"
        fontSize={FONT_SIZE.fs16}
        color={COLOR.coolGray600}
      >
        {paths.map((path, i) => {
          const isCurrentPage = paths.length - 1 === i

          return isCurrentPage ? (
            <BreadcrumbItem
              key={`breadcrumb-${path.name}`}
              color={COLOR.purple800}
              fontWeight="bold"
              isCurrentPage
            >
              <BreadcrumbLink href="#">{path.name}</BreadcrumbLink>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem
              key={`breadcrumb-${path.name}`}
              isCurrentPage={isCurrentPage}
            >
              <BreadcrumbLink
                as={Link}
                color={COLOR.coolGray600}
                to={path.route}
              >
                {path.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
    </FlexBox>
  )
}
