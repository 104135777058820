import { isNaN } from 'lodash'

import { AssetClass, FactorTilt } from 'enums'
import { PerformancePriority } from 'enums/PerformancePriorityEnum'
import {
  Asset,
  InvestmentPreference,
  InvestmentTemplate,
  PartialInvestmentPreference,
  Portfolio,
  PortfolioAsset,
} from 'types'
import { InvestmentTemplateFormState } from 'types/InvestmentTemplateTypes'
import { FactorTiltObject } from 'types/generalTypes'
import {
  PortfolioFormPayload,
  PortfolioFormState,
  PortfolioFormStateFilled,
} from 'types/portfolioTypes'

import {
  BALANCED,
  DEFAULT,
  DEFAULT_FRACTIONAL_SHARES,
  DEFAULT_TARGET_ASSET_COUNT,
  DEFAULT_THIRD_PARTY_MANAGED_INVESTMENT_PREFERENCE,
} from 'constants/portfolio.constants'

import { isNullish } from './generalUtils'

/**
 * @function formatToPortfolioForm
 * Maps an incoming Portfolio to match the shape of PortfolioFormState
 *
 * Current usecase is for the "Edit Portfolio Flow".
 * Would be nice to use for all portfolio related forms, and possibly
 * use a Portfolio type with investmentPreference as a mandatory property
 * @returns PortfolioFormState
 */
export function formatToPortfolioForm(
  portfolio: Portfolio,
  isNewPortfolio: boolean
): PortfolioFormState {
  const { investmentPreference } = portfolio
  const factorTilts = investmentPreference?.factorTilts
  const isPlaidAccount = portfolio.brokerageAccounts.some(
    (account) =>
      account?.plaidAccountInfo?.activeConnectedAccountIds !== undefined
  )

  const portfolioForm: PortfolioFormState = {
    activeConnectedAccountIds: isPlaidAccount
      ? getActiveConnectedAccountIdsFromPortfolio(portfolio)
      : null,
    assets: portfolio.assets,
    availableCash: portfolio.availableCash ?? null,
    benchmarkId: portfolio.benchmarkId ?? null,
    clientAccountId: portfolio.clientAccountId ?? null,
    displayName: portfolio.displayName,
    isThirdPartyManaged: portfolio.isThirdPartyManaged ?? false,
    userApprovedAutomatedTradesOnce:
      portfolio.userApprovedAutomatedTradesOnce ?? false,

    /** Investment Preference Fields */
    assetClass: investmentPreference?.assetClass || AssetClass.EQUITY,
    automation: investmentPreference?.automation ?? false,
    enableTaxOptimization: investmentPreference?.enableTaxOptimization ?? false,
    excludedAssetClasses: investmentPreference?.excludedAssetClasses ?? [],
    excludedAssets: investmentPreference?.excludedAssets ?? [],
    excludedIndustries: investmentPreference?.excludedIndustries ?? [],
    excludedSectors: investmentPreference?.excludedSectors ?? [],
    fractionalShares:
      investmentPreference?.fractionalShares ?? DEFAULT_FRACTIONAL_SHARES,
    investmentTemplateId: portfolio.investmentPreferencesTemplateId ?? null,
    buyListId: portfolio.buyListId ?? null,
    multiAssetClassStrategy: investmentPreference?.multiAssetClassStrategy,
    performancePriority: investmentPreference?.performancePriority,
    targetAssetCount: investmentPreference?.targetAssetCount ?? null,
    targetCashWeightPercent: investmentPreference?.targetCashWeight
      ? investmentPreference.targetCashWeight * 100
      : null,
    // Factor Tilts
    factorTilts: factorTilts,
    globalMacroSensitivity: getGlobalMacroSensitivity(factorTilts ?? []),
    interestRateSensitivity: getInterestRateSensitivity(factorTilts ?? []),
    investmentStyle: getInvestmentStyle(factorTilts ?? []),
    usDollarSensitivity: getUsDollarSensitivity(factorTilts ?? []),
    volatilityPreference: getVolatilityPreference(factorTilts ?? []),
  }

  if (isNewPortfolio) {
    portfolioForm.availableCash = null
    portfolioForm.targetAssetCount = null
  }

  return portfolioForm
}

export function getPortfolioWithdrawalPayload(
  portfolio: Portfolio,
  withdrawalAmount: number
): PortfolioFormPayload {
  const formState = formatToPortfolioForm(
    portfolio,
    false
  ) as PortfolioFormStateFilled
  const payload = formatToPortfolioFormPayload(formState)

  // We pass in the createdAt from the frontend because otherwise:
  // 1) We'd have special logic on the backend to add the createdAt, which is difficult since we're reusing an endpoint
  // 2) There's no substantial risk with the createdAt date being spoofed as this is only for metrics
  const withdrawalCashRequest = {
    cashAmount: withdrawalAmount,
    createdAt: new Date().toISOString(),
  }
  payload.investmentPreference.withdrawalCashRequest = withdrawalCashRequest
  return payload
}

/**
 * @function formatToPortfolioFormPayload
 * Formats the reducer form state into the payload shape
 *
 * A possible improvement is add new reducer actions that will
 * update the form state to default values when switching to a
 * multi asset class portfolio or switching to a third party
 * managed portfolio.
 */
export function formatToPortfolioFormPayload(
  formState: PortfolioFormStateFilled
): PortfolioFormPayload {
  const isMultiAssetClassPortfolio =
    formState.assetClass === AssetClass.MULTI_ASSET_CLASS &&
    formState.multiAssetClassStrategy

  // target asset count will have a default value of 55 if not set by the user
  const targetAssetCount = isNaN(formState.targetAssetCount)
    ? DEFAULT_TARGET_ASSET_COUNT
    : formState.targetAssetCount

  let investmentPreference: InvestmentPreference = {
    assetClass: formState.assetClass,
    automation: formState.automation,
    enableTaxOptimization: formState.enableTaxOptimization,
    excludedAssetClasses: formState.excludedAssetClasses,
    excludedAssets: formState.excludedAssets,
    excludedIndustries: formState.excludedIndustries,
    excludedSectors: formState.excludedSectors,
    factorTilts: formState.factorTilts,
    fractionalShares: formState.fractionalShares,
    performancePriority: formState.performancePriority,
    targetAssetCount,
    targetCashWeight: formState.targetCashWeightPercent
      ? formState.targetCashWeightPercent / 100
      : null,
  }

  if (formState.isThirdPartyManaged) {
    investmentPreference = {
      ...DEFAULT_THIRD_PARTY_MANAGED_INVESTMENT_PREFERENCE,
      automation: formState.automation,
      fractionalShares: formState.fractionalShares,
    }
  } else if (isMultiAssetClassPortfolio) {
    investmentPreference.multiAssetClassStrategy =
      formState.multiAssetClassStrategy
    // Multi asset class default values
    investmentPreference.excludedIndustries = []
    investmentPreference.excludedSectors = []
  }

  const formattedAssets: PortfolioAsset[] = formState.assets.map(
    (asset: Asset): PortfolioAsset => ({
      symbol: asset.symbol,
      shares: asset.shares,
      tradablePercentage: asset.tradablePercentage,
      averageCost: asset?.averageCost,
    })
  )

  return {
    assets: formattedAssets,
    availableCash: formState.availableCash,
    benchmarkId: formState.benchmarkId,
    buyListId: formState.buyListId,
    displayName: formState.displayName.trim(),
    investmentPreference,
    investmentPreferencesTemplateId: formState.investmentTemplateId ?? null,
    isThirdPartyManaged: formState.isThirdPartyManaged,
    userApprovedAutomatedTradesOnce: false,
  }
}

/**
 * @function updatePortfolioFormGivenInvestmentTemplate
 * Maps an existing Portfolio Form to match a new Investment Template's
 * preferences
 *
 * After merging, it also evaluates impacted tax optimization fields
 * @returns PortfolioFormState
 */
export function updatePortfolioFormGivenInvestmentTemplate(
  portfolioForm: PortfolioFormState,
  investmentTemplate: InvestmentTemplate | null
): PortfolioFormState {
  if (!investmentTemplate) return portfolioForm
  const { investmentPreferences } = investmentTemplate
  const factorTilts =
    investmentPreferences?.factorTilts ?? (portfolioForm.factorTilts || [])

  const templateExcludedAssets = investmentPreferences?.excludedAssets
  const templateExcludedIndustries = investmentPreferences?.excludedIndustries
  const templateExcludedSectors = investmentPreferences?.excludedSectors

  const newFormState: PortfolioFormState = {
    ...portfolioForm,
    automation: investmentPreferences?.automation ?? portfolioForm.automation,
    benchmarkId: investmentTemplate.benchmarkId ?? portfolioForm.benchmarkId,
    buyListId: investmentTemplate.buyListId ?? portfolioForm.buyListId,

    /** Investment Preference Fields */
    assetClass: investmentPreferences?.assetClass ?? portfolioForm.assetClass,
    enableTaxOptimization:
      investmentPreferences?.enableTaxOptimization ??
      portfolioForm.enableTaxOptimization,
    excludedAssets:
      templateExcludedAssets && templateExcludedAssets.length > 0
        ? templateExcludedAssets
        : portfolioForm.excludedAssets,
    excludedIndustries:
      templateExcludedIndustries && templateExcludedIndustries.length > 0
        ? templateExcludedIndustries
        : portfolioForm.excludedIndustries,
    excludedSectors:
      templateExcludedSectors && templateExcludedSectors.length > 0
        ? templateExcludedSectors
        : portfolioForm.excludedSectors,
    fractionalShares:
      investmentPreferences?.fractionalShares ?? portfolioForm.fractionalShares,
    multiAssetClassStrategy:
      investmentPreferences?.multiAssetClassStrategy ??
      portfolioForm.multiAssetClassStrategy,
    performancePriority:
      investmentPreferences?.performancePriority ??
      portfolioForm.performancePriority,
    targetAssetCount:
      investmentPreferences?.targetAssetCount ?? portfolioForm.targetAssetCount,
    // Factor Tilts
    factorTilts: factorTilts,
    globalMacroSensitivity: getGlobalMacroSensitivity(factorTilts),
    interestRateSensitivity: getInterestRateSensitivity(factorTilts),
    investmentStyle: getInvestmentStyle(factorTilts),
    usDollarSensitivity: getUsDollarSensitivity(factorTilts),
    volatilityPreference: getVolatilityPreference(factorTilts),
  }

  // Evaluate fields depending on template and form state's tax optimization
  const defaultPerformancePriority = getDefaultPerformancePriority(newFormState)
  const otherDefaultPriority =
    defaultPerformancePriority === PerformancePriority.SLIGHTLY_O
      ? PerformancePriority.BALANCED
      : PerformancePriority.SLIGHTLY_O

  const isPerformancePriorityTemplateField =
    investmentTemplate.investmentPreferences.performancePriority
  if (isPerformancePriorityTemplateField) {
    if (newFormState.performancePriority !== defaultPerformancePriority) {
      // Factor Tilts
      newFormState.factorTilts = []
      newFormState.globalMacroSensitivity = getGlobalMacroSensitivity([])
      newFormState.interestRateSensitivity = getInterestRateSensitivity([])
      newFormState.investmentStyle = getInvestmentStyle([])
      newFormState.usDollarSensitivity = getUsDollarSensitivity([])
      newFormState.volatilityPreference = getVolatilityPreference([])
    }
  } else {
    const hasFactorTilts = Boolean(newFormState.factorTilts?.length)

    if (
      hasFactorTilts ||
      newFormState.performancePriority === otherDefaultPriority
    ) {
      newFormState.performancePriority = defaultPerformancePriority
    }
  }

  return newFormState
}

/**
 * Checks if a field is part of a template
 */
export function isTemplateField(
  template: InvestmentTemplate | null,
  field: keyof InvestmentTemplate | keyof PartialInvestmentPreference
) {
  // special cases
  if (!template) return false
  if (field === 'benchmarkId') {
    return template.hasOwnProperty(field)
  }
  if (field === 'buyListId') {
    return template.hasOwnProperty(field) && !isNullish(template.buyListId)
  }
  if (field === 'factorTilts') {
    const { factorTilts } = template.investmentPreferences
    return factorTilts ? factorTilts.length > 0 : false
  }
  // default to check inside investmentPreferences
  const preferenceField = field as keyof PartialInvestmentPreference
  return (
    template.investmentPreferences.hasOwnProperty(preferenceField) &&
    (template.investmentPreferences[preferenceField] !== null ||
      template.investmentPreferences[preferenceField] !== undefined)
  )
}

/**
 * @function setIbPortfolioFormPayloadFields
 * Ensures the payload has fields set specific to IB portfolios
 */
export function setIbPortfolioFormPayloadFields(
  formPayload: PortfolioFormPayload
): PortfolioFormPayload {
  formPayload.isThirdPartyManaged = false
  return formPayload
}
/**
 * @function setManualPortfolioFormPayloadFields
 * Ensures the payload has fields set specific to manual portfolios
 */
export function setManualPortfolioFormPayloadFields(
  formPayload: PortfolioFormPayload
): PortfolioFormPayload {
  formPayload.investmentPreference.automation = false
  formPayload.investmentPreference.enableTaxOptimization = false
  formPayload.isThirdPartyManaged = false
  return formPayload
}

/**
 * @function setPlaidPortfolioFormPayloadFields
 * Ensures the payload has fields set specific to plaid portfolios
 */
export function setPlaidPortfolioFormPayloadFields(
  formPayload: PortfolioFormPayload
): PortfolioFormPayload {
  formPayload.investmentPreference.automation = false
  return formPayload
}

/**
 * @function getDefaultPerformancePriority
 * Returns the preferred default performance priority depending
 * on the form's tax optimization status
 */
export function getDefaultPerformancePriority(
  formState: PortfolioFormState | InvestmentTemplateFormState
): PerformancePriority {
  return formState.enableTaxOptimization === true
    ? PerformancePriority.SLIGHTLY_O
    : PerformancePriority.BALANCED
}

/**
 * PRIVATE
 */

function getActiveConnectedAccountIdsFromPortfolio(
  portfolio: Portfolio
): string[] {
  const { brokerageAccounts } = portfolio
  let activeAccountIds: string[] = []
  for (const account of brokerageAccounts) {
    const plaidAccountIds = account?.plaidAccountInfo?.activeConnectedAccountIds
    if (plaidAccountIds) {
      activeAccountIds = activeAccountIds.concat(plaidAccountIds)
    }
  }
  return activeAccountIds
}

function getFactorTiltType(
  factorTiltName: FactorTilt,
  defaultValue: string,
  factorTilts: FactorTiltObject[]
): string {
  const factorTilt = factorTilts?.find((tilt: FactorTiltObject) => {
    const tiltName = Object.keys(tilt)[0]
    return tiltName === factorTiltName
  })

  return factorTilt ? Object.values(factorTilt)[0] : defaultValue
}

function getGlobalMacroSensitivity(
  inputFactorTilts: FactorTiltObject[]
): string {
  return getFactorTiltType(
    FactorTilt.GLOBAL_MACRO_SENSITIVITY,
    DEFAULT,
    inputFactorTilts
  )
}

function getInterestRateSensitivity(
  inputFactorTilts: FactorTiltObject[]
): string {
  return getFactorTiltType(
    FactorTilt.INTEREST_RATE_SENSITIVITY,
    DEFAULT,
    inputFactorTilts
  )
}

function getInvestmentStyle(inputFactorTilts: FactorTiltObject[]): string {
  return getFactorTiltType(
    FactorTilt.INVESTMENT_STYLE,
    BALANCED,
    inputFactorTilts
  )
}

function getUsDollarSensitivity(inputFactorTilts: FactorTiltObject[]): string {
  return getFactorTiltType(
    FactorTilt.US_DOLLAR_SENSITIVITY,
    DEFAULT,
    inputFactorTilts
  )
}

function getVolatilityPreference(inputFactorTilts: FactorTiltObject[]): string {
  return getFactorTiltType(
    FactorTilt.VOLATILITY_PREFERENCE,
    BALANCED,
    inputFactorTilts
  )
}
