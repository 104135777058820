import { IconButton } from '@chakra-ui/button'
import styled from '@emotion/styled'
import { TrashIcon } from '@heroicons/react/outline'
import { NumericFormat } from 'react-number-format'

import { Asset } from 'types'

import { Box, Switch } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

type Props = {
  asset: Asset
  isEditingAsset: boolean
  handleChange: (e: any) => void
  handleDelete: (e: any) => void
  readOnly?: boolean
  showTradableSwitches?: boolean
}

const AssetRow = ({
  asset,
  isEditingAsset,
  handleChange,
  handleDelete,
  readOnly,
  showTradableSwitches = false,
}: Props) => {
  return (
    <tr style={{ width: '100%' }}>
      <td>
        <Input
          style={{ color: readOnly ? COLOR.coolGray400 : COLOR.coolGray700 }}
          type="text"
          name="symbol"
          value={asset.symbol}
          onChange={(e) => handleChange(e.target)}
          disabled={readOnly}
        />
      </td>
      <td>
        <NumericFormat<any>
          style={{ color: readOnly ? COLOR.coolGray400 : COLOR.coolGray700 }}
          allowEditing={true}
          allowLeadingZeros={false}
          allowNegative={false}
          customInput={Input}
          decimalScale={4}
          disabled={readOnly}
          displayType="input"
          maxLength={12}
          min={1}
          name="shares"
          onChange={(e) => {
            if (parseFloat(e.target.value) > 0) {
              handleChange(e.target)
            }
          }}
          placeholder="Shares"
          value={asset.shares}
        />
      </td>
      {showTradableSwitches || (
        <>
          <td>
            <label>
              <Switch
                checked={asset.tradablePercentage === 1}
                onChange={() =>
                  handleChange({
                    name: 'tradablePercentage',
                    value: asset.tradablePercentage === 1 ? 0 : 1,
                  })
                }
              />
            </label>
          </td>
        </>
      )}
      <td>
        {readOnly || (
          <Box display={!isEditingAsset ? 'flex' : 'none'}>
            <IconButton
              onClick={() => handleDelete(asset)}
              aria-label="Cancel edit asset"
              icon={<TrashIcon />}
              padding={2}
              size="sm"
            />
          </Box>
        )}
      </td>
    </tr>
  )
}

const Input = styled.input`
  border: ${({ theme }) => `1px solid ${theme.colors.coolGray[300]}`};
  border-radius: 0.25rem;
  height: 2rem;
  padding: ${SPACE.sp8} ${SPACE.sp12};
  width: 98%;
`

export default AssetRow
