import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import { isFinite } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Asset } from 'types'

import { Box, Tag, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'
import { roundToHundredths } from 'utils/numberUtils'

type Props = {
  asset: Asset
  portfolioId: string
}

function AssetCard({ asset, portfolioId }: Props) {
  const {
    name,
    symbol,
    price,
    priceDetails,
    shares,
    totalValue,
    percentageOfPortfolio,
    isSupported,
    averageCost,
  } = asset

  const navigate = useNavigate()
  const onAssetClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    navigate(`/asset/${symbol}?portfolioId=${portfolioId}`)
  }

  // TODO: Update Card with a new Card Button
  // Final Check on child heirarchy
  const gain = averageCost ? (price - averageCost) * shares : undefined

  return (
    <a
      onClick={onAssetClick}
      href={`/asset/${symbol}?portfolioId=${portfolioId}`}
    >
      <InteractiveCard
        width="12.5rem"
        minHeight="12.5rem"
        style={{ cursor: 'pointer' }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <FlexBox gap={SPACE.sp4} flexDirection="column">
          <FlexBox justifyContent="space-between">
            <Tag>{symbol}</Tag>
            {!isSupported && (
              <Tag variant="red" style={{ marginInline: SPACE.sp6 }}>
                <Text fontSize={FONT_SIZE.fs12}>Unsupported</Text>
              </Tag>
            )}
          </FlexBox>
          <FlexBox overflow="hidden">
            <Text
              className="interactive-text"
              fontSize={FONT_SIZE.fs14}
              fontWeight="sm"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              title={name}
            >
              {name}
            </Text>
          </FlexBox>
        </FlexBox>
        <Box>
          <Box marginTop={SPACE.sp8}>
            <Text fontSize={FONT_SIZE.fs20} fontWeight="bold">
              {isFinite(totalValue)
                ? formatAsCurrency(totalValue)
                : 'Pricing unavailable'}
            </Text>
          </Box>
          <Text
            color={COLOR.coolGray500}
            fontSize={FONT_SIZE.fs12}
            fontWeight="medium"
          >
            {roundToHundredths(shares)} shares
            {isFinite(percentageOfPortfolio) &&
              ` \u00B7 ${percentageOfPortfolio}%`}
          </Text>
          <FlexBox alignItems="center" marginTop={SPACE.sp12}>
            <Text
              fontWeight="bold"
              color={COLOR.coolGray700}
              marginRight={SPACE.sp16}
            >
              {isFinite(price) ? formatAsCurrency(price) : '$--'}
            </Text>
            {isFinite(priceDetails?.dayGain) && (
              <Tag
                size="sm"
                icon={
                  priceDetails.dayGain >= 0 ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )
                }
                iconPosition="left"
                variant={priceDetails.dayGain >= 0 ? 'green' : 'red'}
              >
                <Text fontSize={FONT_SIZE.fs10} fontWeight="bold">
                  {priceDetails.dayGain}%
                </Text>
              </Tag>
            )}
          </FlexBox>
          <Box>
            {gain ? (
              <Text
                fontWeight="medium"
                fontSize={FONT_SIZE.fs12}
                color={COLOR.coolGray500}
              >
                {`${gain > 0 ? 'GAIN' : 'LOSS'}: `}
                <Text
                  fontWeight="medium"
                  fontSize={FONT_SIZE.fs12}
                  color={gain >= 0 ? COLOR.emerald600 : COLOR.red600}
                >
                  {formatAsCurrency(gain)}
                </Text>
              </Text>
            ) : (
              <Text
                fontWeight="medium"
                fontSize={FONT_SIZE.fs12}
                color={COLOR.coolGray500}
              >
                GAIN: -
              </Text>
            )}
          </Box>
        </Box>
      </InteractiveCard>
    </a>
  )
}

export default AssetCard
