import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  isAutomationOn?: boolean
  onClick: () => void
  onClose: () => void
  showModal: boolean
}

export default function ConfirmTriggerSuggestedTradesModal({
  isAutomationOn,
  onClick,
  onClose,
  showModal,
}: Props) {
  return (
    <Modal isOpen={showModal} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              Save Investment Preferences?
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              Saving this portfolio's investment preferences will trigger the
              generation of suggested trades.
            </Text>
          </Box>
          <Box marginTop={SPACE.sp16}>
            {isAutomationOn ? (
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                Since automation is on, these trades will be placed on the
                following trading day. If you are unhappy with the suggested
                trades, you can edit the investment preferences until you are
                satisfied or simply turn off automation to keep Pave from
                automatically placing your trades.
              </Text>
            ) : (
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                Since automation is off, you will be required to approve the
                suggested trades before they are placed by Pave.
              </Text>
            )}
          </Box>
        </ModalBody>

        <ModalFooter marginBottom={SPACE.sp8}>
          <Box width="100%">
            <Box marginBottom={SPACE.sp12}>
              <Button
                onClick={onClick}
                size="md"
                variant="primary"
                width="100%"
              >
                Confirm
              </Button>
            </Box>

            <Button
              onClick={onClose}
              size="md"
              variant="secondary"
              width="100%"
            >
              Back
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
