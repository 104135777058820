import { ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, LinkText, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SUPPORT_EMAIL, SUPPORT_MAILTO_LINK } from 'constants/general.constants'
import { setStep } from 'store/createPortfolioSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function RIASubAccountFlow() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const goBack = () => {
    dispatch(setStep(1))
  }

  return (
    <Box height="100%">
      <ModalHeader>Create New Portfolio</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FlexBox flexDirection="column" gap={SPACE.sp12}>
          <Text color={COLOR.coolGray600} fontWeight="700">
            Link RIA Sub Account
          </Text>
          <FlexBox flexDirection="column" gap={SPACE.sp16}>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14} as="p">
              To link a sub account that is in a master account already linked
              to Pave Pro, please:
            </Text>

            <Box>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14} as="p">
                1. Head to the Connected Accounts page by clicking on your name
                in the top right corner
              </Text>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14} as="p">
                2. Find the sub account you want to link and select it by
                clicking the check box
              </Text>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14} as="p">
                3. Press assign to group and then select the desired group
              </Text>
            </Box>
          </FlexBox>

          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs14}
            as="p"
            mt="16px"
            mb="16px"
          >
            If you would like to link another master account to Pave Pro, please
            reach out to customer support team by emailing{' '}
            <LinkText href={SUPPORT_MAILTO_LINK} label={SUPPORT_EMAIL} bold />
          </Text>

          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            gap={SPACE.sp12}
            mt={SPACE.sp12}
            mb={SPACE.sp12}
          >
            <Button
              color={COLOR.purple800}
              style={{ width: '100%' }}
              onClick={() => navigate('/connected-accounts')}
            >
              Connected Accounts
            </Button>
            <Button variant="secondary" onClick={goBack} active={false}>
              Back
            </Button>
          </Box>
        </FlexBox>
      </ModalBody>
    </Box>
  )
}

export default RIASubAccountFlow
