import React from 'react'
import { useNavigate } from 'react-router-dom'

import PaveLogo from 'assets/images/pave-logo.svg'
import { Box } from 'components/common'
import { COLOR } from 'styles/constants/color'

type Props = {
  menu?: React.ReactNode
}

export default function TopNav({ menu }: Props) {
  const navigate = useNavigate()
  const goHome = () => navigate('/')

  return (
    <Box
      alignItems="center"
      backgroundColor={COLOR.white}
      borderBottom={`1px solid ${COLOR.coolGray300}`}
      display="flex"
      height="5.5rem"
      justifyContent="space-between"
      padding="1rem 3.5rem"
      width="100%"
    >
      <img
        src={PaveLogo}
        alt="Pave Logo"
        width={89}
        height={48}
        onClick={goHome}
        style={{ cursor: 'pointer' }}
      />
      <div>{menu}</div>
    </Box>
  )
}
