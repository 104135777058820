import { analytics } from 'analytics'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import uuid4 from 'uuid4'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Button, Separator, Text } from 'components/common'
import { DrawerHeader, SlideOutMenu } from 'components/partials'
import { AccountClient } from 'components/partials/Account/AccountPreferences/AccountClient'
import {
  AccountDetailsFormFields,
  AccountPrimaryContactDetails,
  accountDetailsInitialValues,
} from 'components/partials/Forms/AccountDetailsFormFields'
import { GROUP_EDIT_CLIENTS_SUBMITTED } from 'constants/track.constants'
import { closeEditAccountClients } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

type ClientFields = AccountPrimaryContactDetails & {
  id: string
}

type Props = {
  account: ClientAccountDto | undefined
  onEditClientAccountSettings: (account: Partial<ClientAccountDto>) => void
}

const initialValues: ClientFields = {
  ...accountDetailsInitialValues,
  id: '',
}

function AccountEditClientsModal({
  account,
  onEditClientAccountSettings,
}: Props) {
  const [accountClient, setAccountClient] =
    useState<ClientFields>(initialValues)
  const [clientsToAdd, setClientsToAdd] = useState<ClientAccountDto['clients']>(
    account?.clients || []
  )
  const [addClientForm, setAddClientForm] = useState<
    'new' | 'editing' | 'none'
  >('none')

  const { showEditAccountClients } = useAppSelector((state) => state.modals)
  const dispatch = useAppDispatch()

  const handleSaveChanges = (): void => {
    onEditClientAccountSettings({
      ...(account as ClientAccountDto),
      clients: clientsToAdd,
    })
    dispatch(closeEditAccountClients())
    analytics.track(GROUP_EDIT_CLIENTS_SUBMITTED)
  }

  const handleAddClient = () => {
    if (addClientForm === 'editing' || addClientForm === 'none') {
      setAddClientForm('new')
      setAccountClient(initialValues)
    }
  }

  const handleEditClient = (clientId: string) => {
    const client = clientsToAdd.find((c) => c.id === clientId)
    if (client) {
      setAddClientForm('editing')
      setAccountClient(client)
    }
  }

  const handleRemoveClient = (id: string) => {
    setClientsToAdd(clientsToAdd.filter((client) => client.id !== id))
  }

  const handleSubmit = (values: ClientFields, actions: any) => {
    if (addClientForm === 'editing') {
      editClient(values)
    } else {
      addClient(values)
    }
    setAddClientForm('none')
    actions.setSubmitting(false)
    setAccountClient(initialValues)
  }

  const addClient = (client: ClientFields) => {
    const clients = [...clientsToAdd]
    clients.push({
      ...client,
      id: uuid4(),
      avatarUrl: '',
    })
    setClientsToAdd(clients)
  }

  const editClient = (client: ClientFields) => {
    const clients = [...clientsToAdd]
    const index = clients.findIndex((c) => c.id === accountClient.id)
    clients[index] = {
      ...clients[index],
      ...client,
    }
    setClientsToAdd(clients)
  }

  return (
    <>
      <SlideOutMenu
        isOpen={showEditAccountClients}
        onClose={() => dispatch(closeEditAccountClients())}
        size="lg"
        formId="edit-client-account-primary-contact-form"
        useDefaultHeader={false}
      >
        <DrawerHeader
          title="Edit Clients"
          buttonText="Edit Clients"
          customButtons={
            <Button onClick={handleSaveChanges} variant="primary" size="md">
              Save Changes
            </Button>
          }
        />
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          paddingX="24px"
          paddingY="8px"
          overflow="auto"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding="0px"
            gap="12px"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              padding="0px"
              width="100%"
            >
              <Text
                fontWeight="bold"
                fontSize="lg"
                lineHeight="lg"
                color="coolGray.600"
              >
                Clients
              </Text>
              <Button
                variant="tertiary"
                size="xs"
                active={addClientForm === 'editing' || addClientForm === 'none'}
                onClick={handleAddClient}
              >
                Add Client
              </Button>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              padding="0px"
              gap="20px"
              width="100%"
            >
              {clientsToAdd.map((c) => {
                return (
                  <AccountClient
                    key={c.id}
                    {...c}
                    removable={true}
                    onRemove={() => handleRemoveClient(c.id)}
                    onEdit={handleEditClient}
                  ></AccountClient>
                )
              })}
            </Box>
            <Separator></Separator>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              padding="0px"
              gap="8px"
              justifyContent="space-between"
            ></Box>
            {(addClientForm === 'new' || addClientForm === 'editing') && (
              <Box width="100%">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={accountClient}
                  enableReinitialize
                  paddingBottom="8px"
                >
                  <Form id="edit-client-account-primary-contact-form">
                    <AccountDetailsFormFields />
                    <Button
                      type="submit"
                      size="sm"
                      variant="primary"
                      style={{ width: '100%', marginTop: '16px' }}
                    >
                      {addClientForm === 'editing'
                        ? 'Save Changes'
                        : 'Add Client'}
                    </Button>
                  </Form>
                </Formik>
              </Box>
            )}
          </Box>
        </Box>
        {/* </form> */}
      </SlideOutMenu>
    </>
  )
}
export default AccountEditClientsModal
