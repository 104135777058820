import { RefreshIcon, UserGroupIcon } from '@heroicons/react/solid'
import { analytics } from 'analytics'
import { formatRelative } from 'date-fns'

import { Portfolio, PortfolioLinkType } from 'types'

import { paveApi } from 'api'
import { Box, Button, Tag, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { PORTFOLIO_REFRESH_CLICKED } from 'constants/track.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import {
  getIsAccountDisconnected,
  getPortfolioLinkType,
  shouldAllowRefresh,
} from 'utils/portfolioUtil'

type Props = {
  portfolio?: Portfolio
}

function PortfolioHeader({ portfolio }: Props) {
  const { useRefreshPortfolioMutation } = paveApi
  const [refreshPortfolio] = useRefreshPortfolioMutation()
  const isAccountDisconnected = getIsAccountDisconnected(
    portfolio?.accountStatus
  )

  const linkType = getPortfolioLinkType(portfolio)
  const { isThirdPartyManaged } = portfolio || {}

  let showRefreshButton = false
  if (portfolio) {
    showRefreshButton = shouldAllowRefresh(portfolio)
  }

  const handleRefresh = () => {
    analytics.track(PORTFOLIO_REFRESH_CLICKED)
    if (portfolio) {
      refreshPortfolio({ id: portfolio.id })
    }
  }
  let updatedAt = ''
  if (portfolio?.updatedAt) {
    updatedAt = formatRelative(new Date(portfolio.updatedAt), new Date())
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Box>
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs16}
            fontWeight="bold"
          >
            Portfolio Overview
          </Text>
          <FlexBox justifyContent="start" alignItems="center">
            <Text
              color={COLOR.coolGray800}
              fontWeight="700"
              fontSize="3xl"
              lineHeight="3xl"
              as="h2"
            >
              {portfolio?.displayName}
            </Text>
            {isThirdPartyManaged && (
              <Tag icon={<UserGroupIcon />} iconPosition="left">
                Managed by 3rd party
              </Tag>
            )}
          </FlexBox>
        </Box>
      </Box>
      <FlexBox alignItems="center">
        {isAccountDisconnected ? (
          <Tag variant="red">
            <Text fontSize={FONT_SIZE.fs8} fontWeight={500}>
              &#9679;
            </Text>{' '}
            Portfolio Disconnected
          </Tag>
        ) : (
          linkType !== PortfolioLinkType.None &&
          !isThirdPartyManaged && (
            <>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs14}
                fontWeight="bold"
                marginRight={SPACE.sp4}
              >
                Last update:
              </Text>
              {/* TODO: Update time with to become a badge */}
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs14}
                fontWeight="medium"
                marginRight={SPACE.sp16}
              >
                {updatedAt}
              </Text>
            </>
          )
        )}
        {showRefreshButton && (
          <Box>
            <Button variant="secondary" onClick={handleRefresh}>
              <FlexBox
                alignItems="center"
                gap={SPACE.sp6}
                justifyContent="center"
              >
                <RefreshIcon width={20} height={20} />
                <Text fontSize="sm" fontWeight="medium">
                  Refresh
                </Text>
              </FlexBox>
            </Button>
          </Box>
        )}
      </FlexBox>
    </Box>
  )
}

export default PortfolioHeader
