import styled from '@emotion/styled/macro'
import React, { InputHTMLAttributes } from 'react'

import { LabelText, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Variant = 'base' | 'template' | 'green' | 'invalid'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url'
  label?: string
  status?: 'error' | 'success'
  icon?: React.ReactNode
  errorMessage?: string
  showRequiredStar?: boolean
  passwordToggle?: React.ReactNode
  variant?: Variant
}

function TextInput({
  label,
  icon,
  errorMessage,
  showRequiredStar = false,
  variant = 'base',
  ...props
}: Props) {
  let TextInputVariant

  switch (variant) {
    case 'base':
      TextInputVariant = BaseTextInput
      break
    case 'template':
      TextInputVariant = TemplateTextInput
      break
    case 'invalid':
      TextInputVariant = InvalidTextInput
      break
    case 'green':
      TextInputVariant = GreenTextInput
      break
    default:
      TextInputVariant = BaseTextInput
  }

  return (
    <Label>
      <LabelText>
        {label} {showRequiredStar && <Text color={COLOR.purple700}>*</Text>}
      </LabelText>
      <InputContainer>
        <IconContainer>{icon}</IconContainer>
        <TextInputVariant icon={icon} {...props} type={props.type} />
        {props.passwordToggle}
      </InputContainer>
      {errorMessage && (
        <Text
          color="status.error"
          fontWeight="medium"
          fontSize="xs"
          lineHeight="xxs"
          mt="sm"
          mb={0}
          as="p"
        >
          {errorMessage}
        </Text>
      )}
    </Label>
  )
}

const Label = styled.label`
  display: inline-block;
  width: 100%;
`

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  width: 12px;
  height: 12px;
`

const InputContainer = styled.div<Props>`
  position: relative;
  display: inline-block;
  width: 100%;
  &:focus-within {
    ${IconContainer} {
      color: ${COLOR.coolGray500};
    }
  }
  background-color: ;
`

const BaseTextInput = styled.input<Props>`
  padding: 16px 12px;
  gap: 10px;
  width: 100%;
  height: 52px;
  background: ${COLOR.white};
  border: 1px solid
    ${({ status, theme }) =>
      status === 'error' ? theme.colors.status.error : COLOR.coolGray300};
  border-radius: 4px;
  padding-left: ${({ icon }) => (icon ? '32px' : '16px')};
  font-size: ${FONT_SIZE.fs14};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  &::placeholder {
    color: ${COLOR.coolGray400};
  }
  :focus {
    outline: none;
    border: 1px solid ${COLOR.purple700};
  }
  :disabled {
    background: ${COLOR.coolGray50};
    color: ${COLOR.coolGray400};
    cursor: not-allowed;
  }
`

const TemplateTextInput = styled(BaseTextInput)`
  background: ${COLOR.purple50};
  border: 1px solid ${COLOR.purple50};
  color: ${COLOR.purple800};
  :disabled {
    background: ${COLOR.purple50};
    border: 1px solid ${COLOR.purple50};
    color: ${COLOR.purple800};
  }
`

const GreenTextInput = styled(BaseTextInput)`
  border: 1px solid ${COLOR.green700};
`

const InvalidTextInput = styled(BaseTextInput)`
  :disabled {
    background: ${COLOR.coolGray100};
    border: 1px solid ${COLOR.red600};
  }
`

export default TextInput
