import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'

import { ChartTimePeriod } from 'enums'
import { ChartTime, LineChartData } from 'types/chartTypes'

import chartUtil from 'utils/chartUtil'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip
)

type Props = {
  data: LineChartData
  selectedTime: string
}

export default function PerformanceChart({ data, selectedTime }: Props) {
  let timeUnit: ChartTime

  switch (selectedTime) {
    case ChartTimePeriod.ONE_MONTH:
      timeUnit = 'day'
      break
    case ChartTimePeriod.FIVE_YEAR:
      timeUnit = 'year'
      break
    case ChartTimePeriod.SINCE_INCEPTION:
      const lineData = data.datasets[0].data
      const startDate = new Date(lineData[0].x)
      const endDate = new Date(lineData[lineData.length - 1].x)
      timeUnit = chartUtil.genChartLabelUnit(startDate, endDate)
      break
    default:
      timeUnit = 'month'
      break
  }

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (dataPoint) {
            return ` $${dataPoint.formattedValue}`
          },
          // Title's argument is an array of a single dataPoint, formats date
          title: function (dataPointArr) {
            const label = dataPointArr[0].label
            return label.split(',').slice(0, 2).join(',')
          },
        },
      },
    },
    spanGaps: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          source: 'labels',
        },
        time: {
          unit: timeUnit,
          minUnit: 'day',
        },
        type: 'time',
      },
      y: {
        ticks: {
          callback: function (value, _index, _ticks) {
            return `$${value}`
          },
        },
      },
    },
  }

  return <Line data={data} options={options} />
}
