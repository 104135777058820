import { analytics } from 'analytics'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function usePageViews() {
  let location = useLocation()
  useEffect(() => {
    analytics.page(location.pathname)
  }, [location])
}
