import { Accordion } from '@chakra-ui/react'
import { analytics } from 'analytics'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Portfolio } from 'types'

import { Box, Button, Dropdown, SearchBar, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import Pagination from 'components/common/Pagination/Pagination'
import { PortfolioListItem } from 'components/partials'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { GROUP_ADD_PORTFOLIO_CLICKED } from 'constants/track.constants'
import { openCreatePortfolio } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { SPACE } from 'styles/constants/space'
import { searchFilter } from 'utils/searchFilter'

type Props = {
  portfolios: Portfolio[]
}

type SortByOptions = 'name' | 'AUM'

function PortfolioList({ portfolios }: Props) {
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState<SortByOptions>('name')
  const [portfolioList, setPortfolioList] = useState<Portfolio[]>(portfolios)
  const [page, setPage] = useState(1)
  const [portfoliosCount, setPortfoliosCount] = useState(portfolios.length)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    const filteredPortfolios = portfolios.filter((portfolio) =>
      searchFilter(portfolio, 'displayName', search)
    )
    setPortfolioList(filteredPortfolios)
  }, [search, portfolios])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    const filteredPortfolios = portfolios.filter((portfolio) =>
      searchFilter(portfolio, 'displayName', search)
    )
    const portfoliosToShow = filteredPortfolios.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )
    setPortfolioList(portfoliosToShow)
    setPortfoliosCount(filteredPortfolios.length)
  }, [search, portfolios, page, selectedItemsPerPageOption])

  useEffect(() => {
    if (sortField === 'name') {
      setPortfolioList((prev) => orderBy(prev, 'displayName'))
    }
    if (sortField === 'AUM') {
      setPortfolioList((prev) => orderBy(prev, 'value', 'desc'))
    }
  }, [sortField])

  const handleAddPortfolio = () => {
    dispatch(openCreatePortfolio())
    analytics.track(GROUP_ADD_PORTFOLIO_CLICKED)
  }

  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: portfoliosCount,
  })

  return (
    <Card
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="space-between"
      padding={`${SPACE.sp20} ${SPACE.sp24}`}
    >
      <Box display="flex" flexDirection="column" gap={SPACE.sp24} height="100%">
        <Box display="flex" flexDirection="column" gap={SPACE.sp12}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              <Text
                color="coolGray.600"
                fontSize="xl"
                lineHeight="xl"
                fontWeight="700"
              >
                Portfolios
                <Text
                  color="coolGray.600"
                  fontSize="xl"
                  lineHeight="xl"
                  fontWeight="400"
                >
                  &nbsp;({portfoliosCount})
                </Text>
              </Text>
            </Box>
            <Button variant="tertiary" size="xs" onClick={handleAddPortfolio}>
              Add Portfolio
            </Button>
          </Box>
          <Box
            width="100%"
            display="grid"
            gridTemplateColumns="3fr 16fr 3fr"
            gap={SPACE.sp12}
          >
            <Dropdown
              options={itemsPerPageOptions}
              onChange={(option: any) => {
                setSelectedItemsPerPageOption(option)
                setPage(1)
              }}
              value={selectedItemsPerPageOption}
              isSearchable={false}
              styles={{
                control: (base: any) => ({
                  ...base,
                  borderRadius: '0.5rem',
                  height: '2.5rem',
                }),
              }}
            />
            <Box width="100%">
              <SearchBar
                placeholder="Search by portfolio name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Text
                fontSize="xs"
                lineHeight="xs"
                fontWeight="bold"
                color="coolGray.500"
              >
                Sort by:
              </Text>
              <Button
                variant="tertiary"
                size="xs"
                active={sortField === 'name'}
                onClick={() => setSortField('name')}
              >
                Name
              </Button>
              <Button
                variant="tertiary"
                size="xs"
                active={sortField === 'AUM'}
                onClick={() => setSortField('AUM')}
              >
                AUM
              </Button>
            </Box>
          </Box>
        </Box>
        <Box maxHeight="700px" overflow="scroll">
          <Accordion allowMultiple defaultIndex={[0]} width="100%">
            {portfolioList.map((portfolio) => (
              <PortfolioListItem key={portfolio.id} {...portfolio} />
            ))}
          </Accordion>
        </Box>
      </Box>
      <Box>
        <Pagination
          currentPage={page}
          handlePageChange={handlePageChange}
          itemsCount={portfoliosCount}
          itemsPerPage={selectedItemsPerPageOption.value}
        />
      </Box>
    </Card>
  )
}

export default PortfolioList
