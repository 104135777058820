import React, { useState } from 'react'
import PinInput from 'react-pin-input'

import Keyhole from 'assets/icons/keyhole.svg'
import { Box, Button, Text } from 'components/common'

import { FormContainer } from './shared'

type Props = {
  onSubmit: (code: string) => void
}

function VerifyMFACode({ onSubmit }: Props) {
  const [code, setCode] = useState('')

  return (
    <FormContainer>
      <Box
        width="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        gap="30px"
      >
        <Box>
          <img src={Keyhole} alt="Keyhole" />
        </Box>
        <Text fontSize="lg" fontWeight="bold" as="p">
          Enter authentication code
        </Text>
        <Text as="p">
          Please enter the 6-character confirmation code from your linked
          authenticator app
        </Text>
        <PinInput
          length={6}
          focus
          type="numeric"
          inputMode="text"
          style={{ padding: '8px' }}
          inputStyle={{
            width: '60px',
            height: '60px',
            borderColor: '#D1D5DB',
            borderRadius: '8px',
          }}
          onComplete={(value) => setCode(value)}
        />
        <Button
          color="purple.800"
          style={{ width: '100%' }}
          onClick={() => onSubmit(code)}
        >
          Verify
        </Button>
      </Box>
    </FormContainer>
  )
}

export default VerifyMFACode
