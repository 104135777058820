import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function InterestRateSensitivityPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Interest Rate Sensitivity
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Monetary policy is consistently a key area analyzed by Pave’s
                technology as interest rates play a key role in driving the
                financial markets. Since market interest rates move in
                anticipation of the direction of Fed policy as well as inflation
                and growth, Pave Pro takes into account several factors to build
                an accurate picture of an asset’s exposure to interest rates.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                If Interest Rate Sensitivity is left on Default, Pave Pro will
                automatically adjust client portfolios such that their interest
                rate exposure is as close as possible to that of their
                benchmark. If you would like to tilt Pave Pro towards favoring
                assets that are more sensitive to changes in interest rates,
                select Overweight. For the opposite, select Underweight.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default InterestRateSensitivityPopOver
