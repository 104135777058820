import { analytics } from 'analytics'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { paveApi } from 'api'
import { Box } from 'components/common'
import DropdownMenu from 'components/common/DropdownMenu/DropdownMenu'
import PageLoader from 'components/common/Loader/PageLoader'
import { TopNav } from 'components/partials'
import { LOGOUT_CLICKED } from 'constants/track.constants'
import { signOut } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { resetUser } from 'store/userSlice'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

type Props = {
  children: React.ReactNode
  showLoader?: boolean
  hideMenu?: boolean
}

function PageLayout({ children, showLoader = false, hideMenu }: Props) {
  const userState = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const onLogout = () => {
    analytics.track(LOGOUT_CLICKED)
    dispatch(signOut())
    dispatch(resetUser())
    dispatch(paveApi.util.resetApiState())
    navigate('/')
  }

  const userName = `${userState.firstName} ${userState.lastName}`

  const menuItems = [
    {
      action: () => navigate('/settings'),
      icon: 'cog',
      name: 'Settings',
    },
    {
      action: () => navigate('/connected-accounts'),
      icon: 'link',
      name: 'Connected Accounts',
    },
    {
      action: () => navigate('/investment-templates'),
      icon: 'pencil',
      name: 'Investment Templates',
    },
    {
      action: () => navigate('/buy-lists'),
      icon: 'clipboard',
      name: 'Approved Buy Lists',
    },
    {
      action: onLogout,
      icon: 'logout',
      name: 'Log out',
      color: COLOR.red600,
    },
  ]

  // TODO: Move the topnav to be an prop passed into the page layout
  return (
    <Box height="100vh" backgroundColor={COLOR.gray50}>
      <TopNav
        menu={
          !hideMenu && (
            <>
              <Box>
                <DropdownMenu menuItems={menuItems} menuName={userName} />
              </Box>
            </>
          )
        }
      />
      <Box
        gridArea="content"
        backgroundColor={COLOR.gray50}
        paddingBottom={SPACE.sp24}
      >
        {showLoader ? <PageLoader /> : children}
      </Box>
    </Box>
  )
}

export default PageLayout
