import styled from '@emotion/styled'

import Box from '../Box/Box'

type AssetRowProps = {
  name: string
  value: string
  onClick: (value: string) => void
}

const AssetRow = ({ name, value, onClick }: AssetRowProps) => {
  return (
    <Box onClick={() => onClick(value)}>
      <StyledText>{name}</StyledText>
    </Box>
  )
}

const StyledText = styled.p`
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.coolGray[500]};
  font-size: ${({ theme }) => theme.fontSizes.xxs};

  &:hover {
    background-color: ${({ theme }) => theme.colors.coolGray[200]};
  }
`

export default AssetRow
