import styled from '@emotion/styled'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray[200]};
    margin: 0;
    padding: 0;
  }

  .react-tabs__tab {
    display: inline-block;
    height: 40px;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 12px 32px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    line-height: ${({ theme }) => theme.lineHeights.xxs};
    color: ${({ theme }) => theme.colors.coolGray[400]};
    cursor: pointer;
  }

  .react-tabs__tab--selected {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.coolGray[200]};
    color: ${({ theme }) => theme.colors.coolGray[500]};
    border-radius: 8px 8px 0px 0px;
  }

  .react-tabs__tab--disabled {
    color: ${({ theme }) => theme.colors.coolGray[100]};
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab-panel {
    width: 100%;
    height: calc(100% - 40px);
    display: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.coolGray[200]};
    border-radius: 0px 0px 8px 8px;
    margin-top: -1px;
    padding: 24px;
    overflow-y: scroll;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`

export { StyledTabs as Tabs, TabList, Tab, TabPanel }
