import Text from 'components/common/Text/Text'
import { COLOR } from 'styles/constants/color'

type Props = {
  href: string
  label: string
  bold?: boolean
  openInNewTab?: boolean
}

function LinkText({ href, label, bold, openInNewTab }: Props) {
  return (
    <Text color={COLOR.purple800}>
      <a href={href} target={openInNewTab ? '_blank' : ''} rel="noreferrer">
        {bold ? <strong>{label}</strong> : label}
      </a>
    </Text>
  )
}

export default LinkText
