import { createSlice } from '@reduxjs/toolkit'

interface signUpState {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  error: string
  loading: boolean
  phoneNumber: string
  addressLine1: string
  addressLine2: string
  underReview: boolean | null
}

const initialState: signUpState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  error: '',
  loading: false,
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  underReview: true,
}

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setPassword: (state, action) => {
      state.password = action.payload
    },
    setConfirmPassword: (state, action) => {
      state.confirmPassword = action.payload
    },
  },
})

export const { setEmail, setPassword, setConfirmPassword } = signUpSlice.actions

export default signUpSlice.reducer
