import { Base64 } from 'js-base64'
import {
  createMessage,
  decrypt,
  decryptKey,
  encrypt,
  readKey,
  readMessage,
  readPrivateKey,
} from 'openpgp'

import {
  PAVE_API_PASSPHRASE,
  PAVE_API_PRIVATE_KEY,
  PAVE_API_PUBLIC_KEY,
} from 'constants/app.constants'

export async function encryptJsonPayload(jsonString: any) {
  const encryptionKey = await getEncryptionKeys()

  const encryptedPayload = await encrypt({
    message: await createMessage({ text: jsonString }),
    encryptionKeys: encryptionKey,
    format: 'binary',
  })
  return Base64.fromUint8Array(encryptedPayload as Uint8Array)
}

export async function decryptJsonPayload(encryptedPayload: any) {
  const binaryPayload = Base64.toUint8Array(encryptedPayload)
  const decryptionKey = await getDecryptionKeys()

  const decryptedBinary = await decrypt({
    message: await readMessage({
      binaryMessage: binaryPayload,
    }),
    decryptionKeys: decryptionKey,
    format: 'binary',
    config: { allowInsecureDecryptionWithSigningKeys: true },
  })
  const decryptedJsonString = Base64.fromUint8Array(
    decryptedBinary.data as Uint8Array
  )

  return Base64.decode(decryptedJsonString)
}

async function getEncryptionKeys() {
  if (!PAVE_API_PUBLIC_KEY) {
    throw new Error('No public key provided')
  }
  const publicKey = await readKey({
    armoredKey: PAVE_API_PUBLIC_KEY,
  })
  return publicKey
}

async function getDecryptionKeys() {
  if (!PAVE_API_PRIVATE_KEY) {
    throw new Error('No private key provided')
  }

  const privateKey = await decryptKey({
    privateKey: await readPrivateKey({
      armoredKey: PAVE_API_PRIVATE_KEY,
    }),
    passphrase: PAVE_API_PASSPHRASE,
  })
  return privateKey
}
