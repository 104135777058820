import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import ModalLoader from '../ModalLoader'

type Props = {
  hideModal: () => void
  onClick: () => void
  showModal: boolean
  isLoading: boolean
}

export default function ConfirmEditTemplateModal({
  hideModal,
  onClick,
  isLoading,
  showModal,
}: Props) {
  return (
    <Modal isOpen={showModal} onClose={hideModal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              Confirm template changes?
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              Once this template is saved, linked portfolio investment
              preferences will update immediately and any suggested trades will
              be cleared. It may take a while for the suggested trades to be
              updated. Any affected portfolios set to trade today, will now
              trade during the following trading day.
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter marginBottom={SPACE.sp8}>
          <Box width="100%">
            <Box marginBottom={SPACE.sp12}>
              <Button
                onClick={onClick}
                size="md"
                variant="primary"
                width="100%"
                disabled={isLoading}
              >
                Confirm
              </Button>
            </Box>

            <Button
              onClick={hideModal}
              size="md"
              variant="secondary"
              width="100%"
              active={false}
            >
              Cancel
            </Button>
            {isLoading && <ModalLoader />}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
