import React from 'react'

import { Box, Button, Text, TextInput } from 'components/common'

import { FormContainer, FormInputsContainer } from './shared'

type Props = {
  isLoading: boolean
  onSubmit: (formData: any) => void
}

function ForgotPassword({ isLoading, onSubmit }: Props) {
  const [email, setEmail] = React.useState('')

  return (
    <FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit({ email })
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="0px"
          gap="24px"
          width="100%"
        >
          <Text
            fontSize="xl"
            fontWeight="bold"
            lineHeight="xl"
            textAlign="center"
            color="black"
          >
            Forgot your password?
          </Text>
          <FormInputsContainer>
            <TextInput
              name="email"
              width="100%"
              type="email"
              value={email}
              required
              placeholder="Email Address"
              onChange={(e) => {
                setEmail(e.target.value.trim())
              }}
            />
          </FormInputsContainer>

          <Button
            disabled={isLoading}
            size="lg"
            type="submit"
            variant="primary"
          >
            Request a code to reset it
          </Button>
        </Box>
      </form>
    </FormContainer>
  )
}

export default ForgotPassword
