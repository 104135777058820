import { createSlice } from '@reduxjs/toolkit'

import { ConnectionMethod } from 'enums/ConnectionMethodEnum'

interface CreatePortfolioState {
  step: number
  plaidStep: number
  displayName: string
  linkMethod: ConnectionMethod | null
  automation: boolean
  brokerageAccounts: string[]
  portfolioId: string
}

const initialState: CreatePortfolioState = {
  step: 1,
  plaidStep: 1,
  displayName: '',
  linkMethod: null,
  automation: false,
  brokerageAccounts: [],
  portfolioId: '',
}

export const createPortfolioSlice = createSlice({
  name: 'createPortfolio',
  initialState,
  reducers: {
    reset: () => initialState,
    setDisplayName: (state, action) => {
      state.displayName = action.payload
    },
    setLinkMethod: (state, action) => {
      state.linkMethod = action.payload
    },
    setAutomation: (state, action) => {
      state.automation = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setPlaidStep: (state, action) => {
      state.plaidStep = action.payload
    },
    addBrokerageAccount: (state, action) => {
      state.brokerageAccounts.push(action.payload)
    },
    setPortfolioId: (state, action) => {
      state.portfolioId = action.payload
    },
  },
})

export const {
  reset,
  setDisplayName,
  setLinkMethod,
  setAutomation,
  setStep,
  setPlaidStep,
  addBrokerageAccount,
  setPortfolioId,
} = createPortfolioSlice.actions

export default createPortfolioSlice.reducer
