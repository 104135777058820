import styled from '@emotion/styled'
import { analytics } from 'analytics'

import { Box, Button, Separator, Tag, Text } from 'components/common'
import BulletList from 'components/common/BulletList/BulletList'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SETTINGS_EDIT_ADVISOR_INFORMATION_CLICKED } from 'constants/track.constants'
import { openEditUserSettings } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  user: any
  mfaEnabled: boolean
}
function AdvisorPreferences({ user, mfaEnabled }: Props) {
  const { firstName, lastName, email, contactInfo } = user
  const { phoneNumber, addressLine1, addressLine2 } = contactInfo
  const timeZone = new Intl.DateTimeFormat('en-us', {
    timeZoneName: 'short',
  })
    .formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value
  const preferences = {
    timeZone: `${new Date().toLocaleTimeString('en-us', {
      hour: '2-digit',
      minute: '2-digit',
    })} ${timeZone}`,
    primaryContact: {
      name: `${firstName} ${lastName}`,
      avatar: '',
      phone: phoneNumber,
      email: email,
      address: `${addressLine1} ${addressLine2}`,
    },
  }

  const dispatch = useAppDispatch()

  const handleEditAdvisorSettings = () => {
    analytics.track(SETTINGS_EDIT_ADVISOR_INFORMATION_CLICKED)
    dispatch(openEditUserSettings())
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding="20px 24px"
      backgroundColor={COLOR.white}
      borderRadius="8px"
      gap={SPACE.sp20}
      width="100%"
      height="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={SPACE.sp8}
        width="100%"
      >
        <Text
          fontSize={FONT_SIZE.fs16}
          fontWeight="bold"
          lineHeight="md"
          color={COLOR.coolGray700}
        >
          Advisor Settings
        </Text>
        <Button
          size="xxs"
          variant="tertiary"
          onClick={handleEditAdvisorSettings}
        >
          Edit
        </Button>
        <Text
          fontSize={FONT_SIZE.fs12}
          fontWeight="medium"
          lineHeight="xs"
          color={COLOR.coolGray500}
          display="none"
        >
          Local Time: {preferences.timeZone}
        </Text>
      </Box>
      <Separator color={COLOR.coolGray300} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={SPACE.sp12}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={SPACE.sp24}
          justifyContent="space-between"
          width="100%"
        >
          <Text
            fontSize={FONT_SIZE.fs12}
            fontWeight="bold"
            lineHeight="xs"
            color={COLOR.coolGray500}
          >
            Contact Information
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={SPACE.sp12}
          width="100%"
        >
          <FlexBox flexDirection="column">
            <Text
              fontSize={FONT_SIZE.fs14}
              fontWeight="bold"
              color={COLOR.gray700}
            >
              {preferences.primaryContact.name}
            </Text>
            <BulletList
              items={[
                preferences.primaryContact.phone,
                preferences.primaryContact.email,
                preferences.primaryContact.address,
              ]}
            />
          </FlexBox>
          <Separator color={COLOR.coolGray300} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={SPACE.sp24}
            justifyContent="space-between"
            width="100%"
          >
            <Text
              fontSize={FONT_SIZE.fs12}
              fontWeight="bold"
              lineHeight="xs"
              color={COLOR.coolGray500}
            >
              Security
            </Text>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Text fontSize={FONT_SIZE.fs14} fontWeight="bold">
                MFA
              </Text>
              <Tag variant={mfaEnabled ? 'green' : 'red'}>
                {mfaEnabled ? 'On' : 'Off'}
              </Tag>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.coolGray[200]};
  img {
    border-radius: 50%;
  }
`
export default AdvisorPreferences
