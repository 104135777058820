import styled from '@emotion/styled'
import React from 'react'

import { Box, BoxProps } from 'components/common'

type Props<T> = BoxProps & {
  data: T[]
  header?: React.ReactNode
  renderItem: (item: T) => React.ReactNode
}

function GridView<T extends unknown>({
  data,
  header = null,
  renderItem,
  ...props
}: Props<T>) {
  return (
    <Container {...props}>
      {header}
      {data.map((item) => renderItem(item))}
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`

export default GridView
