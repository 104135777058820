import { isArray } from 'lodash'
import { MenuListProps } from 'react-select'
import { FixedSizeList as List } from 'react-window'

const height = 36

const VirtualOptionsList = (props: MenuListProps) => {
  const { options, children, maxHeight, getValue } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height
  let quantity = 0
  if (isArray(children)) {
    quantity = children.length
    return (
      <List
        width="100%"
        height={maxHeight}
        itemCount={quantity}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    )
  }
  return null
}

export default VirtualOptionsList
