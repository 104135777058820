import { ThemeProvider as EmotionProvider } from '@emotion/react'
import React from 'react'

import GlobalStyles from 'styles/GlobalStyles'
import { themes } from 'styles/system/themes'

type Props = {
  children: React.ReactNode
}

function ThemeProvider({ children }: Props) {
  return (
    <EmotionProvider theme={themes.default}>
      <>
        {children}
        <GlobalStyles />
      </>
    </EmotionProvider>
  )
}

export default ThemeProvider
