import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export default function BuyListLearnMoreModal({ isOpen, onClose }: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Buy List Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="grid" gap={SPACE.sp8}>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                Buy Lists allow a firm to set which assets Pave Pro can
                recommend within a given client portfolio.
              </Text>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                Buy Lists will not guarantee that all assets in the list are
                included in a portfolio, but will ensure that no asset outside
                of the list is allocated to unless otherwise specified.
              </Text>
              <Text
                fontSize={FONT_SIZE.fs16}
                fontWeight="400"
                color={COLOR.coolGray500}
              >
                Pave Pro will default to using assets from the chosen benchmark
                when building a portfolio if a Buy List is not specified.
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <FlexBox width="100%" gap={SPACE.sp12}>
              <Button
                color={COLOR.purple800}
                style={{ width: '100%' }}
                onClick={onClose}
              >
                Got It
              </Button>
            </FlexBox>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
