import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  hideModal: () => void
  onClick: () => void
  showModal: boolean
}

export default function ConfirmDeleteGroupModal({
  hideModal,
  onClick,
  showModal,
}: Props) {
  return (
    <Modal isOpen={showModal} onClose={hideModal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              Delete group?
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              Are you sure you want to delete this group? This action cannot be
              undone.
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter marginBottom={SPACE.sp8}>
          <Box width="100%">
            <Box marginBottom={SPACE.sp12}>
              <Button onClick={onClick} size="md" variant="danger" width="100%">
                Delete
              </Button>
            </Box>

            <Button
              onClick={hideModal}
              size="md"
              variant="secondary"
              width="100%"
              active={false}
            >
              Cancel
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
