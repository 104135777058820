import { Form, Formik } from 'formik'
import { useState } from 'react'
import validator from 'validator'

import {
  Box,
  Button,
  Checkbox,
  /* PhotoPreview, FileUpload */
  Text,
} from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { TERMS_OF_SERVICE_URL } from 'constants/general.constants'
import { useCaseOptions } from 'constants/signup.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import {
  AccountDetailsFormFields,
  AccountPrimaryContactDetails,
  accountDetailsInitialValues,
} from './AccountDetailsFormFields'
import { Dropdown } from './Fields'
import { FormContainer } from './shared'

type Props = {
  email: string
  isButtonDisabled: boolean
  onSubmit: (values: AccountPrimaryContactDetails) => void
}
function CompleteProfileForm({ email, isButtonDisabled, onSubmit }: Props) {
  const initialValues = { ...accountDetailsInitialValues, email: email }
  const [isChecked, setIsChecked] = useState(false)
  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        validate={(values: AccountPrimaryContactDetails) => {
          const errors: Partial<AccountPrimaryContactDetails> = {}
          if (validator.isEmpty(values.firstName)) {
            errors.firstName =
              'First name is required and must only contain letters'
          }
          if (validator.isEmpty(values.lastName)) {
            errors.lastName =
              'Last name is required and must only contain letters'
          }
          if (validator.isEmpty(values.country)) {
            errors.country = 'Country is required and must only contain letters'
          }
          if (validator.isEmpty(values.state)) {
            errors.state = 'State is required and must only contain letters'
          }
          if (validator.isEmpty(values.zipCode)) {
            errors.zipCode =
              'Zip code is required and must only contain letters'
          }
          return errors
        }}
        onSubmit={(values: AccountPrimaryContactDetails) => {
          onSubmit(values)
        }}
      >
        <Form>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0px"
            gap="24px"
            width="546px"
          >
            <Text
              fontSize="xl"
              fontWeight="bold"
              lineHeight="xl"
              textAlign="center"
              color="black"
            >
              Create your Pave Pro account
            </Text>
            <Box display="flex" flexDirection="column" gap="16px">
              <AccountDetailsFormFields disableEmail />
              <Dropdown
                name="useCase"
                label="Which case best describes you?"
                options={useCaseOptions}
                placeholder="Select a use case"
              />
              <FlexBox gap={SPACE.sp8} alignItems="center" lineHeight="xs">
                <Checkbox
                  width="1rem"
                  height="1rem"
                  onClick={() => {
                    setIsChecked(!isChecked)
                  }}
                  checked={isChecked}
                />
                <Text
                  fontSize={FONT_SIZE.fs12}
                  fontWeight={400}
                  lineHeight="xs"
                  color={COLOR.coolGray600}
                >
                  I have read and accepted the{' '}
                  <a
                    href={TERMS_OF_SERVICE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text
                      fontSize={FONT_SIZE.fs12}
                      fontWeight={600}
                      color={COLOR.purple600}
                    >
                      terms of service
                    </Text>
                  </a>
                </Text>
              </FlexBox>
              <Button
                disabled={isButtonDisabled || !isChecked}
                size="lg"
                type="submit"
                variant="primary"
              >
                Create Account
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
    </FormContainer>
  )
}
export default CompleteProfileForm
