import { DropdownVariant } from 'components/common/Dropdown/Dropdown'
import VirtualOptionsList from 'components/common/VirtualOptionsList/VirtualOptionsList'
import DropdownWithTags, {
  DropdownOptionString,
} from 'components/partials/DropdownWithTags'

type ExcludedAssetsDropdownProps = {
  isDisabled?: boolean
  assetsOptions: DropdownOptionString[]
  onChange: (values: string[]) => void
  excludedAssets: string[]
  variant?: DropdownVariant
}

// Reusable component for the excluded assets dropdown
const ExcludedAssetsDropdown = ({
  isDisabled,
  assetsOptions,
  onChange,
  excludedAssets,
  variant,
}: ExcludedAssetsDropdownProps) => {
  return (
    <DropdownWithTags
      id="excluded-assets"
      isDisabled={isDisabled}
      options={assetsOptions}
      tagColor="red"
      label="Excluded Assets"
      placeholder="Search by asset name or ticker"
      customComponents={{ MenuList: VirtualOptionsList }}
      onChange={onChange}
      value={excludedAssets}
      variant={variant}
    />
  )
}

export default ExcludedAssetsDropdown
