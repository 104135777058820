import { Tooltip } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { analytics } from 'analytics'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SlideOutMenu } from 'components/partials'
import ClientAccountForm, {
  AccountFields,
} from 'components/partials/Account/ClientAccountForm'
import { accountDetailsInitialValues } from 'components/partials/Forms/AccountDetailsFormFields'
import { GROUP_EDIT_PRIMARY_CONTACT_SUBMITTED } from 'constants/track.constants'
import { closeEditAccountSettings } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import ModalLoader from '../ModalLoader'

type Props = {
  account: ClientAccountDto | undefined
  isDeleteDisabled: boolean
  isLoading: boolean
  onDelete: () => void
  onEditClientAccountSettings: (account: Partial<ClientAccountDto>) => void
}
function AccountEditSettingsModal({
  account,
  isDeleteDisabled,
  isLoading,
  onDelete,
  onEditClientAccountSettings,
}: Props) {
  const accountDetails: AccountFields = {
    displayName: account?.displayName || '',
    description: account?.description || '',
    primaryContact: {
      ...accountDetailsInitialValues,
      ...account?.primaryContact,
    },
  }

  const { showEditAccountSettings } = useAppSelector((state) => state.modals)
  const dispatch = useAppDispatch()

  const onSubmit = (values: AccountFields): void => {
    analytics.track(GROUP_EDIT_PRIMARY_CONTACT_SUBMITTED)
    onEditClientAccountSettings(values)
  }

  return (
    <>
      <SlideOutMenu
        customHeaderButtons={
          <FlexBox gap={SPACE.sp12} alignItems="center">
            {isDeleteDisabled && (
              <Tooltip
                backgroundColor={COLOR.white}
                border={`1px solid ${COLOR.coolGray300}`}
                borderRadius="8"
                placement="auto-start"
                label={
                  <FlexBox
                    color={COLOR.coolGray600}
                    flexDirection="column"
                    gap={SPACE.sp12}
                    margin={SPACE.sp12}
                  >
                    <Text fontWeight="700" fontSize={FONT_SIZE.fs18}>
                      Why am I unable to delete this group?
                    </Text>
                    <Text color={COLOR.coolGray700} fontWeight="regular">
                      Deleting a group that contains one or more portfolios is
                      not possible. If you would like to delete this group,
                      please delete or move all of the portfolios within it.
                    </Text>
                  </FlexBox>
                }
              >
                <InformationCircleIcon
                  color={COLOR.coolGray500}
                  height="1.25rem"
                  width="1.25rem"
                />
              </Tooltip>
            )}
            <Button
              disabled={isDeleteDisabled}
              onClick={onDelete}
              size="md"
              type="submit"
              variant="danger"
            >
              Delete
            </Button>
            <Button
              form="edit-client-account"
              size="md"
              type="submit"
              variant="primary"
            >
              Save Changes
            </Button>
          </FlexBox>
        }
        formId="edit-client-account"
        isOpen={showEditAccountSettings}
        onClose={() => dispatch(closeEditAccountSettings())}
        size="lg"
        title="Edit Group Settings"
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          <ClientAccountForm
            formId="edit-client-account"
            onSubmit={onSubmit}
            initialValues={accountDetails}
          />
        </Box>
        {isLoading && <ModalLoader />}
      </SlideOutMenu>
    </>
  )
}
export default AccountEditSettingsModal
