/**
 * TradeStatus Enum
 */
export const TradeStatus = {
  NOT_SENT: 'NOT_SENT',
  PENDING: 'PENDING',
  EXECUTED: 'EXECUTED',
  FAILED: 'FAILED',
  ABANDONED: 'ABANDONED',
} as const
// eslint-disable-next-line
export type TradeStatus = typeof TradeStatus[keyof typeof TradeStatus]
