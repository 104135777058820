import styled from '@emotion/styled'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import Collapsible from 'react-collapsible'

import { Portfolio } from 'types'

import { Box, DEPRECATEDFlexCard } from 'components/common'
import { AssetsTable } from 'components/partials'

import PortfolioListItemTrigger from './PortfolioListItemTrigger'

type Props = Portfolio

function PortfolioListItem(portfolio: Props) {
  const assets = portfolio?.assets
  const totalAssets = assets?.length || 0

  return (
    <Container>
      <Collapsible
        triggerDisabled={totalAssets === 0}
        trigger={
          <PortfolioListItemTrigger
            portfolio={portfolio}
            icon={<ChevronDownIcon />}
            totalAssets={totalAssets}
          />
        }
        triggerWhenOpen={
          <PortfolioListItemTrigger
            portfolio={portfolio}
            icon={<ChevronUpIcon />}
            totalAssets={totalAssets}
          />
        }
      >
        <Wrapper>
          <AssetsTable assets={assets} />
        </Wrapper>
      </Collapsible>
    </Container>
  )
}

const Container = styled(DEPRECATEDFlexCard)`
  display: flex;
  width: 100%;
  padding: 0 0;
  margin-bottom: 12px;
  .Collapsible {
    width: 100%;
  }
`

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export default PortfolioListItem
