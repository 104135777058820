import styled from '@emotion/styled'
import React from 'react'

import { Text } from 'components/common'
import { PageLayout } from 'components/partials'

function SearchPage() {
  return (
    <PageLayout>
      <Container>
        <Text fontSize="3xl" as="p">
          Coming Soon
        </Text>
        <Text as="p">
          Search for any globally traded equity to see the asset's price chart,
          fundamental data, as well as Pave's proprietary risk and return
          analytics.
        </Text>
      </Container>
    </PageLayout>
  )
}

const Container = styled.div`
  padding: 32px;
`

export default SearchPage
