import {
  CheckCircleIcon,
  CogIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { LinkIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import { PortfolioSummary } from 'types'

import { Box, Checkbox, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import { ChecklistOption } from 'components/common/CheckList/CheckList'
import { DEPRECATEDInteractiveText } from 'components/common/DEPRECATEDInteractiveText'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = PortfolioSummary & {
  handleCheckboxChange: (newPortfolio: ChecklistOption) => void
  isChecked: boolean
}

function InvestmentTemplatesPortfolioListItem({
  id,
  automation,
  isInvestmentPreferenceCompleted,
  displayName,
  groupId,
  groupName,
  templateName,
  handleCheckboxChange,
  isChecked = false,
}: Props) {
  return (
    <InteractiveCard
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      width="100%"
      minHeight={SPACE.sp48}
    >
      <FlexBox alignItems="center" flex={1} gap={SPACE.sp10}>
        <Box marginTop={SPACE.sp6}>
          <Checkbox
            width="1rem"
            height="1rem"
            onClick={() => {
              handleCheckboxChange({
                label: displayName,
                value: id,
                extraData: { automation },
              })
            }}
            checked={isChecked}
          />
        </Box>
        <Link to={`/portfolio/${id}`}>
          <DEPRECATEDInteractiveText
            fontWeight="bold"
            fontSize={FONT_SIZE.fs12}
            color={COLOR.coolGray600}
            lineHeight="xs"
          >
            {displayName}
          </DEPRECATEDInteractiveText>
        </Link>
      </FlexBox>

      <FlexBox alignItems="center" flex={1} gap={SPACE.sp12}>
        <Link to={`/account/${groupId}`}>
          <DEPRECATEDInteractiveText
            fontWeight="medium"
            fontSize={FONT_SIZE.fs12}
            color={COLOR.coolGray600}
          >
            <FlexBox alignItems="center" gap={SPACE.sp8}>
              <Box>
                <UserGroupIcon width="1rem" height="1rem" />
              </Box>
              {groupName}
            </FlexBox>
          </DEPRECATEDInteractiveText>
        </Link>
      </FlexBox>

      <FlexBox flex={0.5} gap={SPACE.sp12}>
        <Text
          fontWeight="medium"
          fontSize={FONT_SIZE.fs12}
          color={COLOR.coolGray600}
        >
          <FlexBox alignItems="center" gap={SPACE.sp8}>
            <Box>
              <LinkIcon width="1rem" height="1rem" />
            </Box>
            {templateName}
          </FlexBox>
        </Text>
      </FlexBox>

      {isInvestmentPreferenceCompleted ? (
        <FlexBox flex={1} gap={SPACE.sp12} justifyContent="flex-end">
          <Box width="1rem" height="1rem" color={COLOR.green600}>
            <CheckCircleIcon />
          </Box>
          <Text
            fontWeight="medium"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.green600}
            style={{ whiteSpace: 'nowrap' }}
          >
            Settings Completed
          </Text>
        </FlexBox>
      ) : (
        <FlexBox flex={1} gap={SPACE.sp12} justifyContent="flex-end">
          <Box width="1rem" height="1rem" color={COLOR.amber600}>
            <CogIcon />
          </Box>
          <Text
            fontWeight="medium"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.amber600}
            style={{ whiteSpace: 'nowrap' }}
          >
            Pending Settings Completion
          </Text>
        </FlexBox>
      )}
    </InteractiveCard>
  )
}

export default InvestmentTemplatesPortfolioListItem
