import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, PopupMenu, Text } from 'components/common'

export default function AssetOutlookInformation() {
  return (
    <PopupMenu
      icon={<InformationCircleIcon color="coolGray.400" />}
      content={
        <Box display="flex" flexDirection="column">
          <Box>
            <Text fontSize="lg" fontWeight={700}>
              Asset Outlook Information
            </Text>
          </Box>
          <Text fontSize="xs" fontStyle="italic" mt={4}>
            Return Rank
          </Text>
          <Text fontSize="xs" mt={4}>
            Return rank represents how an asset’s return score ranks amongst all
            assets Pave scores on expected return.
          </Text>
          <Text fontSize="xs" mt={4}>
            The higher the return rank, the more Pave expects an asset to return
            relative to other assets. For example, an asset with a 5 star return
            rank is expected to return more than about 80% of the assets Pave
            scored.
          </Text>

          <Text fontSize="xs" fontStyle="italic" mt={4}>
            Risk Rank
          </Text>
          <Text fontSize="xs" mt={4}>
            Risk rank represents how an asset’s risk score ranks amongst all
            assets Pave scores on expected risk.
          </Text>
          <Text fontSize="xs" mt={4}>
            The higher the risk rank, the riskier Pave expects an asset to be
            relative to other assets. For example, an asset with a 5 star risk
            rank is expected to be less risky than about 80% of the assets Pave
            scored.
          </Text>

          <Text fontSize="xs" fontStyle="italic" mt={4}>
            Pave Designation
          </Text>
          <Text fontSize="xs" mt={4}>
            Pave designation is based on an asset’s return score and shows how
            favorably or unfavorably Pave views an asset. It is split into five
            categories ranging from most to least favorable: buy, overweight,
            neutral, underweight, and sell.
          </Text>
          <Text fontSize="xs" mt={4}>
            Your portfolio will likely be made up of Buys. However, since Pave
            takes risk factors as well as return expectation into account when
            making recommendations, there are scenarios in which Pave may
            recommended assets that are not Buys. This could be due to market
            conditions or your personal preferences.
          </Text>
        </Box>
      }
    />
  )
}
