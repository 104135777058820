import { Asset, Portfolio } from 'types'

import { Box, Tag, Text } from 'components/common'

type Props = {
  asset: Asset
  portfolio?: Portfolio
}

function AssetHeader({ asset }: Props) {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="20px"
        m="0 0 24px 0"
      >
        <Text
          color="coolGray.800"
          fontWeight="700"
          fontSize="3xl"
          lineHeight="3xl"
          as="h2"
        >
          {asset?.name}
        </Text>
        <Tag>{asset?.symbol}</Tag>
      </Box>
    </Box>
  )
}

export default AssetHeader
