import styled from '@emotion/styled'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import Collapsible from 'react-collapsible'

import { Box } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SPACE } from 'styles/constants/space'

import ActivityGroupHeader from '../TradeActivities/ActivityGroupHeader'
import HomeActivityGroupItem from './HomeActivityGroupItem'

export type HomeActivityGroupProps = {
  id: string
  headerText: string
  accountName: string
  accountLogo: string
  link: string
  activities: Activity[]
  count: number
  open?: boolean
}

type Activity = {
  id: string
  leftText: string
  rightText?: string
  link: string
}

function HomeActivityGroup({
  id,
  open = false,
  ...props
}: HomeActivityGroupProps) {
  const [isOpen, setIsOpen] = useState(open)
  return (
    <ActivitiesGroupContainer key={id}>
      <Collapsible
        open={isOpen}
        triggerWhenOpen={
          <ActivityGroupHeader
            {...props}
            id={id}
            collapseContentIcon={<ChevronDownIcon />}
          />
        }
        trigger={
          <ActivityGroupHeader
            {...props}
            id={id}
            collapseContentIcon={<ChevronUpIcon />}
          />
        }
        handleTriggerClick={() => setIsOpen(!isOpen)}
      >
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          marginTop={SPACE.sp12}
          gap={SPACE.sp8}
          width="100%"
        >
          {props.activities.map((activity: Activity, index: number) => {
            return (
              <HomeActivityGroupItem
                leftText={activity.leftText}
                rightText={activity.rightText}
                link={activity.link}
                index={index}
                key={index}
              />
            )
          })}
        </FlexBox>
      </Collapsible>
    </ActivitiesGroupContainer>
  )
}

export const ActivitiesGroupContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  .Collapsible {
    width: 100%;
  }
`

export default HomeActivityGroup
