import styled from '@emotion/styled'

import { Box } from 'components/common'

/**
 * DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
 *
 * Please use the up to date Card component in common
 */
const DEPRECATEDFlexCard = styled(Box)`
  display: flex;
  padding: 16px 20px;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.coolGray[300]};
  border-radius: 8px;
`

export default DEPRECATEDFlexCard
