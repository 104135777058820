import styled from '@emotion/styled'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React from 'react'

import { Asset } from 'types'

import { Box, Tag, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { formatAsCurrency } from 'utils'
import { roundToHundredths } from 'utils/numberUtils'

function AssetsTable({ assets }: { assets: Asset[] }) {
  const [sorting, setSorting] = React.useState<SortingState>([])

  const columns = React.useMemo<ColumnDef<Asset>[]>(
    () => [
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            TICKER
          </Text>
        ),
        accessorKey: 'symbol',
        cell: (info) => <Tag>{info.getValue() as string}</Tag>,
        size: 40,
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            NAME
          </Text>
        ),
        accessorKey: 'name',
        cell: (info) => (
          <Text
            fontSize="xs"
            lineHeight="xs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            {info.getValue() as string}
          </Text>
        ),
        size: 200,
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            PRICE
          </Text>
        ),
        accessorKey: 'price',
        cell: (info) => (
          <Text
            fontSize="xs"
            lineHeight="xs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            {formatAsCurrency(info.getValue() as number)}
          </Text>
        ),
        size: 80,
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            QUANTITY
          </Text>
        ),
        accessorKey: 'shares',
        cell: (info) => (
          <Text
            fontSize="xs"
            lineHeight="xs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            {roundToHundredths(info.getValue() as number) as string}
          </Text>
        ),
        size: 80,
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="500"
            color={COLOR.coolGray600}
          >
            DAY GAIN
          </Text>
        ),
        accessorKey: 'priceDetails.dayGain',
        cell: (info) => (
          <Box width="65px">
            <Tag
              size="sm"
              icon={
                (info.getValue() as number) >= 0 ? (
                  <ArrowUpIcon width="10px" height="10px" />
                ) : (
                  <ArrowDownIcon />
                )
              }
              iconPosition="left"
              variant={(info.getValue() as number) >= 0 ? 'green' : 'red'}
            >
              <Text fontSize="xxs" fontWeight="700">
                {Math.abs(info.getValue() as number)}%
              </Text>
            </Tag>
          </Box>
        ),
        size: 80,
      },
      {
        header: () => (
          <Text
            fontSize="xxs"
            lineHeight="xxs"
            fontWeight="500"
            color={COLOR.coolGray600}
            display="flex"
            justifyContent="end"
          >
            TOTAL VALUE
          </Text>
        ),
        accessorKey: 'totalValue',
        cell: (info) => (
          <Text
            fontSize="xs"
            lineHeight="xs"
            fontWeight="500"
            color={COLOR.coolGray600}
            display="flex"
            justifyContent="end"
          >
            {!isNaN(info.getValue() as number) &&
              formatAsCurrency(info.getValue() as number)}
          </Text>
        ),
        size: 120,
      },
    ],
    []
  )

  const table = useReactTable({
    data: assets,
    columns,
    state: {
      sorting,
      columnVisibility: {
        symbol: true,
        name: true,
        price: true,
        quantity: true,
        totalValue: true,
        dayGainPercentage: true,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <Table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        display="flex"
                        color={COLOR.coolGray500}
                        justifyContent={
                          header.id === 'totalValue' ? 'end' : 'start'
                        }
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <ChevronUpIcon width={16} height={16} />,
                          desc: <ChevronDownIcon width={16} height={16} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    )}
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id} style={{ height: '2.75rem' }}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  text-align: left;
  padding: 0px 20px 10px 20px;
`

export default AssetsTable
