import React, { useEffect, useState } from 'react'

import { BuyListSummaryBasic, BuyListSummaryOwner } from 'types'

import { Box, Button, Dropdown, SearchBar, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

import { DropdownOptionNumber } from '../DropdownWithTags'
import ListView from '../ListView'
import BuyListCardOwnerHeader from './BuyListCardOwnerHeader'
import BuyListCardOwnerItem from './BuyListCardOwnerItem'
import BuyListCardViewerHeader from './BuyListCardViewerHeader'
import BuyListCardViewerItem from './BuyListCardViewerItem'

type Props = {
  buyLists: (BuyListSummaryBasic | BuyListSummaryOwner)[]
  isBuyListOwner: boolean
  handleCreateBuyList: () => void
  handleBuyListClick: (selectedBuyListId: string) => void
}

function BuyListCard({
  buyLists,
  isBuyListOwner,
  handleCreateBuyList,
  handleBuyListClick,
}: Props) {
  const [search, setSearch] = React.useState('')
  const [buyListPaginationList, setBuyListPaginationList] =
    useState<(BuyListSummaryBasic | BuyListSummaryOwner)[]>(buyLists)
  const [page, setPage] = useState(1)
  const [buyListsCount, setBuyListsCount] = useState(buyLists.length)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )

  useEffect(() => {
    const filteredBuyLists = buyLists.filter((buyLists) =>
      searchFilter(buyLists, 'displayName', search)
    )
    setBuyListPaginationList(filteredBuyLists)
  }, [search, buyLists])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    let clonedBuyLists = jsonClone(buyLists) as (
      | BuyListSummaryBasic
      | BuyListSummaryOwner
    )[]

    const filteredBuyLists = clonedBuyLists.filter((buyList) =>
      searchFilter(buyList, 'displayName', search)
    )

    const buyListsToShow = filteredBuyLists.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )
    setBuyListPaginationList(buyListsToShow)
    setBuyListsCount(filteredBuyLists.length)
  }, [search, buyLists, page, selectedItemsPerPageOption])

  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: buyListsCount,
  })

  const TableHeader = isBuyListOwner
    ? BuyListCardOwnerHeader
    : BuyListCardViewerHeader

  const TableContent = isBuyListOwner
    ? BuyListCardOwnerItem
    : BuyListCardViewerItem

  return (
    <>
      <Card
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
        padding={`${SPACE.sp20} ${SPACE.sp24}`}
      >
        <FlexBox
          flexDirection="column"
          gap={SPACE.sp24}
          height="100%"
          marginTop={SPACE.sp8}
        >
          <FlexBox flexDirection="column" gap={SPACE.sp12}>
            <FlexBox justifyContent="space-between" width="100%">
              <Box>
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs20}
                  lineHeight="xl"
                  fontWeight="700"
                >
                  Buy Lists
                  <Text
                    color={COLOR.coolGray600}
                    fontSize={FONT_SIZE.fs20}
                    lineHeight="xl"
                    fontWeight="400"
                  >
                    &nbsp;({buyListsCount})
                  </Text>
                </Text>
              </Box>
              {isBuyListOwner && (
                <FlexBox gap={SPACE.sp6}>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleCreateBuyList}
                  >
                    Create Buy List
                  </Button>
                </FlexBox>
              )}
            </FlexBox>
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="3fr 15fr"
              gap={SPACE.sp12}
            >
              <Dropdown
                options={itemsPerPageOptions}
                onChange={(option: unknown) => {
                  setSelectedItemsPerPageOption(option as DropdownOptionNumber)
                  setPage(1)
                }}
                value={selectedItemsPerPageOption}
                isSearchable={false}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                  }),
                }}
              />
              <Box width="100%">
                <SearchBar
                  placeholder="Search by list name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    setPage(1)
                  }}
                />
              </Box>
            </Box>
          </FlexBox>
          <ListView
            gap={SPACE.sp12}
            data={buyListPaginationList}
            header={
              <FlexBox
                fontSize="xxs"
                fontWeight="medium"
                color={COLOR.coolGray600}
                paddingX={`${SPACE.sp16}`}
                justifyContent="flex-start"
              >
                <TableHeader />
              </FlexBox>
            }
            renderItem={(item: any) => (
              <TableContent
                key={item.id}
                buyListSummary={item}
                handleBuyListClick={handleBuyListClick}
              />
            )}
          />
        </FlexBox>
        <Box marginTop={SPACE.sp12}>
          <Pagination
            currentPage={page}
            handlePageChange={handlePageChange}
            itemsCount={buyListsCount}
            itemsPerPage={selectedItemsPerPageOption.value}
          />
        </Box>
      </Card>
    </>
  )
}

export default BuyListCard
