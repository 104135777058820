import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function CashCardPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Cash Information
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                Pave Pro displays the value of all tradable cash in a client’s
                portfolio.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                USD can be traded by Pave, but cash equivalents must be traded
                by the advisor. Therefore, if you would like to invest any
                excess cash held in cash equivalents, you must sell those assets
                yourself before Pave can invest it elsewhere.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                If a client’s portfolio contains cash equivalents, they can be
                found in the Cash Equivalents panel below the Asset panel.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default CashCardPopOver
