import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

import { COLOR } from 'styles/constants/color'

type Props = {
  onConfirm?: () => void
  onCancel: () => void
  confirmButtonText?: string
  title: string
  description: string | ReactElement
  isOpen: boolean
  colorScheme?: 'purple' | 'red' | 'green'
  style?: React.CSSProperties
  disableConfirmButton?: boolean
  disableCancelButton?: boolean
  customFooter?: ReactElement
}

function ConfirmDialog({
  onConfirm,
  onCancel,
  title,
  description,
  confirmButtonText,
  colorScheme = 'purple',
  isOpen,
  style,
  disableConfirmButton,
  disableCancelButton,
  customFooter,
}: Props) {
  const cancelRef: any = React.useRef()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent style={style}>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            color={COLOR.coolGray600}
          >
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            {customFooter ? (
              customFooter
            ) : (
              <>
                <Button
                  ref={cancelRef}
                  onClick={onCancel}
                  disabled={disableCancelButton}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme={colorScheme}
                  onClick={onConfirm}
                  ml={3}
                  disabled={disableConfirmButton}
                >
                  {confirmButtonText}
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ConfirmDialog
