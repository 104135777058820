/**
 * AssetClass Enum
 */

export const AssetClass = {
  EQUITY: 'equity',
  MULTI_ASSET_CLASS: 'multiAsset',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AssetClass = typeof AssetClass[keyof typeof AssetClass]
