import styled from '@emotion/styled'
import React from 'react'

import { Box } from 'components/common'

type Props = {
  color?: string
  strokeWidth?: number
}
function Separator({ color = 'inherit', strokeWidth = 2 }: Props) {
  return <StyledSeparator color={color} strokeWidth={strokeWidth} />
}

const StyledSeparator = styled(Box)<Props>`
  height: 0px;
  border-bottom: ${({ strokeWidth }) => `${strokeWidth}px solid`};
  width: 100%;
`

export default Separator
