import { ClientAccountDto } from 'types/dto/ClientAccount.dto'
import { LinkedManagementOrganizationAccountResponseDto } from 'types/dto/ManagementOrganization.dto'

import { Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Loader from 'components/common/Loader/Loader'
import CardSection from 'components/common/Section/CardSection'
import ManagementOrganizationAccountsList from 'components/partials/Settings/ManagementOrganizationAccountsList'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Props = {
  managementOrganizationAccounts?: LinkedManagementOrganizationAccountResponseDto[]
  clientAccounts?: ClientAccountDto[]
  handleAssignToGroup: (selectedPortfolioIds: string[]) => void
  isFetching: boolean
}
function ManagementOrganizationAccountsCardSection({
  managementOrganizationAccounts,
  clientAccounts,
  handleAssignToGroup,
  isFetching,
}: Props) {
  let accountsContent = <></>
  if (isFetching) {
    accountsContent = (
      <>
        <Text
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs18}
          fontWeight="700"
        >
          Connected Accounts{' '}
        </Text>
        <FlexBox height="100%" alignItems="center" justifyContent="center">
          <Loader />
        </FlexBox>
      </>
    )
  } else {
    if (managementOrganizationAccounts) {
      accountsContent = (
        <ManagementOrganizationAccountsList
          managementOrganizationAccounts={managementOrganizationAccounts}
          clientAccounts={clientAccounts}
          handleAssignToGroup={handleAssignToGroup}
        />
      )
    } else {
      accountsContent = (
        <FlexBox flexDirection="column">
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs18}
            fontWeight="700"
          >
            Connected Accounts{' '}
          </Text>
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs14}
            fontWeight="400"
          >
            {ERROR_MESSAGE_1}
          </Text>
        </FlexBox>
      )
    }
  }
  return <CardSection style={{ height: '100%' }}>{accountsContent}</CardSection>
}

export default ManagementOrganizationAccountsCardSection
