/**
 * Validation Constants
 */
export const BUY_LIST_MINIMUM_ASSET_COUNT =
  'Buy lists must have at least 20 supported assets'
export const BUY_LIST_REQUIRES_ONE_ASSET =
  'Buy lists must have at least one asset'
export const INCORRECT_SELECTION = 'Incorrect selection'
export const INVALID_PERFORMANCE_PRIORITY = 'Invalid performance priority'
export const INVALID_PORTFOLIO_VALUE =
  'Portfolio value must be greater than 0. Add cash or assets to your portfolio.'
export const INVALID_TARGET_CASH_WEIGHT =
  'Please enter a number between 3.00% and 100.00%'
export const NUMBER_GREATER_THAN_ZERO = 'Number needs to be greater than 0'
export const PORTFOLIO_NEEDS_VALUE =
  'Portfolio must have value before investment preferences can be set'
export const REQUIRED_FIELD = 'Required field'
