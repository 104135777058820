import { analytics } from 'analytics'
import { Link, useNavigate } from 'react-router-dom'

import { paveApi } from 'api'
import { Box, Button } from 'components/common'
import { CompleteProfileForm, PublicLayout, TopNav } from 'components/partials'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { NETWORK_ERROR } from 'constants/error.constants'
import {
  SIGNUP_COMPLETED,
  SIGNUP_FAILED,
  SIGNUP_SUBMITTED,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'

function CompleteProfilePage() {
  const navigate = useNavigate()
  const { email, password } = useAppSelector((state) => state.signUp)
  const [signUp, { isLoading }] = paveApi.useSignUpMutation()
  const { showErrorToast } = useCustomToast()

  const onSubmit = (formData: any) => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      phoneNumber,
      country,
      state,
      city,
      zipCode,
      useCase,
    } = formData

    const payload = {
      email,
      password,
      userType: 'PRO',
      underReview: true,
      phone: phoneNumber,
      userDetails: {
        firstName,
        lastName,
        contactInfo: {
          addressLine1,
          addressLine2,
          country,
          state,
          city,
          zipCode,
        },
      },
      useCase,
    }
    analytics.track(SIGNUP_SUBMITTED)
    signUp(payload)
      .unwrap()
      .then(() => {
        analytics.track(SIGNUP_COMPLETED)
        navigate('/confirm-user')
      })
      .catch((error) => {
        analytics.track(SIGNUP_FAILED, error)
        const errorCode = error?.data?.error
        if (errorCode) {
          showErrorToast('Error signing up', errorCode)
        } else if (error.status === NETWORK_ERROR) {
          showErrorToast('Error signing up', 'Network error')
        } else {
          showErrorToast(ERROR_MESSAGE_1)
        }
      })
  }
  return (
    <PublicLayout
      navbar={
        <TopNav
          menu={
            <Link to="/signup">
              <Button>Sign up</Button>
            </Link>
          }
        />
      }
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop="5%"
        padding="44px"
      >
        <CompleteProfileForm
          email={email}
          isButtonDisabled={isLoading}
          onSubmit={onSubmit}
        />
      </Box>
    </PublicLayout>
  )
}

export default CompleteProfilePage
