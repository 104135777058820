import React from 'react'

import { Box, Checkbox, Text } from 'components/common'

type Props = {
  title: string
  dueDate: string
  notes?: string
  isDone?: boolean
}
export function Task(props: Props) {
  return (
    <Box opacity={props.isDone ? 0.5 : 1}>
      <Box display="flex" flexDirection="row" gap="14px">
        <Checkbox width="14px" height="14px" checked={props.isDone}></Checkbox>
        <Box display="flex" flexDirection="column" padding="0px" gap="8px">
          <Text
            fontWeight="bold"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {props.title}
          </Text>
          <Text
            fontWeight="medium"
            fontSize="xxs"
            lineHeight="xxs"
            color="coolGray.500"
          >
            {props.dueDate}
          </Text>
          {props.notes ? (
            <Box
              padding="8px 12px"
              backgroundColor="coolGray.50"
              borderRadius="4px"
            >
              <Text
                fontWeight="regular"
                fontSize="xxs"
                lineHeight="xxs"
                color="coolGray.400"
              >
                {props.notes}
              </Text>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
