import { analytics } from 'analytics'
import React, { useEffect } from 'react'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Button, Text, TextInput } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { FormInputsContainer } from 'components/partials/Forms/shared'
import { GROUP_EDIT_GENERAL_INFORMATION_SUBMITTED } from 'constants/track.constants'
import { closeEditAccount } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

type Props = {
  onEditAccount: (account: Partial<ClientAccountDto>) => void
  account: ClientAccountDto | undefined
}
function EditAccountModal({ onEditAccount, account }: Props) {
  const [accountDisplayName, setAccountDisplayName] = React.useState('')
  const [accountDescription, setAccountDescription] = React.useState('')
  useEffect(() => {
    if (account) {
      setAccountDisplayName(account?.displayName || '')
      setAccountDescription(account?.description || '')
    }
  }, [account])
  const dispatch = useAppDispatch()
  const { showEditAccount } = useAppSelector((state) => state.modals)
  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    analytics.track(GROUP_EDIT_GENERAL_INFORMATION_SUBMITTED)
    try {
      onEditAccount({
        displayName: accountDisplayName,
        description: accountDescription,
      })
      dispatch(closeEditAccount())
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <SlideOutMenu
        isOpen={showEditAccount}
        onClose={() => dispatch(closeEditAccount())}
        size="xl"
        title="Edit Group"
        customHeaderButtons={
          <Box display="flex" gap="12px">
            <Button
              variant="primary"
              size="md"
              type="submit"
              form="edit-family"
            >
              Save Changes
            </Button>
          </Box>
        }
      >
        <form onSubmit={onFormSubmit} id="edit-family">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              padding="0px"
              gap="12px"
              maxWidth="40%"
            >
              <Text
                fontWeight="bold"
                fontSize="lg"
                lineHeight="lg"
                color="coolGray.600"
              >
                General Information
              </Text>
              <FormInputsContainer>
                <TextInput
                  width="100%"
                  type="text"
                  placeholder="Placeholder Text"
                  label="Group Name"
                  required
                  value={accountDisplayName}
                  onChange={(e) => setAccountDisplayName(e.target.value)}
                />
                <TextInput
                  width="100%"
                  type="text"
                  placeholder="Placeholder Text"
                  value={accountDescription}
                  onChange={(e) => setAccountDescription(e.target.value)}
                  label="Description (optional)"
                />
              </FormInputsContainer>
            </Box>
          </Box>
        </form>
      </SlideOutMenu>
    </>
  )
}
export default EditAccountModal
