import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { analytics } from 'analytics'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ConnectionMethod } from 'enums/ConnectionMethodEnum'

import { paveApi } from 'api'
import {
  Box,
  Button,
  Dropdown,
  Switch,
  Text,
  TextInput,
} from 'components/common'
import { AutomationConfirmDialog, AutomationPopOver } from 'components/partials'
import { eligibleAutomationConnections } from 'constants/brokerageAccount.constants'
import {
  PORTFOLIO_CREATE_MANUAL_SUBMITTED,
  PORTFOLIO_CREATE_STARTED,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import {
  setDisplayName,
  setLinkMethod,
  setStep,
} from 'store/createPortfolioSlice'
import { closeCreatePortfolio } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

import ModalLoader from '../ModalLoader'

function BrokerageLinkSelector() {
  const { showErrorToast } = useCustomToast()
  const navigate = useNavigate()
  const [createEmptyPortfolio, { isLoading }] =
    paveApi.useCreateEmptyPortfolioMutation()
  const { externalId } = useParams()
  const { data } = paveApi.useGetClientAccountQuery(externalId || '', {
    skip: !externalId,
  })

  const linkOptions = [
    {
      label: 'Create New Account',
      value: ConnectionMethod.INTERACTIVE_BROKERS,
    },
    { label: 'Link RIA Sub Account', value: ConnectionMethod.RIA_SUB_ACCOUNT },
    {
      label: 'Manually Input Account Information',
      value: ConnectionMethod.NONE,
    },
  ]

  const dispatch = useAppDispatch()
  const { linkMethod, displayName, automation } = useAppSelector(
    (state) => state.createPortfolio
  )

  const [link, setLink] = useState(
    linkOptions.find((o) => o.value === linkMethod) || linkOptions[0]
  )
  const [name, setName] = useState(displayName)
  const [auto, setAuto] = useState(automation)
  const isAutomationDisabled = !eligibleAutomationConnections.find(
    (c) => c === link.value
  )

  const [isAutomationConfirmDialogOpen, setIsAutomationConfirmDialogOpen] =
    useState(false)

  const handleSubmit = () => {
    if (link.value === ConnectionMethod.NONE) {
      analytics.track(PORTFOLIO_CREATE_MANUAL_SUBMITTED)
      createEmptyPortfolio({
        displayName: name.trim(),
        isThirdPartyManaged: false,
        clientAccountId: data?.clientAccount?.id,
      })
        .unwrap()
        .then((data) => {
          navigate(`/portfolio/${data.id}`)
          dispatch(closeCreatePortfolio())
        })
        .catch((e) => {
          const displayMessage = e?.data?.displayMessage
          if (displayMessage) {
            showErrorToast(displayMessage)
          } else {
            showErrorToast('There was a problem creating the manual portfolio')
          }
        })
    } else {
      analytics.track(PORTFOLIO_CREATE_STARTED)
      dispatch(setDisplayName(name))
      dispatch(setLinkMethod(link.value))
      dispatch(setStep(2))
    }
  }

  return (
    <>
      <ModalHeader>Create New Portfolio</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box>
          <Text color={COLOR.coolGray500} fontWeight="600">
            General Information
          </Text>
          <TextInput
            label="Portfolio Name"
            placeholder="Name"
            value={name}
            onBlur={(e) => setName(e.target.value.trim())}
            onChange={(e) => setName(e.target.value)}
          />
          <Text color={COLOR.coolGray500} fontWeight="600" mt="32px" as="p">
            Brokerage Account Link
          </Text>
          <Text
            color={COLOR.coolGray500}
            fontWeight="600"
            fontSize={FONT_SIZE.fs14}
            as="p"
            mt="4px"
          >
            Connection Method
          </Text>
          <Dropdown
            placeholder="Select method to link account"
            options={linkOptions}
            value={link}
            onChange={(option: any) => {
              if (option !== ConnectionMethod.INTERACTIVE_BROKERS) {
                setAuto(false)
              }
              setLink(option)
            }}
          />
          {link.value === 'INTERACTIVE_BROKERS' && (
            <Box mt="12px">
              <Text fontSize={FONT_SIZE.fs14} as="p" color={COLOR.coolGray600}>
                Creating a new account means you will open a new brokerage
                account through Pave Securities, LLC. When you create a new
                account, you become a customer of Pave Securities, LLC, which
                acts as an introducing broker on behalf of Interactive Brokers,
                Inc. (also referred to as IBKR). IBKR acts as custodian and
                clearing agent for all customer funds and securities.
              </Text>
            </Box>
          )}
          <label>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignContent="center"
              m="12px 0"
            >
              <Box display="flex" alignItems="center" gap="4px">
                <Text
                  color={
                    isAutomationDisabled ? COLOR.coolGray300 : COLOR.coolGray500
                  }
                  fontWeight="600"
                  fontSize={FONT_SIZE.fs14}
                >
                  Automation
                </Text>
                <AutomationPopOver />
              </Box>
              <Switch
                disabled={isAutomationDisabled}
                checked={auto}
                onChange={() => setIsAutomationConfirmDialogOpen(true)}
              />
            </Box>
          </label>
          {isAutomationDisabled ? (
            <Text fontSize={FONT_SIZE.fs14}>
              Trade automation is currently only available to users who create
              new brokerage accounts through Pave
            </Text>
          ) : (
            <>
              <Text fontSize={FONT_SIZE.fs14} as="p" color={COLOR.coolGray600}>
                If you turn on trade automation, you will still be required to
                approve your first set of suggested trades. From that point on,
                Pave Investment Advisors, LLC will automatically execute any
                suggested trades on your behalf. Leaving automation off will
                require you to manually approve suggested trades. Pave
                Investment Advisors, LLC will notify you by email anytime new
                suggested trades arise.
              </Text>
            </>
          )}
          <AutomationConfirmDialog
            isAutomationConfirmDialogOpen={isAutomationConfirmDialogOpen}
            setIsAutomationConfirmDialogOpen={setIsAutomationConfirmDialogOpen}
            onConfirm={() => setAuto(!auto)}
            isAutomationOn={auto}
          />
        </Box>
        <ModalFooter px="0">
          {isLoading && <ModalLoader />}
          <Button
            disabled={isEmpty(name)}
            color={COLOR.purple800}
            style={{ width: '100%' }}
            onClick={handleSubmit}
          >
            {link.value === ConnectionMethod.NONE ? 'Create Portfolio' : 'Next'}
          </Button>
        </ModalFooter>
      </ModalBody>
    </>
  )
}

export default BrokerageLinkSelector
