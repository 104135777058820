import React from 'react'
import { Link } from 'react-router-dom'

import { Box, PasswordInput, Text, TextInput } from 'components/common'

import { AuthActionButton, FormContainer, FormInputsContainer } from './shared'

type Props = {
  onSubmit: (formData: any) => void
}
function SignUp(props: Props) {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  return (
    <FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          props.onSubmit({ email, password, confirmPassword })
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="0px"
          gridGap="24px"
          width="100%"
        >
          <Text
            fontSize="xl"
            fontWeight="bold"
            lineHeight="xl"
            textAlign="center"
            color="black"
          >
            Create account
          </Text>
          <Text textAlign="center" color="coolGray.500">
            {'Already have an account? '}
            <Link to="/">Sign in to your account instead</Link>
          </Text>
          <FormInputsContainer>
            <TextInput
              name="email"
              width="100%"
              type="email"
              placeholder="Email Address"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordInput
              name="password"
              width="100%"
              type="password"
              placeholder="Password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordInput
              width="100%"
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormInputsContainer>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding="0px"
            width="100%"
          >
            <Box display="none">
              <Link to="/"> Forgot your password?</Link>
              <Link to="/"> Have a company code?</Link>
            </Box>
          </Box>
          <AuthActionButton
            type="submit"
            variant="primary"
            size="lg"
            //onClick={onSubmit}
          >
            Create Account
          </AuthActionButton>
        </Box>
      </form>
    </FormContainer>
  )
}

export default SignUp
