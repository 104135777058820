import { SearchIcon } from '@heroicons/react/solid'
import { ValueContainerProps, components } from 'react-select'

import Box from '../Box/Box'

const CustomValueContainer = ({ children, ...props }: ValueContainerProps) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <Box
            position="absolute"
            width="16px"
            height="16px"
            color="coolGray.400"
            ml={4}
          >
            <SearchIcon />
          </Box>
        )}
        {children}
      </components.ValueContainer>
    )
  )
}

export default CustomValueContainer
