import { createSlice } from '@reduxjs/toolkit'

import { InvestmentTemplate, Portfolio } from 'types'

export interface ModalsState {
  showCreatePortfolio: boolean
  showEditPortfolio: boolean
  showGenerateReport: boolean
  showAccountAllTasks: boolean
  showEditAccountSettings: boolean
  showAccountAllSugggestedTradeActivities: boolean
  showAddAccount: boolean
  showEditAccount: boolean
  showPortfolioAllSuggestedTrades: boolean
  showEditingPortfolioSettings: boolean
  showEditPortfolioAssets: boolean
  showEditUserSettings: boolean
  showEditAccountClients: boolean
  showEditingInvestmentTemplate: boolean

  editingPortfolio: Portfolio | null
  editingPortfolioAssets: Portfolio | null
  editingPortfolioSettings: Portfolio | null
  editingInvestmentTemplate: InvestmentTemplate | null
}

const initialState: ModalsState = {
  showCreatePortfolio: false,
  showEditPortfolio: false,
  showGenerateReport: false,
  showAccountAllTasks: false,
  showEditAccountSettings: false,
  showAccountAllSugggestedTradeActivities: false,
  showAddAccount: false,
  showEditAccount: false,
  showPortfolioAllSuggestedTrades: false,
  showEditingPortfolioSettings: false,
  showEditUserSettings: false,
  showEditPortfolioAssets: false,
  editingPortfolio: null,
  editingPortfolioAssets: null,
  editingPortfolioSettings: null,
  showEditAccountClients: false,
  showEditingInvestmentTemplate: false,
  editingInvestmentTemplate: null,
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openCreatePortfolio: (state) => {
      state.showCreatePortfolio = true
    },
    closeCreatePortfolio: (state) => {
      state.showCreatePortfolio = false
    },
    openEditPortfolio: (state) => {
      state.showEditPortfolio = true
    },
    closeEditPortfolio: (state) => {
      state.showEditPortfolio = false
    },
    openAccountAllTasks: (state) => {
      state.showAccountAllTasks = true
    },
    closeAccountAllTasks: (state) => {
      state.showAccountAllTasks = false
    },
    openGenerateReport: (state) => {
      state.showGenerateReport = true
    },
    closeGenerateReport: (state) => {
      state.showGenerateReport = false
    },
    openEditAccountSettings: (state) => {
      state.showEditAccountSettings = true
    },
    closeEditAccountSettings: (state) => {
      state.showEditAccountSettings = false
    },
    openAccountAllSuggestedTradeActivities: (state) => {
      state.showAccountAllSugggestedTradeActivities = true
    },
    closeAccountAllSuggestedTradeActivities: (state) => {
      state.showAccountAllSugggestedTradeActivities = false
    },
    openAddAccount: (state) => {
      state.showAddAccount = true
    },
    closeAddAccount: (state) => {
      state.showAddAccount = false
    },
    openEditAccount: (state) => {
      state.showEditAccount = true
    },
    closeEditAccount: (state) => {
      state.showEditAccount = false
    },
    openPortfolioAllSuggestedTrades: (state) => {
      state.showPortfolioAllSuggestedTrades = true
    },
    closePortfolioAllSuggestedTrades: (states) => {
      states.showPortfolioAllSuggestedTrades = false
    },
    setEditingPortfolio: (states, action) => {
      states.editingPortfolio = action.payload
    },
    openEditPortfolioAssets: (states, action) => {
      states.showEditPortfolioAssets = true
      states.editingPortfolioAssets = action.payload
    },
    closeEditPortfolioAssets: (states) => {
      states.showEditPortfolioAssets = false
    },
    openEditPortfolioSettings: (states, action) => {
      states.showEditingPortfolioSettings = true
      states.editingPortfolioSettings = action.payload
    },
    closeEditPortfolioSettings: (states) => {
      states.showEditingPortfolioSettings = false
    },
    openEditInvestmentTemplate: (states, action) => {
      states.showEditingInvestmentTemplate = true
      states.editingInvestmentTemplate = action.payload
    },
    closeEditInvestmentTemplate: (states) => {
      states.showEditingInvestmentTemplate = false
    },
    openEditUserSettings: (states) => {
      states.showEditUserSettings = true
    },
    closeEditUserSettings: (states) => {
      states.showEditUserSettings = false
    },
    openEditAccountClients: (states) => {
      states.showEditAccountClients = true
    },
    closeEditAccountClients: (states) => {
      states.showEditAccountClients = false
    },
  },
})

export const {
  openCreatePortfolio,
  closeCreatePortfolio,
  openEditPortfolio,
  closeEditPortfolio,
  openAccountAllTasks,
  closeAccountAllTasks,
  openGenerateReport,
  closeGenerateReport,
  openEditAccountSettings,
  closeEditAccountSettings,
  openAccountAllSuggestedTradeActivities,
  closeAccountAllSuggestedTradeActivities,
  openAddAccount,
  closeAddAccount,
  openEditAccount,
  closeEditAccount,
  openPortfolioAllSuggestedTrades,
  closePortfolioAllSuggestedTrades,
  setEditingPortfolio,
  openEditPortfolioAssets,
  closeEditPortfolioAssets,
  openEditUserSettings,
  closeEditUserSettings,
  openEditPortfolioSettings,
  closeEditPortfolioSettings,
  openEditAccountClients,
  closeEditAccountClients,
  openEditInvestmentTemplate,
  closeEditInvestmentTemplate,
} = modalsSlice.actions

export default modalsSlice.reducer
