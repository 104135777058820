import React from 'react'

import ChakraProvider from './ChakraProvider'
import ReduxProvider from './ReduxProvider'
import ThemeProvider from './ThemeProvider'

type Props = {
  children: React.ReactNode
}

function RootProvider({ children }: Props) {
  return (
    <ReduxProvider>
      <ChakraProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </ChakraProvider>
    </ReduxProvider>
  )
}

export default RootProvider
