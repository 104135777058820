export const hideAccountNumber = (accountNumber: string) => {
  if (accountNumber.length < 5) return accountNumber
  const length = accountNumber.length
  const lastFourDigits = accountNumber.slice(length - 4)
  return `****${lastFourDigits}`
}

/**
 * @function roundToHundredths
 * Converts a number to a string rounded to the hundredths place
 * This ideally prevents floating point rounding errors
 * @param number
 * @returns string
 */
export const roundToHundredths = (number: number): string => {
  const roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100
  return roundedNumber.toFixed(2)
}
