import styled from '@emotion/styled'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import Collapsible from 'react-collapsible'
import { Link } from 'react-router-dom'

import { Box } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { SPACE } from 'styles/constants/space'

import ActivityGroupHeader from './ActivityGroupHeader'
import ActivityGroupText from './ActivityGroupText'

export type ActivityGroupProps = {
  id: string
  headerText: string
  accountName: string
  accountLogo: string
  link: string
  activities: Activity[]
  count: number
  open?: boolean
}

type Activity = {
  id: string
  leftText: string
  rightText?: string
  link: string
}

function ActivityGroup({ id, open = false, ...props }: ActivityGroupProps) {
  const [isOpen, setIsOpen] = useState(open)
  return (
    <ActivitiesGroupContainer key={id}>
      <Collapsible
        open={isOpen}
        triggerWhenOpen={
          <ActivityGroupHeader
            {...props}
            id={id}
            collapseContentIcon={<ChevronDownIcon />}
          />
        }
        trigger={
          <ActivityGroupHeader
            {...props}
            id={id}
            collapseContentIcon={<ChevronUpIcon />}
          />
        }
        handleTriggerClick={() => setIsOpen(!isOpen)}
      >
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          marginTop={SPACE.sp12}
          gap={SPACE.sp8}
          width="100%"
        >
          {props.activities.map((activity: Activity, index: number) => {
            const textComponent = (
              <ActivityGroupText
                leftText={activity.leftText}
                rightText={activity.rightText}
              />
            )
            return (
              <InteractiveCard
                key={index}
                borderRadius={SPACE.sp8}
                width="100%"
                height="3rem"
                backgroundColor={COLOR.coolGray50}
              >
                {activity.link ? (
                  <Link
                    to={activity.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {textComponent}
                  </Link>
                ) : (
                  <>{textComponent}</>
                )}
              </InteractiveCard>
            )
          })}
        </FlexBox>
      </Collapsible>
    </ActivitiesGroupContainer>
  )
}

export const ActivitiesGroupContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  .Collapsible {
    width: 100%;
  }
`

export default ActivityGroup
