/**
 * FactorTilt Enum
 */
export const FactorTilt = {
  INVESTMENT_STYLE: 'investmentStyle',
  VOLATILITY_PREFERENCE: 'volatilityPreference',
  INTEREST_RATE_SENSITIVITY: 'interestRateSensitivity',
  GLOBAL_MACRO_SENSITIVITY: 'globalMacroSensitivity',
  US_DOLLAR_SENSITIVITY: 'usDollarSensitivity',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FactorTilt = typeof FactorTilt[keyof typeof FactorTilt]
