import { Box, ConfirmDialog, LinkText, Text } from 'components/common'
import { INVESTMENT_ADVISORY_AGREEMENT_URL } from 'constants/general.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Props = {
  isAutomationConfirmDialogOpen: boolean
  setIsAutomationConfirmDialogOpen: (isOpen: boolean) => void
  onConfirm: () => void
  automation: boolean | null
}

function TemplateAutomationConfirmDialog({
  isAutomationConfirmDialogOpen,
  setIsAutomationConfirmDialogOpen,
  onConfirm,
  automation,
}: Props) {
  const title = automation ? 'Turn on automation?' : 'Turn off automation?'

  return (
    <>
      <ConfirmDialog
        isOpen={isAutomationConfirmDialogOpen}
        onConfirm={() => {
          onConfirm()
          setIsAutomationConfirmDialogOpen(false)
        }}
        onCancel={() => setIsAutomationConfirmDialogOpen(false)}
        confirmButtonText="Confirm"
        title={title}
        description={
          <Box display="grid" gap="md">
            {automation ? (
              <>
                <Text
                  fontSize={FONT_SIZE.fs16}
                  fontWeight="400"
                  color={COLOR.coolGray500}
                >
                  By turning on automation, you are hiring Pave Investment
                  Advisors, LLC as the discretionary investment adviser for all
                  accounts linked to this template.{' '}
                  <LinkText
                    href={INVESTMENT_ADVISORY_AGREEMENT_URL}
                    label="Click here"
                    bold
                    openInNewTab
                  />{' '}
                  to review your Investment Advisory Agreement with Pave
                  Investment Advisors, LLC.
                </Text>
                <Text
                  fontSize={FONT_SIZE.fs16}
                  fontWeight="400"
                  color={COLOR.coolGray500}
                >
                  Additionally, when turning on automation, you agree to receive
                  the Investment Advisory Agreement, Form ADV Part 2A, Form CRS,
                  as well as other relevant information about the adviser and
                  your relationship as a client of the adviser.
                </Text>
                <Text
                  fontSize={FONT_SIZE.fs16}
                  fontWeight="400"
                  color={COLOR.coolGray500}
                >
                  By pressing confirm, you agree to having reviewed and approved
                  the Investment Advisory Agreement with Pave Investment
                  Advisors, LLC.
                </Text>
              </>
            ) : (
              <>
                <Text
                  fontSize={FONT_SIZE.fs16}
                  fontWeight="400"
                  color={COLOR.coolGray500}
                >
                  By turning off automation you are terminating the
                  discretionary investment advisory relationship with Pave
                  Investment Advisors, LLC for all accounts linked to this
                  template.
                </Text>
                <Text
                  fontSize={FONT_SIZE.fs16}
                  fontWeight="400"
                  color={COLOR.coolGray500}
                >
                  Once you have terminated your discretionary relationship with
                  Pave Investments Advisors, LLC, Pave Investment Advisors, LLC
                  makes non-discretionary recommendations to you as a
                  professional adviser, family office professional,
                  high-net-worth individual, or other type of professional
                  investor that has the investment acumen to assess and act on
                  the non-discretionary recommendations.
                </Text>
                <Text
                  fontSize={FONT_SIZE.fs16}
                  fontWeight="400"
                  color={COLOR.coolGray500}
                >
                  Depending on how you choose to execute trades using the
                  software, you may maintain your relationship as a customer
                  with Pave Securities, LLC. As the customer, you will maintain
                  the sole discretion to approve or not approve trades for all
                  accounts linked to this template.
                </Text>
              </>
            )}
          </Box>
        }
        colorScheme="purple"
        style={{
          position: 'absolute',
        }}
      />
    </>
  )
}

export default TemplateAutomationConfirmDialog
