import styled from '@emotion/styled'
import React, { InputHTMLAttributes } from 'react'

import { Text, TextInput } from 'components/common'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}

function PasswordInput({ type, ...props }: Props) {
  const [inputType, setInputType] = React.useState<'text' | 'password'>(
    'password'
  )
  const onShowPassword = () => {
    switch (inputType) {
      case 'password':
        return setInputType('text')
      case 'text':
        return setInputType('password')
      default:
        setInputType('password')
    }
  }
  return (
    <TextInput
      label={props.label}
      type={inputType}
      passwordToggle={
        <ShowPasswordContainer>
          <Text
            color="lightBlue.600"
            fontSize="xs"
            fontWeight="bold"
            onClick={(e) => {
              onShowPassword()
            }}
          >
            {`${inputType === 'password' ? 'Show' : 'Hide'}`}
          </Text>
        </ShowPasswordContainer>
      }
      {...props}
    />
  )
}
const ShowPasswordContainer = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  right: 10%;
  //width: 12px;
  height: 12px;
  span {
    cursor: pointer;
  }
`
export default PasswordInput
