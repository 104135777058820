import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { analytics } from 'analytics'
import { useState } from 'react'

import { paveApi } from 'api'
import { Box, Button, Text } from 'components/common'
import CheckList, {
  ChecklistOption,
} from 'components/common/CheckList/CheckList'
import FlexBox from 'components/common/FlexBox/FlexBox'
import {
  INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED,
  INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_CONFLICT,
  INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import ModalLoader from '../ModalLoader'

type Props = {
  isOpen: boolean
  onClose: () => void
  selectedPortfolios: ChecklistOption[]
  setSelectedPortfolios: React.Dispatch<React.SetStateAction<ChecklistOption[]>>
}
function RemoveTemplateModal({
  isOpen,
  onClose,
  selectedPortfolios,
  setSelectedPortfolios,
}: Props) {
  const [bulkRemoveInvestmentTemplate, { isLoading }] =
    paveApi.useBulkRemoveInvestmentTemplateMutation()
  const { showErrorToast, showSuccessToast } = useCustomToast()

  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleSave = () => {
    analytics.track(INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED)
    bulkRemoveInvestmentTemplate({
      portfolioIds: selectedPortfolios.map((portfolio) => portfolio.value),
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          showErrorToast(
            'Could not remove investment template',
            'There are issues with one or more of the portfolios you tried to remove the template from.'
          )
          setErrorMessage(response.displayMessage ?? '')
          analytics.track(INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_CONFLICT)
        } else {
          showSuccessToast('Template removed!')
          analytics.track(INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_SUCCESS)
          onClose()
        }
      })
      .catch(() => {
        showErrorToast('An error occurred when removing the template.')
        analytics.track(INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_FAILED)
      })
  }

  const clearErrors = () => {
    setErrorMessage('')
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        clearErrors()
      }}
      size="lg"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.2)" />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              Confirm template removal?
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingX={SPACE.sp24}>
          <FlexBox flexDirection="column" gap={SPACE.sp12}>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs12}>
              Are you sure you want to remove investment templates from the
              selected portfolios? The selected portfolios will retain their
              current investment preferences.
            </Text>
            <FlexBox
              flexDirection="column"
              gap={SPACE.sp8}
              marginTop={SPACE.sp10}
              maxHeight="20rem"
              position="relative"
              overflow="hidden"
            >
              <CheckList
                allOptions={selectedPortfolios}
                headerLabel="Affected Portfolios"
                selectedOptions={selectedPortfolios}
                setSelectedOptions={setSelectedPortfolios}
              />
            </FlexBox>
          </FlexBox>
        </ModalBody>
        <ModalFooter marginBottom={SPACE.sp8}>
          <FlexBox flexDirection="column" gap={SPACE.sp8} width="100%">
            {errorMessage && (
              <Text
                color={COLOR.red500}
                fontWeight="medium"
                fontSize={FONT_SIZE.fs12}
                lineHeight="xxs"
                mt="sm"
                mb={0}
                as="p"
              >
                <FlexBox
                  flexDirection="column"
                  gap={SPACE.sp8}
                  marginBottom={SPACE.sp8}
                >
                  {errorMessage}
                </FlexBox>
              </Text>
            )}
            <FlexBox
              flexDirection="column"
              gap={SPACE.sp8}
              marginBottom={SPACE.sp12}
              width="100%"
            >
              <Button
                onClick={handleSave}
                size="md"
                variant="primary"
                width="100%"
                disabled={selectedPortfolios.length < 1 || isLoading}
              >
                Confirm
              </Button>
              <Button
                onClick={onClose}
                size="md"
                variant="secondary"
                width="100%"
              >
                Cancel
              </Button>
              {isLoading && <ModalLoader />}
            </FlexBox>
          </FlexBox>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default RemoveTemplateModal
