import { Box, Checkbox, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import Card from '../Card/Card'
import { ChecklistOption } from './CheckList'

type Props = {
  item: ChecklistOption
  handleCheckboxChange: (newOption: ChecklistOption) => void
  isChecked: boolean
}
function CheckListItem({ item, handleCheckboxChange, isChecked }: Props) {
  return (
    <Card
      display="flex"
      flexDirection="row"
      lineHeight="xs"
      gap={SPACE.sp12}
      width="100%"
    >
      <Box>
        <Checkbox
          width="1rem"
          height="1rem"
          onClick={() => {
            handleCheckboxChange(item)
          }}
          checked={isChecked}
        />
      </Box>
      <Text
        fontSize={FONT_SIZE.fs12}
        fontWeight={700}
        lineHeight="xs"
        color={COLOR.coolGray600}
      >
        {item.label}
      </Text>
    </Card>
  )
}
export default CheckListItem
