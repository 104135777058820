import FlexBox from '../FlexBox/FlexBox'
import Loader from './Loader'

export default function PageLoader() {
  return (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      marginTop="30vh"
      width="100%"
    >
      <Loader />
    </FlexBox>
  )
}
