import { createSlice } from '@reduxjs/toolkit'

import { paveApi } from 'api'

export interface UserState {
  userId: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  countryOfBirth: string | null
  contactInfo: any
  managementOrganizationId: string | null
  underReview: boolean | null
}
const initialState: UserState = {
  userId: null,
  email: null,
  firstName: null,
  lastName: null,
  managementOrganizationId: null,
  contactInfo: {},
  countryOfBirth: null,
  underReview: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const {
        id,
        firstName,
        lastName,
        email,
        managementOrganizationId,
        contactInfo,
        countryOfBirth,
        underReview,
      } = action.payload

      state.userId = id
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.countryOfBirth = countryOfBirth
      state.managementOrganizationId = managementOrganizationId
      state.contactInfo = contactInfo
      state.underReview = underReview
    },
    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      paveApi.endpoints.getUserById.matchFulfilled,
      (state, action) => {
        const {
          id,
          firstName,
          lastName,
          email,
          managementOrganizationId,
          contactInfo,
          countryOfBirth,
          underReview,
        } = action.payload

        state.userId = id
        state.email = email
        state.firstName = firstName
        state.lastName = lastName
        state.countryOfBirth = countryOfBirth
        state.managementOrganizationId = managementOrganizationId
        state.contactInfo = contactInfo
        state.underReview = underReview
      }
    )
  },
})

export const { setUser, resetUser } = userSlice.actions

export default userSlice.reducer
