import { keyBy, orderBy } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PortfolioSuggestedTrade } from 'types'

import { paveApi } from 'api'
import { Box, Button, Text } from 'components/common'

import SuggestedTradeItem from './SuggestedTradeItem'

type Props = {
  portfolioId: string
  suggestedTrades: PortfolioSuggestedTrade[]
}

export type ViewType = 'percentage' | 'shares' | 'value'

const sortByKey: Record<ViewType, keyof PortfolioSuggestedTrade> = {
  percentage: 'recommendedWeight',
  shares: 'shares',
  value: 'value',
}

function SuggestedTradesList({ portfolioId, suggestedTrades }: Props) {
  const [view, setView] = useState<ViewType>('percentage')
  const { data: assets } = paveApi.useGetAssetsQuery()
  const navigate = useNavigate()
  const onAssetClick = (symbol: string) => {
    navigate(`/asset/${symbol}?portfolioId=${portfolioId}`)
  }
  const assetsInfo = assets?.filter((asset) => {
    return suggestedTrades.some((trade) => trade.symbol === asset.symbol)
  })
  const assetsMap = keyBy(assetsInfo, 'symbol')

  const sortedTrades = orderBy(
    suggestedTrades,
    ['action', sortByKey[view]],
    ['asc', 'desc']
  )

  return (
    <Box width="100%">
      <Box>
        <Text
          fontSize="xs"
          lineHeight="xs"
          fontWeight="bold"
          color="coolGray.500"
        >
          View as:
        </Text>
        <Button
          variant="tertiary"
          size="xs"
          active={view === 'percentage'}
          onClick={() => setView('percentage')}
        >
          Percentage
        </Button>
        <Button
          variant="tertiary"
          size="xs"
          active={view === 'shares'}
          onClick={() => setView('shares')}
        >
          Share
        </Button>
        <Button
          variant="tertiary"
          size="xs"
          active={view === 'value'}
          onClick={() => setView('value')}
        >
          Value
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        gap="8px"
      >
        {sortedTrades.map((trade, index) => {
          return (
            <SuggestedTradeItem
              key={index}
              trade={trade}
              asset={assetsMap[trade.symbol]}
              onAssetClick={onAssetClick}
              viewType={view}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default SuggestedTradesList
