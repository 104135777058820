import {
  Account,
  AssetOverview,
  Home,
  Notification,
  Portfolio,
  Search,
  Settings,
} from 'pages'
import BuyListPage from 'pages/BuyListPage'
import ConnectedAccountsPage from 'pages/ConnectedAccountsPage'
import InvestmentTemplatesPage from 'pages/InvestmentTemplatesPage'
import UnderReviewPage from 'pages/UnderReviewPage'
import { Route, Routes } from 'react-router-dom'

import { usePageViews } from 'hooks/usePageViews'
import { useAppSelector } from 'store/storeHooks'

export default function HomeNavigator() {
  const { underReview } = useAppSelector((state: any) => state.user)
  usePageViews()
  return (
    <Routes>
      {underReview ? (
        <>
          <Route path="*" element={<UnderReviewPage />} />
        </>
      ) : (
        <>
          <Route index element={<Home />} />
          <Route path="/account/:externalId" element={<Account />} />
          <Route path="/portfolio/:externalId" element={<Portfolio />} />
          <Route path="/asset/:symbol" element={<AssetOverview />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/search" element={<Search />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/connected-accounts"
            element={<ConnectedAccountsPage />}
          />
          <Route
            path="/investment-templates"
            element={<InvestmentTemplatesPage />}
          />
          <Route path="/buy-lists" element={<BuyListPage />} />
        </>
      )}
    </Routes>
  )
}
