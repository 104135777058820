import { analytics } from 'analytics'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import {
  Box,
  Button,
  Dropdown,
  SearchBar,
  Text,
  ViewToggle,
} from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import CardSection from 'components/common/Section/CardSection'
import {
  AccountCard,
  AccountListItem,
  GridView,
  ListView,
} from 'components/partials'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { HOME_ADD_GROUP_CLICKED } from 'constants/track.constants'
import { openAddAccount } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

type Props = {
  accounts: ClientAccountDto[]
}

type SortByOptions = 'name' | 'AUM'

function AccountList({ accounts }: Props) {
  const [view, setView] = React.useState<'grid' | 'list'>('grid')
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState<SortByOptions>('name')

  const dispatch = useAppDispatch()

  const [accountsList, setAccountsList] = useState(accounts)
  const [page, setPage] = useState(1)
  const [accountsCount, setAccountsCount] = useState(accounts.length)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )
  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: accountsCount,
  })

  useEffect(() => {
    let clonedAccounts = jsonClone(accounts) as ClientAccountDto[]

    if (sortField === 'name') {
      clonedAccounts = orderBy(clonedAccounts, 'displayName')
    }
    if (sortField === 'AUM') {
      clonedAccounts = orderBy(clonedAccounts, 'balance', 'desc')
    }

    const filteredAccounts = clonedAccounts.filter((item) =>
      searchFilter(item, 'displayName', search)
    )
    const accountsToShow = filteredAccounts.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )

    setAccountsList(accountsToShow)
    setAccountsCount(filteredAccounts.length)
  }, [accounts, search, sortField, page, selectedItemsPerPageOption])

  const handleAddAccount = () => {
    dispatch(openAddAccount())
    analytics.track(HOME_ADD_GROUP_CLICKED)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <CardSection>
      <FlexBox flexWrap="wrap" gap={SPACE.sp24}>
        <Box display="grid" gap={SPACE.sp12}>
          <FlexBox justifyContent="space-between">
            <Box>
              <Text color="coolGray.600" fontSize="lg" fontWeight="700">
                Groups{' '}
              </Text>
              <Text color="coolGray.600" fontSize="lg" fontWeight="400">
                ({accountsCount})
              </Text>
            </Box>
            <Button variant="tertiary" size="xs" onClick={handleAddAccount}>
              Add Group
            </Button>
          </FlexBox>
          <Box
            width="100%"
            display="grid"
            justifyContent="space-between"
            gridTemplateColumns="repeat(17, 1fr)"
            gap={SPACE.sp12}
            alignItems="center"
          >
            <Box gridColumn="span 1">
              <ViewToggle view={view} onChange={setView} />
            </Box>
            <FlexBox gridColumn="span 2">
              <Dropdown
                options={itemsPerPageOptions}
                onChange={(option: any) => {
                  setSelectedItemsPerPageOption(option)
                  setPage(1)
                }}
                value={selectedItemsPerPageOption}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                  }),
                }}
              />
            </FlexBox>
            <Box gridColumn="span 14">
              <Box
                display="grid"
                gridTemplateColumns="repeat(7, 1fr)"
                justifyContent="space-between"
                gap="24px"
                alignItems="center"
              >
                <Box gridColumn="span 6">
                  <SearchBar
                    placeholder="Search by group name"
                    value={search}
                    onChange={handleSearch}
                  />
                </Box>
                <FlexBox
                  justifyContent="end"
                  alignItems="center"
                  minWidth="9rem"
                >
                  <Text
                    fontSize={FONT_SIZE.fs12}
                    lineHeight="xs"
                    fontWeight="bold"
                    color={COLOR.coolGray500}
                  >
                    Sort by:
                  </Text>
                  <Button
                    variant="tertiary"
                    size="xs"
                    active={sortField === 'name'}
                    onClick={() => setSortField('name')}
                  >
                    Name
                  </Button>
                  <Button
                    variant="tertiary"
                    size="xs"
                    active={sortField === 'AUM'}
                    onClick={() => setSortField('AUM')}
                  >
                    AUM
                  </Button>
                </FlexBox>
              </Box>
            </Box>
          </Box>
        </Box>
        {view === 'grid' && (
          <GridView
            gap="24px"
            data={accountsList}
            renderItem={(item) => <AccountCard key={item.id} {...item} />}
          />
        )}
        {view === 'list' && (
          <ListView
            gap={SPACE.sp12}
            data={accountsList}
            renderItem={(item) => <AccountListItem key={item.id} {...item} />}
          />
        )}
        <Pagination
          currentPage={page}
          handlePageChange={handlePageChange}
          itemsCount={accountsCount}
          itemsPerPage={selectedItemsPerPageOption.value}
        />
      </FlexBox>
    </CardSection>
  )
}

export default AccountList
