import { analytics } from 'analytics'
import { Form, Formik } from 'formik'
import { useState } from 'react'

import { UserDto } from 'types/dto/User.dto'

import { Box, ConfirmDialog, Switch, Text } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { SETTINGS_DISABLE_MFA_CONFIRMED } from 'constants/track.constants'
import { closeEditUserSettings } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

import {
  AccountDetailsFormFields,
  AccountPrimaryContactDetails,
  accountDetailsInitialValues,
} from '../Forms/AccountDetailsFormFields'
import MFASetupFlow from './MFASetupFlow'

type Props = {
  onEditUserSettings: (user: Partial<UserDto>) => void
  setUserMFAPreference: (body: any) => void
  user: any
  mfaEnabled: boolean
}

function EditUserPreferencesModal({
  onEditUserSettings,
  setUserMFAPreference,
  user,
  mfaEnabled,
}: Props) {
  const userInfo: AccountPrimaryContactDetails = {
    ...accountDetailsInitialValues,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    ...user?.contactInfo,
  }

  const [isMFAFlowOpen, setIsMFAFlowOpen] = useState(false)
  const [isDisableMFAConfirmOpen, setIsDisableMFAConfirmOpen] = useState(false)

  const dispatch = useAppDispatch()
  const { showEditUserSettings } = useAppSelector((state) => state.modals)

  const onFormSubmit = (values: AccountPrimaryContactDetails) => {
    try {
      const { firstName, lastName, email, ...contactInfo } = values
      onEditUserSettings({
        ...user,
        firstName: firstName,
        lastName: lastName,
        email: email,
        contactInfo,
      })
      dispatch(closeEditUserSettings())
    } catch (error) {
      console.error(error)
    }
  }

  const handleMFAToggle = () => {
    if (!mfaEnabled) {
      setIsMFAFlowOpen(true)
    } else {
      setIsDisableMFAConfirmOpen(true)
    }
  }
  return (
    <>
      <SlideOutMenu
        isOpen={showEditUserSettings}
        onClose={() => dispatch(closeEditUserSettings())}
        size="lg"
        title="Advisor Preferences"
        buttonText="Save Preferences"
        formId="edit-advisor-preferences"
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            padding="0px"
            gap="12px"
            width="100%"
          >
            <Text
              fontWeight="bold"
              fontSize="lg"
              lineHeight="lg"
              color="coolGray.600"
            >
              Contact Information
            </Text>
            <Box width="100%">
              <Formik
                onSubmit={onFormSubmit}
                initialValues={userInfo}
                enableReinitialize
              >
                <Form id="edit-advisor-preferences">
                  <AccountDetailsFormFields />
                </Form>
              </Formik>
            </Box>
          </Box>
        </Box>
        <Box width="100%" mt="32px" display="flex" flexDirection="column">
          <Text
            fontWeight="bold"
            fontSize="lg"
            lineHeight="lg"
            color="coolGray.500"
          >
            Security
          </Text>
          <Text
            fontWeight={600}
            fontSize="md"
            lineHeight="md"
            color="coolGray.500"
          >
            Multi-Factor Authentication
          </Text>
          <label>
            <Box
              display="flex"
              justifyContent="space-between"
              alignContent="center"
            >
              <Text fontSize="sm">
                MFA increases security by requiring a code from an authenticator
                app at log in
              </Text>
              <Switch checked={mfaEnabled} readOnly onClick={handleMFAToggle} />
            </Box>
          </label>
        </Box>
      </SlideOutMenu>
      <MFASetupFlow
        open={isMFAFlowOpen}
        onClose={() => setIsMFAFlowOpen(false)}
        setUserMFAPreference={setUserMFAPreference}
      />
      <ConfirmDialog
        isOpen={isDisableMFAConfirmOpen}
        onCancel={() => {
          setIsDisableMFAConfirmOpen(false)
        }}
        onConfirm={() => {
          setUserMFAPreference({ mfaEnabled: false })
          setIsDisableMFAConfirmOpen(false)
          analytics.track(SETTINGS_DISABLE_MFA_CONFIRMED)
        }}
        confirmButtonText="Disable MFA"
        title="Disable MFA"
        description="Are you sure you want to disable MFA for your account? You will be able to re-enable it at any time"
        colorScheme="red"
      />
    </>
  )
}
export default EditUserPreferencesModal
