import { Avatar } from '@chakra-ui/react'

import { ReactComponent as SchwabLogo } from 'assets/icons/SCHWAB.svg'
import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  accountNumber: string
}

function SchwabAccountItem({ accountNumber }: Props) {
  return (
    <FlexBox
      justifyContent="space-between"
      gap={SPACE.sp8}
      borderBottom={`1px solid ${COLOR.coolGray300}`}
      padding={SPACE.sp16}
      border={`1px solid ${COLOR.coolGray300}`}
      borderRadius={SPACE.sp8}
    >
      <Box display="flex" flexDirection="row" gap={SPACE.sp8}>
        <Box>
          <Avatar size="xs" icon={<SchwabLogo />} bg={COLOR.white} />
        </Box>
        <Box display="flex" flexDirection="column">
          <Text
            fontSize={FONT_SIZE.fs14}
            fontWeight="600"
            color={COLOR.coolGray600}
            as="p"
          >
            {accountNumber}
          </Text>
        </Box>
      </Box>
    </FlexBox>
  )
}

export default SchwabAccountItem
