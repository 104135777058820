import styled from '@emotion/styled'
import { useField } from 'formik'
import Select, { createFilter } from 'react-select'

import { LabelText, Text } from 'components/common'
import DropdownIndicator from 'components/common/Dropdown/DropDownIndicator'

interface Props {
  [x: string]: any
  name: string
}

function Dropdown({ label, theme, disabled, ...props }: Props) {
  const [field, meta, helpers] = useField(props)

  const { options } = props
  const { touched, error } = meta
  const { setValue } = helpers
  return (
    <Container>
      {label && <LabelText>{label}</LabelText>}
      <CustomSelect
        className="select-container"
        classNamePrefix="select"
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        filterOption={createFilter({ ignoreAccents: true })}
        styles={{
          control: (base: any) => ({
            ...base,
            minHeight: 52,
          }),
          ...props.styles,
        }}
        options={options}
        value={
          options
            ? options.find((option: any) => option.value === field.value)
            : ''
        }
        name={field.name}
        onChange={(option: any) => setValue(option.value)}
        menuPlacement="auto"
        placeholder={props.placeholder}
      />
      {touched && error && (
        <Text
          color="status.error"
          fontWeight="medium"
          fontSize="xs"
          lineHeight="xxs"
          mt="sm"
          mb={0}
          as="p"
        >
          {error}
        </Text>
      )}
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
`
const CustomSelect = styled(Select)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  color: ${({ theme }) => theme.colors.black};

  .select__control {
    border-color: ${({ theme }) => theme.colors.coolGray[300]};
  }

  .select__control--is-focused {
    border-color: ${({ theme }) => theme.colors.purple[700]};
    box-shadow: none;
    :hover {
      border-color: ${({ theme }) => theme.colors.purple[700]};
    }
  }

  .select__menu {
    box-shadow: ${({ theme }) => theme.shadows.md};
  }

  .select__option {
    padding: 12px 16px;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.xxs};
  }

  .select__option--is-focused {
    background-color: ${({ theme }) => theme.colors.coolGray[50]};
  }

  .select__option--is-selected {
    background-color: ${({ theme }) => theme.colors.blue[50]};
    color: ${({ theme }) => theme.colors.blue[500]};
  }
`

export default Dropdown
