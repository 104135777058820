/**
 * Old conversions:
 * xxs = fs10,
 * xs = fs12,
 * sm = fs14,
 * md = fs16,
 * lg = fs18,
 * xl = fs20,
 * xxl = fs24
 * 3xl = fs28
 */
export const FONT_SIZE = {
  fs6: '.375rem',
  fs8: '.5rem',
  fs10: '.625rem',
  fs12: '0.75rem',
  fs14: '0.875rem',
  fs16: '1rem',
  fs18: '1.125rem',
  fs20: '1.25rem',
  fs22: '1.375rem',
  fs24: '1.5rem',
  fs28: '1.75rem',
  fs32: '2rem',
}
