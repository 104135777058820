import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { analytics } from 'analytics'
import React from 'react'

import { Box, Button, Text } from 'components/common'
import { SETTINGS_ENABLE_MFA_COMPLETED } from 'constants/track.constants'

type Props = {
  isOpen: boolean
  onClose: () => void
  setUserMFAPreference: (body: any) => void
}

function MFASetupFinishModal({ isOpen, onClose, setUserMFAPreference }: Props) {
  const handleFinish = () => {
    analytics.track(SETTINGS_ENABLE_MFA_COMPLETED)
    setUserMFAPreference({ mfaEnabled: true })
    onClose()
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link Authenticator App</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" alignItems="center" gap="12px" mb="14px">
              <Box color="emerald.600">
                <CheckCircleIcon width={40} height={40} />
              </Box>
              <Text as="p" fontWeight="500" fontSize="24px" mt="12px">
                Success!
              </Text>
            </Box>
            <Text as="p">Your authenticator app was successfully linked.</Text>
          </ModalBody>

          <ModalFooter>
            <Box display="flex" width="100%" flexDirection="column" gap="12px">
              <Button
                color="purple.800"
                style={{ width: '100%' }}
                onClick={handleFinish}
              >
                Enable MFA
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MFASetupFinishModal
