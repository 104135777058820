import styled from '@emotion/styled'
import { orderBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'

import { ASSET_LIST_TYPE, Asset } from 'types'

import {
  Box,
  Button,
  Dropdown,
  SearchBar,
  Text,
  ViewToggle,
} from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import CardSection from 'components/common/Section/CardSection'
import {
  AssetCard,
  AssetListItem,
  GridView,
  ListView,
} from 'components/partials'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

type Props = {
  assetType: ASSET_LIST_TYPE
  initialAssets: Asset[]
  portfolioId: string
  rightContent?: ReactElement | null
}

type AssetSortOption = 'total' | 'change' | 'ticker'
type DisplayView = 'grid' | 'list'

function AssetList({
  assetType,
  initialAssets,
  portfolioId,
  rightContent,
}: Props) {
  const [view, setView] = useState<DisplayView>('grid')
  const [search, setSearch] = useState('')
  const [sortField, setSortField] = useState<AssetSortOption>('total')

  const [assets, setAssets] = useState<Asset[]>(initialAssets)

  const [assetCount, setAssetCount] = useState(initialAssets.length)
  const [page, setPage] = useState(1)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )

  const titles = {
    [ASSET_LIST_TYPE.UNSUPPORTED]: 'Unsupported Assets',
    [ASSET_LIST_TYPE.SUPPORTED]: 'Assets',
    [ASSET_LIST_TYPE.MONEY_MARKET]: 'Cash Equivalents',
  }

  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: assetCount,
  })

  useEffect(() => {
    let newAssets = jsonClone(initialAssets) as Asset[]

    function processAssets(assets: Asset[]): Asset[] {
      switch (sortField) {
        case 'total':
          assets = orderBy(
            assets,
            [(item) => (!item.totalValue ? -Infinity : item.totalValue)],
            'desc'
          )
          break
        case 'change':
          assets = orderBy(
            assets,
            [
              (item) =>
                !item?.priceDetails?.dayGain
                  ? -Infinity
                  : item.priceDetails.dayGain,
            ],
            'desc'
          )
          break
        case 'ticker':
          assets = orderBy(assets, 'symbol')
          break
      }

      const filteredAssets = assets.filter(
        (item) =>
          item?.shares > 0 &&
          (searchFilter(item, 'name', search) ||
            searchFilter(item, 'symbol', search))
      )

      return filteredAssets
    }

    newAssets = processAssets(newAssets)

    const filteredAssets = [...newAssets]

    const assetsToShow = filteredAssets.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )

    setAssets(assetsToShow)
    setAssetCount(filteredAssets.length)
  }, [initialAssets, selectedItemsPerPageOption, search, page, sortField])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <CardSection
      borderColor={
        assetType === ASSET_LIST_TYPE.UNSUPPORTED
          ? COLOR.red600
          : COLOR.coolGray300
      }
    >
      <>
        <Header>
          <div>
            <Text
              color="coolGray.600"
              fontSize={FONT_SIZE.fs16}
              lineHeight="md"
              fontWeight="bold"
            >
              {titles[assetType]}
              <Text
                color="coolGray.600"
                fontSize={FONT_SIZE.fs16}
                lineHeight="md"
              >
                &nbsp;({assetCount})
              </Text>
            </Text>
          </div>
          {rightContent}
        </Header>
        <MenuBar>
          <FlexBox gap={SPACE.sp24}>
            <ViewToggle view={view} onChange={setView} />
            <FlexBox>
              <Dropdown
                options={itemsPerPageOptions}
                onChange={(option: any) => {
                  setSelectedItemsPerPageOption(option)
                  setPage(1)
                }}
                value={selectedItemsPerPageOption}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                  }),
                }}
              />
            </FlexBox>
            <Box width="20rem">
              <SearchBar
                placeholder="Search assets"
                value={search}
                onChange={handleSearch}
              />
            </Box>
          </FlexBox>
          <Box paddingLeft={SPACE.sp6}>
            <Text
              fontSize={FONT_SIZE.fs12}
              lineHeight="xs"
              fontWeight="bold"
              color="coolGray.500"
              padding={`${SPACE.sp8} ${SPACE.sp6}`}
            >
              Sort by:
            </Text>
            <Button
              variant="tertiary"
              size="xs"
              active={sortField === 'total'}
              onClick={() => setSortField('total')}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Total Value
            </Button>
            {assetType !== ASSET_LIST_TYPE.MONEY_MARKET && (
              <Button
                variant="tertiary"
                size="xs"
                active={sortField === 'change'}
                onClick={() => setSortField('change')}
              >
                % Change
              </Button>
            )}
            <Button
              variant="tertiary"
              size="xs"
              active={sortField === 'ticker'}
              onClick={() => setSortField('ticker')}
            >
              Ticker
            </Button>
          </Box>
        </MenuBar>
        {view === 'grid' && (
          <GridView
            marginTop={SPACE.sp24}
            gap={4}
            data={assets}
            renderItem={(item) => (
              <AssetCard
                key={item.id ?? item.symbol}
                asset={item}
                portfolioId={portfolioId}
              />
            )}
          />
        )}
        {view === 'list' && (
          <ListView
            data={assets}
            header={
              <FlexBox
                width="100%"
                p={`${SPACE.sp16} ${SPACE.sp20}`}
                fontSize={FONT_SIZE.fs10}
                lineHeight="xxs"
                fontWeight="medium"
                color={COLOR.coolGray500}
                gap={SPACE.sp4}
              >
                <Box flex={1}>
                  <Text>SYMBOL</Text>
                </Box>
                <Box flex={2}>
                  <Text>NAME</Text>
                </Box>
                <Box flex={1}>
                  <Text>PRICE</Text>
                </Box>
                <Box flex={1}>
                  <Text>QUANTITY</Text>
                </Box>
                <Box flex={1}>
                  <Text>TOTAL VALUE</Text>
                </Box>
                <Box flex={1}>
                  <Text>% PORTFOLIO</Text>
                </Box>
                <Box flex={1}>
                  <Text>DAY GAIN %</Text>
                </Box>
                <Box flex={1}>
                  <Text>DAY GAIN VALUE</Text>
                </Box>
              </FlexBox>
            }
            renderItem={(item) => (
              <AssetListItem
                key={item.id}
                asset={item}
                portfolioId={portfolioId}
              />
            )}
          />
        )}
        <Box marginTop={SPACE.sp16}>
          <Pagination
            currentPage={page}
            handlePageChange={handlePageChange}
            itemsCount={assetCount}
            itemsPerPage={selectedItemsPerPageOption.value}
          />
        </Box>
      </>
    </CardSection>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.625rem;
`

const MenuBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`

export default AssetList
