import { Image } from '@chakra-ui/react'
import styled from '@emotion/styled'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CogIcon,
  ExclamationCircleIcon,
  HandIcon,
} from '@heroicons/react/outline'
import { MenuAlt2Icon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Portfolio, PortfolioLinkType } from 'types'

import iBLogo from 'assets/icons/IBKR.svg'
import paveLogo from 'assets/icons/PAVE.svg'
import plaidLogo from 'assets/icons/PLAID.svg'
import schwabLogo from 'assets/icons/SCHWAB.svg'
import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { PaveSecuritiesTooltip } from 'components/partials'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'
import {
  getIbAccountStatus,
  getPortfolioLinkType,
  isManualPortfolio,
} from 'utils/portfolioUtil'

type Props = {
  icon: React.ReactNode
  portfolio: Portfolio
  totalAssets: number
}

function PortfolioListItemTrigger({ portfolio, icon, totalAssets }: Props) {
  const [isPortfolioComplete, setIsPortfolioComplete] = useState(false)
  const { id, displayName, value, performance, brokerageAccounts } = portfolio
  const brokerName: string | undefined = brokerageAccounts?.[0]?.brokerName
  const linkType = getPortfolioLinkType(portfolio)
  const status = getIbAccountStatus(portfolio)
  const portfolioHasAssets = totalAssets > 0

  // TODO: Refactor this when we start passing in the icon in the response
  let brokerLogo: any = undefined
  if (linkType === PortfolioLinkType.PaveSecurities) {
    brokerLogo = paveLogo
  } else if (linkType === PortfolioLinkType.InteractiveBrokers) {
    brokerLogo = iBLogo
  } else if (linkType === PortfolioLinkType.Plaid) {
    brokerLogo = plaidLogo
  } else if (linkType === PortfolioLinkType.CharlesSchwab) {
    brokerLogo = schwabLogo
  }

  const navigate = useNavigate()
  const onPortfolioClick = () => {
    navigate(`/portfolio/${id}`)
  }

  const CompletePortfolioBody = () => {
    return (
      <Box
        display="flex"
        flex={4}
        alignItems="center"
        justifyContent="end"
        gap={SPACE.sp8}
      >
        <>
          <Box
            width={SPACE.sp15}
            height={SPACE.sp15}
            color={portfolioHasAssets ? COLOR.coolGray500 : COLOR.coolGray400}
          >
            <MenuAlt2Icon />
          </Box>
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color={portfolioHasAssets ? COLOR.coolGray600 : COLOR.coolGray400}
          >
            {totalAssets} Assets
          </Text>
        </>
        <Box
          width={SPACE.sp16}
          height={SPACE.sp16}
          color={portfolioHasAssets ? COLOR.coolGray600 : COLOR.coolGray400}
        >
          {icon}
        </Box>
      </Box>
    )
  }

  const IncompletePortfolioBody = ({ text }: { text: string }) => {
    return (
      <Box
        display="flex"
        flex={4}
        alignItems="center"
        gap={SPACE.sp8}
        color={COLOR.red600}
        justifyContent="end"
      >
        <Box width="15px" height="15px">
          <ExclamationCircleIcon />
        </Box>
        <Text fontSize={FONT_SIZE.fs12} fontWeight={500}>
          {text}
        </Text>
      </Box>
    )
  }
  const Body = () => {
    const portfolioIsPendingSettingsCompletion =
      !portfolio?.isInvestmentPreferenceCompleted &&
      !portfolio?.isThirdPartyManaged

    useEffect(() => {
      if (!portfolioIsPendingSettingsCompletion) {
        setIsPortfolioComplete(true)
      }
    }, [portfolioIsPendingSettingsCompletion])

    if (status === 'C') {
      return <IncompletePortfolioBody text="Closed Account" />
    }

    if (status === 'R') {
      return <IncompletePortfolioBody text="Rejected Account" />
    }

    if (status === 'P' || status === 'N') {
      return <IncompletePortfolioBody text="Pending Account Opening" />
    }
    if (portfolioIsPendingSettingsCompletion) {
      return (
        <FlexBox flex={1} gap={SPACE.sp12} justifyContent="flex-end">
          <Box width="1rem" height="1rem" color={COLOR.amber600}>
            <CogIcon />
          </Box>
          <Text
            fontWeight="medium"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.amber600}
            style={{ whiteSpace: 'nowrap' }}
          >
            Pending Settings Completion
          </Text>
        </FlexBox>
      )
    }
    return <CompletePortfolioBody />
  }

  return (
    <TriggerContainer>
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        onClick={onPortfolioClick}
        flex={15}
        gap={SPACE.sp8}
      >
        <Box display="flex" flex={1}>
          <Text
            fontWeight="700"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray600}
          >
            {displayName}
          </Text>
        </Box>
        {isPortfolioComplete && (
          <>
            <Box
              display="flex"
              flex={1}
              gap={SPACE.sp8}
              flexDirection="row"
              alignItems="center"
              padding="0px"
              height="24px"
            >
              <Text
                fontWeight="medium"
                fontSize={FONT_SIZE.fs12}
                lineHeight="xs"
                color={COLOR.coolGray600}
              >
                {formatAsCurrency(value)}
              </Text>
              {!isManualPortfolio(portfolio) && (
                <Text
                  fontSize={FONT_SIZE.fs10}
                  fontWeight="700"
                  color={performance >= 0 ? COLOR.emerald600 : COLOR.red600}
                  display="flex"
                  alignItems="center"
                >
                  {performance >= 0 ? (
                    <ArrowUpIcon width={10} height={10} />
                  ) : (
                    <ArrowDownIcon width={10} height={10} />
                  )}
                  {Math.abs(isNaN(performance) ? 0 : performance)}%
                </Text>
              )}
            </Box>
            <Box display="flex" flex={1} alignItems="center" gap={SPACE.sp8}>
              <Box>
                {brokerLogo ? (
                  <Image
                    src={brokerLogo}
                    alt="broker logo"
                    height="18px"
                    width="18px"
                  />
                ) : (
                  <HandIcon height="18px" width="18px" />
                )}
              </Box>

              <Text
                fontWeight="medium"
                fontSize={FONT_SIZE.fs12}
                lineHeight="xs"
                color={COLOR.coolGray600}
              >
                <Box display="flex" gap="xs">
                  {brokerName ? brokerName : 'Manual Connection'}
                  {linkType === PortfolioLinkType.PaveSecurities && (
                    <PaveSecuritiesTooltip />
                  )}
                </Box>
              </Text>
            </Box>
          </>
        )}
      </Box>

      <Body />
    </TriggerContainer>
  )
}

const TriggerContainer = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0.9rem;
`

export default PortfolioListItemTrigger
