import styled from '@emotion/styled'
import { MenuIcon, ViewGridIcon } from '@heroicons/react/solid'
import React, { MouseEvent } from 'react'

type ViewType = 'grid' | 'list'

type Props = {
  view: ViewType
  onChange: (view: ViewType) => void
}

function ViewToggle({ view, onChange }: Props) {
  const handleChange = (e: MouseEvent<HTMLButtonElement>) => {
    onChange(e.currentTarget.value as ViewType)
  }
  return (
    <Container>
      <Button value="grid" onClick={handleChange} active={view === 'grid'}>
        <ViewGridIcon width={16} height={16} />
      </Button>
      <Separator />
      <Button value="list" onClick={handleChange} active={view === 'list'}>
        <MenuIcon width={16} height={16} />
      </Button>
    </Container>
  )
}

const Container = styled.div`
  width: 80px;
  height: 40px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.coolGray[300]};
  border-radius: 8px;
`

const Separator = styled.div`
  width: 16px;
  height: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray[300]};
  transform: rotate(90deg);
`

const Button = styled.button<{ active: boolean }>`
  width: 16px;
  height: 16px;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.purple[800]};
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  :hover {
    color: ${({ theme }) => theme.colors.purple[900]};
  }
`

export default ViewToggle
