/**
 * Constants used for Segment tracking analytics
 * Tracking event names should be created here and where needed in the app
 * Doc: https://segment.com/docs/connections/spec/track/
 */

// Login
export const LOGIN_SUBMITTED = 'Login Submitted'
export const LOGIN_SUBMITTED_SUCCESS = 'Login Submitted Success'
export const LOGIN_SUBMITTED_FAILED = 'Login Submitted Failed'

// Logout
export const LOGOUT_CLICKED = 'Logout Clicked'

// Signup
export const SIGNUP_STARTED = 'Signup Started'
export const SIGNUP_SUBMITTED = 'Signup Submitted'
export const SIGNUP_COMPLETED = 'Signup Completed'
export const SIGNUP_FAILED = 'Signup Failed'
export const SIGNUP_EMAIL_CONFIRMED = 'Signup Email Confirmed'

// Forgot Password
export const FORGOT_PASSWORD_CODE_REQUESTED = 'Forgot Password Code Requested'
export const FORGOT_PASSWORD_RESET_SUBMITTED = 'Forgot Password Reset Submitted'
export const FORGOT_PASSWORD_RESET_SUBMITTED_SUCCESS =
  'Forgot Password Reset Submitted Success'
export const FORGOT_PASSWORD_RESET_SUBMITTED_FAILED =
  'Forgot Password Reset Submitted Failed'

// Home
export const HOME_ADD_GROUP_CLICKED = 'Home Add Group Clicked'
export const HOME_ADD_GROUP_SUBMITTED = 'Home Add Group Submitted'
export const HOME_ADD_GROUP_SUCCESS = 'Home Add Group Success'
export const HOME_ADD_GROUP_FAILED = 'Home Add Group Failed'

// Connected Accounts Page
export const CONNECTED_ACCOUNTS_ADD_GROUP_CLICKED =
  'Connected Accounts Add Group Clicked'
export const CONNECTED_ACCOUNTS_ADD_GROUP_SUBMITTED =
  'Connected Accounts Add Group Submitted'
export const CONNECTED_ACCOUNTS_ADD_GROUP_SUCCESS =
  'Connected Accounts Add Group Success'
export const CONNECTED_ACCOUNTS_ADD_GROUP_FAILED =
  'Connected Accounts Add Group Failed'

export const LINK_SUB_ACCOUNT_TO_GROUP_MODAL_OPENED =
  'Link Sub Account To Group Modal Opened'
export const LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED =
  'Link Sub Account To Group Modal Submitted'
export const LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_SUCCESS =
  'Link Sub Account To Group Modal Submitted Success'
export const LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_FAILED =
  'Link Sub Account To Group Modal Submitted Failed'

// Account - Group
export const GROUP_ADD_PORTFOLIO_CLICKED = 'Group Add Portfolio Clicked'
export const GROUP_EDIT_GENERAL_INFORMATION_CLICKED =
  'Group Edit General Information Clicked'
export const GROUP_EDIT_GENERAL_INFORMATION_SUBMITTED =
  'Group Edit General Information Submitted'
export const GROUP_EDIT_CLIENTS_CLICKED = 'Group Edit Clients Clicked' // Unused
export const GROUP_EDIT_CLIENTS_SUBMITTED = 'Group Edit Clients Submitted'
export const GROUP_EDIT_PRIMARY_CONTACT_CLICKED =
  'Group Edit Primary Contact Clicked'
export const GROUP_EDIT_PRIMARY_CONTACT_SUBMITTED =
  'Group Edit Primary Contact Submitted'
export const GROUP_EDIT_DELETE_CANCELED = 'Group Edit Delete Canceled'
export const GROUP_EDIT_DELETE_CONFIRMED = 'Group Edit Delete Confirmed'
export const GROUP_EDIT_DELETE_FAILED = 'Group Edit Delete Failed'
export const GROUP_EDIT_DELETE_STARTED = 'Group Edit Delete Started'
export const GROUP_EDIT_DELETE_SUCCESS = 'Group Edit Delete Success'

// Portfolio - Creation
export const PORTFOLIO_CREATE_STARTED = 'Portfolio Create Started'
export const PORTFOLIO_CREATE_PLAID_LINK_STARTED =
  'Portfolio Create Plaid Link Started'
export const PORTFOLIO_CREATE_PLAID_LINK_COMPLETED =
  'Portfolio Create Plaid Link Completed'
export const PORTFOLIO_CREATE_IB_SUBMITTED = 'Portfolio Create IB Submitted'
export const PORTFOLIO_CREATE_PLAID_SUBMITTED =
  'Portfolio Create Plaid Submitted'
export const PORTFOLIO_CREATE_MANUAL_SUBMITTED =
  'Portfolio Create Manual Submitted'

// Portfolio - Editing
export const PORTFOLIO_FINALIZE_INVESTMENT_PREFERENCES_CLICKED =
  'Portfolio Finalize Investment Preferences Clicked'
export const PORTFOLIO_EDIT_CLICKED = 'Portfolio Edit Clicked'
export const PORTFOLIO_REFRESH_CLICKED = 'Portfolio Refresh Clicked'
export const PORTFOLIO_EDIT_ASSETS_CLICKED = 'Portfolio Edit Assets Clicked'
export const PORTFOLIO_EDIT_ASSETS_SUBMITTED = 'Portfolio Edit Assets Submitted'
export const PORTFOLIO_INVALID_FORM_DATA_SUBMITTED =
  'Portfolio Invalid Form Data Submitted'
export const PORTFOLIO_DELETE_CLICKED = 'Portfolio Delete Clicked'
export const PORTFOLIO_DELETE_CONFIRMED = 'Portfolio Delete Confirmed'

// Portfolio - Trading
export const PORTFOLIO_APPROVE_TRADES_CONFIRMATION_MODAL_CLOSED =
  'Portfolio Approve Trades Confirmation Modal Closed'
export const PORTFOLIO_APPROVE_TRADES_CONFIRMATION_MODAL_OPENED =
  'Portfolio Approve Trades Confirmation Modal Opened'
export const PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED =
  'Portfolio Approve Trades Automation Submitted'
export const PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED_SUCCESS =
  'Portfolio Approve Trades Automation Submitted Success'
export const PORTFOLIO_APPROVE_TRADES_AUTOMATION_SUBMITTED_FAILED =
  'Portfolio Approve Trades Automation Submitted Failed'
export const PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED =
  'Portfolio Approve Trades Manual Submitted'
export const PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED_SUCCESS =
  'Portfolio Approve Trades Manual Submitted Success'
export const PORTFOLIO_APPROVE_TRADES_MANUAL_SUBMITTED_FAILED =
  'Portfolio Approve Trades Manual Submitted Failed'
export const PORTFOLIO_APPROVE_TRADES_TRADE_NOW_MODAL_CLOSED =
  'Portfolio Approve Trades Trade Now Modal Closed'
export const PORTFOLIO_APPROVE_TRADES_TRADE_NOW_MODAL_OPENED =
  'Portfolio Approve Trades Trade Now Modal Opened'

// Template
export const INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED =
  'Investment Template Create Form Submitted'
export const INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_SUCCESS =
  'Investment Template Create Form Submitted Success'
export const INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED =
  'Investment Template Create Form Submitted Failed'

export const INVESTMENT_TEMPLATE_EDIT_FORM_CONFIRMATION_OPENED =
  'Investment Template Edit Form Confirmation Opened'
export const INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED =
  'Investment Template Edit Form Submitted'
export const INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_SUCCESS =
  'Investment Template Edit Form Submitted Success'
export const INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_FAILED =
  'Investment Template Edit Form Submitted Failed'

export const INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_OPENED =
  'Investment Template Create Form Delete Confirmation Opened'
export const INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED =
  'Investment Template Create Form Delete Confirmation Submitted'
export const INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_SUCCESS =
  'Investment Template Create Form Delete Confirmation Submitted Success'
export const INVESTMENT_TEMPLATE_EDIT_FORM_DELETE_CONFIRMATION_SUBMITTED_FAILED =
  'Investment Template Create Form Delete Confirmation Submitted Failed'

export const INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED =
  'Investment Template Bulk Apply Submitted'
export const INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_SUCCESS =
  'Investment Template Bulk Apply Submitted Success'
export const INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_CONFLICT =
  'Investment Template Bulk Apply Submitted Conflict'
export const INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_FAILED =
  'Investment Template Bulk Apply Submitted Failed'

export const INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED =
  'Investment Template Bulk Remove Submitted'
export const INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_SUCCESS =
  'Investment Template Bulk Remove Submitted Success'
export const INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_CONFLICT =
  'Investment Template Bulk Remove Submitted Conflict'
export const INVESTMENT_TEMPLATE_BULK_REMOVE_SUBMITTED_FAILED =
  'Investment Template Bulk Remove Submitted Failed'

// Settings
export const SETTINGS_EDIT_ADVISOR_INFORMATION_CLICKED =
  'Settings Edit Advisor Information Clicked'
export const SETTINGS_EDIT_ADVISOR_INFORMATION_SUBMITTED =
  'Settings Edit Advisor Information Submitted'
export const SETTINGS_ENABLE_MFA_STARTED = 'Settings Enable MFA Started'
export const SETTINGS_ENABLE_MFA_COMPLETED = 'Settings Enable MFA Completed'
export const SETTINGS_DISABLE_MFA_CONFIRMED = 'Settings Disable MFA Confirmed'

// Account and Settings
export const EDIT_CONNECTIONS_MODAL_OPENED = 'Edit Connections Modal Opened'

// Buy Lists
export const BUY_LIST_CREATE_FORM_SUBMITTED = 'Buy List Create Form Submitted'
export const BUY_LIST_CREATE_FORM_SUBMITTED_SUCCESS =
  'Buy List Create Form Submitted Success'
export const BUY_LIST_CREATE_FORM_SUBMITTED_FAILED =
  'Buy List Create Form Submitted Failed'

export const BUY_LIST_EDIT_FORM_SUBMITTED = 'Buy List Edit Form Submitted'
export const BUY_LIST_EDIT_FORM_SUBMITTED_SUCCESS =
  'Buy List Edit Form Submitted Success'
export const BUY_LIST_EDIT_FORM_SUBMITTED_FAILED =
  'Buy List Edit Form Submitted Failed'
