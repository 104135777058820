import styled from '@emotion/styled/macro'
import { CheckIcon } from '@heroicons/react/solid'
import { hideVisually } from 'polished'
import React, { InputHTMLAttributes } from 'react'

type Props = InputHTMLAttributes<HTMLInputElement>

function Checkbox({ className, checked, ...props }: Props) {
  return (
    <Container className={className}>
      <HiddenCheckbox type="checkbox" checked={checked} {...props} />
      <StyledCheckbox checked={checked} {...props}>
        <Icon />
      </StyledCheckbox>
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled(CheckIcon)`
  color: ${({ theme }) => theme.colors.white};
`

const HiddenCheckbox = styled.input<Props>`
  ${hideVisually()}
`

const StyledCheckbox = styled.div<Props>`
  display: inline-block;
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  background: ${({ theme, checked }) =>
    checked ? theme.colors.blue[400] : theme.colors.white};
  border: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.colors.blue[400] : theme.colors.coolGray[300]};
  border-radius: 4px;
  cursor: pointer;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    border: 2px solid ${({ theme }) => theme.colors.blue[400]};
  }

  ${HiddenCheckbox}:disabled + & {
    background: ${({ theme }) => theme.colors.coolGray[100]};
    cursor: not-allowed;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

export default Checkbox
