/**
 * DEPRECATED
 *
 * Values under systems will be migrated under the constants directory or
 * renamed or removed
 */
export const spacing: any = [
  0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 56, 64, 80, 96, 112, 128, 144,
  160, 176, 192, 208, 224, 240, 256, 288, 320, 384,
]
spacing.xxs = spacing[1]
spacing.xs = spacing[2]
spacing.sm = spacing[3]
spacing.md = spacing[4]
spacing.lg = spacing[5]
spacing.xl = spacing[6]
spacing.xxl = spacing[7]
