import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { Box, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function GlobalMacroSensitivityPopOver() {
  return (
    <Popover trigger="hover" openDelay={0} placement="auto-start">
      <PopoverTrigger>
        <Box>
          <InformationCircleIcon
            color={COLOR.coolGray600}
            height={SPACE.sp18}
            width={SPACE.sp18}
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <Box>
          <PopoverArrow />
          <PopoverBody>
            <FlexBox m={SPACE.sp12} flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray800}
                fontWeight={700}
                fontSize={FONT_SIZE.fs18}
                lineHeight="3xl"
                as="h2"
              >
                Global Macro Sensitivity
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                The world’s macroeconomic backdrop can have a significant impact
                on any portfolio, even if it’s only made up of US stocks.
                Commodity prices, economic policy, and economic health can all
                play a role in shaping the fundamentals and technicals of an
                asset.
              </Text>
              <Text color={COLOR.coolGray700} fontWeight="regular">
                If Global Macro Sensitivity is left on Default, Pave Pro will
                automatically adjust client portfolios such that their exposure
                to global macroeconomic factors is as close as possible to that
                of their benchmark. If you would like to tilt Pave Pro towards
                favoring assets that are more sensitive to the macroeconomic
                environment, select Overweight. For the opposite, select
                Underweight.
              </Text>
            </FlexBox>
          </PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default GlobalMacroSensitivityPopOver
