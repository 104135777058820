import styled from '@emotion/styled'
import {
  ColorProps,
  DisplayProps,
  FlexboxProps,
  SpaceProps,
  TypographyProps,
  color,
  display,
  flexbox,
  space,
  typography,
} from 'styled-system'

export type Props = TypographyProps &
  ColorProps &
  SpaceProps &
  DisplayProps &
  FlexboxProps & {
    as?: React.ElementType | keyof JSX.IntrinsicElements
    wrapText?: boolean
  }

const Text = styled.span<Props>`
  ${color}
  ${display}
  ${flexbox}
  ${space}
  ${typography}
  ${({ wrapText }) =>
    wrapText && 'white-space: normal; word-break: break-word;'}
`

export default Text
