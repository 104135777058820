import { useState } from 'react'

import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import FlexBox from '../FlexBox/FlexBox'
import Text from '../Text/Text'
import CheckListItem from './CheckListItem'

export type ChecklistOption = {
  label: string
  value: string
  extraData?: { [key: string]: unknown }
}

type Props = {
  allOptions: ChecklistOption[]
  headerLabel: string
  selectedOptions: ChecklistOption[]
  setSelectedOptions: React.Dispatch<React.SetStateAction<ChecklistOption[]>>
}
function CheckList({
  allOptions,
  headerLabel,
  selectedOptions,
  setSelectedOptions,
}: Props) {
  const [initialOptions] = useState<ChecklistOption[]>(allOptions)
  const handleCheckboxChange = (newOption: ChecklistOption) => {
    const updatedSelectedOptions = [...selectedOptions]
    const index = updatedSelectedOptions.findIndex(
      (option) => option.label === newOption.label
    )
    if (index !== -1) {
      updatedSelectedOptions.splice(index, 1)
    } else {
      updatedSelectedOptions.push(newOption)
    }
    setSelectedOptions(updatedSelectedOptions)
  }
  return (
    <>
      <Text
        color={COLOR.coolGray600}
        fontSize={FONT_SIZE.fs14}
        fontWeight={500}
      >
        {headerLabel} ({selectedOptions.length}/{initialOptions.length})
      </Text>
      <FlexBox
        flexDirection="column"
        alignItems="flex-start"
        overflow="scroll"
        gap={SPACE.sp8}
      >
        {initialOptions.map((item) => (
          <CheckListItem
            item={item}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={selectedOptions.includes(item)}
          />
        ))}
      </FlexBox>
    </>
  )
}
export default CheckList
