import { useEffect, useState } from 'react'

import { BuyListAsset } from 'types'

import { Box, Dropdown, SearchBar, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { searchFilter } from 'utils/searchFilter'

import BuyListAssetRow from './BuyListAssetRow'
import { DropdownOptionString } from './DropdownWithTags'

type Props = {
  assets: BuyListAsset[]
}

function BuyListAssetsTable({ assets }: Props) {
  const hasUnsupportedAsset = assets?.find((asset) => !asset.isSupported)
  const assetsPerPage = 12
  const [assetList, setAssetList] = useState(assets)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [assetsCount, setAssetsCount] = useState(assets.length)
  const assetTypeOptions = [
    { label: 'All Assets', value: 'all' },
    { label: 'Supported', value: 'supported' },
    { label: 'Unsupported', value: 'unsupported' },
  ]
  const [selectedAssetType, setSelectedAssetType] = useState(
    assetTypeOptions[0].value
  )
  const handlePageChange = (page: number) => {
    setPage(page)
  }
  useEffect(() => {
    let clonedAssets = jsonClone(assets) as BuyListAsset[]

    if (selectedAssetType !== 'all') {
      const isSelectedAssetTypeSupported = selectedAssetType === 'supported'
      clonedAssets = clonedAssets.filter(
        (asset) => asset.isSupported === isSelectedAssetTypeSupported
      )
    }

    const filteredAssets = clonedAssets.filter(
      (item) =>
        searchFilter(item, 'name', search) ||
        searchFilter(item, 'symbol', search)
    )
    const assetsToShow = filteredAssets.slice(
      (page - 1) * assetsPerPage,
      page * assetsPerPage
    )

    setAssetList(assetsToShow)
    setAssetsCount(filteredAssets.length)
  }, [assets, search, selectedAssetType, page])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <Box>
      <FlexBox
        width="100%"
        minWidth="24rem"
        gap={SPACE.sp12}
        alignItems="center"
        flexDirection="row"
      >
        <Box flex={4}>
          <Box gridColumn="span 6">
            <SearchBar
              placeholder="Search by asset name or ticker"
              value={search}
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Dropdown
            options={assetTypeOptions}
            onChange={(option: unknown) => {
              const dropdownOption = option as DropdownOptionString
              setSelectedAssetType(dropdownOption.value)
              setPage(1)
            }}
            value={selectedAssetType}
            styles={{
              control: (base: any) => ({
                ...base,
                borderRadius: '0.25rem',
                height: '2.5rem',
              }),
            }}
          />
        </Box>
      </FlexBox>
      {hasUnsupportedAsset && (
        <Text
          color="status.error"
          fontWeight="medium"
          fontSize={FONT_SIZE.fs12}
          lineHeight="xxs"
          mt={SPACE.sp8}
          mb={0}
          as="p"
        >
          One or more of the assets in your list are unsupported by Pave Pro.
          These assets will not be included in client portfolios using this Buy
          List.
        </Text>
      )}
      <table
        style={{
          margin: `${SPACE.sp16} 0`,
          width: '100%',
        }}
      >
        <thead style={{ width: '100%' }}>
          <tr style={{ width: '100%' }}>
            <th style={{ textAlign: 'left' }}>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs14}
                fontWeight={500}
              >
                Ticker
              </Text>
            </th>
            <th style={{ textAlign: 'left' }}>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs14}
                fontWeight={500}
              >
                Name
              </Text>
            </th>
          </tr>
        </thead>

        <tbody>
          {assetList?.map((asset, index) => (
            <BuyListAssetRow key={index} asset={asset} />
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={page}
        handlePageChange={handlePageChange}
        itemsCount={assetsCount}
        itemsPerPage={12}
      />
    </Box>
  )
}

export default BuyListAssetsTable
