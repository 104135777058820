import { useState } from 'react'

import { paveApi } from 'api'
import { Box, Text } from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import { ChecklistOption } from 'components/common/CheckList/CheckList'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { PageLayout } from 'components/partials'
import AddInvestmentTemplateSlideIn from 'components/partials/AddInvestmentTemplateSlideIn'
import { DropdownOptionString } from 'components/partials/DropdownWithTags'
import EditInvestmentTemplateSlideIn from 'components/partials/EditInvestmentTemplateSlideIn'
import InvestmentTemplatesList from 'components/partials/InvestmentTemplates/InvestmentTemplatesList'
import InvestmentTemplatesPortfolioList from 'components/partials/InvestmentTemplates/InvestmentTemplatesPortfolioList'
import ApplyTemplateModal from 'components/partials/Settings/ApplyTemplateModal'
import RemoveTemplateModal from 'components/partials/Settings/RemoveTemplateModal'
import { useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function InvestmentTemplatesPage() {
  const { managementOrganizationId } = useAppSelector(
    (state: any) => state.user
  )
  const { data: clientAccounts, isLoading: isLoadingClientAccounts } =
    paveApi.useGetClientAccountsSummaryQuery(
      {
        managementOrgId: managementOrganizationId,
      } || {},
      {
        skip: !managementOrganizationId,
        refetchOnMountOrArgChange: true,
      }
    )

  const portfolios = clientAccounts?.flatMap((c) =>
    c.portfolios.map((p) => {
      return {
        ...p,
        automation: p.investmentPreference?.automation,
        groupName: c?.displayName,
        groupId: c.id,
        templateName:
          p.investmentPreferencesTemplate?.displayName ?? 'No Template',
      }
    })
  )

  const { data: templatesResponse, isLoading: isLoadingTemplates } =
    paveApi.useGetManagementOrganizationInvestmentTemplatesQuery(
      managementOrganizationId || null,
      {
        skip: !managementOrganizationId,
        refetchOnMountOrArgChange: true,
      }
    )

  const statusOptions = [
    { value: '', label: 'All Statuses' },
    { value: 'incomplete', label: 'Pending' },
    { value: 'complete', label: 'Complete' },
  ]

  const allGroupsOption = [{ value: '', label: 'All Groups' }]
  const groupOptions = allGroupsOption.concat(
    clientAccounts?.map((group) => {
      return {
        value: group.displayName,
        label: group.displayName,
      }
    }) as DropdownOptionString[]
  )

  const defaultTemplateOptions = [
    { value: '', label: 'All Templates' },
    { value: 'No Template', label: 'No Template' },
  ]
  const templateOptions = defaultTemplateOptions.concat(
    templatesResponse?.investmentPreferencesTemplates.map((template) => {
      return {
        value: template.displayName,
        label: template.displayName,
      }
    }) as DropdownOptionString[]
  )

  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
  const showCreateTemplateSlideIn = () => {
    setShowCreateTemplateModal(true)
  }
  const hideCreateTemplateSlideIn = () => setShowCreateTemplateModal(false)

  const [selectedPortfolios, setSelectedPortfolios] = useState<
    ChecklistOption[]
  >([])
  const [isApplyTemplateOpen, setIsApplyTemplateOpen] = useState(false)
  const [isRemoveTemplateOpen, setIsRemoveTemplateOpen] = useState(false)

  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: 'Investment Templates',
      route: `#`,
    },
  ]

  const handleApplyTemplate = () => {
    setIsApplyTemplateOpen(true)
  }

  const handleRemoveTemplate = () => {
    setIsRemoveTemplateOpen(true)
  }

  const clearSelectedPortfolios = () => {
    setSelectedPortfolios([])
  }

  const isLoading = isLoadingClientAccounts || isLoadingTemplates
  return (
    <PageLayout showLoader={isLoading}>
      <BreadcrumbBar paths={breadcrumbs} />
      <Box marginLeft={SPACE.sp32}>
        <Text
          color={COLOR.coolGray600}
          fontWeight="700"
          fontSize={FONT_SIZE.fs14}
          lineHeight="sm"
          as="h1"
        >
          Investment Templates
        </Text>
        <Text
          color={COLOR.coolGray600}
          fontWeight="700"
          fontSize={FONT_SIZE.fs28}
          lineHeight="3xl"
          as="h2"
        >
          Create & Manage Templates
        </Text>
      </Box>
      <FlexBox flexDirection="row" gap={SPACE.sp24} padding={SPACE.sp32}>
        <Box flex={1}>
          <InvestmentTemplatesList
            templates={templatesResponse?.investmentPreferencesTemplates || []}
            openCreateTemplate={showCreateTemplateSlideIn}
          />
        </Box>
        <Box flex={4}>
          <InvestmentTemplatesPortfolioList
            portfolios={portfolios || []}
            statusOptions={statusOptions}
            groupOptions={groupOptions}
            templateOptions={templateOptions}
            handleApplyTemplate={handleApplyTemplate}
            handleRemoveTemplate={handleRemoveTemplate}
            selectedPortfolios={selectedPortfolios}
            setSelectedPortfolios={setSelectedPortfolios}
          />
        </Box>
      </FlexBox>
      <AddInvestmentTemplateSlideIn
        showModal={showCreateTemplateModal}
        closeModal={hideCreateTemplateSlideIn}
        managementOrganizationId={managementOrganizationId}
      />
      <EditInvestmentTemplateSlideIn />
      <ApplyTemplateModal
        isOpen={isApplyTemplateOpen}
        onClose={() => {
          setIsApplyTemplateOpen(false)
          clearSelectedPortfolios()
        }}
        selectedPortfolios={selectedPortfolios}
        setSelectedPortfolios={setSelectedPortfolios}
        templates={templatesResponse?.investmentPreferencesTemplates || []}
      />
      <RemoveTemplateModal
        isOpen={isRemoveTemplateOpen}
        onClose={() => {
          setIsRemoveTemplateOpen(false)
          clearSelectedPortfolios()
        }}
        selectedPortfolios={selectedPortfolios}
        setSelectedPortfolios={setSelectedPortfolios}
      />
    </PageLayout>
  )
}

export default InvestmentTemplatesPage
