import { BuyListAsset } from 'types'

import { Box, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import BuyListAssetsTable from '../BuyListAssetsTable'
import BuyListAssetPopOver from '../PopOver/BuyListAssetPopOver'

type Props = {
  assets: BuyListAsset[]
}
export default function BuyListReadOnlyView({ assets }: Props) {
  return (
    <Card marginBottom={SPACE.sp20}>
      <Box
        display="flex"
        alignItems="center"
        gap={SPACE.sp4}
        marginBottom={SPACE.sp12}
      >
        <Text
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs14}
          fontWeight={600}
        >
          Assets
        </Text>
        <BuyListAssetPopOver />
      </Box>
      {assets.length > 0 && <BuyListAssetsTable assets={assets} />}
    </Card>
  )
}
