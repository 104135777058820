import { ToastType } from 'types'

import FlexBox from 'components/common/FlexBox/FlexBox'
import Text from 'components/common/Text/Text'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  title: string
  description?: string
  type: ToastType
}

export function CustomToast({ title, description, type }: Props) {
  let borderLeftColor

  switch (type) {
    case 'Error':
      borderLeftColor = COLOR.red600
      break
    case 'Success':
      borderLeftColor = COLOR.emerald600
      break
    default:
      borderLeftColor = COLOR.purple800
      break
  }

  return (
    <FlexBox
      backgroundColor={COLOR.white}
      borderLeft={SPACE.sp6}
      borderLeftColor={borderLeftColor}
      borderRadius={SPACE.sp4}
      borderStyle="solid"
      flexDirection="column"
      marginTop={SPACE.sp20}
      padding="1rem 1.125rem"
      width="25rem"
    >
      <Text fontSize={FONT_SIZE.fs16} fontWeight={700} lineHeight={SPACE.sp24}>
        {title}
      </Text>
      <Text
        fontSize={FONT_SIZE.fs14}
        fontWeight={400}
        lineHeight={SPACE.sp20}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {description}
      </Text>
    </FlexBox>
  )
}
