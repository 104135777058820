export enum API_ERRORS {
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  USER_NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
  USERNAME_ALREADY_EXISTS = 'UsernameExistsException',
  USER_ALREADY_CONFIRMED = 'UserAlreadyConfirmedException',
  CODE_MISMATCH = 'CodeMismatchException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  CONSTRAINT_LIMIT_EXCEEDED = 'ConstraintLimitExceeded',
}
