import styled from '@emotion/styled'
import {
  ColorProps,
  SpaceProps,
  TypographyProps,
  color,
  space,
  typography,
} from 'styled-system'

type Props = SpaceProps &
  TypographyProps & {
    value: number
    sign?: '$' | '%'
  }

type BaseProps = TypographyProps & ColorProps & SpaceProps

export default function NumberStatus(props: Props) {
  const { value, sign } = props
  let color = 'coolGray.800'
  if (value > 0) color = 'green.600'
  if (value < 0) color = 'red.500'

  return (
    <BaseNumberStatus color={color} {...props}>
      {sign === '$' && '$'}
      {value > 0 && '+'}
      {value}
      {sign === '%' && '%'}
    </BaseNumberStatus>
  )
}

const BaseNumberStatus = styled.span<BaseProps>`
  ${color}
  ${space}
  ${typography}
`
