/**
 * @function formatStringLabelWithCount
 * Creates the correct formatted string with the count + label (pluralized or otherwise)
 * @param singularLabel base label for string
 * @param pluralLabel plural label for string
 * @param number counter to help format label
 * @returns string
 */
export const formatStringLabelWithCount = (
  singularLabel: string,
  pluralLabel: string,
  number: number
): string => {
  return `${number} ${number === 1 ? singularLabel : pluralLabel}`
}
