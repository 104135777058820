import React, { useCallback, useEffect } from 'react'

import { Asset } from 'types'

import { Box, Button, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import AddAssetForm from './AddAssetForm'
import AssetRow from './AssetRow'

type Props = {
  assets: Asset[]
  setAssets: (assets: Asset[]) => void
  bottomRef?: any
  readOnly?: boolean
  showTradableSwitches?: boolean
}

function RequiredAssetsTable({
  assets,
  setAssets,
  bottomRef,
  readOnly = false,
  showTradableSwitches = false,
}: Props) {
  const handleChange = (target: HTMLInputElement, index: number) => {
    const { name, value } = target
    const updatedAssets = [...assets]

    updatedAssets[index] = Object.assign({}, updatedAssets[index], {
      [name]: name === 'symbol' ? value : parseFloat(value),
    })

    setAssets(updatedAssets)
  }

  const handleAdd = (newAsset: Asset) => {
    setShowForm(false)

    if (assets.some((asset) => asset.symbol === newAsset.symbol)) {
      return
    }
    setAssets([...assets, newAsset])
  }

  const handleDelete = (deletingAsset: Asset) => {
    setAssets([
      ...assets.filter(({ symbol }) => symbol !== deletingAsset.symbol),
    ])
  }

  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [editingAsset, setEditingAsset] = React.useState<string | null>(null)

  const scrollDown = useCallback(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [bottomRef])

  useEffect(() => {
    if (showForm) {
      scrollDown()
    }
  }, [showForm, scrollDown])
  return (
    <Box>
      <table style={{ margin: `${SPACE.sp16} 0`, width: '100%' }}>
        {assets.length > 0 && (
          <thead style={{ width: '100%' }}>
            <tr style={{ width: '100%' }}>
              <th style={{ textAlign: 'left' }}>
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs14}
                  fontWeight={500}
                >
                  SYMBOL
                </Text>
              </th>
              <th style={{ textAlign: 'left' }}>
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs14}
                  fontWeight={500}
                >
                  SHARES
                </Text>
              </th>
              {showTradableSwitches || (
                <>
                  <th style={{ textAlign: 'left', width: '6rem' }}>
                    <Text
                      color={COLOR.coolGray600}
                      fontSize={FONT_SIZE.fs14}
                      fontWeight={500}
                    >
                      TRADABLE
                    </Text>
                  </th>
                  <th />
                </>
              )}
            </tr>
          </thead>
        )}

        <tbody>
          {assets.map((asset, index) => {
            const isEditingAsset = asset.symbol === editingAsset
            return (
              <AssetRow
                key={index}
                asset={asset}
                isEditingAsset={isEditingAsset}
                handleChange={(target: any) => handleChange(target, index)}
                handleDelete={handleDelete}
                readOnly={readOnly}
                showTradableSwitches={showTradableSwitches}
              />
            )
          })}
          <tr>
            <td colSpan={4}>
              {showForm && (
                <AddAssetForm scrollDown={() => {}} handleAdd={handleAdd} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {!readOnly && !showForm && (
        <Button
          variant="tertiary"
          size="xs"
          onClick={() => {
            setShowForm(true)
            setEditingAsset(null)
          }}
        >
          {assets.length === 0 ? '+ Add Asset' : '+ Add Another Asset'}
        </Button>
      )}
    </Box>
  )
}

export default RequiredAssetsTable
