import styled from '@emotion/styled'
import { ButtonHTMLAttributes } from 'react'

import { Size } from 'types/styleTypes'

import { COLOR } from 'styles/constants/color'

type Variant = 'primary' | 'secondary' | 'tertiary' | 'danger'

type ButtonSizeConfig = {
  height: string
  padding: string
  fontSize: string
}

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: Size
  variant?: Variant
  active?: boolean
  ref?: any
  width?: string
  minWidth?: string
}

const BUTTON_SIZE_CONFIGURATIONS: Record<Size, ButtonSizeConfig> = {
  xxs: {
    height: '32px',
    padding: '8px 6px',
    fontSize: '10px',
  },
  xs: {
    height: '32px',
    padding: '8px 6px',
    fontSize: '12px',
  },
  sm: {
    height: '39px',
    padding: '12px 16px',
    fontSize: '14px',
  },
  md: {
    height: '44px',
    padding: '12px 16px',
    fontSize: '16px',
  },
  lg: {
    height: '52px',
    padding: '16px 24px',
    fontSize: '18px',
  },
}

function Button({
  variant,
  size = 'md',
  active = true,
  width,
  minWidth,
  ...props
}: Props) {
  switch (variant) {
    case 'primary':
      return (
        <ColoredButton size={size} active={active} width={width} {...props} />
      )
    case 'secondary':
      return (
        <PlainButton
          size={size}
          active={active}
          width={width}
          minWidth={minWidth}
          {...props}
          color={COLOR.coolGray600}
          style={{
            border: active
              ? `1px solid ${COLOR.purple800}`
              : `1px solid ${COLOR.coolGray300}`,
          }}
        />
      )
    case 'tertiary':
      return (
        <TransparentButton
          size={size}
          active={active}
          width={width}
          {...props}
        />
      )
    case 'danger':
      return (
        <DangerButton size={size} active={active} width={width} {...props} />
      )

    default:
      return (
        <ColoredButton size={size} active={active} width={width} {...props} />
      )
  }
}
const BaseButton = styled.button<Props>`
  height: ${({ size, style }) =>
    style?.height || BUTTON_SIZE_CONFIGURATIONS[size || 'md'].height};
  padding: ${({ size, style }) =>
    style?.padding || BUTTON_SIZE_CONFIGURATIONS[size || 'md'].padding};
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ size }) => BUTTON_SIZE_CONFIGURATIONS[size || 'md'].fontSize};
  text-align: center;
  cursor: pointer;
`
const ColoredButton = styled(BaseButton)`
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.purple[700]};
  :hover {
    background-color: ${({ theme }) => theme.colors.purple[800]};
    color: ${({ theme }) => theme.colors.white};
  }
  :disabled {
    opacity: 0.4;
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
  width: ${({ width }) => width};
`
const PlainButton = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  color: ${({ theme }) => theme.colors.gray[600]};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    background-color: ${({ theme }) => theme.colors.gray[50]};
    color: ${({ theme }) => theme.colors.gray[600]};
  }
  :disabled {
    opacity: 0;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    color: ${({ theme }) => theme.colors.gray[300]};
  }
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
`
const TransparentButton = styled(BaseButton)`
  border: none;
  color: ${({ theme }) => theme.colors.purple[800]};
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  :hover {
    color: ${({ theme }) => theme.colors.purple[900]};
  }
  :disabled {
    opacity: 0.4;
    border: none;
    color: ${({ theme }) => theme.colors.purple[800]};
  }
  width: ${({ width }) => width};
`
const DangerButton = styled(BaseButton)`
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red[700]};
  :hover {
    background-color: ${({ theme }) => theme.colors.red[800]};
    color: ${({ theme }) => theme.colors.white};
  }
  :disabled {
    opacity: 0.4;
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
  width: ${({ width }) => width};
`

export default Button
