import { IconButton } from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { SyntheticEvent } from 'react'

import { Box, Button } from 'components/common'
import { SPACE } from 'styles/constants/space'
import { white } from 'styles/system/colors'

type Props = {
  currentPage: number
  handlePageChange: (page: number) => void
  itemsCount: number
  itemsPerPage: number
}

const VISIBLE_PAGES_PER_SIDE = 3

function Pagination({
  currentPage,
  handlePageChange,
  itemsCount,
  itemsPerPage,
}: Props) {
  const totalPages = Math.ceil(itemsCount / itemsPerPage)

  const paginationButtons = generatePaginationButtonGroup(
    totalPages,
    currentPage,
    VISIBLE_PAGES_PER_SIDE
  )

  function generatePaginationButtonGroup(
    totalPages: number,
    currentPage: number,
    visiblePages: number
  ) {
    let startPage, endPage
    if (totalPages <= visiblePages + 1) {
      startPage = 1
      endPage = totalPages
    } else {
      // Handle edge cases where the current page is near the beginning or end
      if (currentPage <= visiblePages) {
        startPage = 1
      } else if (currentPage >= totalPages - visiblePages + 1) {
        startPage = totalPages - visiblePages + 1
      } else {
        // Otherwise, center the visible pages around the current page
        startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2))
      }
      // Ensure the end page is within bounds
      endPage = Math.min(totalPages, startPage + visiblePages - 1)
    }

    // Include "..." if necessary:
    const buttons = []
    if (startPage > 1) {
      buttons.push(
        <Button
          key="pagination-start"
          variant="secondary"
          size="xs"
          minWidth={SPACE.sp32}
          active={1 === currentPage}
          onClick={() => handlePageChange(1)}
        >
          1
        </Button>
      )
      if (startPage > 2) {
        buttons.push('...')
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={`pagination-${i}`}
          variant="secondary"
          size="xs"
          minWidth={SPACE.sp32}
          active={i === currentPage}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault()
            handlePageChange(i)
          }}
        >
          {i}
        </Button>
      )
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        buttons.push('...')
      }
      buttons.push(
        <Button
          key={totalPages}
          variant="secondary"
          size="xs"
          minWidth={SPACE.sp32}
          active={totalPages === currentPage}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault()
            handlePageChange(totalPages)
          }}
        >
          {totalPages}
        </Button>
      )
    }

    return buttons
  }

  return (
    <>
      {totalPages > 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gap="8px" alignItems="center">
            <IconButton
              width={SPACE.sp32}
              height={SPACE.sp32}
              icon={<ArrowLeftIcon height="16px" width="16px" />}
              aria-label="Previous page"
              backgroundColor={white}
              disabled={currentPage === 1}
              onClick={(e: SyntheticEvent) => {
                e.preventDefault()
                handlePageChange(currentPage - 1)
              }}
            />
            {paginationButtons}
            <IconButton
              width={SPACE.sp32}
              height={SPACE.sp32}
              icon={<ArrowRightIcon height="16px" width="16px" />}
              aria-label="Next page"
              backgroundColor={white}
              disabled={currentPage === totalPages}
              onClick={(e: SyntheticEvent) => {
                e.preventDefault()
                handlePageChange(currentPage + 1)
              }}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default Pagination
