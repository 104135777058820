import { ExclamationIcon } from '@heroicons/react/outline'
import { useCallback, useEffect } from 'react'
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'

import { BrokerageAccount } from 'types'

import { paveApi } from 'api'
import { Box, Button, Text } from 'components/common'
import { useCustomToast } from 'hooks/useCustomToast'

type Props = {
  brokerageAccount?: BrokerageAccount
  refetchPortfolio: () => void
}

function UpdatePlaidLink({ brokerageAccount, refetchPortfolio }: Props) {
  const accessToken = brokerageAccount?.plaidAccountInfo?.accessToken
  const [createLinkToken, { data }] = paveApi.useCreatePlaidLinkTokenMutation()
  const { showErrorToast } = useCustomToast()

  useEffect(() => {
    if (accessToken) {
      createLinkToken({ accessToken })
    } else {
      showErrorToast('An error occurred attempting to reconnect your account.')
    }
  }, [createLinkToken, accessToken, showErrorToast])

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      refetchPortfolio()
    },
    [refetchPortfolio]
  )

  const { open, ready } = usePlaidLink({
    token: data?.link_token || null,
    onSuccess,
    onExit: (err, metadata) => {
      // The user exited the Link flow.
      if (err != null) {
        showErrorToast('An error occurred reconnecting your account.')
        console.error(err)
      }
    },
  })

  return (
    <>
      <Box>
        <Box display="flex" color="red.600" gap="10px">
          <ExclamationIcon width={24} height={24} />
          <Text fontSize="lg" fontWeight="bold" mb={4} color="gray.600">
            {brokerageAccount?.brokerName} Connection Lost
          </Text>
        </Box>
        <Box>
          Connection to your {brokerageAccount?.brokerName} account was lost.
          Please reconnect your account to refresh your portfolio information.
          We will not provide new suggested trades until your account connection
          is reestablished.
        </Box>

        <Box mt="24px">
          <Button
            color="purple.800"
            onClick={() => {
              open()
            }}
            disabled={!ready}
          >
            Reconnect Account
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default UpdatePlaidLink
