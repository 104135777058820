import { cloneDeep, get, isEmpty, isEqual, keyBy } from 'lodash'

import { TradeStatus } from '../enums'
import { SuggestedTradesStatus } from 'enums/SuggestedTradesStatusEnum'
import {
  ACCOUNT_STATUS,
  Asset,
  CONNECTION_TYPE,
  IBAccountState,
  IBAccountStatus,
  InvestmentPreference,
  InvestmentPreferenceAction,
  PLAID_ACCOUNT_ERROR_CODE,
  PLAID_ACCOUNT_STATUS,
  Portfolio,
  PortfolioLinkType,
  TradingStatus,
} from 'types'
import {
  PortfolioFormPayload,
  UpdatePreferencesRequest,
} from 'types/portfolioTypes'

import {
  NEEDS_TRADES_UPDATE_STATUS,
  PAVE_SECURITIES_BROKER_NAME,
  TRADES_SCHEDULED_STATUS,
} from 'constants/portfolio.constants'

export const calcAssetWeight = (
  assetPrice: number,
  assetShares: number,
  portfolioValue: number
) => {
  return (assetPrice * assetShares) / portfolioValue
}

export const calcPortfolioValue = (portfolio: Portfolio) => {
  const assetsValue = portfolio.assets.reduce(
    (acc: number, asset: any) => acc + asset.price * asset.shares,
    0
  )
  const totalValue = assetsValue + portfolio.availableCash || 0
  return totalValue
}

export const convertPortfolioToWeightedAssets = (portfolio: Portfolio) => {
  const totalValue = calcPortfolioValue(portfolio)

  const assets: any = cloneDeep(portfolio.assets)
  assets.push({
    symbol: 'CASH_USD',
    name: 'U.S. Dollar',
    shares: 1,
    price: portfolio.availableCash || 0,
    tradablePercentage: 1,
  })

  return assets.map((asset: any) => ({
    symbol: asset.symbol,
    weight: calcAssetWeight(asset.price, asset.shares, totalValue),
  }))
}

export const validateSuggestedTrades = (
  suggestedTrades: any[],
  portfolio: Portfolio,
  assets: Asset[] = []
) => {
  let valid = true
  let message = {
    title: '',
    description: '',
  }
  const newSuggestedTrades = suggestedTrades.map((trade) => ({
    ...trade,
    shares: parseFloat(trade.shares),
  }))

  newSuggestedTrades.forEach((trade) => {
    if (!isFinite(trade.shares) || trade.shares <= 0) {
      valid = false
      message.title = 'Invalid shares amount'
      message.description = `Invalid shares amount for ${trade.symbol}`
    }
  })

  if (!valid) {
    return {
      valid,
      message,
    }
  }

  const tradeSymbols = newSuggestedTrades.map((trade) => trade.symbol)
  const tradeAssets = assets.filter((asset) =>
    tradeSymbols.includes(asset.symbol)
  )
  const tradeAssetsMap = keyBy(tradeAssets, 'symbol')

  const portfolioAssetsMap = keyBy(portfolio.assets, 'symbol')

  const sells = newSuggestedTrades.filter((trade) => trade.action === 'sell')
  const buys = newSuggestedTrades.filter((trade) => trade.action === 'buy')

  sells.forEach((sell) => {
    const asset = portfolioAssetsMap[sell.symbol]
    if (asset.shares < sell.shares) {
      valid = false
      message.title = 'Cannot sell more than you own'
      message.description = `Please sell fewer than or equal to the number of shares you own`
    }
  })

  if (!valid) {
    return {
      valid,
      message,
    }
  }

  const buysValue = buys.reduce(
    (acc, trade) =>
      acc + trade.shares * get(tradeAssetsMap, [trade.symbol, 'price']),
    0
  )
  const sellsValue = sells.reduce(
    (acc, trade) =>
      acc + trade.shares * get(tradeAssetsMap, [trade.symbol, 'price']),
    0
  )

  if (buysValue > sellsValue + portfolio.availableCash) {
    valid = false
    message.title = 'Not enough available cash'
    message.description = `Please sell assets or reduce the number of shares you would like to buy`
  }

  return {
    valid,
    message,
  }
}

export const getNewPortfolioFromSuggestedTrades = (
  portfolio: Portfolio,
  suggestedTrades: any,
  assets: Asset[] = []
) => {
  const tradeSymbols = suggestedTrades.map((trade: any) => trade.symbol) || []
  const tradeAssets = assets.filter((asset) =>
    tradeSymbols.includes(asset.symbol)
  )
  const tradeAssetsMap = keyBy(tradeAssets, 'symbol')

  const newPortfolio = cloneDeep(portfolio)

  suggestedTrades.forEach((trade: any) => {
    const assetIndex = newPortfolio.assets.findIndex(
      (asset: any) => asset.symbol === trade.symbol
    )
    const tradeAsset = tradeAssetsMap[trade.symbol]
    if (assetIndex !== -1) {
      if (trade.action === 'sell') {
        newPortfolio.assets[assetIndex].shares -= trade.shares
      } else if (trade.action === 'buy') {
        newPortfolio.assets[assetIndex].shares += trade.shares
      }
    } else {
      newPortfolio.assets.push({
        ...tradeAsset,
        shares: trade.shares,
      })
    }
  })

  const portfolioValue = calcPortfolioValue(portfolio)
  const newPortfolioValue = calcPortfolioValue(newPortfolio)
  let availableCash
  if (newPortfolioValue >= portfolioValue) {
    availableCash =
      portfolio.availableCash - (newPortfolioValue - portfolioValue)
  } else {
    availableCash =
      portfolio.availableCash + (portfolioValue - newPortfolioValue)
  }
  newPortfolio.availableCash = availableCash
  return newPortfolio
}

export const shouldAllowRefresh = (portfolio: Portfolio) => {
  let refresh = false
  if (!portfolio.isThirdPartyManaged) {
    if (portfolio?.accountStatus === ACCOUNT_STATUS.OPEN) {
      refresh = true
    }
  }
  return refresh
}

export const shouldLockPortfolio = (portfolio: Portfolio) => {
  let locked = false
  if (portfolio.needsTradeExecution) {
    locked = true
    return locked
  }

  for (const trade of portfolio.trades) {
    if (
      trade.status !== TradeStatus.EXECUTED &&
      trade.status !== TradeStatus.ABANDONED
    ) {
      locked = true
      break
    }
  }

  if (getIsAccountDisconnected(portfolio?.accountStatus)) {
    locked = true
  }

  return locked
}

export const shouldFreezeHoldings = (porfolio: Portfolio) => {
  let freezeHoldings = false
  const ibAccountStatus = getIbAccountStatus(porfolio)
  if (ibAccountStatus === 'O') {
    freezeHoldings = true
  }
  return freezeHoldings
}

export const getIbAccountStatus = (
  portfolio?: Portfolio
): IBAccountStatus | undefined => {
  const status = portfolio?.brokerageAccounts?.find(
    (account) => account?.ibAccountInfo?.accountStatus
  )
  return status?.ibAccountInfo?.accountStatus
}

export const getSchwabAccountStatus = (
  portfolio?: Portfolio
): ACCOUNT_STATUS | undefined => {
  const status = portfolio?.brokerageAccounts?.find(
    (account) => account?.accountInfo?.status
  )
  return status?.accountInfo?.status
}

export const getIbAccountState = (
  portfolio?: Portfolio
): IBAccountState | undefined => {
  const state = portfolio?.brokerageAccounts?.find(
    (account) => account?.ibAccountInfo?.accountState
  )
  return state?.ibAccountInfo?.accountState
}

export const getIbUsername = (portfolio?: Portfolio): string | undefined => {
  const state = portfolio?.brokerageAccounts?.find(
    (account) => account?.ibAccountInfo?.username
  )
  return state?.ibAccountInfo?.username
}

/**
 * @function getPortfolioLinkType
 * Gets the portfolio link type based on the brokerage account type
 * There should only be one brokerage account per portfolio
 * @returns PortfolioLinkType
 */
export const getPortfolioLinkType = (
  portfolio: Portfolio | undefined | null
): PortfolioLinkType => {
  let linkType = PortfolioLinkType.None
  if (isEmpty(portfolio?.brokerageAccounts)) {
    return PortfolioLinkType.None
  }

  portfolio?.brokerageAccounts.forEach((account) => {
    switch (account.connectionType) {
      case CONNECTION_TYPE.IB:
        linkType =
          account.brokerName === PAVE_SECURITIES_BROKER_NAME
            ? PortfolioLinkType.PaveSecurities
            : PortfolioLinkType.InteractiveBrokers
        break
      case CONNECTION_TYPE.SCHWAB:
        linkType = PortfolioLinkType.CharlesSchwab
        break
      case CONNECTION_TYPE.PLAID:
        linkType = PortfolioLinkType.Plaid
        break
    }
  })
  return linkType
}

/**
 * @function isIbPortfolio
 * Determines if the portfolio is linked using Interactive Brokers, currently
 * leverages getPortfolioLinkType()
 */
export const isIbPortfolio = (portfolio: Portfolio): boolean => {
  const linkType = getPortfolioLinkType(portfolio)
  return (
    linkType === PortfolioLinkType.InteractiveBrokers ||
    linkType === PortfolioLinkType.PaveSecurities
  )
}

/**
 * @function isSchwabRIAPortfolio
 * Determines if the portfolio is linked using Schwab RIA, currently
 * leverages getPortfolioLinkType()
 */
export const isSchwabRIAPortfolio = (portfolio: Portfolio): boolean => {
  const linkType = getPortfolioLinkType(portfolio)
  return linkType === PortfolioLinkType.CharlesSchwab
}

/**
 * @function isPlaidPortfolio
 * Determines if the portfolio is linked using Plaid, currently
 * leverages getPortfolioLinkType()
 */
export const isPlaidPortfolio = (portfolio: Portfolio): boolean => {
  const linkType = getPortfolioLinkType(portfolio)
  return linkType === PortfolioLinkType.Plaid
}

/**
 * @function isManualPortfolio
 * Determines if the portfolio is linked using Plaid, currently
 * leverages getPortfolioLinkType()
 */
export const isManualPortfolio = (portfolio: Portfolio): boolean => {
  const linkType = getPortfolioLinkType(portfolio)
  return linkType === PortfolioLinkType.None
}

/**
 * @function isAutomationSupportedPortfolio
 * Determines if the portfolio is linked using Interactive Brokers or
 * Charles Schwab
 */
export const isAutomationSupportedPortfolio = (
  portfolio: Portfolio | undefined | null
): boolean => {
  if (!portfolio) return false
  return isIbPortfolio(portfolio) || isSchwabRIAPortfolio(portfolio)
}

export const canDeletePortfolio = (portfolio: Portfolio | null | undefined) => {
  if (portfolio?.isTrading) return false
  if (!isEmpty(portfolio?.brokerageAccounts)) {
    for (const account of portfolio!.brokerageAccounts) {
      if (isIbPortfolio(portfolio as Portfolio)) {
        const accountStatus = account?.ibAccountInfo?.accountStatus
        if (
          accountStatus === 'O' ||
          accountStatus === 'P' ||
          accountStatus === 'N'
        ) {
          return false
        }
      }
    }
  }
  return true
}

export const getInvestmentPreferenceAction = (
  currentPortfolio: Portfolio,
  portfolioFormPayload: PortfolioFormPayload
): InvestmentPreferenceAction => {
  const currentInvestmentPreference = currentPortfolio.investmentPreference
  const newInvestmentPreference = portfolioFormPayload.investmentPreference
  let action = InvestmentPreferenceAction.NO_ACTION

  // Compare automation.
  if (
    currentInvestmentPreference?.automation !==
    newInvestmentPreference?.automation
  ) {
    action = InvestmentPreferenceAction.PATCH_AUTOMATION
  }

  // Handle portfolios without finalized investment preferences.
  if (!currentInvestmentPreference) {
    return action === InvestmentPreferenceAction.PATCH_AUTOMATION
      ? InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION
      : InvestmentPreferenceAction.EDIT
  }

  // Compare optimization-related preferences
  // (exlcudes automation).
  for (const [field, newValue] of Object.entries(newInvestmentPreference)) {
    const currentValue =
      currentInvestmentPreference[field as keyof InvestmentPreference]

    // Skip if values are equal.
    if (isEqual(newValue, currentValue) || field === 'automation') {
      continue
    }

    // Changes to optimization-related preferences require an EDIT action.
    // Combine actions if PATCH_AUTOMATION was already set.
    return action === InvestmentPreferenceAction.PATCH_AUTOMATION
      ? InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION
      : InvestmentPreferenceAction.EDIT
  }

  // Compare benchmark IDs, return EDIT if they are different.
  if (currentPortfolio.benchmarkId !== portfolioFormPayload.benchmarkId) {
    // Combine actions if PATCH_AUTOMATION was already set.
    return action === InvestmentPreferenceAction.PATCH_AUTOMATION
      ? InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION
      : InvestmentPreferenceAction.EDIT
  }

  // Compare buy list IDs, return EDIT if they are different.
  if (currentPortfolio.buyListId !== portfolioFormPayload.buyListId) {
    // Combine actions if PATCH_AUTOMATION was already set.
    return action === InvestmentPreferenceAction.PATCH_AUTOMATION
      ? InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION
      : InvestmentPreferenceAction.EDIT
  }

  // Compare asset tradable percentages, return EDIT if they are different.
  const isNewTradablePercentages = getAssetTradablePercentages(
    currentPortfolio.assets,
    portfolioFormPayload.assets
  )
  if (isNewTradablePercentages) {
    // Combine actions if PATCH_AUTOMATION was already set.
    return action === InvestmentPreferenceAction.PATCH_AUTOMATION
      ? InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION
      : InvestmentPreferenceAction.EDIT
  }

  // Compare template IDs.
  if (
    currentPortfolio.investmentPreferencesTemplateId !==
    portfolioFormPayload.investmentPreferencesTemplateId
  ) {
    // Combine actions if PATCH_AUTOMATION was already set.
    action =
      action === InvestmentPreferenceAction.PATCH_AUTOMATION
        ? InvestmentPreferenceAction.PATCH_AUTOMATION_AND_TEMPLATE_ID
        : InvestmentPreferenceAction.PATCH_TEMPLATE_ID
  }

  return action
}

export const getFirstPlaidBrokerageAccount = (portfolio: Portfolio) => {
  const brokerageAccount = portfolio?.brokerageAccounts?.[0]
  if (isEmpty(brokerageAccount?.plaidAccountInfo)) {
    return undefined
  }
  return brokerageAccount
}

export const shouldUpdatePlaidLink = (portfolio: Portfolio) => {
  const plaidAccount =
    getFirstPlaidBrokerageAccount(portfolio)?.plaidAccountInfo
  return (
    plaidAccount?.status === PLAID_ACCOUNT_STATUS.ERROR &&
    plaidAccount?.errorCode === PLAID_ACCOUNT_ERROR_CODE.ITEM_LOGIN_REQUIRED
  )
}

export const calculatePortfolioValue = (
  portfolioCash: number | null,
  portfolioAssets: Asset[]
) => {
  let portfolioValue = portfolioCash || 0
  portfolioAssets.forEach((asset) => {
    if (asset.price) portfolioValue += asset.shares * asset.price
  })
  return portfolioValue
}

export const buildUpdatePreferencesRequest = (
  currentPortfolio: Portfolio,
  updatedPortfolio: PortfolioFormPayload
): UpdatePreferencesRequest => {
  const {
    id,
    investmentPreference: currentInvestmentPreference,
    benchmarkId: currentBenchmarkId,
    buyListId: currentBuyListId,
    investmentPreferencesTemplateId: currentTemplateId,
    assets: currentAssets,
  } = currentPortfolio

  const {
    investmentPreference: updatedInvestmentPreference,
    benchmarkId: updatedBenchmarkId,
    buyListId: updatedBuyListId,
    investmentPreferencesTemplateId: updatedTemplateId,
    assets: updatedAssets,
  } = updatedPortfolio

  const request: UpdatePreferencesRequest = {
    id,
    investmentPreference: getInvestmentPreference(
      currentInvestmentPreference,
      updatedInvestmentPreference
    ),
    benchmarkId: getBenchmarkId(currentBenchmarkId!, updatedBenchmarkId!),
    buyListId: getBuyListId(currentBuyListId, updatedBuyListId),
    investmentPreferencesTemplateId: getInvestmentPreferenceTemplateId(
      currentTemplateId,
      updatedTemplateId
    ),
    assetTradablePercentages: getAssetTradablePercentages(
      currentAssets,
      updatedAssets
    ),
    tradePreference: updatedPortfolio.tradePreference,
  }

  let property: keyof UpdatePreferencesRequest

  for (property in request) {
    if (request[property] === undefined) {
      delete request[property]
    }
  }

  return request
}

const getAssetTradablePercentages = <
  T extends { symbol: string; tradablePercentage: number }
>(
  currentAssets: T[],
  updatedAssets: T[]
): T[] | undefined => {
  const originalMap = keyBy(currentAssets, 'symbol')
  const updatedTradablePercentages = updatedAssets.filter((updatedAsset) => {
    const originalTradablePercentage =
      originalMap[updatedAsset.symbol].tradablePercentage
    return updatedAsset.tradablePercentage !== originalTradablePercentage
  })

  if (updatedTradablePercentages.length === 0) return undefined
  return updatedTradablePercentages
}

const getInvestmentPreference = (
  currentInvestmentPreference: InvestmentPreference | undefined,
  updatedInvestmentPreference: InvestmentPreference
) => {
  // Handle portfolios without finalized investment preferences
  if (!currentInvestmentPreference) {
    return updatedInvestmentPreference
  }
  for (const [field, updatedValue] of Object.entries(
    updatedInvestmentPreference
  )) {
    const currentValue =
      currentInvestmentPreference[field as keyof InvestmentPreference]

    if (!isEqual(currentValue, updatedValue)) {
      return updatedInvestmentPreference
    }
  }
  return undefined
}

const getBenchmarkId = (
  currentBenchmarkId: string,
  updatedBenchmarkId: string
) => {
  if (currentBenchmarkId === updatedBenchmarkId) {
    return undefined
  } else {
    return updatedBenchmarkId
  }
}

const getBuyListId = (
  currentBuyListId: string | null,
  updatedBuyListId: string | null
) => {
  if (currentBuyListId === updatedBuyListId) {
    return undefined
  } else {
    return updatedBuyListId
  }
}

const getInvestmentPreferenceTemplateId = (
  currentInvestmentPreferenceTemplateId: string | null,
  updatedInvestmentPreferenceTemplateId: string | null
) => {
  if (
    currentInvestmentPreferenceTemplateId ===
    updatedInvestmentPreferenceTemplateId
  ) {
    return undefined
  } else {
    return updatedInvestmentPreferenceTemplateId
  }
}

export const getIsAccountDisconnected = (accountStatus?: ACCOUNT_STATUS) => {
  return accountStatus === ACCOUNT_STATUS.DISCONNECTED
}

/**
 * Checks if the portfolio requires a trades update given the suggestedTradesStatus
 * @returns true if the portfolios requires a trades update, false otherwise.
 */
export function getNeedsTradesUpdate(
  suggestedTradesStatus?: SuggestedTradesStatus
): boolean {
  return (
    !!suggestedTradesStatus &&
    NEEDS_TRADES_UPDATE_STATUS.includes(suggestedTradesStatus)
  )
}

export function getIsTradesScheduled(
  tradingStatus?: TradingStatus | null
): boolean {
  return !!tradingStatus && TRADES_SCHEDULED_STATUS.includes(tradingStatus)
}
