import { LinkIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import { LinkedManagementOrganizationAccountResponseDto } from 'types/dto/ManagementOrganization.dto'

import { ReactComponent as SchwabLogo } from 'assets/icons/SCHWAB.svg'
import { Box, Checkbox, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import { DEPRECATEDInteractiveText } from 'components/common/DEPRECATEDInteractiveText'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  account: LinkedManagementOrganizationAccountResponseDto
  handleCheckboxChange: (newSubAccountNumber: string) => void
  isChecked: boolean
}
function ManagementOrganizationAccountListItem({
  account,
  handleCheckboxChange,
  isChecked,
}: Props) {
  const clientAccountNameSection = account?.clientAccount?.displayName ? (
    <Link to={`/account/${account?.clientAccount?.id}`}>
      <DEPRECATEDInteractiveText
        color={COLOR.coolGray600}
        fontSize={FONT_SIZE.fs12}
        fontWeight="400"
      >
        <FlexBox alignItems="center">
          <Box>
            <LinkIcon width="1rem" height="1rem" />
          </Box>
          {account?.clientAccount?.displayName ?? 'None'}
        </FlexBox>
      </DEPRECATEDInteractiveText>
    </Link>
  ) : (
    <>
      <Box width="1rem" height="1rem" color={COLOR.coolGray500}>
        <LinkIcon />
      </Box>
      <Text
        fontWeight="medium"
        fontSize={FONT_SIZE.fs12}
        lineHeight="xs"
        color={COLOR.coolGray700}
      >
        None
      </Text>
    </>
  )
  return (
    <InteractiveCard
      display="flex"
      alignItems="center"
      marginBottom={SPACE.sp16}
      height={SPACE.sp48}
    >
      <FlexBox flex={3} gap={SPACE.sp10} marginTop="xs" overflow="hidden">
        <Checkbox
          width="1rem"
          height="1rem"
          onClick={() => {
            handleCheckboxChange(account.subAccountNumber)
          }}
          checked={isChecked}
        />
        <DEPRECATEDInteractiveText
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs12}
          fontWeight="700"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={account.title}
        >
          {account?.portfolio ? (
            <Link to={`/portfolio/${account?.portfolio?.id}`}>
              {account.title}
            </Link>
          ) : (
            <>{account.title}</>
          )}
        </DEPRECATEDInteractiveText>
      </FlexBox>
      <FlexBox flex={1}>
        <Text
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs12}
          fontWeight="400"
        >
          {account.subAccountNumber}
        </Text>
      </FlexBox>
      <FlexBox flex={1}>
        <Text
          color={COLOR.coolGray600}
          fontSize={FONT_SIZE.fs12}
          fontWeight="400"
        >
          {account.mainAccountNumber}
        </Text>
      </FlexBox>
      <FlexBox flex={1}>
        <FlexBox alignItems="center" gap={SPACE.sp8}>
          <SchwabLogo />
          <Text
            color={COLOR.coolGray600}
            fontSize={FONT_SIZE.fs12}
            fontWeight="400"
          >
            Schwab RIA
          </Text>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={1.5} justifyContent="center">
        {clientAccountNameSection}
      </FlexBox>
    </InteractiveCard>
  )
}
export default ManagementOrganizationAccountListItem
