/**
 * BenchmarkType Enum
 */
export const BenchmarkType = {
  STOCK: 'STOCK',
  MULTI_ASSET: 'MULTI_ASSET',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BenchmarkType = typeof BenchmarkType[keyof typeof BenchmarkType]
