import { WithdrawalCashRequestDto } from 'types'

import { Button, Tag, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import FlexBox from 'components/common/FlexBox/FlexBox'
import CardSection from 'components/common/Section/CardSection'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'

import CashCardPopOver from '../PopOver/CashCardPopOver'

type Props = {
  availableCash?: number
  withdrawalCashRequest?: WithdrawalCashRequestDto
  handleRebalanceCash?: () => void
  handleInitiateWithdrawal?: () => void
  handleWithdrawalComplete?: () => void
  showWithdrawalButton: boolean
  targetCashWeight: number
  currentCashAllocation: number
  recommendedAssets: { [key: string]: any }
  portfolioIsLocked: boolean
  isLoading: boolean
  hasMoneyMarket: boolean
}

function CashCard({
  availableCash,
  withdrawalCashRequest,
  handleRebalanceCash,
  handleInitiateWithdrawal,
  handleWithdrawalComplete,
  showWithdrawalButton,
  targetCashWeight,
  currentCashAllocation,
  recommendedAssets,
  portfolioIsLocked,
  isLoading,
  hasMoneyMarket,
}: Props) {
  const withdrawalCashAmount = withdrawalCashRequest?.cashAmount ?? 0.0
  const cashAllocationDiff = currentCashAllocation - targetCashWeight
  const hasActiveWithdrawal = !!withdrawalCashAmount
  const currentCashAboveTarget = cashAllocationDiff > 0.0

  const disableRebalance =
    hasActiveWithdrawal ||
    recommendedAssets?.length === 0 ||
    portfolioIsLocked ||
    !currentCashAboveTarget ||
    isLoading

  return (
    <Card
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding={SPACE.sp24}
      marginBottom={SPACE.sp20}
      gap={SPACE.sp20}
      border={`1px solid ${COLOR.red600}`}
      borderRadius={SPACE.sp8}
    >
      <FlexBox width="100%" alignItems="center" justifyContent="space-between">
        <Text
          fontSize={FONT_SIZE.fs20}
          fontWeight="bold"
          lineHeight="md"
          color={COLOR.coolGray700}
        >
          Cash {hasMoneyMarket && '& Equivalents'}
        </Text>
        <CashCardPopOver />
      </FlexBox>
      <Card width="100%">
        <FlexBox flexDirection="column" gap={SPACE.sp12}>
          <FlexBox flexDirection="column" gap={SPACE.sp4}>
            <Tag>USD</Tag>
            <Text
              fontSize={FONT_SIZE.fs14}
              color={COLOR.coolGray700}
              fontWeight={500}
            >
              US Dollar
            </Text>
          </FlexBox>
          <FlexBox flexDirection="column" gap={SPACE.sp4}>
            <Text
              fontSize={FONT_SIZE.fs24}
              color={COLOR.coolGray700}
              fontWeight={700}
            >
              {availableCash ? formatAsCurrency(availableCash) : '$0.00'}
            </Text>
          </FlexBox>
          <FlexBox gap={SPACE.sp4} alignItems="center" rowGap={SPACE.sp6}>
            <Text
              fontSize={FONT_SIZE.fs12}
              color={COLOR.coolGray600}
              fontWeight={500}
            >
              {(currentCashAllocation * 100)?.toFixed(2) || '0.00'}%
            </Text>
            {currentCashAboveTarget && (
              <FlexBox gap={SPACE.sp4} alignItems="center" rowGap={SPACE.sp6}>
                <Text
                  fontSize={FONT_SIZE.fs6}
                  color={COLOR.coolGray600}
                  fontWeight={500}
                >
                  &#9679;
                </Text>
                <Text
                  fontSize={FONT_SIZE.fs12}
                  color={COLOR.coolGray600}
                  fontWeight={500}
                >
                  {(cashAllocationDiff * 100)?.toFixed(2)}% over target
                </Text>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </Card>
      {hasActiveWithdrawal && (
        <CardSection borderColor={COLOR.red600} style={{ width: '100%' }}>
          <FlexBox flexDirection="column" gap={SPACE.sp12}>
            <FlexBox flexDirection="column" gap={SPACE.sp4}>
              <Text
                fontSize={FONT_SIZE.fs14}
                color={COLOR.coolGray700}
                fontWeight={500}
              >
                To Be Withdrawn
              </Text>
            </FlexBox>
            <FlexBox flexDirection="column" gap={SPACE.sp4}>
              <Text
                fontSize={FONT_SIZE.fs24}
                color={COLOR.coolGray700}
                fontWeight={700}
              >
                {withdrawalCashAmount
                  ? formatAsCurrency(withdrawalCashAmount)
                  : '$0.00'}
              </Text>
            </FlexBox>
          </FlexBox>
        </CardSection>
      )}

      <FlexBox flexDirection="column" width="100%" gap={SPACE.sp8}>
        <Button
          width="100%"
          size="sm"
          variant="primary"
          onClick={handleRebalanceCash}
          disabled={disableRebalance}
        >
          Invest Excess Cash
        </Button>

        {/* {showWithdrawalButton && (
          <Button
            width="100%"
            size="sm"
            variant={hasActiveWithdrawal ? 'danger' : 'secondary'}
            onClick={
              hasActiveWithdrawal
                ? handleWithdrawalComplete
                : handleInitiateWithdrawal
            }
          >
            {hasActiveWithdrawal
              ? 'Mark Withdrawal Complete'
              : 'Initiate Withdrawal'}
          </Button>
        )} */}
      </FlexBox>
    </Card>
  )
}

export default CashCard
