import { Link } from 'react-router-dom'

import { Box, Button, PasswordInput, Text, TextInput } from 'components/common'

import { FormContainer, FormInputsContainer } from './shared'

type Props = {
  isDisabled?: boolean
  onSubmit: (formData: any) => void
  email: string
  password: string
  setEmail: (email: string) => void
  setPassword: (password: string) => void
}
function SignInForm({
  email,
  password,
  setEmail,
  setPassword,
  onSubmit,
  isDisabled,
}: Props) {
  return (
    <FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit({ email, password })
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="0px"
          gap="24px"
          width="100%"
        >
          <Text
            fontSize="xl"
            fontWeight="bold"
            lineHeight="xl"
            textAlign="center"
            color="black"
          >
            Sign in to your account
          </Text>
          <Text textAlign="center" color="coolGray.500">
            {'Don’t have an account? '}
            <Link to="/signup">Create an account instead</Link>
          </Text>
          <FormInputsContainer>
            <TextInput
              name="email"
              width="100%"
              type="email"
              placeholder="Email Address"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordInput
              name="password"
              width="100%"
              type="password"
              placeholder="Password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormInputsContainer>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding="0px"
            width="100%"
          >
            <Link to="/forgot-password"> Forgot your password?</Link>
            <Box display="none">
              <Link to="/"> Have a company code?</Link>
            </Box>
          </Box>
          <Button
            disabled={isDisabled}
            type="submit"
            variant="primary"
            size="lg"
          >
            Sign In
          </Button>
        </Box>
      </form>
    </FormContainer>
  )
}

export default SignInForm
