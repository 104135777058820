import styled from '@emotion/styled/macro'
import { hideVisually } from 'polished'
import React, { InputHTMLAttributes } from 'react'

type Props = InputHTMLAttributes<HTMLInputElement>

function Switch({ className, checked, ...props }: Props) {
  return (
    <Container className={className}>
      <HiddenCheckbox type="checkbox" checked={checked} {...props} />
      <StyledCheckbox checked={checked} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
`

const HiddenCheckbox = styled.input<Props>`
  ${hideVisually()}
`

const StyledCheckbox = styled.div<Props>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.coolGray[200]};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: '';
    height: 27px;
    width: 27px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${({ theme }) => theme.colors.blue[400]};
    :before {
      transform: translateX(20px);
    }
  }

  ${HiddenCheckbox}:focus + &::before {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  }

  ${HiddenCheckbox}:disabled + & {
    background: ${({ theme }) => theme.colors.coolGray[100]};
    cursor: not-allowed;
    :before {
      box-shadow: none;
    }
  }
`

export default Switch
